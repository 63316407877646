import React, { useContext } from 'react';
import useSWR from 'swr';
import { UserContext } from 'providers/contexts';
import { mmAPI } from 'services/Api';
import { ProductContainer } from 'components';

const Product = ({ product_model_id }) => {
  const { user } = useContext(UserContext);
  const { idToken } = user;

  const { data: product } = useSWR(
    [`/api/product/model/${product_model_id}`, idToken],
    (url, idToken) => {
      return mmAPI(url, idToken);
    },
    {
      suspense: true,
    }
  );

  return (
    <>
      <>{product && <ProductContainer product={product} />}</>
    </>
  );
};

export default Product;

import React, { useContext, useEffect, useState } from 'react';
import useSWR from 'swr';

import AtelierMaterialsUpload from './AtelierMaterialsUpload';
import { UserContext } from 'providers/UserProvider';
import { mmAPI } from 'services/Api';

type AtelierUploadConfig = {
  endpoint: string;
  heading: string;
  model: 'Techpack' | 'Graphics' | 'Materials' | 'References' | 'Final';
};

const AtelierMaterials: React.FC<{
  brand_model_id: string;
  style_model_id: string;
  atelierUploadConfig: AtelierUploadConfig;
  view_type: string;
  view_step: string;
  onSetStepComplete: (arg) => void;
  materialsLibrary?: any[];
  materials?: any[];
}> = props => {
  const { brand_model_id, style_model_id, atelierUploadConfig } = props;
  const { user } = useContext(UserContext);
  const { idToken } = user;
  const { endpoint, heading, model } = atelierUploadConfig;

  const messageFetchIntervalRef = React.useRef(0);
  const [messageFetchIntervalCount, setMessageFetchIntervalCount] = useState(0);

  const { data: materials, mutate: mutateMaterials } = useSWR(
    [endpoint, idToken, style_model_id],
    (url, idToken, style) => {
      return mmAPI(url, idToken, { style });
    },
    {
      suspense: true,
      refreshInterval: messageFetchIntervalRef.current,
      onSuccess: () => {
        setMessageFetchIntervalCount(prev => {
          prev = prev + 1;
          return prev;
        });
      },
    }
  );

  const { data: materialsLibrary, mutate: mutateMaterialsLibrary } = useSWR(
    ['api/material/query/brand', idToken, brand_model_id],
    (url, idToken, brand) => {
      return mmAPI(url, idToken, { brand });
    },
    {
      suspense: true,
    }
  );

  const manageRefreshInterval = React.useCallback(
    messageFetchIntervalCountVal => {
      if (messageFetchIntervalRef.current === 300) {
        console.log('materials', materials);
        console.log('materialsLibrary', materialsLibrary);
        if (
          materials
            ?.filter(
              m =>
                [-1, 1].includes(m?.assets['front']?.progress) &&
                [-1, 1].includes(m?.assets['back']?.progress)
            )
            .every(m => m?.frontImg && m?.backImg)
        ) {
          messageFetchIntervalRef.current = 0;
          setMessageFetchIntervalCount(0);
          console.log(
            'reset setMessageFetchIntervalCount with assetUrls, stop fetch after count',
            messageFetchIntervalCountVal
          );
        } else {
          if (messageFetchIntervalCountVal >= 9) {
            console.log('reset after 9 messageFetchIntervalCount');
            messageFetchIntervalRef.current = 0;
            setMessageFetchIntervalCount(0);
          }
        }
      }

      if (messageFetchIntervalCountVal >= 9) {
        console.log('reset after 9 messageFetchIntervalCount');
        messageFetchIntervalRef.current = 0;
        setMessageFetchIntervalCount(0);
      }
    },
    [materials, materialsLibrary]
  );

  useEffect(() => {
    manageRefreshInterval(messageFetchIntervalCount);
  }, [messageFetchIntervalCount, manageRefreshInterval]);

  return (
    <AtelierMaterialsUpload
      setMessageFetchIntervalCount={setMessageFetchIntervalCount}
      messageFetchIntervalRef={messageFetchIntervalRef}
      materials={materials}
      materialsLibrary={materialsLibrary}
      atelierUploadConfig={atelierUploadConfig}
      mutateMaterials={() => {
        mutateMaterials();
        mutateMaterialsLibrary();
      }}
      {...props}
    />
  );
};

export default AtelierMaterials;

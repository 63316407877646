import { ModalsContext } from 'providers/ModalsProvider';
import * as React from 'react';
import {
  mmAPI,
  postInviteMethodEmail,
  postInviteModel,
  postTeamModel,
} from 'services/Api';
import useSWR, { mutate } from 'swr';

import { ShareModelModal } from 'components/Common/Modal/ShareModelModal';
import { FrameUIActionsTypes, useFrameUI } from 'providers/FrameUIProvider';
import { NotificationsContext } from 'providers/NotificationsProvider';
import { UserContext } from 'providers/UserProvider';

type useSidebarModelParams = {
  brand_model_id: string;
  line_model_id?: string;
  collection_model_id?: string;
  style_model_id?: string;
};
type useSidebarHandlerParams = {
  modalActionHandler: (values, model) => null;
};
type useSidebarReturnParams = { jobType?: any };
type useSidebarReturn = {
  onSidebarOpen: (model: any, filters?: useSidebarReturnParams) => void;
};

const useSidebar = (
  idToken: string,
  { brand_model_id }: useSidebarModelParams,
  { modalActionHandler = null }: useSidebarHandlerParams = { modalActionHandler: null }
): useSidebarReturn => {
  const [sidebarModel, setSidebarModel] = React.useState(null);
  const { state, dispatch } = useFrameUI();
  const {
    setOpenAppModal,
    setAppModal,
    openAppModal,
    setAppModalProps,
  } = React.useContext(ModalsContext);
  const { setDisplayToast } = React.useContext(NotificationsContext);
  const { user } = React.useContext(UserContext);

  const { data: teams } = useSWR(
    brand_model_id ? [`/api/team/query/brand`, idToken, brand_model_id] : null,
    (url, idToken, brand) => {
      return mmAPI(url, idToken, { brand });
    },
    { suspense: false }
  );

  const [sidebarProps, setSidebarProps] = React.useState(null);
  const { data: teamInvites, mutate: mutateInvites } = useSWR(
    sidebarProps?.team
      ? [`/api/invite/query/team`, idToken, sidebarProps.team.key]
      : null,
    (url, idToken, team) => {
      return mmAPI(url, idToken, { team });
    },
    { suspense: false }
  );
  const { data: teamAccounts } = useSWR(
    sidebarProps?.team
      ? [`/api/account/query/team`, idToken, sidebarProps.team.key]
      : null,
    (url, idToken, team) => {
      return mmAPI(url, idToken, { team });
    },
    { suspense: false }
  );

  const findTeamByModelKeyAndJobType = React.useCallback(
    (key, jobType) => {
      const teamMatch = teams?.find(t => {
        const matchingGrants = t.grants.filter(g => g.model_id === parseInt(key));
        return matchingGrants?.length;
      });
      if (teamMatch && teamMatch.account_job_type === jobType) {
        return teamMatch;
      }
    },
    [sidebarModel?.model, sidebarModel?.jobType, idToken, teams, brand_model_id]
  );

  const findTeamByModelKey = React.useCallback(
    async key => {
      const teamMatch = teams?.find(t => {
        const matchingGrants = t.grants.filter(g => g.model_id === parseInt(key));
        return matchingGrants?.length;
      });
      console.log('findTeamByModelKey', teams, key, teamMatch);
      if (teamMatch) {
        return teamMatch;
      }

      if (!teamMatch && sidebarModel.model) {
        const { KIND: model, key: model_id, name: model_name } = sidebarModel.model;
        const grants = JSON.stringify([{ model, model_id, type: 'editor' }]);
        const name = `${model_name} ${model} Team`;

        setDisplayToast({
          type: 'info',
          message: `Creating team: ${name}`,
        });
        const teamAttributes = {
          name,
          grants,
          model: 'Brand',
          model_id: brand_model_id,
          description: name,
        };
        console.log('teamAttributes', teamAttributes);
        const {
          data: { data: teamCreated },
        } = await postTeamModel(idToken, teamAttributes);
        return teamCreated;
      }
    },
    [sidebarModel?.model, idToken, teams, brand_model_id]
  );

  const defaultModalActionHandler = async (values, model) => {
    const [first_name, last_name] = values?.name?.split(' ');
    if (!values?.team) {
      setDisplayToast({ type: 'error', message: `Error sharing ${values.lineName}` });
    } else {
      const { data: invite } = await postInviteModel(idToken, {
        ...values,
        first_name,
        last_name,
        role: 'user',
        job_type: model.KIND === 'Style' ? 'vsm' : 'designer',
      });
      if (invite.data?.error) {
        setDisplayToast({ type: 'error', message: invite.data?.error?.message });
      } else {
        const { data: inviteData } = await postInviteMethodEmail(idToken, {
          key: invite.data.key,
          path: `${process.env.REACT_APP_PUBLIC_URL}/invite`,
        });
        mutateInvites();
        onSidebarOpen(model);
        const { email } = inviteData;
        setDisplayToast({
          type: 'success',
          message: `${email} has been invited to ${values.lineName}`,
        });
      }
    }
  };
  const handleAppModal = (model, team, jobType) => {
    setOpenAppModal(true);
    const modal = (
      <ShareModelModal
        teamJobType={jobType}
        formId={`share${model.KIND}-team-${team.key}`}
        open={openAppModal}
        setOpen={setOpenAppModal}
        btnActionHandler={values => {
          modalActionHandler?.(values, model) || defaultModalActionHandler(values, model);
          setOpenAppModal(false);
        }}
      />
    );
    setAppModal(modal);
    setAppModalProps({
      model,
      team,
      jobTypeDefault: sidebarModel?.jobType,
      btnActionHandler: values => {
        modalActionHandler?.(values, model) || defaultModalActionHandler(values, model);
        setOpenAppModal(false);
      },
    });
  };

  const onSidebarOpen = (model, { jobType = null } = {}) => {
    setSidebarModel({ model, jobType });
    console.log('setSidebarModel', model, jobType);
    dispatch({ type: FrameUIActionsTypes.SIDEBAR_LOADING, payload: true });
  };

  React.useEffect(() => {
    const sidebarModelEffect = async (sidebarModelVal, sidebarLoading, idToken) => {
      if (!sidebarModelVal || !sidebarLoading) return;
      const { model, jobType } = sidebarModelVal;
      console.log('sidebarModelVal', sidebarModelVal);

      let team = null;
      if (model && !jobType) {
        team = await findTeamByModelKey(model.key);
        console.log('team', team);
      } else if (model && jobType) {
        team = await findTeamByModelKeyAndJobType(model.key, jobType);
      }
      mutate([`/api/account/query/team`, idToken, team.key]);
      mutate([`/api/invite/query/team`, idToken, team.key]);
      const modelProps = {
        model: model,
        heading: model.name,
        type: model.KIND?.toLowerCase(),
        team,
        handleAppModal: () => {
          handleAppModal(model, team, jobType);
        },
        onClose: () => {
          mutate([`/api/account/query/team`, idToken, team.key], () => null, false);
          mutate([`/api/invite/query/team`, idToken, team.key], () => null, false);
        },
      };
      console.log('sidebarModelEffect modelProps', modelProps);
      setSidebarProps(prev => ({
        ...prev,
        ...modelProps,
      }));
    };
    sidebarModelEffect(sidebarModel, state.sidebar.loading, idToken);
  }, [state.sidebar.loading, sidebarModel, idToken]);

  React.useEffect(() => {
    const sidebarPropsEffect = sidebarPropsVal => {
      if (sidebarPropsVal) {
        dispatch({ type: FrameUIActionsTypes.SIDEBAR_OPEN, payload: true });
        console.log('sidebarPropsVal', sidebarPropsVal);
        dispatch({
          type: FrameUIActionsTypes.SIDEBAR_PROPS,
          payload: {
            ...sidebarPropsVal,
          },
        });
      }
    };
    sidebarPropsEffect(sidebarProps);
  }, [sidebarProps]);

  React.useEffect(() => {
    const sidebarModelEffect = (
      sidebarLoading,
      sidebarInvitesVal,
      sidebarAccountsVal
    ) => {
      if (sidebarInvitesVal && sidebarAccountsVal) {
        setSidebarProps(prevState => ({
          ...prevState,
          invites: sidebarInvitesVal
            .filter(i => i.status === 'pending')
            .sort((a, b) => a.created - b.created)
            .map(({ first_name: firstName, last_name: lastName, token }) => ({
              firstName,
              lastName,
              inviteUrl: `${process.env.REACT_APP_PUBLIC_URL}/invite/?token=${token}`,
            })),
          members: sidebarAccountsVal.map(
            ({ first: firstName, last: lastName, assets }) => ({
              firstName,
              lastName,
              profileAsset: assets?.profile_picture,
            })
          ),
        }));
        dispatch({ type: FrameUIActionsTypes.SIDEBAR_LOADING, payload: false });
      }
    };
    sidebarModelEffect(state.sidebar?.loading, teamInvites, teamAccounts);
  }, [teamInvites, teamAccounts, state.sidebar?.loading]);

  return { onSidebarOpen };
};

export default useSidebar;

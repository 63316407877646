import React, { useState, useEffect } from 'react'
import { PersonalProfileSection } from 'components';
import { ModalsContext } from 'providers/ModalsProvider';

const ViewTeamProfile = props => {
  const { member, setModalOpen, setActiveModalComponent } = props;
  const [formData, setFormData] = useState();
  const { selectedMember } = React.useContext(ModalsContext);

  useEffect(() => {
    setFormData(member?.find(member => member.key === selectedMember));
  }, []);

  return (
    <div
      className='modal modal--regular'
      onClick={() => {
        setModalOpen(false), setActiveModalComponent(null);
      }}
    >
      <div
        className='modal__content'
        style={{ background: '#ffff', padding: '50px', width: '52.5%' }}
      >
        {formData !== undefined && (
          <PersonalProfileSection formData={formData} memberProfile={true} />
        )}
      </div>
    </div>
  );
};

export default ViewTeamProfile
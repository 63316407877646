import React from 'react';
import Tooltip from './Tooltip';
export interface SidebarItemProps {
  header?: string;
  tooltip?: string;
  baseName?: string;
}

const SidebarItem: React.FC<SidebarItemProps> = ({
  header,
  tooltip,
  baseName,
  children,
}) => (
  <div className={`${baseName}-wrapper`}>
    {header &&
      (tooltip ? (
        <div className='sidebar__wrap'>
          <div className='sidebar__info'>
            <div className='sidebar__title'>
              <div className={`${baseName}-heading color-black`}>{header}</div>
              <Tooltip tooltipTxt={tooltip} />
            </div>
          </div>
        </div>
      ) : (
        <div className={`${baseName}-heading`}>{header}</div>
      ))}
    <div className={baseName}>{children}</div>
  </div>
);

SidebarItem.defaultProps = {
  baseName: 'sidebar__item',
};

export default SidebarItem;

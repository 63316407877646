import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import './ImageCropModal.scss';
import { getCroppedImg } from 'utils/image';
import CloseIcon from 'assets/icons/close.svg';
import UploadIcon from 'assets/icons/Icon-upload.svg';
import { ModalsContext } from 'providers/ModalsProvider';

interface CROP {
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  aspect: 1;
}

type ImageCropModalProps = {
  srcFile: File;
  btnActionHandler?: (values) => void;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ImageCropModal = (props: ImageCropModalProps) => {
  const { setAppModalProps, setAppModal } = React.useContext(ModalsContext);
  const handleUploadButtonClicked = e => {
    const [imageFile] = e.target.files;
    if (!imageFile) {
      return;
    }

    setAppModalProps(prev => ({
      ...prev,
      srcFile: imageFile,
    }));
    setImageFile(imageFile);
  };

  const { srcFile, btnActionHandler, open, setOpen } = props;
  const [imageFile, setImageFile] = useState(srcFile);
  const [imageUrl, setImageUrl] = useState('');
  const [imageRef, setImageRef] = useState(undefined);
  const [cropArea, setCropArea] = useState<CROP>({ aspect: 1 });
  const [cropStatus, setCropStatus] = useState('original');

  const CROP_AREA_VALID = cropArea && cropArea.width && cropArea.height;

  useEffect(() => {
    setCropStatus('original');
  }, [srcFile]);

  useEffect(() => {
    setCropArea(prev => ({
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      aspect: 1,
    }));
    setImageUrl(prev => URL.createObjectURL(imageFile));
  }, [imageFile]);

  const doCropImage = async () => {
    try {
      const croppedImageBlob: Blob = (await getCroppedImg(imageRef, cropArea)) as Blob;

      const croppedFile = new File([croppedImageBlob], 'image.jpeg');
      setImageFile(prev => croppedFile);
      setCropStatus('cropped');

      return croppedFile;
    } catch (e) {
      console.log('Crop error:', e.message);
    }
  }

  const onCrop = async () => {
    if (!CROP_AREA_VALID) {
      alert('Select crop area');
      return;
    }
    await doCropImage();
  };

  const onReset = () => {
    if (cropArea.width && cropArea.height) {
      setCropArea(prev => ({
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        aspect: 1,
      }));
      setCropStatus('original');
    } else if (imageFile) {
      setImageFile(prev => srcFile);
      setCropStatus('original');
    }
  };

  const onSave = async () => {
    setOpen(false);
    if (CROP_AREA_VALID) {
      const croppedFile = await doCropImage();
      btnActionHandler?.(croppedFile);
    } else {
      btnActionHandler?.(imageFile);
    }
  };

  const onClose = () => {
    setOpen(false);
    setAppModal(null);
  }

  return (
    <>
      <CSSTransition
        in={open}
        classNames='image-crop-modal'
        timeout={300}
        unmountOnExit
        onExited={onClose}
      >
        <div className='modal modal--rounded'>
          <div className='modal__content'>
            <div className='modal__header'>
              <h2 className='modal__title'>Image Crop</h2>
              <img src={CloseIcon} alt='Close' onClick={onClose} />
            </div>
            <div className='modal__inner' style={{ backgroundColor: '#fff' }}>
              <ReactCrop
                circularCrop={true}
                imageStyle={{ width: '100%', height: '100%' }}
                src={imageUrl}
                crop={cropArea}
                onImageLoaded={setImageRef}
                onChange={arg => {
                  if (cropArea.width !== 0 && cropArea.height !== 0) {
                    setCropStatus('cropped');
                  }
                  setCropArea(arg);
                }}
              />
            </div>
            <div className='modal__footer'>
              <div className='image-upload button__replace-file__container button-transparent-gray button-smaller'>
                <label htmlFor={'profile_picture'} className='button__replace-file'>
                  <img className='btn-upload-img' src={UploadIcon} alt='Upload' />
                  Replace
                </label>
                <input
                  id={'profile_picture'}
                  type='file'
                  onChange={handleUploadButtonClicked}
                  onClick={event => ((event.target as HTMLInputElement).value = null)}
                  name={'profile_picture'}
                />
              </div>
              {(cropStatus === 'cropped' || (!!cropArea.width && !!cropArea.height)) && (
                <button
                  className='button-transparent-gray button-smaller'
                  onClick={onReset}
                >
                  Reset
                </button>
              )}
              <button className='button-transparent-gray button-smaller' onClick={onCrop}>
                Crop
              </button>
              <button className='button-primary button-smaller' onClick={onSave}>
                Save
              </button>
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  );
};

import React, { ReactNode, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Switch from 'components/Common/Switch/Switch';
import Modal from './Modal';
import { CreateModelModalProps } from './CreateModelModal';

export const CreateBrandModal = (props: CreateModelModalProps) => {
  const {
    formId,
    modelName,
    modelInputLabel,
    modalHeading = 'Add New',
    btnActionLabel = 'save',
    btnCloseLabel = 'cancel',
    btnActionHandler,
    open,
    setOpen,
    closeOnSubmit = false,
    data,
  } = props;

  const [assetLibraryEnabled, setAssetLibraryEnabled] = useState(false);

  const { handleSubmit, register } = useForm();
  const onSubmit = values => {
    btnActionHandler?.({
      ...values,
      preferences: JSON.stringify({
        ...values?.preferences,
        asset_library: assetLibraryEnabled,
      }),
      data,
    });
    closeOnSubmit && setOpen(false);
  };
  return (
    <Modal
      modalHeading={`${modalHeading} ${modelName}`}
      modalBtnClose={btnCloseLabel}
      modalBtnActionLabel={btnActionLabel}
      backgroundColor='#fff'
      modalOpen={open}
      setModalOpen={setOpen}
      formHandlerId={formId}
    >
      <form id={formId} onSubmit={handleSubmit(onSubmit)}>
        <div className='control-block'>
          <label className='control--label control--required'>{modelInputLabel}</label>
          <input
            ref={register}
            name={`name`}
            required
            className='control control--text'
            type='text'
          />
        </div>
      </form>
      <Switch label={'Asset Library'} onSwitchChange={setAssetLibraryEnabled} />
    </Modal>
  );
};

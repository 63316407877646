import React from 'react';
import { ISteps } from 'models/Dashboard/ISteps';
import './SideNavigation.scss';
import { Link } from '@reach/router';

export interface ISideNavigationSteps {
  steps: ISteps[];
  currentStep?: ISteps;
  stepsButtonsVisibility?: boolean;
  buttonPrimaryLabel?: string;
  buttonPrimaryHandler?: () => void;
  buttonSecondaryLabel?: string;
  buttonSecondaryHandler?: (params) => void;
}

const SideNavigation: React.FC<ISideNavigationSteps> = props => {
  const {
    steps,
    stepsButtonsVisibility = true,
    currentStep,
    buttonPrimaryLabel,
    buttonPrimaryHandler,
    buttonSecondaryLabel,
    buttonSecondaryHandler,
  } = props;


  return (
    <div className='side-navigation'>
      <div className='side-navigation__list'>
        {steps &&
          steps.map(({ title, url, completed, inProgress }, idx) => (
            <div
              className={
                completed
                  ? 'side-navigation__item completed'
                  : inProgress
                    ? 'side-navigation__item in-progress'
                    : 'side-navigation__item not-started'
              }
              key={idx}
            >
              <span className='side-navigation__step'>{idx + 1}</span>
              <Link to={url ?? ''} className='side-navigation__a'>
                {title}
              </Link>
            </div>
          ))}
      </div>

      {steps && (currentStep?.stepsButtonVisibility || stepsButtonsVisibility) && (
        <div className='side-navigation__controls'>
          <button
            onClick={buttonSecondaryHandler || null}
            className='button-transparent-black mr-3'
          >
            {buttonSecondaryLabel || 'Next'}
          </button>
          <button
            onClick={buttonPrimaryHandler || currentStep?.buttonPrimaryHandler || null}
            className='button-primary btn-large'
          >
            {buttonPrimaryLabel || 'Save'}
          </button>
        </div>
      )}
    </div>
  );
};

export default SideNavigation;

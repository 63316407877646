import React, { useEffect, useState, useContext } from 'react';
import { useParams } from '@reach/router';
import { Controller, useForm } from 'react-hook-form';
import ManageIcon from 'assets/icons/manage-icon.svg';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import './CollectionAccessModal.scss';
import Modal from 'components/Common/Modal/Modal';
import { IPopUpOptions } from 'models/Dashboard/IPopUpOptions';
import { ProfileAssetCircle } from 'components/Dashboard/SidebarInfo/SidebarInfo';
import { NotificationsContext } from 'providers/NotificationsProvider';
import {
  deleteTeamMethodRemoveMember,
  postInviteMethodEmail,
  postInviteModel,
} from 'services/Api';
import { UserContext } from 'providers/UserProvider';
import { ConditionalWrap } from 'components/ConditionalWrap';
import useTeam, { JobType } from 'hooks/useTeam';
import classNames from 'classnames';
import PopUp from 'components/Common/PopUp/PopUp';
import { ModalsContext } from 'providers/ModalsProvider';
import { mutate } from 'swr';
import LogRocket from 'logrocket';

export type ModelMember = {
  firstName: string;
  lastName: string;
  email: string;
  accessLevel: string;
  profileAsset?: string;
  inviteUrl?: string;
  key: string;
  created: number;
};

export type ModelAccessModal = {
  heading: string;
  allMembers?: ModelMember[];
  allInvites?: ModelMember[];
  open: boolean;
  model?: any;
  jobType?: JobType;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

const ModelAccessModal: React.FC<ModelAccessModal> = props => {
  const {
    model,
    heading,
    allMembers = [],
    allInvites = [],
    open,
    setOpen,
    jobType,
  } = props;

  const { brand_model_id } = useParams();
  const [accountType, setAccountType] = useState();
  const {
    user: { idToken },
  } = useContext(UserContext);

  const { team, setTeamModelAssoc, mutateAll, teamModelAssoc } = useTeam(idToken, {
    brand_model_id,
  });

  const [allUsers, setAllUsers] = useState(null);
  useEffect(() => {
    const membersInviteesAggregate = allMembers
      ?.concat(allInvites)
      .sort((a, b) => b.created - a.created);
    setAllUsers(membersInviteesAggregate);
  }, [allMembers, allInvites]);

  const { handleSubmit, register, setValue, control, errors } = useForm({});
  const [openPopUp, setOpenPopUp] = useState(false);

  const { setDisplayToast } = useContext(NotificationsContext);

  const InviteGrantTrigger = {
    immediate: 'immediate',
    accept: 'accept',
  };

  const memberInviteRequest = async (values, model) => {
    const { email, first_name, team, modelName, account_type } = values;
    if (!team || !teamModelAssoc.jobType) {
      setDisplayToast({
        type: 'error',
        message: `Error granting access to ${modelName || ''}`,
      });
    } else {
      try {
        const services = `${account_type},`;
        const { data: invite } = await postInviteModel(idToken, {
          email: email.trim(),
          team,
          first_name,
          role: 'user',
          services,
          job_type: teamModelAssoc.jobType,
          grant_trigger: InviteGrantTrigger.immediate,
        });
        if (invite.data?.error || invite.error) {
          setDisplayToast({
            type: 'error',
            message: invite.data?.error?.message || invite.error?.message,
          });
        } else {
          const { data: inviteData } = await postInviteMethodEmail(idToken, {
            key: invite.data.key,
            path: `${process.env.REACT_APP_PUBLIC_URL}/invite`,
          });
          LogRocket.track('inviteRequest', {
            accountType,
            email,
            first_name,
            team,
            modelName,
          });
          const { link } = inviteData;
          setOpen(false);
          setDisplayToast({
            type: 'success',
            message: `${email} has been invited to ${modelName}`,
          });

          mutateAll();

          return {
            [email]: {
              invite: invite?.data,
              inviteEmail: inviteData,
              link,
            },
          };
        }
      } catch (error) {
        if (error?.message) {
          setDisplayToast({
            type: 'error',
            message: error.message,
          });
        }
      }
    }
  };

  const onSubmit = values => {
    values.account_type = accountType;
    console.log('memberInviteRequest', values, model);
    memberInviteRequest?.(values, model);
  };

  const NameEmailSelectProps = props => {
    return <CreatableSelect {...props} isClearable components={{ SingleValue }} />;
  };

  const SingleValue = ({ children, ...props }) => {
    const [emailValue, setEmailValue] = useState('');
    useEffect(() => {
      const nameEmailChildrenEffect = nameEmailVal => {
        if (!nameEmailVal) return;
        const nameEmailSeparatorIndex = nameEmailVal.lastIndexOf('<') + 1;
        if (nameEmailSeparatorIndex > 0) {
          const name = nameEmailVal.substring(0, nameEmailSeparatorIndex - 1);
          setValue('first_name', name, { shouldValidate: true });
        }
        const email = nameEmailVal?.substring(
          nameEmailSeparatorIndex,
          nameEmailSeparatorIndex > 0 ? nameEmailVal.length - 1 : nameEmailVal.length
        );
        setEmailValue(email);
      };
      nameEmailChildrenEffect(children);
    }, [children]);
    return <components.SingleValue {...props}>{emailValue}</components.SingleValue>;
  };

  const accessModalItemOptions: IPopUpOptions[] = [
    {
      selectItem: 'Revoke',
    },
  ];

  useEffect(() => {
    setTeamModelAssoc(prev => ({
      ...prev,
      model,
    }));
  }, [model]);

  useEffect(() => {
    setTeamModelAssoc(prev => ({
      ...prev,
      jobType,
    }));
  }, [jobType]);

  const [selectedItem, setSelectedItem] = useState(null);
  const deleteTeamMemberActionHandler = async ({ member, team }) => {
    const { key } = member;
    try {
      let response = null;
      if (team?.key) {
        response = await deleteTeamMethodRemoveMember({
          account: key,
          team: team?.key,
        });
        mutate([`/api/account/query/team/`, team?.key]);
        mutate([`/api/account/query/brand/`, brand_model_id]);
        setDisplayToast({
          type: 'success',
          message: `Team member${
            member?.email ? ' ' + member?.email + ' ' : ''
          }has been successfully deactivated.`,
        });
      } else {
        setDisplayToast({
          type: 'error',
          message: `Unable to fetch team. Please contact an admin or service owner`,
        });
      }
    } catch (e) {
      setDisplayToast({
        type: 'error',
        message: e?.response?.data?.error?.message || e.toString(),
      });
    }
  };

  const { setOpenDialog, setDialogProps } = useContext(ModalsContext);

  return (
    <div className='collection-access-modal'>
      <Modal
        modalHeading={heading}
        backgroundColor='#fff'
        modalOpen={open}
        setModalOpen={setOpen}
        modalSubheading={``}
        closeOnClickAway={false}
        modalBtnActionHandler={() => setOpen(false)}
      >
        <form id={`#modelAccessModal-${model?.key}`} onSubmit={handleSubmit(onSubmit)}>
          <input type='hidden' name='team' value={team?.key} ref={register({})} />
          <input type='hidden' name='modelName' value={model?.name} ref={register({})} />
          <input type='hidden' name='modelKey' value={model?.key} ref={register({})} />
          <div className='collection-access-modal__label'>
            <label className='control--label control'>Add Member</label>
          </div>
          <label className='control--label control--required'>First Name</label>
          <input
            ref={register({
              required: 'This field is required',
              validate: value => {
                return (
                  [/^[A-Za-zÀ-ÖØ-öø-ÿZ0-9-_ ]+$/].every(pattern => pattern.test(value)) ||
                  'Invalid First Name. Alphanumeric characters expected.'
                );
              },
            })}
            name='first_name'
            className='control control--text'
            placeholder='First Name'
            type='text'
          />
          {errors?.first_name && (
            <div className='error-text'>{errors?.first_name?.message}</div>
          )}
          <label className='control--label control--required'>Email</label>

          <input
            ref={register({
              required: 'This field is required',
              validate: value => {
                return (
                  [/^[\w-.]+(?:\+([\w-.]+))?@([\w-]+\.)+[\w-]{2,4}$/].every(pattern =>
                    pattern.test(value)
                  ) || 'Invalid Email.'
                );
              },
            })}
            name='email'
            className='control control--text'
            placeholder='Email'
            type='text'
          />
          {errors?.first_name && (
            <div className='error-text'>{errors?.email?.message}</div>
          )}
          <br />
          <br />
          <label className='control--label control--required'>Choose Account Type</label>
          <div>
            <div className='collection-access-modal__label'>
              {/* @ts-ignore */}
              <input
                type='radio'
                value='union'
                name='account_type'
                style={{ marginRight: '5px' }}
                checked={accountType === 'union'}
                onChange={e => setAccountType(e.target.value)}
                required
              />
              <label className='control--label control'>Union Account</label>
            </div>
            <br />
            <div className='collection-access-modal__label'>
              {/* @ts-ignore */}
              <input
                type='radio'
                value='agency'
                name='account_type'
                style={{ marginRight: '5px' }}
                checked={accountType === 'agency'}
                onChange={e => setAccountType(e.target.value)}
                required
              />
              <label className='control--label control'>Agency Account</label>
            </div>
            <br />
            <div className='collection-access-modal__label'>
              {/* @ts-ignore */}
              <input
                type='radio'
                value='athlete'
                name='account_type'
                style={{ marginRight: '5px' }}
                checked={accountType === 'athlete'}
                onChange={e => setAccountType(e.target.value)}
                required
              />
              <label className='control--label control'>Athlete Account</label>
            </div>
            <br />
            <div className='collection-access-modal__label'>
              {/* @ts-ignore */}
              <input
                type='radio'
                value='talent'
                name='account_type'
                style={{ marginRight: '5px' }}
                checked={accountType === 'talent'}
                onChange={e => setAccountType(e.target.value)}
                required
              />
              <label className='control--label control'>Talent Account</label>
            </div>
            <br />
            <div className='collection-access-modal__label'>
              {/* @ts-ignore */}
              <input
                type='radio'
                value='brand'
                name='account_type'
                style={{ marginRight: '5px' }}
                checked={accountType === 'brand'}
                onChange={e => setAccountType(e?.target?.value)}
                required
              />
              <label className='control--label control'>Brand Account</label>
            </div>
          </div>
          {errors?.account_type && (
            <div className='error-text'>{errors?.account_type?.message}</div>
          )}
          <div className='collection-access-modal__add-btn'>
            <button
              type='submit'
              disabled={!team?.key}
              className={classNames('button-primary button-wider', {
                disabled: !team?.key,
              })}
            >
              Add
            </button>
          </div>

          <div className='collection-access-modal__members'>
            {allUsers &&
              allUsers.map((user, idx) => {
                const {
                  accessLevel,
                  inviteUrl,
                  email,
                  firstName,
                  lastName,
                  profileAsset,
                  key,
                } = user;
                const userAccessModalItemOptions = inviteUrl
                  ? [{ selectItem: `${accessLevel}` }, ...accessModalItemOptions]
                  : [...accessModalItemOptions];

                return (
                  <div className='collection-access-modal__member' key={key}>
                    <div className='collection-access-modal__member-name'>
                      {profileAsset?.progress > 0 ? (
                        <ProfileAssetCircle
                          {...user}
                          imgClassName='collection-access-modal__member-photo'
                        />
                      ) : (
                        <span className='collection-access-modal__member-photo'>
                          {firstName?.slice(0, 1)}
                          {lastName?.slice(0, 1)}
                        </span>
                      )}
                      {firstName} {lastName}
                    </div>
                    <ConditionalWrap
                      condition={inviteUrl}
                      wrap={children => (
                        <CopyToClipboard
                          text={inviteUrl}
                          key={inviteUrl?.split('token')?.[1]}
                        >
                          {children}
                        </CopyToClipboard>
                      )}
                    >
                      <div className='collection-access-modal__member-email'>{email}</div>
                    </ConditionalWrap>
                    <div className='collection-access-modal__member-access'>
                      <button className='button-select'>
                        {inviteUrl ? 'Invited' : `${jobType}`}
                      </button>
                      <img
                        className='manage-team__dots'
                        onClick={() => {
                          setOpenPopUp(true);
                          setSelectedItem(key);
                        }}
                        src={ManageIcon}
                        alt='Manage User'
                      />
                      <PopUp open={openPopUp && selectedItem === user.key}>
                        {accessModalItemOptions &&
                          accessModalItemOptions.map(({ selectItem }) => (
                            <li
                              className='pop-up__li'
                              key={selectItem}
                              onClick={() => {
                                setOpen(false);
                                setDialogProps({
                                  dialogBody:
                                    'Are you sure you would like to revoke access for this team member?',
                                  btnActionHandler: () => {
                                    deleteTeamMemberActionHandler({
                                      member: user,
                                      team: team,
                                    });
                                  },
                                });
                                setOpenDialog(true);
                              }}
                            >
                              {selectItem}
                            </li>
                          ))}
                      </PopUp>
                    </div>
                  </div>
                );
              })}
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default ModelAccessModal;

import React from 'react';
import './ModalTemplate.scss';
import Video from 'components/Video/Video';
import { Asset } from 'components';

import CloseIcon from 'assets/icons/close-white.svg';
const localVideo1 = process.env.PUBLIC_URL + '/videos/video-template-1.mp4'; //local for now

export interface IModalTemplate {
  logo?: string;
  brand?: any;
  saveImages?: boolean;
  onUploadHandler?: () => Promise<void>;
  edit?: boolean;
  upload?: boolean;
  fontColor?: string;
  cta?: string;
  headline?: string;
  playtxt?: string;
  handleUploadButtonClicked?: (e) => void;
}

const ModalTemplate: React.FC<IModalTemplate> = props => {
  const {
    logo,
    brand,
    saveImages,
    onUploadHandler,
    edit = false,
    upload,
    fontColor,
    cta,
    headline,
    playtxt,
    handleUploadButtonClicked,
  } = props;

  return (
    <div className='modal-template modal-template--overlay'>
      <div className='modal-template__header'></div>
      <div className='modal-template__item'>
        <Asset
          asset={['image_0', brand?.assets?.image_0]}
          saveImages={saveImages}
          onUploadHandler={onUploadHandler}
          upload={upload}
          onDropHandler={handleUploadButtonClicked}
        >
          <Video
            ctaUrl='#'
            // thumbnailImage={localThumbnail1}
            videoPath={localVideo1}
            darkMode={true}
            cta={cta}
            headline={headline}
            playtxt={playtxt}
            playIconBottom={true}
            playIconCentered={false}
            playerControls={false}
            aspectRatio='16-7'
            autoPlay={false}
            edit={edit}
            fontColor={fontColor}
            handleUploadButtonClicked={handleUploadButtonClicked}
          />
        </Asset>
      </div>
      <div className='modal-template__item'>
        <Asset
          asset={['image_1', brand?.assets?.image_1]}
          saveImages={saveImages}
          onUploadHandler={onUploadHandler}
          onDropHandler={handleUploadButtonClicked}
        >
          <Video
            ctaUrl='#'
            // thumbnailImage={localThumbnail2}
            videoPath={localVideo1}
            darkMode={true}
            cta={cta}
            headline={headline}
            playtxt={playtxt}
            playIconBottom={true}
            playIconCentered={false}
            playerControls={false}
            aspectRatio='16-7'
            autoPlay={false}
            edit={edit}
            fontColor={fontColor}
            handleUploadButtonClicked={handleUploadButtonClicked}
          />
        </Asset>
      </div>
      <div className='modal-template__item'>
        <Asset
          asset={['image_2', brand?.assets?.image_2]}
          saveImages={saveImages}
          onDropHandler={handleUploadButtonClicked}
        >
          <Video
            ctaUrl='#'
            // thumbnailImage={localThumbnail3}
            videoPath={localVideo1}
            darkMode={true}
            cta={cta}
            headline={headline}
            playtxt={playtxt}
            playIconBottom={true}
            playIconCentered={false}
            playerControls={false}
            aspectRatio='16-7'
            autoPlay={false}
            edit={edit}
            fontColor={fontColor}
            handleUploadButtonClicked={handleUploadButtonClicked}
          />
        </Asset>
      </div>
    </div>
  );
};

export default ModalTemplate;

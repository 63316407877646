export const DEPARTMENTS = {
  CREATIVE: 'Creative',
  PM: 'Product Management',
  WHOLESALE: 'Wholesale Management',
  RETAILOPS: 'Retail Operations',
  FINANCE: 'Finance',
  SALES: 'Sales',
  MARKETING: 'Marketing and Digital',
  CUSTOMERSERVICE: 'Customer Service',
  VISUALMERCHANDISING: 'Visual and Merchandising',
  DISTRIBUTION: 'Distribution',
  SHIPPING: 'Shipping and Delivery',
  IT: 'IT',
  HT: 'HR',
  PURCHASING: 'Purchasing',
  MANAGEMENT: 'Management',
};

import { useParams } from '@reach/router';
import React from 'react';
import './ProductMedia.scss';
import ProductMediaThumb from './ProductMediaThumb';
import DragAndDrop from 'components/Upload/DragAndDrop';
import { NotificationsContext } from 'providers/NotificationsProvider';
import { postMediaMethodDetach } from 'services/Api';
import { UserContext } from 'providers/UserProvider';
import classNames from 'classnames';

export type Media = {
  key?: number;
  src: string;
  name: string;
  created?: number;
};

export type ProductMedia = {
  media: Media[];
  onDeleteMedia?: any;
  mutateBrandMedia?: any;
  mutateListingData?: any;
};

const ProductMedia: React.FC<ProductMedia> = props => {
  const { media, onDeleteMedia, mutateBrandMedia, mutateListingData } = props;

  const onDragStart = e => {
    const imageUrl = e.target.src;
    e.dataTransfer.setData('text/uri-list', imageUrl);
    e.dataTransfer.setData('text/plain', imageUrl);
    e.dataTransfer.setData('text/html', e.target.outerHTML); // "<p> Hello World </p>"
    e.dataTransfer.setData('mediakey', e.target.dataset.mediakey);
    e.dataTransfer.setData('medianame', e.target.dataset.medianame);
  };

  const { brand_model_id } = useParams();
  const { user } = React.useContext(UserContext);
  const { idToken } = user;
  const { setDisplayToast } = React.useContext(NotificationsContext);

  const onDragEnterHandler = e => {
    console.log('enter highlight');
    setDropTargetActive(true);
  };
  const onDragLeaveHandler = e => {
    setDropTargetActive(false);
  };
  const onDropDetachedHandler = async e => {
    const mediaDragged = e.dataTransfer;

    const mediaDraggedListingKey = mediaDragged.getData('listingkey');
    const mediaDraggedListingName = mediaDragged.getData('listingname');

    if (!mediaDraggedListingKey) return;

    const mediaDraggedKey = mediaDragged.getData('mediakey');
    const mediaDraggedName = mediaDragged.getData('medianame');
    try {
      await postMediaMethodDetach(idToken, {
        media: mediaDraggedKey,
        brand: brand_model_id,
        name: mediaDraggedName,
      });
      setDisplayToast({
        type: 'info',
        message: `Media association has been removed from this ${mediaDraggedListingName}.`,
      });
    } catch (e) {
      setDisplayToast({
        type: 'error',
        message: `Media association could not be removed from this ${mediaDraggedListingName}.`,
      });
    }
    setDropTargetActive(false);
    mutateBrandMedia?.();
    mutateListingData?.();
  };

  const [dropTargetActive, setDropTargetActive] = React.useState(false);
  return (
    <div
      className={classNames('customize-sign-off__block customize-sign-off__scrollable', {
        'color-active-bg': dropTargetActive,
      })}
    >
      <DragAndDrop
        onDropHandler={onDropDetachedHandler}
        onDragEnterHandler={onDragEnterHandler}
        onDragLeaveHandler={onDragLeaveHandler}
      >
        <div className='product-media'>
          <div className='product-media__title'>Product Media</div>
          <div className='product-media__txt'>
            Drag, drop, and save media to the corresponding product.
          </div>
          <div className='product-media__block-right' style={{ position: 'relative' }}>
            {media &&
              media
                .sort((a, b) => b.created - a.created)
                .map(({ src, name, key }) => (
                  <ProductMediaThumb
                    key={key}
                    metadata={{ src, name, key, onDragStart }}
                    onDeleteMedia={onDeleteMedia}
                  />
                ))}
          </div>
        </div>
      </DragAndDrop>
    </div>
  );
};

export default ProductMedia;

import React from 'react';
import Loader from 'components/Loader/Loader';

export const LoadingAlert = ({ children }) => {
  return (
    <div className='flex flex-col items-center'>
      {children || <Loader active={true} />}
    </div>
  );
};

export default LoadingAlert;

import React, { useEffect, useContext, useCallback, useMemo } from 'react';
import { navigate } from '@reach/router';
import useSWR, { mutate } from 'swr';
import { UserContext, NotificationsContext, ViewModeContext } from 'providers/contexts';
import { VIEWMODE } from 'providers/ViewModeProvider';
import { mmAPI, apiBrandModel, getUserPermission } from 'services/Api';
import { ConfirmDialog } from 'components';
import { CreateBrandModal } from 'components/Common/Modal';
import Breadcrumbs from 'components/Common/Breadcrumbs/Breadcrumbs';
import ModelsList from 'components/Dashboard/Collections/CollectionsList/ModelsList';
import { useFrameUI, FrameUIActionsTypes } from 'providers/FrameUIProvider';

const Brands = () => {
  const { user, setUser } = useContext(UserContext);
  const { setDisplayToast } = useContext(NotificationsContext);
  const { viewMode, setViewMode, setViewsList } = useContext(ViewModeContext);
  const { idToken } = user;

  const pages = [{ title: 'Brands', link: `/brands` }];

  const { data: brands } = useSWR(
    user.account ? ['/api/brand/query/user/', idToken] : null,
    mmAPI,
    { suspense: true }
  );

  useEffect(() => {
    const viewModeEffect = () => {
      setViewsList([VIEWMODE.LIST, VIEWMODE.CARD]);
      if (![VIEWMODE.LIST.key, VIEWMODE.CARD.key].includes(viewMode)) {
        setViewMode(VIEWMODE.LIST.key);
      }
    };
    viewModeEffect();
  }, []);

  const createModelActionHandler = useCallback(
    async values => {
      const response = await apiBrandModel(idToken, values);
      if (response.data?.error) {
        setDisplayToast({ type: 'error', message: response.data?.error?.message });
      } else {
        const { name, key } = response.data?.data;
        mutate(['/api/permission/query/grantee', idToken], async () => {
          const updatedPermissionResponse = await getUserPermission(idToken);
          const { data: permissions } = updatedPermissionResponse.data;
          setUser(prevUser => ({
            ...prevUser,
            permissions,
          }));
          navigate(`/brand/${key}/edit`);
          setDisplayToast({ type: 'success', message: `Brand ${name} has been created` });
          return permissions;
        });
      }
    },
    [idToken]
  );

  const deleteBrandActionHandler = useCallback(brand_model_id => {
    console.log('Delete Brand:', brand_model_id);
  }, []);
  const removeBrandPermissionsActionHandler = useCallback(brand_model_id => {
    console.log('Remove Brand Permissions:', brand_model_id);
  }, []);

  const popUpCardOptions = useMemo(
    () => [
      {
        selectItem: 'Delete Brand',
        ModalComponent: (
          <ConfirmDialog
            dialogBody='Are you sure you would like to delete this brand?'
            btnActionHandler={deleteBrandActionHandler}
          />
        ),
      },
      {
        selectItem: 'Remove Brand Permissions',
        ModalComponent: (
          <ConfirmDialog
            dialogBody='Are you sure you would like to remove permissions for this brand?'
            btnActionHandler={removeBrandPermissionsActionHandler}
          />
        ),
      },
    ],
    []
  );

  const { dispatch } = useFrameUI();

  const popUpCreateBrand = useMemo(
    () => [
      {
        selectItem: 'Create New Brand',
        ModalComponent: (
          <CreateBrandModal
            formId='createBrand'
            modelName='Brand'
            modelInputLabel='Brand Name'
            btnActionHandler={createModelActionHandler}
          />
        ),
      },
    ],
    [createModelActionHandler]
  );

  useEffect(() => {
    const filterBarEffect = () => {
      dispatch({ type: FrameUIActionsTypes.FILTER_BAR_VISIBLE, payload: true });
      dispatch({
        type: FrameUIActionsTypes.FILTER_BAR_OPTIONS,
        payload: user?.account?.role === 'admin' ? [...popUpCreateBrand] : [],
      });

      return () => {
        dispatch({ type: FrameUIActionsTypes.FILTER_BAR_RESET });
      };
    };
    return filterBarEffect();
  }, [user?.account?.role, popUpCreateBrand]);

  return (
    <>
      <Breadcrumbs pages={pages} />
      {brands && (
        <ModelsList
          title='Brands List'
          models={brands
            .sort((a, b) => b.modified - a.modified)
            .map(brand => ({
              key: brand.key,
              image: brand.assets.hero,
              name: brand.name,
              route: `/brand/${brand.key}`,
            }))}
          popUpOptions={user.account?.role === 'admin' ? popUpCardOptions : []}
        />
      )}
    </>
  );
};

export default Brands;

import React, { useCallback, useContext, useEffect, useState } from 'react';

import { UserContext } from 'providers/UserProvider';
import NotificationItem from './NotificationItem';
import { mmAPI } from 'services/Api';
import useSWR from 'swr';

const NotificationList: React.FC = () => {
  const { user } = useContext(UserContext);
  const { idToken } = user;
  const [todayNotification, setTodayNotification] = useState([]);
  const [currentMonthNotification, setCurrentMonthNotification] = useState([]);
  const [previousMonthNotificationFirst, setPreviousMonthNotificationFirst] = useState(
    []
  );
  const [previousMonthNotificationSecond, setPreviousMonthNotificationSecond] = useState(
    []
  );

  const [notificationsEmpty, setNotificationsEmpty] = useState(true);
  const [notifications, setBrandNotifications] = useState([]);

  const { data: notificationsData } = useSWR(
    user?.brand
      ? [
          `/api/notification/method/notifications_by_scope?target=${user.brand.key}`,
          idToken,
        ]
      : null,
    (url, idToken, account) => {
      return mmAPI(url, idToken, { account });
    },
    {
      suspense: true,
    }
  );
  useEffect(() => {
    if (notificationsData && notificationsData.length) {
      const notificationsTransformed = notificationsData?.map(n => {
        return {
          firstName: n.firstName,
          lastName: n.lastName,
          segments: n.segments,
          when: n.when,
          key: n.targetId,
          whenRead: n.whenRead,
        };
      });
      setBrandNotifications(notificationsTransformed);
      setNotificationsEmpty(false);
    }
  }, [notificationsData]);

  const getFirstDayPreviousMonth = useCallback((months: number) => {
    const date = new Date();
    return new Date(date.getFullYear(), date.getMonth() - months, 1);
  }, []);

  useEffect(() => {
    const setNotifications = () => {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const todayNotifications = notifications.filter(
        notification => new Date(notification.when * 1000) >= today
      );
      setTodayNotification(todayNotifications);
      const firstDate = getFirstDayPreviousMonth(0);
      setCurrentMonthNotification(
        notifications.filter(
          notification =>
            new Date(notification.when * 1000) < today &&
            new Date(notification.when * 1000) > firstDate
        )
      );
      const firstPrevious = getFirstDayPreviousMonth(1);
      setPreviousMonthNotificationFirst(
        notifications.filter(
          notification =>
            new Date(notification.when * 1000) < firstDate &&
            new Date(notification.when * 1000) > firstPrevious
        )
      );
      const secondPrevious = getFirstDayPreviousMonth(2);
      setPreviousMonthNotificationSecond(
        notifications.filter(
          notification =>
            new Date(notification.when * 1000) < firstPrevious &&
            new Date(notification.when * 1000) > secondPrevious
        )
      );
    };
    notifications.length && setNotifications();
  }, [notifications]);

  const titeStyle = {
    color: '#CBCBCB',
    fontSize: '10px',
    fontWeight: 700,
    lineHeight: '13px',
    marginTop: '24px',
    marginBottom: '13.6px',
  };

  const notificationView = () => (
    <div>
      {notificationsEmpty ? (
        <div className='control-block'>
          <span style={titeStyle}>No new notifications available.</span>
        </div>
      ) : (
        <div className='control-block'>
          {todayNotification.length > 0 && <span style={titeStyle}>TODAY</span>}
          {todayNotification &&
            todayNotification
              .sort((a, b) => b.when * 1000 - a.when * 1000)
              .map(item => <NotificationItem key={item.key} notification={item} />)}
          {currentMonthNotification.length > 0 && (
            <span style={titeStyle}>THIS MONTH</span>
          )}
          {currentMonthNotification &&
            currentMonthNotification
              .sort((a, b) => b.when * 1000 - a.when * 1000)
              .map(item => <NotificationItem key={item.key} notification={item} />)}
          {previousMonthNotificationFirst.length > 0 && (
            <span style={titeStyle}>
              {new Date(previousMonthNotificationFirst[0].when * 1000)
                .toLocaleString('en-us', {
                  month: 'long',
                })
                .toUpperCase()}
            </span>
          )}
          {previousMonthNotificationFirst &&
            previousMonthNotificationFirst
              .sort((a, b) => b.when * 1000 - a.when * 1000)
              .map(item => <NotificationItem key={item.key} notification={item} />)}
          {previousMonthNotificationSecond.length > 0 && (
            <span style={titeStyle}>
              {new Date(previousMonthNotificationSecond[0].when * 1000)
                .toLocaleString('en-us', {
                  month: 'long',
                })
                .toUpperCase()}
            </span>
          )}
          {previousMonthNotificationSecond &&
            previousMonthNotificationSecond
              .sort((a, b) => b.when * 1000 - a.when * 1000)
              .map(item => <NotificationItem key={item.key} notification={item} />)}
        </div>
      )}
    </div>
  );

  return (
    <div className='product-upload' style={{ marginTop: '57px' }}>
      <div className='product-upload__header' style={{ marginTop: '40px' }}>
        <div className='product-upload__heading'>Notification Center</div>
      </div>
      {notificationView()}
    </div>
  );
};

export default NotificationList;

import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

export interface MenuIconProps {
  fontColor: string;
  openSidebar: boolean;
}

const SlashButton = styled.div`
  &::before {
    background-color: ${props => props.fontColor || 'white'};
  }
`;

const MenuIcon: React.FC<MenuIconProps> = ({ fontColor, openSidebar }) => {
  return (
    <>
      <SlashButton
        fontColor={fontColor}
        className={openSidebar ? 'frame__close-active' : 'frame__close'}
      />
      <svg
        className='frame__slash'
        style={{ backgroundColor: `${fontColor} !important` }}
        width='32'
        height='33'
        viewBox='0 0 32 33'
        fill={fontColor || 'white'}
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect
          x='24'
          y='7.63965'
          width='2'
          height='24'
          transform='rotate(45 24 7.63965)'
          fill={fontColor || 'white'}
        />
      </svg>
    </>
  );
};

export default MenuIcon;

import React, { useEffect, useState } from 'react';
import { Link } from '@reach/router';
import './ManageTeam.scss';
import 'components/Dashboard/Collections/CollectionsList/CollectionsList.scss';
import PopUp from 'components/Common/PopUp/PopUp';
import { IPopUpOptions } from 'models/Dashboard/IPopUpOptions';

import ManageIcon from 'assets/icons/manage-icon.svg';
import map from 'lodash.map';
import { getRouteFromModelAndBrand } from 'utils/getRouteFromModelAndBrand';
import { ProfileAssetCircle } from '../SidebarInfo/SidebarInfo';

export type IMembers = {
  key: string;
  name: string;
  email: string;
  accountType: string;
  activeAccount: string;
  status: string;
  inviteURL?: string;
  department: string;
  cache: any;
};
export interface IMembersTeam {
  team?: {
    key: number;
    name: string;
    description?: string;
  };
  model?: {
    key: number;
    KIND: string;
    name: string;
    description?: string;
  };
  brand?: any;
  members: IMembers[];
  popUpOptions: IPopUpOptions[];
}

const membersSlice = 5;

const getRouteFromTeam = team => {
  return getRouteFromModelAndBrand(team.brand, team.KIND, team.key);
};

const SummarizeTeam: React.FC<IMembersTeam> = props => {
  /**
   * team is actually model
   * match for team by key (if available) or by team grant matches to correctly filter
   */
  const { team, model, members, popUpOptions } = props;
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const [membersMatch, setMembersMatch] = useState([]);

  useEffect(() => {
    const teamMembers = members?.filter(m => {
      const keyMatch = m?.cache?.teams?.map(t => parseInt(t.key)).includes(team?.key);
      let grantsMatch = false;
      if (!keyMatch) {
        const grantsFlattened = m?.cache?.teams
          ?.map(t => t.grants.map(g => parseInt(g.model_id)))
          .flat();
        grantsMatch = grantsFlattened?.includes(model?.key);
      }
      return keyMatch || grantsMatch;
    });
    // console.log('teamMembers', team?.key, team?.name, teamMembers);
    setMembersMatch(teamMembers);
  }, [team, members]);

  return (
    <div className='manage-team'>
      <div className='collections-list__item' key={team.key}>
        <span className='collections-list__txt'>
          <Link to={getRouteFromTeam(team)}>{team?.name || team?.description}</Link>
        </span>
        <div className='collections-list__end'>
          <span className='collections-list__members'>
            <SummarizeProfiles members={membersMatch} />
          </span>
          {members?.length > membersSlice && (
            <span className='collections-list__members-count'>
              <div className='sidebar-info__member'>
                <span>+</span>
              </div>
            </span>
          )}
          {/* <span className='collections-list__members-count'>
            {membersMatch.length} {membersMatch.length === 1 ? `member` : `members`}
          </span> */}
          <img
            className='collections-list__dots'
            src={ManageIcon}
            alt='Dots'
            onClick={() => {
              setOpen(true);
              setSelectedItem(team.key);
            }}
          />
        </div>

        <PopUp open={open && selectedItem === team.key} setOpen={setOpen}>
          {popUpOptions &&
            popUpOptions.map(({ selectItem, onClick, to }) => (
              <li
                className='pop-up__li'
                key={selectItem}
                onClick={() => {
                  setOpen(false);
                  onClick?.();
                }}
              >
                {to ? <Link to={to}>{selectItem}</Link> : selectItem}
              </li>
            ))}
        </PopUp>
      </div>
    </div>
  );
};

const SummarizeProfiles = props => {
  const { members } = props;
  return (
    <>
      {map(
        members?.slice(0, membersSlice),
        ({
          name,
          first,
          last,
          email,
          department,
          activeAccount,
          accountType,
          inviteURL,
          status,
          key,
        }) => (
          <div className='sidebar-info__member' key={key}>
            <ProfileAssetCircle firstName={first || name} lastName={last || ''} />
          </div>
        )
      )}
    </>
  );
};

export default SummarizeTeam;

import React, { useEffect, useState, useContext } from 'react';
import useSWR from 'swr';
import { mmAPI } from 'services/Api';
import { UserContext } from 'providers/contexts';
import { ImageGrid, ModalTemplate } from 'components';

const Brand = ({ brand_model_id }) => {
  const { user, setUser } = useContext(UserContext);
  const { idToken } = user;

  const { data: brand } = useSWR(
    [`/api/brand/model/${brand_model_id}`, idToken],
    (url, idToken) => {
      return mmAPI(url, idToken);
    },
    {
      suspense: true,
    }
  );

  useEffect(() => {
    const brandEffect = () => {
      if (!brand) return;
      setUser(prevUser => ({
        ...prevUser,
        brand,
      }));
    };
    brandEffect();
  }, [brand]);

  const [gridImages, setGridImages] = useState({});
  useEffect(() => {
    const getGridImages = () => {
      if (!brand) return;
      const { assets } = brand;
      setGridImages({
        image_2: assets?.image_2,
        image_3: assets?.image_3,
        image_4: assets?.image_4,
        image_5: assets?.image_5,
        image_6: assets?.image_6,
        image_7: assets?.image_7,
      });
    };
    getGridImages();
  }, [brand]);

  const gridTemplate = user?.brand?.template?.landing?.template === 'gridtemplate';
  return (
    <>
      {gridTemplate ? (
        <ImageGrid images={gridImages} aspectRatio='1-1' upload={false} />
      ) : (
        <ModalTemplate brand={brand} upload={false} />
      )}
    </>
  );
};

export default Brand;

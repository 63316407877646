import React from 'react';
import { useForm } from 'react-hook-form';
import Modal from './Modal';
import CaretBottomIcon from 'assets/icons/caret-bottom.svg';
import { UserContext } from 'providers/UserProvider';
import PopUp from '../PopUp/PopUp';

type CreateModelModalProps = {
  model?: any;
  team?: any;
  teamJobType?: string;
  modalHeading?: string;
  btnActionLabel?: string;
  btnCloseLabel?: string;
  btnActionHandler?: (values) => void;
  formId?: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ShareModelModal = (props: CreateModelModalProps) => {
  const {
    formId,
    model,
    team,
    teamJobType,
    modalHeading = 'Share',
    btnActionLabel = 'Share',
    btnCloseLabel = 'Cancel',
    btnActionHandler,
    open,
    setOpen,
  } = props;
  React.useEffect(() => {
    const modelEffect = modelVal => {
      if (!modelVal) return;
    };
    modelEffect(model);
  }, [model]);

  const { user } = React.useContext(UserContext);
  const [jobType, setJobType] = React.useState(teamJobType);
  const popUpOptions = [
    {
      selectItem: 'Admin',
    },
    {
      selectItem: 'Designer',
    },
    {
      selectItem: 'Sales Rep',
    },
    {
      selectItem: 'Delegate',
    },
  ];
  const [openPopUp, setOpenPopUp] = React.useState(false);

  const { handleSubmit, register } = useForm();
  const onSubmit = values => {
    btnActionHandler?.(values);
  };
  return (
    <Modal
      modalHeading={`${modalHeading} ${model?.name}`}
      modalBtnClose={btnCloseLabel}
      modalBtnActionLabel={btnActionLabel}
      backgroundColor='#fff'
      modalOpen={open}
      setModalOpen={setOpen}
      formHandlerId={formId}
    >
      <form id={formId} onSubmit={handleSubmit(onSubmit)}>
        <div className='control-block share-model-modal'>
          <input type='hidden' name='team' value={team?.key} ref={register({})} />
          <input type='hidden' name='lineName' value={model?.name} ref={register({})} />
          <input type='hidden' name='lineKey' value={model?.key} ref={register({})} />
          <label className='control--label control--required'>Name</label>
          <input
            ref={register}
            name={`name`}
            required
            className='control control--text'
            type='text'
          />

          <div className='share-model-modal__member-email'>
            <label className='control--label control--required'>Email</label>
            <input
              ref={register}
              name={`email`}
              required
              className='control control--text'
              type='text'
            />
          </div>
          {teamJobType &&
            (user?.account?.role === 'admin' || user?.account.job_type === 'admin') && (
              <>
                <div className='share-model-modal__member-access'>
                  <label className='control--label control--required'>Permissions</label>
                  <button
                    className='button-select'
                    onClick={() => {
                      setOpenPopUp(p => !p);
                    }}
                  >
                    {jobType}
                    <img src={CaretBottomIcon} alt='Designer permissions' />
                  </button>
                  <PopUp open={openPopUp} setOpen={setOpenPopUp}>
                    {popUpOptions &&
                      popUpOptions.map(({ selectItem }) => (
                        <li
                          data-value={selectItem}
                          onClick={e => {
                            const jobTypeValue = e.currentTarget.getAttribute(
                              'data-value'
                            );
                            console.log('li', jobTypeValue);
                            setJobType(jobTypeValue);
                            setOpenPopUp(p => !p);
                          }}
                          className='pop-up__li'
                          key={selectItem}
                        >
                          {selectItem}
                        </li>
                      ))}
                  </PopUp>
                  <input
                    type='hidden'
                    name='jobType'
                    value={jobType.toLowerCase()}
                    ref={register({})}
                  />
                </div>
              </>
            )}
        </div>
      </form>
    </Modal>
  );
};

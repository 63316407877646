import React, { useEffect, useState, useContext, useCallback } from 'react';
import useSWR, { mutate } from 'swr';

import { mmAPI } from 'services/Api';
import { NotificationsContext } from 'providers/NotificationsProvider';
import { ModelMember } from 'components/Dashboard/Collections/CollectionAccessModal/ModelAccessModal';
import LogRocket from 'logrocket';

type useTeamModelParams = {
  brand_model_id: string;
  line_model_id?: string;
  collection_model_id?: string;
  style_model_id?: string;
  model?: any;
  matchMultiTeams?: boolean;
  jobType?: JobType;
};
type useSidebarReturn = {
  team: any;
  teams: any[];
  teamInvites: any[];
  teamInvitesState: ModelMember[];
  teamAccountsState: ModelMember[];
  brandAccounts: any[];
  brandTeamsInvites: any[];
  teamModelAssoc: any;
  setTeamModelAssoc: (arg?) => void;
  findTeamByModelKeyAndJobType: (key?: number, jobType?: 'admin') => void;
  mutateAll: () => void;
};

type TeamModel = {
  key: number;
  KIND: string;
  name: string;
};

export type JobType = 'designer' | 'vsm' | 'delegate' | 'admin' | 'guest';

type TeamModelAssoc = {
  model: TeamModel;
  jobType?: JobType;
};

const useTeam = (
  idToken: string,
  { brand_model_id, model, jobType, matchMultiTeams }: useTeamModelParams
): useSidebarReturn => {
  const { setDisplayToast } = useContext(NotificationsContext);
  const [teamModelAssoc, setTeamModelAssoc] = useState<TeamModelAssoc>({
    model,
    jobType,
  });
  const [team, setTeam] = useState(null);
  const [teamInvitesState, setTeamInvitesState] = useState(null);
  const [teamAccountsState, setTeamAccountsState] = useState(null);

  const { data: teams } = useSWR(
    brand_model_id ? [`/api/team/query/brand`, idToken, brand_model_id] : null,
    (url, idToken, brand) => {
      return mmAPI(url, idToken, { brand });
    },
    { suspense: false }
  );

  const { data: teamInvites, mutate: mutateTeamInvites } = useSWR(
    team ? [`/api/invite/query/team`, idToken, team.key] : null,
    (url, idToken, team) => {
      return mmAPI(url, idToken, { team });
    },
    { suspense: false }
  );

  const { data: brandAccounts, mutate: mutateBrandAccounts } = useSWR(
    ['/api/account/query/brand', idToken, brand_model_id],
    (url, idToken, brand) => {
      return mmAPI(url, idToken, { brand });
    },
    {
      suspense: true,
    }
  );

  const { data: modelInvites, mutate: mutateModelInvites } = useSWR(
    teamModelAssoc?.model
      ? [
          '/api/invite/query/model',
          idToken,
          teamModelAssoc.model.KIND,
          teamModelAssoc.model.key,
        ]
      : null,
    (url, idToken, model, model_id) => {
      return mmAPI(url, idToken, { model, model_id });
    },
    {
      suspense: true,
    }
  );

  const { data: brandTeamsInvites, mutate: mutateBrandTeamsInvites } = useSWR(
    ['/api/invite/query/brand_teams', idToken, brand_model_id],
    (url, idToken, brand) => {
      return mmAPI(url, idToken, { brand });
    },
    {
      suspense: true,
    }
  );

  const getPermissionTypeFromJobType = jobType => {
    if (jobType === 'admin') return 'owner';
    if (['editor', 'designer', 'vsm'].includes(jobType)) return 'editor';
    return 'read';
  };

  /**
   * Team lookup based on team grants and account_job_type attribute
   */
  const findTeamByModelKeyAndJobType = useCallback(
    (key, jobType) => {
      const teamMatch = teams?.find(t => {
        const matchingGrants = t.grants.filter(
          g =>
            g.model_id === parseInt(key) &&
            g.type === getPermissionTypeFromJobType(jobType)
        );
        return matchingGrants?.length && t.account_job_type === jobType;
      });
      if (teamMatch) {
        return teamMatch;
      }
      if (teamModelAssoc.model && jobType) {
        setDisplayToast({
          type: 'error',
          message: `Unable to fetch team. Please contact an admin or service owner`,
        });
        LogRocket.captureMessage('TeamNotFound', {
          tags: {
            match_target_key: key,
            match_target_job_type: jobType,
          },
          extra: {
            team_assoc_model: model,
            teams: teams,
          },
        });
        console.trace('findTeamByModelKeyAndJobType TeamNotFound');
        LogRocket.captureException(
          new Error('findTeamByModelKeyAndJobType TeamNotFound')
        );
      }
    },
    [teamModelAssoc, idToken, teams, brand_model_id]
  );

  const findTeamByModelKey = useCallback(
    key => {
      const teamMatch = teams?.find(t => {
        const matchingGrants = t.grants.filter(g => g.model_id === parseInt(key));
        return matchingGrants?.length;
      });
      if (teamMatch) {
        return teamMatch;
      }
      if (!teamMatch && teamModelAssoc.model) {
        setDisplayToast({
          type: 'error',
          message: `Unable to fetch team. Please contact an admin or service owner`,
        });
        LogRocket.captureMessage('TeamNotFound', {
          tags: {
            match_target_key: key,
          },
          extra: {
            team_assoc_model: model,
            teams: teams,
          },
        });
      }
    },
    [teamModelAssoc, idToken, teams, brand_model_id]
  );

  useEffect(() => {
    const sidebarModelEffect = (teamModelAssocVal, idToken) => {
      if (!teamModelAssocVal) return;
      const { model, jobType } = teamModelAssocVal;
      if (!matchMultiTeams) {
        let teamMatch = null;
        if (model && !jobType) {
          teamMatch = findTeamByModelKey(model.key);
        } else if (model && jobType) {
          teamMatch = findTeamByModelKeyAndJobType(model.key, jobType);
        }
        if (teamMatch) {
          console.log('sidebarModelEffect setTeam', teamMatch);
          mutate([`/api/account/query/team`, idToken, teamMatch.key]);
          mutate([`/api/invite/query/team`, idToken, teamMatch.key]);
          setTeam(teamMatch);
        }
      }
    };
    sidebarModelEffect(teamModelAssoc, idToken);
  }, [teamModelAssoc, matchMultiTeams, teams, idToken]);

  useEffect(() => {
    const teamModelEffect = (
      teamVal,
      matchMultiTeamsVal,
      teamsVal,
      teamModelAssocVal
    ) => {
      const teamInvitesStateTransform = ({
        key,
        created,
        first_name: firstName,
        last_name: lastName,
        token,
        email,
        job_type,
      }) => ({
        key,
        created,
        firstName,
        lastName,
        email,
        inviteUrl: `${process.env.REACT_APP_PUBLIC_URL}/invite/?token=${token}`,
        accessLevel: job_type,
      });
      const teamAccountsStateTransform = (
        { key, created, first: firstName, last: lastName, assets, email, job_type },
        team = null
      ) => {
        return {
          key,
          created,
          firstName,
          lastName,
          email,
          profileAsset: assets?.profile_picture,
          accessLevel: team ? team.account_job_type : job_type,
        };
      };
      if (matchMultiTeamsVal && teamsVal && teamModelAssocVal?.model) {
        /**
         * teamModelAssoc state
         *  - filters out teams with grants matching the model association
         *  - filters out accounts and invites from all brand accounts and invites
         */
        const teamModelMatches = teamsVal?.filter(t => {
          // also filters by account job type
          const matchingGrants = t.grants.filter(
            g => g.model_id === teamModelAssocVal.model?.key
          );
          return (
            matchingGrants?.length &&
            (teamModelAssocVal.jobType
              ? t.account_job_type === teamModelAssocVal.jobType
              : true)
          );
        });
        const teamModelMatchKeys = teamModelMatches.map(t => t.key);
        const teamModelAccounts = brandAccounts
          .filter(a => a.teams.some(atk => teamModelMatchKeys.includes(atk)))
          .sort((a, b) => b.created - a.created)
          .map(teamAccountsStateTransform);
        setTeamAccountsState(teamModelAccounts);
        const teamModelInvites = modelInvites
          .filter(
            i =>
              teamModelMatchKeys.includes(i.team) &&
              ['pending', 'invited'].includes(i.status)
          )
          .sort((a, b) => b.created - a.created)
          .map(teamInvitesStateTransform);
        setTeamInvitesState(teamModelInvites);
      }
    };
    teamModelEffect(team, matchMultiTeams, teams, teamModelAssoc);
  }, [matchMultiTeams, teams, teamModelAssoc, team, modelInvites, brandAccounts]);

  const mutateAll = () => {
    mutateModelInvites?.();
    mutateBrandAccounts?.();
    mutateTeamInvites?.();
    mutateBrandTeamsInvites?.();
  };

  return {
    team,
    teams,
    teamInvites,
    teamInvitesState,
    teamAccountsState,
    brandAccounts,
    brandTeamsInvites,
    teamModelAssoc,
    setTeamModelAssoc,
    mutateAll,
    findTeamByModelKeyAndJobType,
  };
};

export default useTeam;

import React, { useEffect, useState, useContext } from 'react';
import useSWR from 'swr';
import { mmAPI } from 'services/Api';
import { UserContext } from 'providers/contexts';
import ManageContacts from 'components/Dashboard/ManageTeam/ManageContacts';
import { firebaseStorageUrl } from 'providers/UploadProvider';

export const ContactsContainer = ({ team, viewMode = 'SUMMARIZE', brand_model_id }) => {
  const { user } = useContext(UserContext);
  const { idToken } = user;

  const { data: accounts } = useSWR(
    [
      `/api/account/query/${team ? 'team' : 'brand'}/`,
      idToken,
      team ? team.key : brand_model_id,
    ],
    (url, idToken, queryModel) => {
      return mmAPI(url, idToken, { [team ? 'team' : 'brand']: queryModel, cursor: 0 });
    },
    {
      suspense: true,
    }
  );

  const [membersFromAccounts, setMembersFromAccounts] = useState([]);

  useEffect(() => {
    const accountsEffect = async () => {
      if (!accounts) return;
      const accountsMapped = await Promise.all(
        accounts
          .filter(a => a.key != user.account.key)
          .sort((a, b) => b.created - a.created)
          .map(async account => {
            const {
              KIND,
              name,
              email,
              job_type: accountType,
              department,
              status,
              key,
              mobile_number,
            } = account;
            const activeAccount = 'active';
            const firstName = account.first || '';
            const lastName = account.last || '';
            let profileImg = '';
            if (account.assets.profile_picture.path) {
              try {
                profileImg = await firebaseStorageUrl(
                  account.assets.profile_picture.path
                );
              } catch (err) {
                console.log(err);
              }
            }
            return {
              KIND,
              key,
              name,
              email,
              accountType,
              activeAccount,
              status,
              department,
              profileImg,
              firstName,
              lastName,
              phoneNum: mobile_number,
            };
          })
      );
      setMembersFromAccounts(accountsMapped);
    };
    accountsEffect();
  }, [accounts]);

  return (
    <div>
      {viewMode === 'MANAGE' && (
        <ManageContacts team={team} members={membersFromAccounts} />
      )}
    </div>
  );
};

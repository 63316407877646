import React, { useState } from 'react';
import { Link } from '@reach/router';
import classNames from 'classnames';
import Checkbox from 'components/Common/Checkboxes/Checkboxes';
import PopUp from 'components/Common/PopUp/PopUp';
import { IPopUpOptions } from 'models/Dashboard/IPopUpOptions';
import { IProduct } from 'models/Dashboard/IProduct';

import './ProductsGridView.scss';
import DotsIcon from 'assets/icons/dots-grey.svg';

export interface IProductsGridView {
  size?: string;
  listings: IProduct[];
  editTileOptions: IPopUpOptions[];
  selectedProductKeys: any[];
  placeholder?: string;
  handleToggle?: (product_key: any) => void;
}

export interface IProductsGridItem {
  size?: string;
  itemData: IProduct;
  editTileOptions: IPopUpOptions[];
  checked: boolean;
  onToggle?: (product_key: any) => void;
  onPopUpModal: (itemKey: any, ModalComponent: React.ReactElement) => void;
}

const ProductsGridView: React.FC<IProductsGridView> = props => {
  const {
    size = 'md',
    listings,
    editTileOptions,
    selectedProductKeys,
    placeholder = 'No listings are available',
    handleToggle,
  } = props;
  const [selectedItem, setSelectedItem] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [ActiveModalComponent, setActiveModalComponent] = useState(null);

  const handlePopUpModal = (itemKey, ModalComponent) => {
    setSelectedItem(itemKey);
    setActiveModalComponent(ModalComponent);
    setModalOpen(true);
  };

  return (
    <div className='ProductsGridView'>
      {listings.length === 0 ? (
        placeholder
      ) : (
        <div className='row'>
          {listings?.map(itemData => (
            <ProductsGridItem
              key={itemData.key}
              size={size}
              itemData={itemData}
              editTileOptions={editTileOptions}
              checked={selectedProductKeys.includes(itemData.key)}
              onToggle={handleToggle}
              onPopUpModal={handlePopUpModal}
            />
          ))}
        </div>
      )}

      {ActiveModalComponent &&
        modalOpen &&
        React.cloneElement(ActiveModalComponent, {
          open: modalOpen,
          setOpen: setModalOpen,
          data: listings.find(item => item.key === selectedItem),
        })}
    </div>
  );
};

const ProductsGridItem: React.FC<IProductsGridItem> = props => {
  const {
    size = 'md',
    itemData,
    editTileOptions,
    checked,
    onToggle,
    onPopUpModal,
  } = props;
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(0);

  const { key, style_name, style_number, color, imageUrl, linkTo } = itemData;

  return (
    <div
      className={classNames('ProductsGridView__file', {
        'col-md-3 col-sm-6': size === 'md',
        'col-md-6 col-sm-12': size === 'lg',
      })}
    >
      <div className='ProductsGridView__image ratio3-4 ratio-wrap'>
        <Link to={linkTo}>
          <img className='ratio-img' style={{ objectFit: 'contain' }} src={imageUrl} alt={style_name} />
        </Link>
      </div>
      <div className='ProductsGridView__content'>
        <Link to={linkTo} className='ProductsGridView__left'>
          <h3 className='ProductsGridView__name'>{style_name}</h3>
          <div className='ProductsGridView__id'>{style_number}</div>
          <div className='ProductsGridView__color'>{color?.name}</div>
        </Link>
        <div className='ProductsGridView__right'>
          <img
            className='ProductsGridView__dots'
            src={DotsIcon}
            alt='Dots'
            onClick={() => {
              setOpen(true);
              setSelectedItem(key);
            }}
          />
          <PopUp open={open && selectedItem === key} setOpen={setOpen}>
            {editTileOptions?.map(({ selectItem, to, ModalComponent, onClick }) => (
              <li
                className='pop-up__li'
                onClick={() => {
                  setOpen(false);
                  if (ModalComponent) {
                    onPopUpModal(itemData.key, ModalComponent);
                  } else if (onClick) {
                    onClick(itemData);
                  }
                }}
                key={selectItem}
              >
                {to ? <Link to={to}>{selectItem}</Link> : selectItem}
              </li>
            ))}
          </PopUp>

          <Checkbox
            value={key}
            checked={checked}
            onChange={event => onToggle?.(parseInt(event.currentTarget.value))}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductsGridView;

import React, { useEffect, useState, useCallback, useMemo, useContext } from 'react';
import { Link, useParams } from '@reach/router';
import './ManageTeam.scss';
import '../ContactCards/ContactCards.scss';

import map from 'lodash.map';

import { NotificationsContext, UserContext } from 'providers/contexts';
import { ConfirmDialog } from 'components/Common/Dialog/ConfirmDialog';
import {
  apiDeleteInvitedTeamMember,
  deleteTeamMethodRemoveMember,
  getBrandModel,
  mmAPI,
  postTeamModel,
} from 'services/Api';
import useSWR, { mutate } from 'swr';
import useMessages, { MessageModelAssoc } from 'hooks/useMessages';

export type IMembers = {
  key: string;
  name: string;
  email: string;
  accountType: string;
  activeAccount: string;
  status: string;
  inviteURL?: string;
  department: string;
  profileImg?: string;
  firstName?: string;
  lastName?: string;
  phoneNum?: string;
};
export interface IMembersTeam {
  team?: any;
  brand?: any;
  members: IMembers[];
}

interface IAddress {
  phone?: string;
  country?: string;
  state: string;
  street?: string;
}

const ManageContacts: React.FC<IMembersTeam> = props => {
  const { team, members } = props;
  const [selectedItem, setSelectedItem] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [ActiveModalComponent, setActiveModalComponent] = useState(null);
  const [address, setAddress] = useState<IAddress>({});

  const { brand_model_id } = useParams();

  const { user } = useContext(UserContext);
  const { idToken } = user;
  const { setDisplayToast } = useContext(NotificationsContext);

  const [contactModelAssoc, setContactModelAssoc] = React.useState<MessageModelAssoc>(
    null
  );
  const { data: contactTeams, mutate: mutateContactTeams } = useSWR(
    contactModelAssoc
      ? ['/api/team/query/model', idToken, contactModelAssoc.KIND, contactModelAssoc.key]
      : null,
    (url, idToken, model, model_id) => {
      return mmAPI(url, idToken, { model, model_id });
    },
    {
      suspense: true,
    }
  );

  const messageContactHandler = (member, e) => {
    setContactModelAssoc(member);
  };

  React.useEffect(() => {
    const contactModelAssocEffect = async () => {
      if (!contactModelAssoc) return;
      console.log('contactTeams', contactTeams);
      console.log('contactModelAssoc', contactModelAssoc);
      console.log('contactModelAssoc.key', contactModelAssoc.key);
      const teamMatch = contactTeams?.find(t => {
        console.log('t.grants', t, t.grants);
        const contactGrantsMatch = t.grants.find(
          g => g.model_id === contactModelAssoc.key
        );
        const accountGrantsMatch = t.grants.find(g => g.model_id === user?.account?.key);
        return contactGrantsMatch && accountGrantsMatch;
      });

      console.log('teamMatch', teamMatch);
      if (teamMatch) {
        setMessageModelAssoc(teamMatch);
        onMessagesOpen();
      } else if (contactModelAssoc && !teamMatch) {
        const description = `Contacts Chat: ${user?.account?.name} <> ${contactModelAssoc?.name}`;
        const grants = JSON.stringify([
          { model: 'Account', model_id: contactModelAssoc?.key },
          { model: 'Account', model_id: user?.account?.key },
        ]);
        console.log('creating chat', description);
        console.log('grants', grants);

        const {
          data: { data: contactTeamCreated },
        } = await postTeamModel(idToken, {
          name: contactModelAssoc?.name,
          grants,
          model: 'Account',
          model_id: user?.account?.key,
          description,
        });
        setMessageModelAssoc(contactTeamCreated);
        onMessagesOpen();
      }
    };
    contactModelAssocEffect();
  }, [contactModelAssoc]);

  useEffect(() => {
    const getBrandDetails = async () => {
      const response = await getBrandModel(idToken, { key: brand_model_id });
      setAddress(response?.data.data.address);
    };
    getBrandDetails();
  }, [idToken, brand_model_id]);

  const { onMessagesOpen, setMessageModelAssoc } = useMessages(idToken, {
    model: null,
    open: false,
  });

  const handlePopUpModal = useCallback(ModalComponent => {
    setActiveModalComponent(ModalComponent);
    setModalOpen(true);
  }, []);

  const [teamRoute, setTeamRoute] = useState(null);

  useEffect(() => {
    const teamEffect = () => {
      if (!team) return;
      const routeGrant = team.grants.find(g =>
        ['Line', 'Collection', 'Style'].includes(g.model)
      );
      let route = null;
      if (routeGrant?.model?.toLowerCase() === 'style')
        route = `/brand/${team?.model_id}/${routeGrant.model.toLowerCase()}/${
          routeGrant.model_id
        }`;
      else if (routeGrant?.model)
        route = `/brand/${team?.model_id}/products?${routeGrant.model.toLowerCase()}s=${
          routeGrant.model_id
        }`;
      else route = `/brand/${team?.model_id}/products`;
      setTeamRoute(route);
    };
    teamEffect();
  }, [team]);

  const deleteTeamInviteActionHandler = useCallback(
    async member => {
      const { key: invite_key } = member;

      const response = await apiDeleteInvitedTeamMember(idToken, invite_key);
      if (response.data?.error) {
        setDisplayToast({ type: 'error', message: response.data?.error?.message });
      } else {
        mutate([
          `/api/invite/query/${team ? 'team' : 'brand'}/`,
          idToken,
          team ? team.key : brand_model_id,
        ]);
        mutate([
          `/api/account/query/${team ? 'team' : 'brand'}/`,
          idToken,
          team ? team.key : brand_model_id,
        ]);
        setDisplayToast({
          type: 'success',
          message: `Invite has been deleted successfully.`,
        });
      }
    },
    [idToken, team?.key]
  );

  const deleteTeamMemberActionHandler = useCallback(
    async member => {
      const { key } = member;

      const response = await deleteTeamMethodRemoveMember({
        account: key,
        team: team.key,
      });
      if (response.data?.error) {
        setDisplayToast({ type: 'error', message: response.data?.error?.message });
      } else {
        mutate([
          `/api/account/query/${team ? 'team' : 'brand'}/`,
          idToken,
          team ? team.key : brand_model_id,
        ]);
        setDisplayToast({
          type: 'success',
          message: `Team member has been successfully deactivated.`,
        });
      }
    },
    [idToken, team?.key]
  );

  const popUpOptions = useMemo(
    () => [
      {
        selectItem: 'Deactivate',
        ModalComponent: (
          <ConfirmDialog
            dialogBody='Are you sure you would like to deactivate this team member?'
            btnActionHandler={deleteTeamMemberActionHandler}
          />
        ),
      },
      {
        selectItem: 'Delete',
        ModalComponent: (
          <ConfirmDialog
            dialogBody='Are you sure you would like to delete this invited team member?'
            btnActionHandler={deleteTeamInviteActionHandler}
          />
        ),
      },
      { selectItem: 'Edit' },
      { selectItem: 'View' },
      { selectItem: 'Reassign user' },
    ],
    [deleteTeamMemberActionHandler, deleteTeamInviteActionHandler]
  );

  if (members?.length === 0) {
    return <></>;
  }

  return (
    <div className='manage-team'>
      <div className='contact-cards'>
        <h1 className='heading'>contacts</h1>
        <div className='row'>
          {map(members, member => {
            const {
              profileImg,
              accountType,
              firstName,
              lastName,
              email,
              phoneNum,
              key,
            } = member;
            return (
              <div key={key} className='col-md-3 mb-4'>
                <div className='contact-cards__card'>
                  <div className='contact-cards__header'>
                    {!profileImg ? (
                      <div className='contact-cards__avatar'>
                        {firstName.slice(0, 1)}
                        {lastName.slice(0, 1)}
                      </div>
                    ) : (
                      <img
                        src={profileImg}
                        alt='Profile'
                        className='contact-cards__avatar'
                      />
                    )}
                    <div>
                      <div className='contact-cards__name'>
                        {firstName} {lastName}
                      </div>
                      <div className='flex-inline'>
                        <button className='button-badge color-gray-bd-bg mr-2'>
                          {accountType}
                        </button>
                        {/* <p className='txt-gray'>{company}</p> */}
                      </div>
                    </div>
                  </div>
                  <div className='mt-3 mb-3'>
                    <p>{email}</p>
                    {address.phone && <p>{address.phone}</p>}
                    {address.country && <p>{`${address.street}, ${address.country}`}</p>}
                  </div>
                  <div className='flex-center mt-1'>
                    <button
                      className='button-smallest color-white-bd-bg'
                      onClick={e => messageContactHandler(member, e)}
                    >
                      Message
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {ActiveModalComponent &&
        modalOpen &&
        React.cloneElement(ActiveModalComponent, {
          open: modalOpen,
          setOpen: setModalOpen,
          data: members.find(item => item.key === selectedItem),
        })}
    </div>
  );
};

export default ManageContacts;

import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import { Link, navigate, useLocation } from '@reach/router';
import useSWR from 'swr';
import classNames from 'classnames';
import LogRocket from 'logrocket';

import './StyleSummary.scss';
import './StyleSampleReview.scss';
import 'components/Dashboard/AFSAssetReview/AFSAssetReview.scss';

import StyleSampleReviewHeader from './StyleSampleReviewHeader';
import { ApiAsset } from './StyleSummary';
import AssetAnnotationModal from './AssetAnnotationModal';
import { UserContext } from 'providers/UserProvider';
import { FrameUIActionsTypes, useFrameUI } from 'providers/FrameUIProvider';
import { UploadContext } from 'providers/UploadProvider';
import { ModalsContext } from 'providers/ModalsProvider';
import SlickRailMode from './SlickRailMode';
import StyleSampleListView from './StyleSampleListView';
import { ConfirmDialog } from 'components';
import Breadcrumbs, { IPages } from 'components/Common/Breadcrumbs/Breadcrumbs';
import {
  deleteModel,
  mmAPI,
  postActivityMethod,
  putModel,
  postModel,
  updateReferencePath,
  referenceMethodClone,
} from 'services/Api';
import useMessages from 'hooks/useMessages';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { getImageDimensions } from 'utils/getImageDimensions';
import { WORKFLOW_STATUS } from './util';
import PopUp from 'components/Common/PopUp/PopUp';
import { IPopUpOptions } from 'models/Dashboard/IPopUpOptions';
import ListIconBlack from 'assets/icons/Icon-list-black.svg';
import RailIcon from 'assets/icons/Icon-rail.svg';
import PlusIcon from 'assets/icons/plus-icon-dark.svg';
import PrevIcon from 'assets/icons/arrow-prev.svg';
import Asset from 'components/Asset';
import PlaceholderImg from 'assets/images/placeholder-image.png';
import DashboardNav from 'components/Dashboard/DashboardNav/DashboardNav';
import { FOLDER, ASSETS_LIBRARY } from 'components/Dashboard/AssetLibrary/util';
import { CreateModelModal } from '../../Common/Modal/CreateModelModal';
import { NotificationsContext } from 'providers/NotificationsProvider';
import StyleSampleMoveToModal from './StyleSampleMoveToModal';
import { blobToFile } from 'utils/file';
import { ASSET_TYPES } from 'constants/assets';
import { AtelierGalleryImage } from 'components/VirtualAtelier/Uploads/AtelierModelUploads/AtelierModelUploads';

export type APIMedia = {
  assets: {
    image: ApiAsset;
  };
  created: number;
  modified: number;
  brand: number;
  key: number;
  name: string;
  pose: string;
  position: number;
  products: number[];
  shot: string;
  size: string;
  sku: string;
  source: 'brand' | 'vsm';
  style: number;
  style_number: string;
  tags: string[];
  type: 'image' | 'fabric' | 'trim' | 'hardware' | 'pattern';
  src?: string;
};

export type StyleSampleReview = {
  style: any;
  media: APIMedia;
  brand_model_id: string;
  style_model_id: string;
  reference_model_id: string;
  references: any;
  archivedSampleSlideshow: any[];
  mutateReferencesArchive: any;
  mutateReferences: any;
  sampleSlideshow: any[];
  sampleSlideshowUploading: any[];
  updatePositionsHandler?: any;
  onDropHandler?: (files: File[], path?: number[]) => void;
  archiveView: boolean;
};

const VIEWMODE = {
  RAIL: 'RAIL',
  LIST: 'LIST',
};

const StyleSampleReview: React.FC<StyleSampleReview> = props => {
  const {
    style,
    media,
    brand_model_id,
    style_model_id,
    reference_model_id,
    references,
    archivedSampleSlideshow,
    mutateReferences,
    mutateReferencesArchive,
    sampleSlideshow: sampleSlides,
    sampleSlideshowUploading,
    onDropHandler,
    updatePositionsHandler,
    archiveView,
  } = props;
  const { user } = useContext(UserContext);
  const { idToken } = user;
  const [openModal, setOpenModal] = useState(false);
  const { state, dispatch } = useFrameUI();

  const location = useLocation();

  const virtualSampleNavItems = ['Virtual Sample', 'Archived'];
  const [tab, setTab] = useState(virtualSampleNavItems[0]);

  const allowedExtensions = ASSET_TYPES.image.extensions.concat(...ASSET_TYPES.model.extensions, ...ASSET_TYPES.gif.extensions);

  const sampleSlideshow = tab === 'Archived' ? archivedSampleSlideshow : sampleSlides;

  const initialSlideIndex = sampleSlideshow
    .filter(s => s.type !== FOLDER)
    .forEach((slide, index) => {
      if (!reference_model_id) {
        return 0;
      }
      if (+slide.reference_model_id === +reference_model_id) {
        return index;
      }
      return 0;
    });

  const [slideIndex, setSlideIndex] = useState<any | null>(initialSlideIndex || 0);
  const [referenceMediaIndex, setReferenceMediaIndex] = useState<any | null>(0);

  const { setDisplayToast } = useContext(NotificationsContext);

  useEffect(() => {
    if (archiveView) {
      setTab(virtualSampleNavItems[1]);
      setViewMode(VIEWMODE.LIST);
    }
  }, [archiveView, sampleSlideshow]);

  useEffect(() => {
    const setIndexEffect = () => {
      sampleSlideshow
        .filter(s => s.type !== FOLDER)
        .forEach((slide, index) => {
          if (!reference_model_id) {
            setSlideIndex(0);
            return;
          }
          if (+slide.reference_model_id === +reference_model_id) {
            setSlideIndex(index);
          }
        });
    };
    setIndexEffect();
  }, [sampleSlideshow, reference_model_id]);

  const [lastReferences, setLastReferences] = useState([]);

  useEffect(() => {
    setLastReferences(references);
    const setPositionsEffect = async () => {
      let str = '';
      references
        .sort((a, b) => a.position - b.position)
        .forEach((ref, index) => {
          str =
            str +
            `${ref.key}:${index + 1}` +
            `${index < references.length - 1 ? ';' : ''}`;
        });
      if (str !== '') await updatePositionsHandler({ position: str });
    };
    if (references.length < lastReferences.length) setPositionsEffect();
  }, [references]);

  const [viewMode, setViewMode] = useState(VIEWMODE.RAIL);
  const [open, setOpen] = useState(false);
  const [, setModalOpen] = useState(false);
  const [, setActiveModalComponent] = useState(null);
  const batchUploadPlus = useRef();

  const sampleSlideFolders = useMemo(() => sampleSlides.filter(s => s.type === FOLDER), [
    sampleSlides,
  ]);

  const [imageUrlZoomed, setImageUrlZoomed] = useState(null);
  const [sampleSlideshowLoaded, setSampleSlideshowLoaded] = useState(
    sampleSlideshow?.reduce((prev, current) => {
      prev[current] = false;
      return prev;
    }, {})
  );
  const [referenceMedia, setReferenceMedia] = useState(null);
  const { setAppModal, setOpenAppModal, openAppModal } = useContext(ModalsContext);

  const [referenceImagesToggle, setReferenceImagesToggle] = useState(true);
  const [currentPath, setCurrentPath] = useState([]);

  const currentPathname = currentPath.map(
    key => sampleSlideshow.find(r => +r.key === +key)?.name
  );

  const getFolderItems = (folderPath, all = false) => {
    const filtered = sampleSlideshow.filter(slide => {
      const rPath = slide.path ?? [];
      if (rPath.length < folderPath.length) {
        return false;
      }
      if (!all && rPath.length !== folderPath.length) {
        return false;
      }
      for (let i = 0; i < folderPath.length; i++) {
        if (rPath[i] !== folderPath[i]) {
          return false;
        }
      }
      return true;
    });
    return filtered;
  };

  const filteredSampleSlideshow = useMemo(() => getFolderItems(currentPath), [
    currentPath,
    references,
    sampleSlides,
    sampleSlideshow,
  ]);

  const { data: bucket, error } = useSWR(
    ['/api/bucket/query/brand', idToken, brand_model_id],
    (url, idToken, brand) => {
      return mmAPI(url, idToken, { brand });
    },
    {
      suspense: false,
    }
  );

  const deleteVSMActionHandler = async data => {
    try {
      const response = await deleteModel(idToken, 'reference', data.key, {
        style: style_model_id,
        brand: brand_model_id,
        location:
          window.location.href.split('/style').shift() +
          `/style/${style_model_id}/samples`,
      });
      if (response?.data?.data) {
        setDisplayToast({
          persist: false,
          type: 'success',
          message: `Deleted Virtual Sample ${data?.name}`,
        });
      }
      mutateReferences();
      mutateReferencesArchive();
    } catch (error) {
      LogRocket.captureException(error);
      setDisplayToast({
        type: 'error',
        persist: false,
        message: `Error occured while archiving sample.`,
      });
    }
  };

  const onArchiveHandler = async values => {
    const { type } = values;
    try {
      const response = await deleteModel(idToken, 'reference', values.key, {
        archive: true,
        style: style_model_id,
        brand: brand_model_id,
        location:
          window.location.href.split('/style').shift() +
          `/style/${style_model_id}/samples/archive`,
      });
      setDisplayToast({
        type: 'success',
        persist: false,
        message:
          response?.data?.data?.message || `Sample has been archived successfully.`,
      });

      if (type === FOLDER) {
        // folder
        const folderItems = getFolderItems([...currentPath, values.key], true);
        for (const item of folderItems) {
          if (item.type === FOLDER) {
            continue;
          }

          const { name } = item;
          const xhr = new XMLHttpRequest();
          xhr.responseType = 'blob';
          xhr.onload = event => {
            const blob = xhr.response;
            const file = blobToFile(blob, name);

            const folder_path =
              `${values.name}/` +
              item.path
                .slice(currentPath.length + 1)
                .map(p => {
                  const folder = folderItems.find(f => +f.key == +p);
                  return folder.name + '/';
                })
                .join('');

            const url = `${ASSETS_LIBRARY.archive_library.assets_storage_path}${bucket.key}/${folder_path}${name}`;
            const ext = name.split('.').pop();
            const metadata = {
              ext: ext,
            };
            handleFireBaseUploadFile(url, file, null, false, metadata);
          };
          xhr.open('GET', item.original);
          xhr.send();
        }
      } else {
        // file
        const { name } = values;
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = event => {
          const blob = xhr.response;
          const file = blobToFile(blob, name);

          const url = `${ASSETS_LIBRARY.archive_library.assets_storage_path}${bucket.key}/${name}`;
          const ext = name.split('.').pop();
          const metadata = {
            ext: ext,
          };
          handleFireBaseUploadFile(url, file, null, false, metadata);
        };
        xhr.open('GET', values.original);
        xhr.send();
      }

      mutateReferences();
      mutateReferencesArchive();
    } catch (error) {
      LogRocket.captureException(error);
      setDisplayToast({
        type: 'error',
        persist: false,
        message: `Error occured while archiving sample.`,
      });
    }
  };

  const onRestoreHandler = useCallback(
    async item => {
      try {
        const response = await deleteModel(idToken, 'reference', item.key, {
          archive: false,
          style: style_model_id,
          brand: brand_model_id,
          restore: true,
          location:
            window.location.href.split('/style').shift() +
            `/style/${style_model_id}/samples/${item.key}`,
        });
        setDisplayToast({
          type: 'success',
          persist: false,
          message:
            response?.data?.data?.message || `Sample has been restored successfully.`,
        });
        mutateReferences();
        mutateReferencesArchive();
      } catch (error) {
        LogRocket.captureException(error);
        setDisplayToast({
          type: 'error',
          persist: false,
          message: `Error occured while unarchiving sample.`,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const tagAsFinalFile = async data => {
    if (data.status !== WORKFLOW_STATUS.FINAL.value) {
      try {
        setDisplayToast({
          persist: false,
          type: 'info',
          message: `Tagging Virtual Sample ${data?.name} as Final`,
        });
        const { data: referenceClone } = await referenceMethodClone(idToken, {
          params: {
            reference: data.key,
          },
        });
        const cloneExtensionIndex = referenceClone?.data?.name.lastIndexOf('.');
        const cloneName = referenceClone?.data?.name.slice(0, cloneExtensionIndex);
        const cloneExtension = referenceClone?.data?.name.slice(cloneExtensionIndex + 1);

        const d = new Date();
        const cloneDatetime =
          d.getUTCFullYear() +
          '-' +
          ('0' + (d.getUTCMonth() + 1)).slice(-2) +
          '-' +
          ('0' + d.getUTCDate()).slice(-2) +
          '-' +
          ('0' + d.getUTCHours()).slice(-2) +
          ('0' + d.getUTCMinutes()).slice(-2) +
          ('0' + d.getUTCSeconds()).slice(-2);

        const cloneTimezone = new Date()
          .toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'long' })
          .substring(4)
          .match(/\b(\w)/g)
          .join('');

        const finalName = `${cloneName}-final-${cloneDatetime}-${cloneTimezone}.${cloneExtension}`;
        const { data: referenceUpdate } = await putModel(
          idToken,
          'reference',
          referenceClone?.data?.key,
          {
            brand: brand_model_id,
            style: style_model_id,
            status: WORKFLOW_STATUS.FINAL.value,
            tags: [...data.tags, 'final'].join(','),
            action: 'final',
            name: finalName,
          }
        );
        if (!referenceUpdate.data || referenceUpdate.data?.error) {
          setDisplayToast({
            type: 'error',
            message: referenceUpdate.data?.error?.message,
          });
        } else {
          setDisplayToast({
            persist: false,
            type: 'success',
            message: `Tagged Virtual Sample ${referenceUpdate.data?.name} as Final`,
          });
          mutateReferences();
          mutateReferencesArchive();
        }
      } catch (e) {
        LogRocket.captureException(e);
        setDisplayToast({
          type: 'error',
          message: `Error tagging Virtual Sample as Final: ${e}`,
        });
      }
    }
  };
  const onClickActivityHandler = async props => {
    try {
      await postActivityMethod(idToken, 'reference', {
        reference: props.key,
        activity: 'reference-downloaded',
        asset_type: 'reference',
        style_id: style_model_id,
        brand_id: brand_model_id,
        location: window.location.href,
      });
      LogRocket.track('reference-downloaded', {
        reference: props.key,
        user: JSON.stringify(user, null, 2),
      });
    } catch (error) {
      LogRocket.captureException(error, {
        tags: {
          // additional data to be grouped as "tags"
          label: 'StyleSampleReview: error on activity POST',
          journey: 'reference-downloaded',
          step: 'onClickActivityHandler',
        },
        extra: {
          reference: props.key,
          user: JSON.stringify(user, null, 2),
        },
      });
    }
  };

  const renameModelActionHandler = async values => {
    const {
      name,
      data: { key },
    } = values;
    const { data: referenceUpdate } = await putModel(idToken, 'reference', key, {
      name,
      brand: brand_model_id,
      style: style_model_id,
      rename: true,
      location: window.location.href,
    });
    if (referenceUpdate.data?.error) {
      setDisplayToast({ type: 'error', message: referenceUpdate.data?.error?.message });
    } else {
      setDisplayToast({ type: 'success', message: `Reference name updated` });
      setOpenModal(false);
      mutateReferences();
      mutateReferencesArchive();
    }
  };

  const onMoveItem = useCallback(
    item => {
      setAppModal(
        <StyleSampleMoveToModal
          headingText='Move items to ...'
          folders={sampleSlideFolders.filter(folder => folder.key !== item.key)}
          open={openAppModal}
          setOpen={setOpenAppModal}
          onSelect={async parent_key => {
            const response = await updateReferencePath(idToken, item.key, {
              parent_key,
            });
            mutateReferences();
            mutateReferencesArchive();
          }}
        />
      );
      setOpenAppModal(true);
    },
    [sampleSlideFolders]
  );

  const editTileOptions = useMemo(() => {
    const options = [
      {
        selectItem: 'Download',
        disabled: false,
        download: true,
        onClick: onClickActivityHandler,
      },
      {
        selectItem: 'Delete',
        ModalComponent: (
          <ConfirmDialog
            dialogBody='Deleting virtual sample can’t be undone. Are you sure you want to continue delete the sample?'
            btnActionHandler={deleteVSMActionHandler}
          />
        ),
        disabled: false,
      },
      {
        selectItem: 'Move',
        disabled: false,
        onClick: onMoveItem,
      },
      {
        selectItem: 'Rename',
        disabled: false,
        ModalComponent: (
          <CreateModelModal
            formId='Rename'
            modalHeading='Rename'
            modelName=''
            btnCloseLabel='CANCEL'
            btnActionLabel='RENAME'
            open={openModal}
            setOpen={setOpenModal}
            modelInputLabel='NEW NAME'
            btnActionHandler={renameModelActionHandler}
            closeOnSubmit={true}
          />
        ),
      },
      {
        selectItem: 'Archive',
        ModalComponent: (
          <ConfirmDialog
            dialogBody='Are you sure you want to continue archive the sample?'
            btnActionHandler={onArchiveHandler}
          />
        ),
        disabled: false,
      },
      {
        selectItem: 'Restore',
        ModalComponent: (
          <ConfirmDialog
            dialogBody='Are you sure you want to restore the archived sample?'
            btnActionHandler={onRestoreHandler}
          />
        ),
        disabled: false,
      },
      {
        selectItem: 'Copy Link',
        disabled: false,
        onClick: () => {
          navigator.clipboard.writeText(
            process.env.REACT_APP_PUBLIC_URL + location.pathname
          );
          setDisplayToast({
            persist: false,
            type: 'success',
            message: `Copied to clipboard.`,
          });
        },
      },
    ];
    const featureFlaggedOption = {
      selectItem: 'Lock as final file',
      onClick: tagAsFinalFile,
      disabled: false,
    };
    options.push(featureFlaggedOption);
    return options;
  }, [
    onClickActivityHandler,
    deleteVSMActionHandler,
    onMoveItem,
    renameModelActionHandler,
    onRestoreHandler,
    tagAsFinalFile,
  ]);

  useEffect(() => {
    const filterBarEffect = () => {
      dispatch({
        type: FrameUIActionsTypes.LOCATIONBAR_TITLE,
        payload: 'Virtual Atelier',
      });
      dispatch({
        type: FrameUIActionsTypes.LOCATIONBAR_BACK_ONCLICK,
        payload: () => window.history.back(),
      });
    };
    filterBarEffect();

    return () => {
      dispatch({
        type: FrameUIActionsTypes.LOCATIONBAR_TITLE,
        payload: null,
      });
      dispatch({
        type: FrameUIActionsTypes.LOCATIONBAR_BACK_ONCLICK,
        payload: null,
      });
    };
  }, [dispatch]);

  type NavigateToSample = { direction?: 'next' | 'previous'; reference?: number };
  const navigateToSample = (options: NavigateToSample) => {
    const { reference } = options;
    if (reference) {
      navigate(`/brand/${brand_model_id}/style/${style_model_id}/samples/${reference}`);
      return;
    }
  };

  const onClickSampleAsset = reference_key => {
    navigateToSample({ reference: reference_key });
  };

  const onClickFolder = reference_key => {
    setCurrentPath(prev => [...prev, reference_key]);
  };

  const { firebaseStorageUrl, handleFireBaseUploadFile } = useContext(UploadContext);

  const srcToFileObjectUrl = (name, src) => {
    if (!src) return null;
    return new Promise(function (resolve, reject) {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = event => {
        const blob = xhr.response;
        const file = blobToFile(blob, name);
        const fileObjectUrl = URL.createObjectURL(file);
        resolve(fileObjectUrl);
      };
      xhr.open('GET', src);
      xhr.send();
    });
  };

  const { data: referenceModel } = useSWR(
    [
      `/api/reference/model/${
        reference_model_id
          ? reference_model_id
          : sampleSlideshow.filter(s => s.type !== FOLDER)?.[0]?.key
      }`,
      idToken,
    ],
    (url, idToken) => {
      return mmAPI(url, idToken);
    },
    {
      suspense: true,
    }
  );

  useEffect(() => {
    sampleSlideshow.map(s => {
      if (!sampleSlideshowLoaded[s.key] || sampleSlideshowLoaded[s.key] === false) {
        setSampleSlideshowLoaded(prev => ({
          [s.key]: true,
          ...prev,
        }));
      }
    });
  }, [sampleSlideshow, sampleSlideshowLoaded]);

  useEffect(() => {
    const referenceModelEffect = async referenceModelVal => {
      if (!referenceModelVal?.assets?.image || sampleSlideshow.length === 0) {
        return;
      }

      const imageUrlFromAsset = sampleSlideshow.find(
        item => parseInt(item.reference_model_id) === parseInt(referenceModelVal?.key)
      )?.assetUrl;

      if (imageUrlFromAsset) {
        const { height, width } = await getImageDimensions(imageUrlFromAsset);

        const fileObjectUrlZoomed = await srcToFileObjectUrl(
          referenceModelVal.name,
          imageUrlFromAsset
        );
        setImageUrlZoomed({
          reference_model_id: referenceModelVal?.key,
          src: fileObjectUrlZoomed,
          width,
          height,
        });
      }
    };
    referenceModelEffect(referenceModel);
  }, [referenceModel, sampleSlideshow]);

  useEffect(() => {
    const mediaEffect = async mediaVal => {
      const getMediaUrls = async m => {
        const { assets } = m;
        const assetUrl =
          assets?.image?.progress > 0
            ? await firebaseStorageUrl(assets?.image?.path)
            : null;
        const { aspectRatio } = await getImageDimensions(assetUrl);
        return {
          ...m,
          assetUrl,
          reference_model_id,
          aspectRatio,
        };
      };

      const getMedia = mediaModels => {
        return Promise.all(
          mediaModels.filter(m => m.assets?.image?.progress > 0).map(m => getMediaUrls(m))
        );
      };

      const mediaFiltered = mediaVal.filter(m => m.source === 'brand');
      const mediaResolved = await getMedia(mediaFiltered);
      setReferenceMedia(mediaResolved.filter(m => m.assetUrl));
    };
    mediaEffect(media);
  }, [media]);

  const { setMessageModelAssoc, onMessagesOpen } = useMessages(idToken, {
    model: null,
    reset: true,
  });

  const openAnnotationModal = () => {
    const modal = (
      <AssetAnnotationModal
        heading='Annotate'
        open={openAppModal}
        setOpen={setOpenAppModal}
        imgSrc={imageUrlZoomed?.src}
        reference={referenceModel}
        onDropHandler={onDropHandler}
        currentPath={currentPath}
        slideIndex={slideIndex}
        slides={filteredSampleSlideshow.filter(s => s.type !== FOLDER)}
        referenceMediaIndex={referenceMediaIndex}
        referenceMedia={
          referenceMedia?.length > 0
            ? referenceMedia.sort((a, b) => a.position - b.position)
            : [{ assetUrl: PlaceholderImg }]
        }
        imageUrlZoomed={imageUrlZoomed}
        style={style}
        setReferenceMediaIndex={setReferenceMediaIndex}
      />
    );
    setAppModal(modal);
    setOpenAppModal(true);
  };

  const handlePopUpModal = ModalComponent => {
    setActiveModalComponent(ModalComponent);
    setModalOpen(true);
  };

  const onCreateFolder = useCallback(async () => {
    await postModel(idToken, 'reference', {
      type: FOLDER,
      brand: brand_model_id,
      model: 'Style',
      model_id: style.key,
      name: 'untitled folder',
      path: currentPath.length > 0 ? currentPath.join(',') : null,
      pathname: '/' + currentPathname.join('/'),
      notes: 'folder',
      tags: 'vsm,sample',
      style: style_model_id,
      position: 0,
      location: window.location.href,
    });
    mutateReferences();
    mutateReferencesArchive();
  }, [brand_model_id, style.key, currentPath, currentPathname]);

  const popUpOptions = [
    {
      selectItem: 'Add new',
      onClick: () => {
        batchUploadPlus.current && batchUploadPlus.current.click();
      },
    },
    {
      selectItem: 'Create folder',
      onClick: () => {
        onCreateFolder();
      },
    },
  ] as IPopUpOptions[];

  const pages = useMemo(() => {
    const pagesArray = [];
    let link = '/';
    for (let i = 0; i < currentPath.length; i += 1) {
      const key = currentPath[i];
      const reference = sampleSlideshow.find(r => +r.key === +key);
      link += `/${reference?.name}`;
      const page: IPages = {
        title: reference?.name,
      };
      if (i < currentPath.length - 1) {
        page.link = link;
        page.onClick = () => setCurrentPath(currentPath.slice(0, i + 1));
      }
      pagesArray.push(page);
    }
    if (currentPath.length > 0) {
      pagesArray.push({
        title: '<',
        link: '/',
        onClick: () => setCurrentPath(currentPath.slice(0, currentPath.length - 1)),
      });
    }
    return pagesArray;
  }, [currentPath, sampleSlideshow]);

  return (
    <>
      <StyleSampleReviewHeader
        style={style}
        reference={referenceModel}
        asset={referenceModel?.assets?.image}
        imageUrlZoomed={imageUrlZoomed}
        heading={{
          label: `${style?.name ?? style?.listing?.name ?? ''} #${style?.number}`,
        }}
        openAnnotationModal={openAnnotationModal}
        onMessagesOpen={onMessagesOpen}
        referenceImagesToggle={referenceImagesToggle}
        setReferenceImagesToggle={setReferenceImagesToggle}
        slideIndex={slideIndex}
        slides={filteredSampleSlideshow.filter(s => s.type !== FOLDER)}
        {...props}
      />
      <div className='style-sample-review'>
        <div className='container-large'>
          <div className='style-sample-review__compare'>
            <div
              className={classNames('style-sample-review__left-col', {
                hide: !referenceImagesToggle,
              })}
            >
              <div className='style-sample-review__heading px-3 mb-4'>
                <span>Reference Image</span>
                <div className='product-listing-dashboard__arrows'>
                  <img
                    className='product-listing-dashboard__main-prev'
                    src={PrevIcon}
                    alt='Previous'
                    onClick={() =>
                      setReferenceMediaIndex(
                        referenceMediaIndex === 0
                          ? referenceMedia.length - 1
                          : referenceMediaIndex - 1
                      )
                    }
                  />
                  <img
                    className='product-listing-dashboard__main-next'
                    src={PrevIcon}
                    alt='Next'
                    onClick={() =>
                      setReferenceMediaIndex(
                        referenceMediaIndex === referenceMedia.length - 1
                          ? 0
                          : referenceMediaIndex + 1
                      )
                    }
                  />
                </div>
              </div>
              <div style={{ marginTop: '40px' }}>
                <SlickRailMode
                  slideIndex={referenceMediaIndex}
                  media={
                    referenceMedia?.length > 0
                      ? referenceMedia.sort((a, b) => a.position - b.position)
                      : [{ assetUrl: PlaceholderImg }]
                  }
                />
              </div>
            </div>
            <div className='style-sample-review__right-col'>
              <div className='style-sample-review__heading px-3 mb-4'>
                <DashboardNav
                  dashboardNavItems={virtualSampleNavItems.map(item => ({
                    title: item,
                    activeBtn: item === tab,
                  }))}
                  darkMode={false}
                  changeTab={setTab}
                />
                <div className='style-sample-review__buttons-wrapper'>
                  <div className='product-listing-dashboard__arrows'>
                    <img
                      className='product-listing-dashboard__main-prev'
                      src={PrevIcon}
                      alt='Previous'
                      onClick={() =>
                        setSlideIndex(
                          slideIndex === 0
                            ? filteredSampleSlideshow.filter(s => s.type !== FOLDER)
                                .length - 1
                            : slideIndex - 1
                        )
                      }
                    />
                    <img
                      className='product-listing-dashboard__main-next'
                      src={PrevIcon}
                      alt='Next'
                      onClick={() =>
                        setSlideIndex(
                          slideIndex ===
                            filteredSampleSlideshow.filter(s => s.type !== FOLDER)
                              .length -
                              1
                            ? 0
                            : slideIndex + 1
                        )
                      }
                    />
                  </div>
                  {viewMode === VIEWMODE.RAIL ? (
                    <img
                      className='style-sample-review__view-icon'
                      src={RailIcon}
                      alt='Rail'
                      onClick={() => {
                        setViewMode(VIEWMODE.LIST);
                        setSlideIndex(0);
                      }}
                    />
                  ) : (
                    <img
                      className='style-sample-review__view-icon'
                      src={ListIconBlack}
                      alt='List'
                      onClick={() => setViewMode(VIEWMODE.RAIL)}
                    />
                  )}
                  <img
                    src={PlusIcon}
                    className='style-sample-review__add'
                    onClick={() => setOpen(!open)}
                  />
                </div>
                <div
                  className={classNames(
                    'style-sample-review__pop-up',
                    open && 'style-sample-review__pop-up-visible'
                  )}
                >
                  <PopUp open={open} setOpen={setOpen}>
                    {popUpOptions &&
                      popUpOptions.map(({ selectItem, to, onClick, ModalComponent }) => (
                        <li
                          className='pop-up__li'
                          onClick={() => {
                            setOpen(false);
                            if (ModalComponent) {
                              handlePopUpModal(ModalComponent);
                            } else if (onClick) {
                              onClick();
                            }
                          }}
                          key={selectItem}
                        >
                          {to ? <Link to={to}>{selectItem}</Link> : selectItem}
                        </li>
                      ))}
                  </PopUp>
                  <input
                    ref={batchUploadPlus}
                    id='batchUploadPlus'
                    type='file'
                    multiple
                    className='d-none'
                    onChange={e => {
                      const { files } = e.currentTarget;
                      setDisplayToast({
                        type: 'info',
                        persist: false,
                        message: `Processing Virtual Sample uploads...`,
                      });
                      onDropHandler(files, currentPath);
                    }}
                    name='batchUpload'
                  />
                </div>
              </div>
              <Breadcrumbs pages={pages} />

              {sampleSlideshowUploading?.map(upload => (
                <AssetProgress key={upload.key} {...upload} />
              ))}

              {!sampleSlideshowUploading?.length &&
                filteredSampleSlideshow[0]?.progress !== -1 &&
                !Object.values(sampleSlideshowLoaded)?.some(s => s.loaded === false) &&
                (viewMode === VIEWMODE.RAIL ? (
                  <SlickRailMode
                    media={
                      filteredSampleSlideshow.filter(s => s.type !== FOLDER)?.length > 0
                        ? filteredSampleSlideshow
                            .filter(s => s.type !== FOLDER
                              && allowedExtensions.includes(s.type))
                            .sort((a, b) => a.position - b.position)
                        : [{ assetUrl: PlaceholderImg }]
                    }
                    slideIndex={slideIndex}
                    onClickSampleAsset={onClickSampleAsset}
                  />
                ) : (
                  <StyleSampleListView
                    media={filteredSampleSlideshow}
                    editTileOptions={
                      tab === 'Archived'
                        ? editTileOptions.filter(r => r.selectItem !== 'Archive')
                        : editTileOptions.filter(r => r.selectItem !== 'Restore')
                    }
                    onClickSampleAsset={onClickSampleAsset}
                    updatePositionsHandler={updatePositionsHandler}
                    onClickRow={onClickFolder}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

interface AtelierGalleryImageWithCustomMetadata extends AtelierGalleryImage {
  customMetadata?: { model: string; model_id: number };
}

export const AssetProgress: React.FC<AtelierGalleryImageWithCustomMetadata> = props => {
  const { asset, imageAsFileSeed, save, onUploadHandler, customMetadata } = props;
  const [, metadata] = asset;
  const { imageUploadProgress } = useContext(UploadContext);
  const progress = imageUploadProgress[metadata?.path];

  return (
    <Asset
      asset={asset}
      saveImages={save}
      imageAsFileSeed={imageAsFileSeed}
      onUploadHandler={onUploadHandler}
      customMetadata={customMetadata}
    >
      <span>{!!progress && ` Uploading: ${progress}%`}</span>
    </Asset>
  );
};

export default StyleSampleReview;

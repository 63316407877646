import React from 'react';
export interface TooltipInfoIconProps {
  fontColor?: string;
}

const TooltipInfoIcon: React.FC<TooltipInfoIconProps> = ({ fontColor }) => {
  return (
    <svg
      className='tooltip-block--svg'
      width='16'
      height='17'
      viewBox='0 0 16 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.6562 2.67578C12.1465 1.16333 10.1377 0.332031 8 0.332031C5.8623 0.332031 3.85352 1.16333 2.34375 2.67578C0.831055 4.18518 0 6.19458 0 8.33203C0 10.4695 0.831055 12.4789 2.34375 13.9883C3.85645 15.5007 5.8623 16.332 8 16.332C10.1377 16.332 12.1465 15.5007 13.6562 13.9883C15.1689 12.4758 16 10.4695 16 8.33203C16 6.19458 15.1689 4.18518 13.6562 2.67578ZM9 12.332C9 12.8851 8.55273 13.332 8 13.332C7.44727 13.332 7 12.8851 7 12.332V7.33203C7 6.77893 7.44727 6.33203 8 6.33203C8.55273 6.33203 9 6.77893 9 7.33203V12.332ZM7 4.33203C7 4.88513 7.44727 5.33203 8 5.33203C8.55273 5.33203 9 4.88513 9 4.33203C9 3.77893 8.55273 3.33203 8 3.33203C7.44727 3.33203 7 3.77893 7 4.33203Z'
        fill={fontColor || '#cbcbcb'}
      />
    </svg>
  );
};
export default TooltipInfoIcon;

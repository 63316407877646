import React from 'react';
import ReactDOM from 'react-dom';
import LogRocket from 'logrocket';
import './styles.css';
import './styles/index.scss';
import i18n from './utils/i18n';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {
  NotificationsProvider,
  UserProvider,
  UploadProvider,
  ViewModeProvider,
  ModalsProvider,
  PaginationProvider,
} from 'providers';
import { FrameUIProvider } from 'providers/FrameUIProvider';

if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_LOGROCKET) {
  LogRocket.init(process.env.REACT_APP_LOGROCKET, {
    release: process.env.REACT_APP_RELEASE_COMMIT,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <NotificationsProvider>
      <ModalsProvider>
        <UserProvider>
          <UploadProvider>
            <ViewModeProvider>
              <FrameUIProvider>
                <PaginationProvider>
                  <App />
                </PaginationProvider>
              </FrameUIProvider>
            </ViewModeProvider>
          </UploadProvider>
        </UserProvider>
      </ModalsProvider>
    </NotificationsProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { useMemo } from 'react';
import moment from 'moment';

import AssetList from './AssetList';

const headerGroups = [
  {
    header: '',
  },
  {
    header: 'Name',
  },
  {
    header: 'Uploaded Date',
  },
  {
    header: 'Metadata',
  },
];

const AssetListView = ({
  assets,
  selectedAssets,
  popUpOptions,
  onClickRow,
  onSelectRow,
  ...props
}) => {
  const cellData = useMemo(
    () =>
      assets?.map(({ itemData }) => {
        return {
          key: itemData.key,
          col: [
            {
              ...(itemData.icon
                ? { icon: itemData.icon }
                : { imageUrl: itemData.imageUrl }),
            },
            {
              cell: itemData.name,
            },
            {
              cell: moment(itemData.created_at).format('DD MMM ha YYYY') || 'Uploaded Date',
            },
            {
              cell: '',
            },
          ],
          data: itemData,
        };
      }),
    [assets]
  );

  return (
    <AssetList
      headerGroups={headerGroups}
      showHeader={false}
      cellData={cellData}
      selectedItems={selectedAssets}
      popUpOptions={popUpOptions}
      onClickRow={onClickRow}
      onSelectRow={onSelectRow}
      {...props}
    />
  );
};

export default AssetListView;

import React, { createContext, useState } from 'react';

import ListIconGrey from 'assets/icons/Icon-list-grey.svg';
import GridIconGrey from 'assets/icons/Icon-grid-grey.svg';
import Cards2IconGrey from 'assets/icons/cards2-grey.svg';
import CardsIconGrey from 'assets/icons/cards-grey.svg';
import ListIconBlack from 'assets/icons/Icon-list-black.svg';
import GridIconBlack from 'assets/icons/Icon-grid-black.svg';
import Cards2IconBlack from 'assets/icons/cards2-black.svg';
import CardsIconBlack from 'assets/icons/cards-black.svg';

export const VIEWMODE = {
  LIST: {
    key: 'list',
    label: 'List View',
    icon: {
      active: Cards2IconBlack,
      inactive: Cards2IconGrey,
    },
  },
  TABLE: {
    key: 'table',
    label: 'Table View',
    icon: {
      active: ListIconBlack,
      inactive: ListIconGrey,
    },
  },
  CARD: {
    key: 'card',
    label: 'Card View',
    icon: {
      active: GridIconBlack,
      inactive: GridIconGrey,
    },
  },
  LARGE: {
    key: 'large',
    label: 'Large View',
    icon: {
      active: CardsIconBlack,
      inactive: CardsIconGrey,
    },
  },
};

export const ViewModeContext = createContext({
  viewMode: null,
  setViewMode: null,
  viewsList: [],
  setViewsList: null,
  showLabel: false,
  setShowLabel: null,
  viewSettings: null,
  setViewSettings: null,
});

const ViewModeProvider = ({ children }) => {
  const [viewMode, setViewMode] = useState(null);
  const [viewsList, setViewsList] = useState([]);
  const [showLabel, setShowLabel] = useState(false);
  const [viewSettings, setViewSettings] = useState([]);

  return (
    <ViewModeContext.Provider
      value={{
        viewMode,
        setViewMode,
        viewsList,
        setViewsList,
        showLabel,
        setShowLabel,
        viewSettings,
        setViewSettings,
      }}
    >
      {children}
    </ViewModeContext.Provider>
  );
};

export default ViewModeProvider;

import React from 'react';
import InnerImageZoom from 'react-inner-image-zoom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './ProductListingDashboard.scss';
import PopUp from 'components/Common/PopUp/PopUp';
import { IPopUpOptions } from 'models/Dashboard/IPopUpOptions';

import NextIcon from 'assets/icons/arrow-next.svg';
import PrevIcon from 'assets/icons/arrow-prev.svg';
import ArrowIcon from 'assets/icons/angle-right-gray.svg';
import DotsIcon from 'assets/icons/manage-icon-gray.svg';

import DownloadIcon from 'assets/icons/download.svg';
import ViewLargerIcon from 'assets/icons/view-larger.svg';
import CloseIcon from 'assets/icons/close.svg';

export interface IProductInfo {
  title: string;
  content?: string;
  accordion: boolean;
}

export interface ICategories {
  title: string;
  category: string;
}

export interface IImages {
  imageUrl?: string;
}

export interface IProduct {
  images: IImages[];
  productInfo: IProductInfo[];
  categories?: ICategories[];
  heading?: string;
  sku: string;
  upc: string;
  lookName: string;
}

export interface IProductListingDashboard {
  product: IProduct[];
  popUpOptions: IPopUpOptions[];
  lookNavigationVisible: boolean;
}

const ProductPage: React.FC<IProductListingDashboard> = props => {
  const { product, popUpOptions, lookNavigationVisible } = props;
  const [openAccordion, setOpenAccordion] = React.useState([]);
  const [currentLook, setCurrentLook] = React.useState(0);
  const [currentImage, setCurrentImage] = React.useState(0);
  const [openPopUp, setOpenPopUp] = React.useState(false);
  const [lightboxOpen, setLightboxOpen] = React.useState(false);

  return (
    <>
      <div className='product-listing-dashboard test'>
        <div className='row'>
          <div className='col-md-7'>
            <div className='row row-reverse-mobile'>
              <div className='col-md-3'>
                <div className='product-listing-dashboard__right-rail'>
                  {product[currentLook].images.map(({ imageUrl }, idx) => (
                    <img
                      key={idx}
                      className={
                        currentImage === idx
                          ? 'product-listing-dashboard__small-img product-listing-dashboard__current-img'
                          : 'product-listing-dashboard__small-img'
                      }
                      src={imageUrl}
                      onClick={() => setCurrentImage(idx)}
                      alt={product[currentLook].heading}
                    />
                  ))}
                </div>
              </div>
              <div className='col-md-9'>
                <div className='product-listing-dashboard__main'>
                  <TransitionGroup component={null}>
                    <CSSTransition classNames='fade-in' timeout={500} key={currentImage}>
                      <img
                        className='product-listing-dashboard__main-img'
                        src={product[currentLook].images[currentImage].imageUrl}
                        alt={product[currentLook].heading}
                      />
                    </CSSTransition>
                  </TransitionGroup>
                </div>

                <div className='product-listing-dashboard__main-footer'>
                  <button
                    className='button-dashboard color-white-bg'
                    onClick={() => setLightboxOpen(!lightboxOpen)}
                  >
                    <img src={ViewLargerIcon} alt='View Larger' className='btn-icon' />
                    View Larger
                  </button>
                  <div className='product-listing-dashboard__look-name'>
                    Look {product[currentLook].lookName}
                  </div>
                </div>

                {lookNavigationVisible ? (
                  <div className='product-listing-dashboard__count'>
                    <img
                      className='look-listing__prev'
                      src={PrevIcon}
                      alt='Previous'
                      onClick={() =>
                        setCurrentLook(
                          currentLook === 0 ? product.length - 1 : currentLook - 1
                        )
                      }
                    />
                    Look {currentLook + 1} / {product.length}
                    <img
                      className='look-listing__next'
                      src={NextIcon}
                      alt='Next'
                      onClick={() =>
                        setCurrentLook(
                          currentLook === product.length - 1 ? 0 : currentLook + 1
                        )
                      }
                    />
                  </div>
                ) : null}

                <div className='product-listing-dashboard__arrows'>
                  <img
                    className='product-listing-dashboard__main-prev'
                    src={PrevIcon}
                    alt='Previous'
                    onClick={() =>
                      setCurrentImage(
                        currentImage === 0
                          ? product[currentLook].images.length - 1
                          : currentImage - 1
                      )
                    }
                  />
                  <img
                    className='product-listing-dashboard__main-next'
                    src={PrevIcon}
                    alt='Next'
                    onClick={() =>
                      setCurrentImage(
                        currentImage === product[currentLook].images.length - 1
                          ? 0
                          : currentImage + 1
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-5'>
            <div className='product-listing-dashboard__info'>
              <div className='product-listing-dashboard__heading'>
                <h3>{product[currentLook].heading}</h3>
                <div className='product-listing-dashboard__edit'>
                  <img
                    src={DotsIcon}
                    alt='Edit'
                    onClick={() => setOpenPopUp(!openPopUp)}
                  />
                  <div className={openPopUp ? 'visible-popup' : 'hidden-popup'}>
                    <PopUp open={openPopUp}>
                      {popUpOptions &&
                        popUpOptions.map(({ selectItem }, idx) => (
                          <li className='pop-up__li' key={idx}>
                            {selectItem}
                          </li>
                        ))}
                    </PopUp>

                    <div className='pop-up__expand'>
                      <ul className='pop-up__ul'>
                        <li className='pop-up__li'>Edit</li>
                        <li className='pop-up__li'>Delete</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                {product[currentLook].categories &&
                  product[currentLook].categories.map(({ title, category }, idx) => (
                    <div key={idx} className='product-listing-dashboard__categories'>
                      <div className='product-listing-dashboard__cat-title'>{title}</div>
                      <div className='product-listing-dashboard__category'>
                        {category}
                      </div>
                    </div>
                  ))}
              </div>
              {product[currentLook].productInfo &&
                product[currentLook].productInfo.map(
                  ({ title, content, accordion }, idx) => (
                    <div className='product-listing-dashboard__row' key={idx}>
                      <h5
                        className={accordion ? 'product-listing-dashboard__title' : ''}
                        onClick={
                          openAccordion.includes(idx)
                            ? () => setOpenAccordion(openAccordion.filter(e => e !== idx))
                            : () => setOpenAccordion([...openAccordion, idx])
                        }
                      >
                        {title}
                        {accordion ? (
                          <img
                            className={
                              openAccordion.includes(idx) ? 'arrow-bottom' : 'arrow-right'
                            }
                            src={ArrowIcon}
                            alt='Open'
                          />
                        ) : null}
                      </h5>
                      {accordion ? (
                        <div
                          className={
                            openAccordion.includes(idx)
                              ? 'visible-accordion'
                              : 'hidden-accordion'
                          }
                        >
                          <div className='product-listing-dashboard__content'>
                            <h4>{content}</h4>
                          </div>
                        </div>
                      ) : (
                        <div className={accordion ? 'hidden' : 'visible'}>
                          <div className='product-listing-dashboard__content'>
                            <h4>{content}</h4>
                          </div>
                        </div>
                      )}
                    </div>
                  )
                )}
              <div className='product-listing-dashboard__categories pt-4'>
                <div className='product-listing-dashboard__cat-title'>SKU</div>
                <div className='product-listing-dashboard__category'>
                  {product[currentLook].sku}
                </div>
              </div>
              <div className='product-listing-dashboard__categories pt-1'>
                <div className='product-listing-dashboard__cat-title'>UPC</div>
                <div className='product-listing-dashboard__category'>
                  {product[currentLook].upc}
                </div>
              </div>

              <div className='product-listing-dashboard__btn'>
                <img
                  src={DownloadIcon}
                  alt='Download'
                  className='product-listing-dashboard__download pr-4'
                />
                <button className='button-primary'>virtual atelier</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CSSTransition classNames='lightbox' timeout={200} in={lightboxOpen} unmountOnExit>
        <div className='product-listing-dashboard__light-box'>
          <div className='product-listing-dashboard__lightbox-header'>
            {product[currentLook].heading}
            <img
              src={CloseIcon}
              alt='Close'
              onClick={() => setLightboxOpen(!lightboxOpen)}
            />
          </div>
          <div className='product-listing-dashboard__lightbox-inner'>
            <TransitionGroup component={null}>
              <CSSTransition classNames='fade-in' timeout={200} key={currentImage}>
                <InnerImageZoom
                  fullscreenOnMobile={true}
                  className='product-listing-dashboard__main-img'
                  src={product[currentLook].images[currentImage].imageUrl}
                />
              </CSSTransition>
            </TransitionGroup>
            <div className='product-listing-dashboard__arrows'>
              <img
                className='product-listing-dashboard__main-prev'
                src={PrevIcon}
                alt='Previous'
                onClick={() =>
                  setCurrentImage(
                    currentImage === 0
                      ? product[currentLook].images.length - 1
                      : currentImage - 1
                  )
                }
              />
              <img
                className='product-listing-dashboard__main-next'
                src={PrevIcon}
                alt='Next'
                onClick={() =>
                  setCurrentImage(
                    currentImage === product[currentLook].images.length - 1
                      ? 0
                      : currentImage + 1
                  )
                }
              />
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  );
};

export default ProductPage;

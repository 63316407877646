import React, { Dispatch } from 'react';
import TemplateImgTop from 'assets/images/template_img_top.png';
import ViewIcon from 'assets/icons/view.svg';
export interface TemplateSelectionProps {
  id: string;
  templateImg: string;
  selectedTemplate: boolean;
  dispatch: Dispatch<any>;
}

const TemplateSelection: React.FC<TemplateSelectionProps> = ({
  id,
  templateImg,
  selectedTemplate,
  dispatch,
}) => (
  <div className='template_selection__boxes'>
    <div className='template_selection__view-icon-container'>
      <img className='template_selection__view-icon' src={ViewIcon} />
    </div>
    <div className='template_selection__img-top-container'>
      <img className='template_selection__img-top' src={TemplateImgTop} />
    </div>
    <img className='template_selection__template-img' src={templateImg} />
    <div
      className='button-secondary template_selection__button'
      onClick={() => {
        dispatch({ type: 'setEditTemplate', display: false });
        dispatch({ type: 'setTab', payload: 'Landing Page' });
        !selectedTemplate && dispatch({ type: 'setTemplate', template: id });
      }}
    >
      {selectedTemplate ? 'Edit' : 'Use this Template'}
    </div>
  </div>
);

export default TemplateSelection;

import React from 'react';
import DotsIcon from 'assets/icons/dots-grey-horizontal.svg';
import PlusIcon from 'assets/icons/PlusIcon.svg';
import classNames from 'classnames';
import PopUp from 'components/Common/PopUp/PopUp';
import ReferenceAssetProgress from './ReferenceAssetProgress';
import { ModalsContext } from 'providers/ModalsProvider';

const SlickCenterModeThumb = props => {
  const { asNav, showNav, m, deleteMediaActionHandler } = props;
  const [open, setOpen] = React.useState(false);
  const popUpOptions = React.useMemo(
    () => [
      {
        selectItem: 'Delete Image',
      },
    ],
    []
  );

  const { setOpenDialog, setDialogProps } = React.useContext(ModalsContext);

  return (
    <div key={m?.key}>
      {m?.src && !m?.asset && (
        <div
          className={classNames('', {
            'style-summary__vs-single': showNav,
          })}
        >
          <img
            className={classNames({
              'afs-asset-review__img': !showNav,
              'style-summary__img-sm': showNav,
            })}
            src={m?.src}
          />
        </div>
      )}
      {!m?.src && m?.asset ? <ReferenceAssetProgress asNav={asNav} {...m} /> : null}

      <div className='product-media__dots-container' style={{ position: 'relative' }}>
        <img
          className='product-media__dots'
          src={DotsIcon}
          alt='Image options'
          onClick={e => {
            setOpen(!open);
            console.log('setOpen', open);
          }}
        ></img>
        <PopUp open={open} setOpen={setOpen}>
          {popUpOptions &&
            popUpOptions.map(({ selectItem }) => (
              <li
                className='pop-up__li'
                onClick={() => {
                  setDialogProps({
                    dialogBody: 'Are you sure you would like to delete this image?',
                    btnActionHandler: () => {
                      deleteMediaActionHandler(m.key);
                    },
                  });
                  setOpenDialog(true);
                }}
                key={selectItem}
              >
                {selectItem}
              </li>
            ))}
        </PopUp>
      </div>

      {m?.dropRegion && (
        <div
          className={classNames('', {
            'style-summary__vs-single': showNav,
          })}
        >
          <img className='style-summary__vsm-drop-icon' src={PlusIcon} alt='Dropzone' />
        </div>
      )}
    </div>
  );
};

export default SlickCenterModeThumb;

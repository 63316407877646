import React, { createContext, useState } from 'react';

export const PER_PAGES = [
  {
    label: '8 Items',
    value: 8,
  },
  {
    label: '12 Items',
    value: 12,
  },
  {
    label: '24 Items',
    value: 24,
  },
  {
    label: 'View All',
    value: 100,
  },
];
export const DEFAULT_PER_PAGE = 12;

export const PaginationContext = createContext({
  productsListCurPage: null,
  setProductsListCurPage: null,
  productsListPerPage: null,
  setProductsListPerPage: null,
});

const PaginationProvider = ({ children }) => {
  const [productsListCurPage, setProductsListCurPage] = useState(0);
  const [productsListPerPage, setProductsListPerPage] = useState(DEFAULT_PER_PAGE);

  return (
    <PaginationContext.Provider
      value={{
        productsListCurPage,
        setProductsListCurPage,
        productsListPerPage,
        setProductsListPerPage,
      }}
    >
      {children}
    </PaginationContext.Provider>
  );
};

export default PaginationProvider;

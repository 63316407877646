import React, { useRef, useContext, useState } from 'react';
import './ProductInfoSideBar.scss';
import { Tween } from 'react-gsap';
import { UserContext } from 'providers/contexts';
import useTeam from 'hooks/useTeam';
import CollectionAccessModal from '../Collections/CollectionAccessModal/CollectionAccessModal';
import { useFrameUI } from 'providers/FrameUIProvider';

const ProductInfoSideBar = ({ brand_model_id }: any) => {
  const node = useRef();
  const { state } = useFrameUI();
  const [open, setOpen] = useState(false);
  const { user } = useContext(UserContext);
  const { idToken, job_type } = user;
  const { brandAccounts } = useTeam(idToken, { brand_model_id, jobType: job_type });

  return (
    <div className={'livechat--visible'}>
      <CollectionAccessModal
        idToken={idToken}
        collection={state?.selectedCollection?.model}
        heading={state?.selectedCollection?.model?.name}
        allMembers={brandAccounts}
        numOfMembers={brandAccounts.length}
        popUpOptions={[]}
        open={open}
        setOpen={setOpen}
      />
      <Tween
        from={{ x: '50px', autoAlpha: 0 }}
        to={{ x: '0px', autoAlpha: 1 }}
        duration={0.5}
      >
        <div className='ProductInfoSideBar' ref={node}>
          <div className='ProductInfoSideBar__wrap'>
            <div className='ProductInfoSideBar__body'>
              <div className='ProductInfoSideBar__collectionName'>
                {state?.selectedCollection?.model?.name}
              </div>
              <div className='ProductInfoSideBar__memberAccess'>members have access</div>
              <div className='ProductInfoSideBar__profile'>
                {!user?.account?.assets.profile_picture.url ? (
                  <div className='livechat__avatar'>
                    {user?.account?.first?.slice(0, 1)}
                    {user?.account?.last?.slice(0, 1)}
                  </div>
                ) : (
                  <img
                    src={user?.account?.assets.profile_picture.url}
                    alt='Profile'
                    className='livechat__avatar'
                  />
                )}
                {brandAccounts.length > 0 &&
                  (!brandAccounts[0]?.account?.assets.profile_picture.url ? (
                    <div className='livechat__avatar'>
                      {brandAccounts[0]?.first?.slice(0, 1)}
                      {brandAccounts[0]?.last?.slice(0, 1)}
                    </div>
                  ) : (
                    <img
                      src={brandAccounts[0]?.account?.assets.profile_picture.url}
                      alt='Profile'
                      className='livechat__avatar'
                    />
                  ))}
                {brandAccounts.length > 1 &&
                  (!brandAccounts[1]?.account?.assets.profile_picture.url ? (
                    <div className='livechat__avatar'>
                      {brandAccounts[1]?.first?.slice(0, 1)}
                      {brandAccounts[1]?.last?.slice(0, 1)}
                    </div>
                  ) : (
                    <img
                      src={brandAccounts[1]?.account?.assets.profile_picture.url}
                      alt='Profile'
                      className='livechat__avatar'
                    />
                  ))}
                {brandAccounts.length - 2 > 0 && (
                  <div
                    className='profile'
                    style={{
                      color: '#666665',
                      border: '2px solid #CBCBCB',
                      paddingLeft: '4px',
                    }}
                  >
                    +{brandAccounts.length - 2}
                  </div>
                )}
              </div>
              <button
                className='ProductInfoSideBar__ManageAccess'
                onClick={() => setOpen(true)}
              >
                manage access
              </button>
            </div>
          </div>
        </div>
      </Tween>
    </div>
  );
};

export default ProductInfoSideBar;

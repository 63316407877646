import React from 'react';
import { ERRORS } from 'constants/errors';
import { navigate } from '@reach/router';
import { ModalsContext } from 'providers/ModalsProvider';

const ErrorOverlay = props => {
  const { error, resetErrorBoundary } = props;
  const { dialogProps } = React.useContext(ModalsContext);
  const goBack = () => {
    resetErrorBoundary();
    window.history.back();
  };
  return (
    <>
      {(error.type === ERRORS.PERMISSION_ERROR ||
        error.response?.data?.error?.type === ERRORS.PERMISSION_ERROR) && (
        <>
          <div className='dialog'>
            <div className='dialog__content'>
              <div className='dialog__inner'>{dialogProps?.dialogBody}</div>
              <div className='dialog__footer'>
                <button className='button-regular color-warning-bg' onClick={goBack}>
                  Go Back
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ErrorOverlay;

import React from 'react';
import './SidebarInfo.scss';
import useClickAway from 'hooks/useClickAway';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import LineIcon from 'assets/icons/polygon.svg';
import CloseIcon from 'assets/icons/close.svg';
import CollectionIcon from 'assets/icons/polygon.svg';
import UserIcon from 'assets/icons/user.svg';
import VirtualRunwayIcon from 'assets/icons/virtual-runway.svg';
import Loader from 'components/Loader/Loader';
import { ApiAsset } from 'components/VirtualAtelier/StyleSummary/StyleSummary';
import Asset from 'components/Asset';

export interface IMembers {
  firstName: string;
  lastName: string;
  profileImg?: string;
  email?: string;
  profileAsset?: ApiAsset;
}
export type InvitedAccount = {
  firstName: string;
  lastName: string;
  profileImg?: string;
  inviteKey: string;
  email: string;
  inviteUrl?: string;
};

export interface ISidebarInfo {
  model?: any;
  heading: string;
  type: string;
  loading?: boolean;
  members?: IMembers[];
  invites?: InvitedAccount[];
  size?: string;
  modifiedBy?: string;
  modified?: string;
  createdBy?: string;
  created?: string;
  onClose?: () => void;
  handleAppModal?: () => void;
}

const SidebarInfo: React.FC<ISidebarInfo> = props => {
  const {
    model,
    heading,
    type,
    loading,
    members,
    invites,
    size,
    modified,
    created,
    createdBy,
    modifiedBy,
    onClose,
    handleAppModal,
  } = props;

  const sidebarInfoNode = React.useRef();
  useClickAway(sidebarInfoNode, onClose);

  const getIconType = (type: string) => {
    switch (type) {
      case 'line':
        return LineIcon;
      case 'collection':
        return CollectionIcon;
      case 'virtual runway':
        return VirtualRunwayIcon;
      default:
        return LineIcon;
        break;
    }
  };

  return (
    <>
      <div className='sidebar-info' ref={sidebarInfoNode}>
        <div className='sidebar-info__header'>
          <div className='sidebar-info__header-row'>
            <div className='sidebar-info__heading'>{heading}</div>
            <button
              onClick={() => {
                onClose?.();
              }}
            >
              <img className='livechat__close' src={CloseIcon} alt='Close' />
            </button>
          </div>
          <div className='sidebar-info__type'>
            <img src={getIconType(type)} alt={type} />
          </div>
        </div>

        <div className='sidebar-info__body'>
          <div className='sidebar-info__row'>
            {loading && <Loader active={true}></Loader>}
            {!loading && !!members?.length && (
              <>
                <div className='sidebar-info__subheading'>Members</div>
                <div className='sidebar-info__members'>
                  {members?.map((props, idx) => (
                    <div className='sidebar-info__member' key={idx}>
                      <ProfileAssetCircle {...props} />
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>

          <div className='sidebar-info__row'>
            <div className='sidebar-info__subheading'>Type</div>
            <CopyToClipboard text={model?.key}>
              <div className='sidebar-info__text'>{type}</div>
            </CopyToClipboard>
          </div>
          {size && (
            <div className='sidebar-info__row'>
              <div className='sidebar-info__subheading'>Size</div>
              <div className='sidebar-info__text'>{size}</div>
            </div>
          )}
          {modifiedBy && (
            <div className='sidebar-info__row'>
              <div className='sidebar-info__subheading'>Modified</div>
              <div className='sidebar-info__text'>
                {modified} by {modifiedBy}
              </div>
            </div>
          )}
          {createdBy && (
            <div className='sidebar-info__row'>
              <div className='sidebar-info__subheading'>Created</div>
              <div className='sidebar-info__text'>
                {created} by {createdBy}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SidebarInfo;

export const ProfileAssetCircle = props => {
  const {
    profileAsset,
    firstName,
    lastName,
    imgClassName = 'sidebar-info__profile-img',
  } = props;
  return (
    <>
      {profileAsset?.progress > 0 ? (
        <Asset upload={false} asset={['profile_picture', profileAsset]}>
          <img src={UserIcon} alt={`${firstName} ${lastName}`} className={imgClassName} />
        </Asset>
      ) : (
        <>
          {firstName || lastName ? (
            <span
              role='text'
              aria-label={`${firstName} ${lastName}`}
              className={`${imgClassName}-noImg`}
            >
              {firstName?.slice(0, 1)}
              {lastName?.slice(0, 1)}
            </span>
          ) : (
            <img alt={'Member'} src={UserIcon} className='sidebar-info__profile-user' />
          )}
        </>
      )}
    </>
  );
};

import React, { useState, useContext, useMemo } from 'react';

import 'components/Dashboard/ProductUpload/ProductUpload.scss';
import './AssetLibraryFilesUpload.scss';
import Modal from 'components/Common/Modal/Modal';
import DragAndDrop from 'components/Upload/DragAndDrop';
import { UploadContext } from 'providers/contexts';
import { ASSETS_LIBRARY } from '../util';
import { AtelierGalleryImage } from 'components/VirtualAtelier/Uploads/AtelierModelUploads/AtelierModelUploads';
import { ModalsContext } from 'providers/ModalsProvider';

const STEP_UPLOAD = 'upload';
const STEP_PREVIEW = 'preview';
const STEP_LOADING = 'loading';

interface IAssetLibraryFilesUpload {
  path?: string;
  isProductAssetUpload?: boolean;
  prefferedFileTypes?: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose?: () => void;
  onUploadHandler?: () => void;
  onProductAssetUploadHandler?: (files: File[]) => Promise<AtelierGalleryImage[]>;
}

export const AssetLibraryFilesUpload: React.FC<IAssetLibraryFilesUpload> = ({
  path = ASSETS_LIBRARY.assets_library.assets_storage_path,
  isProductAssetUpload = false,
  prefferedFileTypes,
  open,
  setOpen,
  onClose,
  onUploadHandler,
  onProductAssetUploadHandler,
}) => {
  const {
    handleFireBaseUpload,
    handleFireBaseUploadFile,
    imageUploadProgress,
  } = useContext(UploadContext);

  const { setOpenDialog, setDialogProps } = useContext(ModalsContext);

  const [files, setFiles] = useState([]);
  const [step, setStep] = useState(STEP_UPLOAD);

  const addProductAssetModelFiles = async (newFiles: File[]) => {
    setStep(STEP_LOADING);
    const newAtelierImages = await onProductAssetUploadHandler?.(newFiles);
    setFiles([
      ...newAtelierImages.map(image => {
        const [_, imageMetadata] = image.asset;
        const url = imageMetadata.path;
        const customMetadata = {
          model: image.model,
          model_id: image.model_id,
        };

        handleFireBaseUpload(
          url,
          image.localFile,
          { ...imageMetadata, ...customMetadata },
          image.type,
          onUploadHandler
        );

        return {
          name: image.name,
          type: image.name.split('.').pop(),
          url,
        };
      }),
      ...files,
    ]);
    setStep(STEP_PREVIEW);
  };

  const addNewFiles = (newFiles: File[]) => {
    const newFilesArray = Array.from(newFiles);
    setFiles([
      ...newFilesArray.map((file: File) => {
        const url = `${path}/${file.name}`;
        const ext = file.name.split('.').pop();
        const metadata = {
          ext: ext,
        };
        handleFireBaseUploadFile(url, file, onUploadHandler, false, metadata);
        return {
          name: file.name,
          type: file.name.split('.').pop(),
          url,
        };
      }),
      ...files,
    ]);
    setStep(STEP_PREVIEW);
  };

  const onChange = event => {
    const localFiles: File[] = Array.from(event.currentTarget.files);
    if (isProductAssetUpload) {
      addProductAssetModelFiles(localFiles);
    } else {
      addNewFiles(localFiles);
    }
  };

  const onDropFile = (fileList: File[]) => {
    const localFiles = Array.from(fileList);
    if (isProductAssetUpload) {
      addProductAssetModelFiles(localFiles);
    } else {
      addNewFiles(fileList);
    }
  };

  const onCloseHandler = () => {
    if (step === STEP_LOADING) {
      const response = confirm(
        'Are you sure you want to cancel? This may interupt your file upload.'
      );
      if (response) {
        setOpen(!open);
        onClose?.();
      }
    } else {
      setOpen(!open);
      onClose?.();
    }
  };

  const btnActionHandler = () => {
    setOpen(!open);
    onClose?.();
  };

  const totalProgress = useMemo(() => {
    let progress = 0;
    files.map(file => {
      progress += imageUploadProgress[file.url] || 0;
    });
    return progress / Math.max(files.length, 1);
  }, [files, imageUploadProgress]);

  return (
    <Modal
      className='asset-library-file-upload'
      modalHeading={step === STEP_UPLOAD ? 'Upload Asset' : 'Upload Detail'}
      modalBtnActionLabel={step === STEP_PREVIEW && 'Done'}
      modalBtnActionHandler={btnActionHandler}
      edit={step === STEP_PREVIEW}
      secondaryBtnActionLabel={step === STEP_PREVIEW && 'Add more files'}
      secondaryBtnAction={() => setStep(STEP_UPLOAD)}
      backgroundColor='#fff'
      modalOpen={open}
      setModalOpen={setOpen}
      formHandlerId='assetLibraryFilesUpload'
      closeOnClickAway={false}
      modalBtnCloseHandler={onCloseHandler}
      footer={
        <>
          {files.length ? (
            <div className='asset-files__upload-progress'>
              {files.length} item loading : {+totalProgress.toFixed(2)}%
            </div>
          ) : (
            ''
          )}
        </>
      }
    >
      {step === STEP_LOADING ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '2rem 0',
            width: '100%',
          }}
        >
          <p>Processing Product Asset Uploads...</p>
        </div>
      ) : null}

      {step === STEP_UPLOAD ? (
        <DragAndDrop onDropHandler={onDropFile}>
          <div className='product-upload'>
            <div className='product-upload__drag-area'>
              <div className='product-upload__txt'>
                <p className='product-upload__instructions'>
                  Drag and drop or select files
                </p>
                <p className='txt-gray-light'>
                  {prefferedFileTypes ?? 'Files .excel .pdf .jpg .docx etc'}
                </p>
              </div>
              <div className='image-upload'>
                <label htmlFor='upload' className='button-small color-white-bd-bg mt-4'>
                  Select
                </label>
                <input
                  type='file'
                  id='upload'
                  name='upload'
                  multiple
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
        </DragAndDrop>
      ) : (
        <div className='asset-files'>
          {files.map(file => {
            return (
              <div key={file.url} className='asset-file'>
                <div className='asset-file__type'>{file.type}</div>
                <div className='asset-file__url'>
                  {file.url} : {imageUploadProgress[file.url] || 0}%
                </div>
                {/* <img className='asset-file__delete' src={CloseIcon} alt='Close' /> */}
              </div>
            );
          })}
        </div>
      )}
    </Modal>
  );
};

export default AssetLibraryFilesUpload;

import React, { useState, useContext, useEffect } from 'react';
import { navigate } from '@reach/router';
import LogRocket from 'logrocket';
import {
  inviteMethodParse,
  accountMethodSignup,
  setAuthToken,
  checkPasswordIfPwned,
} from 'services/Api';
import LoginScreen from 'components/Authentication/Login/LoginScreen/LoginScreen';
import { useLocationSearchParam } from 'hooks/useLocation';
import useSWR from 'swr';
import { useForm } from 'react-hook-form';
import { UserContext, NotificationsContext } from 'providers/contexts';
import { convertHexToRGBA } from 'utils/hexToRgbaConverter';
import { hashString, verifyPwned, errorMessage } from 'utils/pwned';
import { PasswordRules } from 'constants/index';

const SignUp = () => {
  const { user, setUser } = useContext(UserContext);
  const { setDisplayToast } = useContext(NotificationsContext);
  const inviteTokenParam = useLocationSearchParam('token');

  const [inviteBrand, setInviteBrand] = useState(null);

  const { data: invite, error } = useSWR(
    [inviteTokenParam],
    token => {
      return inviteMethodParse(token);
    },
    {
      suspense: true,
    }
  );

  useEffect(() => {
    /** Determines if invite is accepted and linked to account, redirects if so*/
    const inviteEffect = () => {
      if (!invite) return;

      const { account, status } = invite;
      if (account) {
        navigate(`/?token=${inviteTokenParam}`);
        return;
      }

      const { email, data: inviteData } = invite;
      const brand = inviteData?.find(model => model.KIND === 'Brand');
      const team = inviteData?.find(model => model.KIND === 'Team');
      setInviteBrand(brand);
      if (!user?.account) {
        setDisplayToast({
          persist: true,
          type: 'info',
          message: `${email} has been invited to access ${brand ? `${brand.name}` : ''}`,
        });
      }
    };
    inviteEffect();
  }, [invite, inviteTokenParam]);

  useEffect(() => {
    const errorEffect = errorValue => {
      if (errorValue) {
        setDisplayToast({
          persist: true,
          type: 'error',
          message:
            error?.message ||
            error?.type ||
            'Error parsing invite. Please ask a team Admin to send you a new invite.',
        });
      }
    };
    errorEffect(error);
  }, [error]);

  const { register, handleSubmit, errors } = useForm();

  const checkIfPwned = async value => {
    const hashedPassword = hashString(value);
    const checkPwn = await checkPasswordIfPwned({
      hash: hashedPassword.substring(0, 5),
    });
    const found = verifyPwned(checkPwn.data.data, hashedPassword);
    return found;
  };

  const registrationHandler = async ({
    email,
    password,
    firstName = '',
    lastName = '',
  }) => {
    /** build params with first, lastm and conditionally, an invite token from router */
    const params = {
      email,
      password,
      first: firstName,
      last: lastName,
    };
    if (inviteTokenParam) {
      params.invite = inviteTokenParam;
    }

    try {
      setDisplayToast(false);
      console.trace('accountMethodSignup', params, inviteTokenParam);
      const isPassPwnd = await checkIfPwned(password);
      if (isPassPwnd) {
        setDisplayToast({
          type: 'error',
          message: errorMessage,
        });
        return;
      }

      const response = await accountMethodSignup(params);
      /**
       * Accepted invite should return grants
       */
      const { permissions, account, custom_token, idToken } = response.data.data;
      console.trace('idToken accountMethodSignup', idToken);
      console.trace('permissions accountMethodSignup', permissions);
      console.trace('custom_token accountMethodSignup', custom_token);
      console.trace('account accountMethodSignup', account);
      setAuthToken(idToken);
      const { model_id: brandKey } = permissions.find(g => g.model === 'Brand');
      const { model, model_id } =
        permissions.find(g => !['Brand', 'Team'].includes(g.model)) || {};
      console.trace('brandKey accountMethodSignup', brandKey);
      console.trace('model accountMethodSignup', model, model_id);

      if (brandKey) {
        /**
         * Brand, Account and idToken set on user
         */

        setUser(prevUser => {
          const userSignupProps = {
            ...prevUser,
            custom_token,
            idToken,
            brandKey,
            account,
            invite: null,
          };
          if (model) userSignupProps[model.toLowerCase()] = model_id;
          console.trace('userSignupProps', userSignupProps);
          return userSignupProps;
        });
        navigate(`/brand/${brandKey}`);
      } else {
        setDisplayToast({
          persist: true,
          type: 'error',
          message:
            'Error associating your invite with a parent Brand. Please contact support with details about your invite.',
        });
        LogRocket.captureMessage(`MM API invite error`, {
          tags: {
            // additional data to be grouped as "tags"
            journey: 'account/method/signup',
            step: 'brandPersistence',
          },
          extra: {
            // additional arbitrary data associated with the event
            email,
            firstName,
            lastName,
            inviteTokenParam,
            brandKey,
          },
        });
      }
    } catch (error) {
      setDisplayToast({
        persist: true,
        type: 'error',
        message: `Registration error: ${error?.message}`,
      });
      LogRocket.captureMessage(`MM API invite error`, {
        tags: {
          // additional data to be grouped as "tags"
          journey: 'account/method/signup',
          step: 'registrationHandler',
        },
        extra: {
          // additional arbitrary data associated with the event
          email,
          firstName,
          lastName,
          inviteTokenParam,
        },
      });
      console.log('registrationHandler error?.type', error?.type);
    }
  };

  const fontColor = inviteBrand?.template?.login?.fontColor;
  const modifiedFontColor = fontColor && convertHexToRGBA(fontColor, 30);
  const backgroundColor = inviteBrand?.template?.login?.backgroundColor;

  return (
    <LoginScreen
      title={'Sign Up'}
      bgImage={inviteBrand?.assets?.hero}
      logo={inviteBrand?.assets?.logo}
      logoPublic={true}
      onSubmit={handleSubmit(registrationHandler)}
      centered={true}
      rememberOptionVisible={false}
      lightColor={inviteBrand?.template?.login?.backgroundColor}
      darkColor={inviteBrand?.template?.login?.fontColor}
    >
      <>
        <div className='control-block'>
          <label className='control--label'>{`Email`}</label>
          <input
            name={`email`}
            placeholder={invite?.email}
            defaultValue={invite?.email}
            className='control control--full-width control--text'
            style={{ borderColor: modifiedFontColor }}
            type='text'
            ref={register({
              required: 'Email is required.',
              pattern: /^\S+@\S+$/i,
            })}
          />
        </div>
        {errors.email && <span className='error-text'>{errors.email.message}</span>}

        <InputTextBlock
          name='password'
          validationRules={{
            required: 'Password is required.',
            minLength: {
              value: PasswordRules.minLengthVal,
              message: PasswordRules.minLengthText,
            },
            validate: password => {
              if (!password.match(PasswordRules.regex)) {
                return PasswordRules.reminderText;
              }
            },
          }}
          label='Password'
          errors={errors}
          register={register}
          type='password'
          borderColor={modifiedFontColor}
        />

        <InputTextBlock
          name='firstName'
          placeholder={invite?.first_name}
          defaultValue={invite?.first_name}
          validationRules={{ required: 'First name is required' }}
          label='First Name'
          errors={errors}
          register={register}
          borderColor={modifiedFontColor}
        />
        <InputTextBlock
          name='lastName'
          placeholder={invite?.last_name}
          defaultValue={invite?.last_name}
          validationRules={{ required: 'Last name is required' }}
          label='Last Name'
          errors={errors}
          register={register}
          borderColor={modifiedFontColor}
        />

        <button
          className='button-primary'
          type='submit'
          style={{ backgroundColor: fontColor, color: backgroundColor }}
        >
          Create Account
        </button>
      </>
    </LoginScreen>
  );
};

const InputTextBlock = ({
  label,
  name,
  validationRules = { required: true },
  placeholder,
  type,
  defaultValue,
  errors,
  register,
  borderColor,
}) => {
  return (
    <>
      <div
        className={errors[name] ? 'control-block control-block--error' : 'control-block'}
      >
        <label className='control--label'>{label}</label>
        <input
          name={name}
          className='control control--full-width control--text'
          type={type || 'text'}
          placeholder={placeholder}
          defaultValue={defaultValue}
          ref={register(validationRules)}
          style={{ borderColor }}
        />
      </div>

      {errors[name] && <span className='error-text'>{errors[name].message}</span>}
    </>
  );
};

export default SignUp;

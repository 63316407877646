import React from 'react';

export interface BackIconProps {
  fontColor: string;
}

const BackIcon: React.FC<BackIconProps> = ({ fontColor }) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill={fontColor || 'white'}
        strokeWidth={0}
        d='M3.00391 8.00004C3.01011 8.19072 3.08769 8.37972 3.2371 8.5254L9.64253 14.772C9.95336 15.076 10.4584 15.076 10.7701 14.772C11.0818 14.4689 11.0818 13.9764 10.7701 13.6724L4.95354 8.00001L10.7701 2.32764C11.0818 2.02362 11.0818 1.53114 10.7701 1.22802C10.4584 0.923993 9.95336 0.923993 9.64253 1.22802L3.23712 7.47462C3.08772 7.62033 3.01014 7.8093 3.00391 8.00001L3.00391 8.00004Z'
      />
    </svg>
  );
};

export default BackIcon;

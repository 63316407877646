import * as React from 'react';
import './Switch.scss';

export interface ISwitch {
  onSwitchChange?: (boolean) => any;
  label?: string;
}

const Switch: React.FC<ISwitch> = props => {
  const { onSwitchChange, label } = props;
  return (
    <>
      <div className='switch'>
        <input
          type='checkbox'
          id='switch'
          onChange={e => {
            onSwitchChange(e.target.checked);
          }}
        />
        <label htmlFor='switch'>Toggle</label>
        <span className='switch__label'>{label}</span>
      </div>
    </>
  );
};

export default Switch;

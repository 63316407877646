import React from 'react';
import './AddTeamMember.scss';
import Accordion from 'components/Common/Accordion/Accordion';
import Modal from 'components/Common/Modal/Modal';
import RadioButton from 'components/Common/RadioButtons/RadioButton';
import { IOptions } from 'models/Dashboard/IOptions';
import Dropdowns from 'components/Common/Dropdowns/Dropdowns';
import TextBox from 'components/Common/TextBox/TextBox';
import { useForm } from 'react-hook-form';

import AddUserIcon from 'assets/icons/add-user.svg';

export interface IModalData {
  heading: string;
  accountType?: IAccountType[];
  field?: IField[];
  advancedSettingsFields: IAdvancedSettingsFields[];
  addTeamMemberHandler?: (values) => void;
}

export interface IAdvancedSettingsFields {
  label: string;
  name: string;
  type: string;
  placeholder?: string;
  required?: boolean;
  multi?: boolean;
  options?: IOptions[];
}

export interface IField {
  label: string;
  type: string;
  placeholder?: string;
  required?: boolean;
  options?: IOptions[];
  name: string;
  register?: string;
}

export interface IAccountType {
  typeName: string;
  value?: string;
}

export interface IAddTeamMember {
  btntxt: string;
  heading: string;
  subheading: string;
  modalData: IModalData[];
  selectOptions: IOptions[];
  addTeamMemberHandler?: (values) => void;
}

const AddTeamMember: React.FC<IAddTeamMember> = props => {
  const { btntxt, heading, subheading, modalData, addTeamMemberHandler, ...rest } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <div className='add-team-member'>
      <div className='add-team-member__header'>
        <h2 className='mm--h3 optima'>team</h2>
      </div>

      <div className='add-team-member__body'>
        <div className='add-team-member__icon'>
          <img src={AddUserIcon} alt={heading} />
        </div>
        <div className='add-team-member__heading'>{heading}</div>
        <div className='add-team-member__subheading'>{subheading}</div>
        <button className='button-primary' onClick={() => setOpen(!open)}>
          {btntxt}
        </button>
      </div>
      <AddTeamMemberModal
        open={open}
        setOpen={setOpen}
        modalData={modalData}
        addTeamMemberHandler={addTeamMemberHandler}
        {...rest}
      />
    </div>
  );
};

export default AddTeamMember;

interface IAddTeamMemberModal {
  open?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  modalData: IModalData[];
  selectOptions: IOptions[];
  addTeamMemberHandler?: (values) => void;
  brand_model_id?: string;
}

export const AddTeamMemberModal: React.FC<IAddTeamMemberModal> = ({
  open,
  setOpen,
  modalData,
  selectOptions,
  addTeamMemberHandler,
  brand_model_id,
}) => {
  const formId = 'AddTeamMemberForm';
  const { handleSubmit, register, control, errors } = useForm({});
  return (
    <>
      <Modal
        modalHeading={modalData[0].heading}
        modalBtnActionLabel='Add and invite person'
        backgroundColor='#fff'
        modalOpen={open}
        setModalOpen={setOpen}
        formHandlerId={formId}
      >
        <form id={formId} onSubmit={handleSubmit(addTeamMemberHandler)}>
          <>
            <input type='hidden' name='grants' value={`[]`} ref={register({})} />
            {modalData?.length &&
              modalData[0].field.map(
                ({ name, label, type, required, placeholder, options }) => (
                  <div className='add-team-member__field' key={`${label}-${type}`}>
                    {type === 'dropdown' && (
                      <Dropdowns
                        name={name}
                        grayIcon={true}
                        label={label}
                        options={options || selectOptions}
                        fullWidth={true}
                        placeholder={placeholder}
                        control={control}
                        required={required}
                      />
                    )}
                    {type === 'textbox' && (
                      <TextBox
                        placeholder={placeholder}
                        label={label}
                        required={required}
                        register={register}
                        name={name}
                      />
                    )}

                    {errors && errors[name] && (
                      <span className='error-text'>This field is required</span>
                    )}
                  </div>
                )
              )}
          </>
          <h3 className='add-team-member__subtitle'>Account Type</h3>
          <div className='add-team-member__radio-row'>
            {modalData?.length &&
              modalData[0].accountType.map(({ typeName, value }, idx) => (
                <div className='add-team-member__radio' key={idx}>
                  <RadioButton
                    name={`job_type`}
                    label={typeName}
                    value={value}
                    register={register}
                  />
                </div>
              ))}
          </div>

          <div className='add-team-member__accordion'>
            <Accordion heading='Advanced settings'>
              <div className='row'>
                {modalData?.length &&
                  modalData[0].advancedSettingsFields.map(
                    ({ label, name, type, required, placeholder, multi, options }) => (
                      <div className='col-md-6' key={`${label}-${type}`}>
                        <div className='add-team-member__field'>
                          {type === 'dropdown' && (
                            <Dropdowns
                              name={name}
                              grayIcon={true}
                              label={label}
                              options={options || selectOptions}
                              fullWidth={true}
                              placeholder={placeholder}
                              multi={multi || false}
                              control={control}
                              required={required}
                            />
                          )}
                          {type === 'textbox' && (
                            <TextBox
                              placeholder={placeholder}
                              label={label}
                              required={required}
                              register={register}
                              name={name}
                            />
                          )}
                        </div>
                      </div>
                    )
                  )}
              </div>
            </Accordion>
          </div>
        </form>
      </Modal>
    </>
  );
};

import React from 'react';
import { useForm } from 'react-hook-form';
import { FormHeader } from 'components';
import {
  TextInput,
  SelectInput,
  PhoneInputComponent,
} from 'components/Dashboard/FormComponents';
import { DEPARTMENTS } from 'constants/deparments';

interface ContactAdminSectionProps {
  formData: { contact: ContactInterface };
  submitChanges?: (values: any) => any;
}
interface ContactInterface {
  name: string;
  // lastName: string;
  title: string;
  department: string;
  phone: string;
  email: string;
  language: string;
}

const ContactAdminSection: React.FC<ContactAdminSectionProps> = ({
  formData,
  submitChanges,
}) => {
  const [update, setUpdate] = React.useState(false);
  const { handleSubmit, register, errors, control } = useForm();

  const onSubmit = values => {
    setUpdate(false);
    values.name = `${values?.firstName} ${values?.lastName}`;
    delete values.firstName;
    delete values.lastName;

    if (values.department) {
      values.department = values.department[0].name;
    }
    if (values.language) {
      values.language = values.language[0].name;
    }

    submitChanges({
      contact: values,
    });
  };

  const departments = Object.values(DEPARTMENTS).map(d => ({
    name: d,
  }));

  const languages = [
    { name: 'Spanish' },
    { name: 'English' },
    { name: 'Mandarin' },
    { name: 'Catalan' },
  ];
  return (
    <div>
      <FormHeader
        headingText='Contact Admin'
        setUpdate={setUpdate}
        update={update}
        onSubmit={handleSubmit(onSubmit)}
      />
      <div className='row'>
        <TextInput
          label='First Name'
          name='firstName'
          update={update}
          required='This field is required'
          value={formData?.contact?.name?.split(' ')[0]}
          register={register}
          errors={errors}
          validate={value => {
            return (
              [/^[a-zA-Z0-9-_ ]+$/].every(pattern => pattern.test(value)) ||
              'Invalid First Name. Alphanumeric characters expected.'
            );
          }}
        />
        {errors?.firstName && (
          <div className='error-text w-100'>{errors?.firstName?.message}</div>
        )}
        <TextInput
          label='Last Name'
          name='lastName'
          update={update}
          required='This field is required'
          value={formData?.contact?.name?.split(' ')[1]}
          register={register}
          errors={errors}
          validate={value => {
            return (
              [/^[a-zA-Z0-9-_ ]+$/].every(pattern => pattern.test(value)) ||
              'Invalid Last Name. Alphanumeric characters expected.'
            );
          }}
        />
        {errors?.lastName && (
          <div className='error-text w-100'>{errors?.lastName?.message}</div>
        )}
        <TextInput
          label='Title'
          name='title'
          update={update}
          required='This field is required'
          value={formData?.contact?.title}
          fullWidth
          register={register}
          errors={errors}
          validate={value => {
            return (
              [/^[a-zA-Z0-9-_ ]+$/].every(pattern => pattern.test(value)) ||
              'Invalid Title. Alphanumeric characters expected.'
            );
          }}
        />
        {errors?.title && (
          <div className='error-text w-100'>{errors?.title?.message}</div>
        )}
        <SelectInput
          label='Department'
          name='department'
          update={update}
          options={departments}
          value={formData?.contact?.department}
          control={control}
          errors={errors}
        />
        {/* <selectinput
          label='language'
          name='language'
          value={formdata?.contact?.language}
          update={update}
          options={languages}
          control={control}
          errors={errors}
          required
        /> */}
        <PhoneInputComponent
          label='Country Code/Contact Number'
          name='phone'
          update={update}
          control={control}
          value={formData?.contact?.phone}
          errors={errors}
        />
        <TextInput
          label='Email'
          name='email'
          update={update}
          value={formData?.contact?.email}
          register={register}
          errors={errors}
          validate={value => {
            return (
              [/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/].every(pattern => pattern.test(value)) ||
              'Invalid Email.'
            );
          }}
        />
        {errors?.email && (
          <div className='error-text w-100'>{errors?.email?.message}</div>
        )}
      </div>
    </div>
  );
};

export default ContactAdminSection;

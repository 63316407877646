export const Seasons = {
  spring_summer: 'spring_summer',
  fall_winter: 'fall_winter',
  prefall: 'prefall',
  resort: 'resort',
};

export const Roles = {
  admin: 'admin',
  brand: 'brand',
  subscriber: 'subscriber',
  buyer: 'buyer',
  developer: 'developer',
  samplemaker: 'samplemaker',
};

export const JobTypes = {
  admin: 'admin',
  designer: 'designer',
  marketing: 'marketing',
  sales: 'sales rep',
  delegate: 'delegate',
};

export const Permissions = {
  owner: 'owner',
  read: 'read',
  editor: 'editor',
};

export const PasswordRules = {
  regex: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?=.*[@#$%^&+=?!* ]).{10,128}$/,
  reminderText:
    'Password must contain one uppercase, one lowercase, one number and one special character. Minimum length is 10 characters.',
  minLengthText: 'Minimum length is 10 characters',
  minLengthVal: 10,
};

export const allowedFileTypes = [
  'image/jpeg',
  'image/webp',
  'image/png',
  'image/jpg',
];

export * from './alerts';
export * from './errors';
export * from './invites';
export * from './deparments';

export const TeamModalConfig = (lineOptions, collectionOptions) => {
  return [
    {
      heading: 'Add New Member',
      advancedSettingsFields: [
        {
          name: 'title',
          label: 'Title',
          type: 'textbox',
          required: false,
        },
        {
          name: 'department',
          label: 'Division/Department',
          type: 'dropdown',
          required: false,
          placeholder: 'Select',
          options: [
            { name: 'HR', value: 'hr' },
            { name: 'Finance', value: 'finance' },
            { name: 'Design', value: 'design' },
            { name: 'Other', value: 'other' },
          ],
        },
        {
          name: 'restrictInventory',
          label: 'Restrict Inventory',
          type: 'textbox',
          required: false,
          placeholder: 'Yes',
        },
        {
          name: 'employee_number',
          label: 'EMPLOYEE NUMBER',
          type: 'textbox',
          required: false,
        },
        {
          name: 'lines',
          label: 'Lines',
          type: 'dropdown',
          required: true,
          placeholder: 'Select',
          multi: true,
          options: lineOptions,
        },
        {
          name: 'collections',
          label: 'Collection',
          type: 'dropdown',
          required: true,
          placeholder: 'Select',
          multi: true,
          options: collectionOptions,
        },
      ],
      field: [
        {
          name: 'first_name',
          label: 'First Name',
          type: 'textbox',
          required: true,
        },
        {
          name: 'last_name',
          label: 'Last Name',
          type: 'textbox',
          required: true,
        },
        {
          name: 'email',
          label: 'Email',
          type: 'textbox',
          required: true,
        },
      ],
      accountType: [
        {
          typeName: 'Admin',
          value: 'admin',
        },
        {
          typeName: 'Designer',
          value: 'designer',
        },
        {
          typeName: 'Marketing',
          value: 'marketing',
        },
        {
          typeName: 'Sales Rep',
          value: 'sales',
        },
        {
          typeName: 'Delegate',
          value: 'delegate',
        },
      ],
    },
  ];
};

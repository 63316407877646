import classNames from 'classnames';
import * as React from 'react';
import './Loader.scss';

export interface ILoader {
  active: boolean;
  relative?: boolean;
}

const Loader: React.FC<ILoader> = props => {
  const { active, relative } = props;

  return (
    <div
      className={classNames('loader', {
        'loader--active': active,
        'loader--active-relative': relative,
      })}
    >
      <div className='loader__center'>
        <div className='loader__wrap'>
          <div className='loader__line loader__line-1'>
            <span></span>
          </div>
          <div className='loader__line loader__line-2'>
            <span></span>
          </div>
          <div className='loader__line loader__line-3'>
            <span></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loader;

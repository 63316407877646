import React, { useContext, useState } from 'react';
import './INotificationPopUp.scss';
import { INotification } from 'models/Dashboard/INotification';
import TimeAgo from 'react-time-ago';
import classNames from 'classnames';
import { NotificationsContext } from 'providers/contexts';

export interface INotificationPopUp {
  notifications: INotification[];
  clearNotifications?: () => void;
  clearNotification?: (notification: INotification) => void;
  restoreNotifications?: () => void;
  allUnreadStatus: boolean;
  setAllUnreadStatus: (status: boolean) => void;
}

export interface INotificationItem {
  notification: INotification;
  clearNotification?: (notification: INotification) => void;
}

const NotificationItem: React.FC<INotificationItem> = props => {
  const { notification, clearNotification } = props;
  const { profileImg, segments, when, whenRead } = notification;
  const firstName = segments[0]?.displayText;
  const lastName = segments[0]?.displayText.split(" ");

  let display = null;
  let counter = 1;
  let space = '';

  return (
    <div className='notifications__item' onClick={() => clearNotification(notification)}>
      {!whenRead && (
        <div className='frame__bell-wrap'>
          <span className='frame__bell-dot-active' />
        </div>
      )}
      {profileImg ? (
        <img
          className='notifications__img notifications-popup__img'
          src={profileImg}
          alt='Profile'
        />
      ) : (
        <span className='notifications__thumbnail notifications-popup__img'>
          {firstName.slice(0, 1)}
          {lastName.length > 1 ? lastName[1].slice(0, 1) : lastName[0].slice(0, 1)}
        </span>
      )}
      <div className='notifications__content notifications-popup__content'>
        <div className='notifications__action'>
          {segments &&
            segments.map((segment, index) => {
              if (counter > 1) {
                space = '3px';
              }

              if (segment.url && segment.url.length) {
                if (!segment.accented) {
                  display = (
                    <a
                      style={{ paddingLeft: space }}
                      key={counter}
                      href={segment.url}
                      target='_work'
                      className={classNames({
                        'notifications__activity-type': index === 1,
                      })}
                    >
                      {segment.displayText}
                    </a>
                  );
                } else {
                  display = (
                    <a
                      style={{ paddingLeft: space }}
                      key={counter}
                      href={segment.url}
                      target='_work'
                      className={classNames({
                        'notifications__activity-type': index === 1,
                      })}
                    >
                      {segment.displayText}
                    </a>
                  );
                }
              } else {
                if (!segment.accented) {
                  display = (
                    <span
                      className={classNames({
                        'notifications__activity-type': index === 1,
                      })}
                      style={{ paddingLeft: space }}
                    >
                      {segment.displayText}
                    </span>
                  );
                } else {
                  display = (
                    <span
                      className={classNames({
                        'notifications__activity-type': index === 1,
                      })}
                      style={{ paddingLeft: space }}
                    >
                      {segment.displayText}
                    </span>
                  );
                }
              }
              counter += 1;
              return display;
            })}
        </div>
        <div className='notifications__time'>
          <TimeAgo date={when * 1000} />
        </div>
      </div>
    </div>
  );
};
const NotificationPopUp: React.FC<INotificationPopUp> = props => {
  const {
    notifications,
    clearNotifications,
    clearNotification,
    restoreNotifications,
    allUnreadStatus,
    setAllUnreadStatus,
  } = props;
  const { setNotifications } = useContext(NotificationsContext);

  React.useEffect(() => {
    const checkAllReadStatus = () => {
      notifications.map(n => {
        if (n.whenRead) {
          setAllUnreadStatus(true);
          return;
        }
        setAllUnreadStatus(false);
      });
      setMarkRedNotClicked(true);
    };
    notifications && checkAllReadStatus();
  }, [notifications]);

  const [markReadNotClicked, setMarkRedNotClicked] = useState(true);

  return (
    <div className='notifications'>
      <div className='notifications__header'>
        <h2 className='notifications__headline'>notification</h2>
        {notifications && notifications.length > 0 && (
          <button
            className='notifications__read'
            style={{ color: markReadNotClicked && '#000' }}
            onClick={() => {
              setMarkRedNotClicked(false);
              if (allUnreadStatus) {
                restoreNotifications();
              } else {
                clearNotifications();
                setNotifications([]);
              }
            }}
          >
            {allUnreadStatus ? 'mark all as unread' : 'mark all as read'}
          </button>
        )}
      </div>
      <div
        className='notifications__content'
        style={{
          padding: '0 1rem',
          maxHeight: '800px',
          overflowX: 'hidden',
        }}
      >
        {notifications && notifications.length ? (
          notifications.map((item, index) => (
            <NotificationItem
              key={item.key}
              notification={item}
              clearNotification={() => {
                clearNotification(item);
                const noties = [...notifications];
                noties.splice(index, 1);
                setNotifications(noties);
              }}
            />
          ))
        ) : (
          <div className='notifications__item'>
            <div className='notifications__content'>There are no new notfications.</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationPopUp;

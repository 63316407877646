import React, { useState, useEffect } from 'react';
import { Controller, Control, FieldErrors } from 'react-hook-form';
import Select from 'react-dropdown-select';
import classNames from 'classnames';

export interface SelectInputProps {
  update: boolean;
  value: string;
  options: { name: string }[];
  label: string;
  fullWidth?: boolean;
  required?: boolean;
  multi?: boolean;
  name: string;
  control?: Control<Record<string, any>>;
  errors?: FieldErrors;
  itemKey?: number;
  className?: string;
  helperText?: string;
}

const SelectInput: React.FC<SelectInputProps> = ({
  update,
  value,
  className,
  options,
  required,
  fullWidth,
  label,
  multi = false,
  name,
  control,
  errors = {},
  itemKey,
  helperText,
}) => {
  const [values, setValues] = useState([]);

  useEffect(() => {
    const setDefaultValue = () => {
      if (value) {
        setValues(
          value
            .split('/')
            .map(v => ({ name: v, value: v, ...(itemKey ? { itemKey } : {}) }))
        );
      }
    };
    setDefaultValue();
  }, [value]);

  const checkErrors = () => {
    return Object.prototype.hasOwnProperty.call(errors, name);
  };

  return (
      <div className={`col-md-${fullWidth ? 12 : 6}`}>
        <div className='control-block'>
          <label
            className={classNames('control--label', {
              'control--required': required,
            })}
          >
            {label}
          </label>
          {update ? (
            <div
              className={classNames('control control--select', {
                'control--full-width': fullWidth,
              })}
            >
              <Controller
                as={
                  <Select
                    options={options}
                    values={values}
                    searchBy={'name'}
                    labelField='name'
                    // valueField='name'
                    onChange={selectedValue => {
                      console.log(selectedValue);
                      setValues(selectedValue);
                    }}
                    multi={multi}
                  />
                }
                control={control}
                name={name}
                defaultValue={values.length ? values : null}
                rules={{ required: required }}
              />
              {checkErrors() && (
                <span className='error-text'>This field is required</span>
              )}
            </div>
          ) : (
            <span
              className={classNames('dashboard-forms__updated', {
                [`${className}`]: !!className,
              })}
            >
              {value}
            </span>
          )}

          {helperText && <span className='helper-text'>{helperText}</span>}
        </div>
      </div>
  );
};

export default SelectInput;

export function getListingColor(listingVal) {
  if (listingVal.color) {
    return listingVal.color?.name ||
      listingVal.color?.hexCode ||
      listingVal.color?.hexCode ||
      listingVal.color?.pantone ||
      typeof listingVal.color === 'object'
      ? JSON.stringify(listingVal.color)
      : listingVal.color;
  }
  return null;
}

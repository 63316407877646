import React, { useState, useCallback, useEffect } from 'react';
import Modal from './Modal';
import './HistoryModal.scss';

import TimeAgo from 'react-time-ago';

type HistoryModalProps = {
  history: any;
  openHistory: boolean;
  setOpenHistory: React.Dispatch<React.SetStateAction<boolean>>;
  withLocation?: boolean;
};

export const HistoryModal = (props: HistoryModalProps) => {
  const { history, openHistory, setOpenHistory, withLocation = false } = props;

  const [todayNotification, setTodayNotification] = useState([]);
  const [currentMonthNotification, setCurrentMonthNotification] = useState([]);
  const [previousMonthNotificationFirst, setPreviousMonthNotificationFirst] = useState(
    []
  );
  const [previousMonthNotificationSecond, setPreviousMonthNotificationSecond] = useState(
    []
  );

  const getFirstDayPreviousMonth = useCallback((months: number) => {
    const date = new Date();
    return new Date(date.getFullYear(), date.getMonth() - months, 1);
  }, []);

  useEffect(() => {
    const setHistory = () => {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const todayNotifications = history.filter(
        notification => new Date(notification.when * 1000) >= today
      );
      setTodayNotification(todayNotifications);
      const firstDate = getFirstDayPreviousMonth(0);
      setCurrentMonthNotification(
        history.filter(
          notification =>
            new Date(notification.when * 1000) < today &&
            new Date(notification.when * 1000) > firstDate
        )
      );
      const firstPrevious = getFirstDayPreviousMonth(1);
      setPreviousMonthNotificationFirst(
        history.filter(
          notification =>
            new Date(notification.when * 1000) < firstDate &&
            new Date(notification.when * 1000) > firstPrevious
        )
      );
      const secondPrevious = getFirstDayPreviousMonth(2);
      setPreviousMonthNotificationSecond(
        history.filter(
          notification =>
            new Date(notification.when * 1000) < firstPrevious &&
            new Date(notification.when * 1000) > secondPrevious
        )
      );
    };
    history && setHistory();
  }, [getFirstDayPreviousMonth, history]);

  const titleStyle = {
    color: '#CBCBCB',
    fontSize: '10px',
    fontWeight: 700,
    lineHeight: '13px',
    marginTop: '0',
    marginBottom: '13.6px',
  };

  const HistoryItem = (item, withLocation) => (
    <div key={item.key}>
      <div className='activity-list__item'>
        {!item.avatar ? (
          <div className='activity-list__item-avatar'>
            {`${item.firstName?.split('')[0]}${item.lastName?.split('')[0]}`}
          </div>
        ) : (
          <img src={item.avatar} alt='Profile' className='activity-list__item-avatar' />
        )}
        <div>
          {withLocation ? (
            <div>
              <a href={item.segments[0].url}>{`${item.segments[0].displayText} `}</a>
              <a href={item.segments[1].url}>
                <span className='activity-list__item-activity-type'>
                  {item.segments[1].displayText}
                </span>
              </a>
              <a href={item.segments[2].url}>{` ${item.segments[2].displayText}`}</a>
            </div>
          ) : (
            <div>
              {`${item.segments[0].displayText} `}
              <span className='activity-list__item-activity-type'>
                {item.segments[1].displayText}
              </span>
              {` ${item.segments[2].displayText}`}
            </div>
          )}
          <div className='activity-list__item-activity-time'>
            <TimeAgo date={item.when * 1000} />
          </div>
        </div>
      </div>
      <br />
      <div style={{ height: '1px', background: '#F4F4F4' }}></div>
      <br />
    </div>
  );

  const HistoryItems = withLocation => (
    <div className='control-block'>
      {todayNotification.length > 0 && <span style={titleStyle}>TODAY</span>}
      {todayNotification &&
        todayNotification
          .sort((a, b) => b.when * 1000 - a.when * 1000)
          .map(item => HistoryItem(item, withLocation))}
      {currentMonthNotification.length > 0 && <span style={titleStyle}>THIS MONTH</span>}
      {currentMonthNotification &&
        currentMonthNotification
          .sort((a, b) => b.when * 1000 - a.when * 1000)
          .map(item => HistoryItem(item, withLocation))}
      {previousMonthNotificationFirst.length > 0 && (
        <span style={titleStyle}>
          {new Date(previousMonthNotificationFirst[0].when * 1000)
            .toLocaleString('en-us', {
              month: 'long',
            })
            .toUpperCase()}
        </span>
      )}
      {previousMonthNotificationFirst &&
        previousMonthNotificationFirst
          .sort((a, b) => b.when * 1000 - a.when * 1000)
          .map(item => HistoryItem(item, withLocation))}
      {previousMonthNotificationSecond.length > 0 && (
        <span style={titleStyle}>
          {new Date(previousMonthNotificationSecond[0].when * 1000)
            .toLocaleString('en-us', {
              month: 'long',
            })
            .toUpperCase()}
        </span>
      )}
      {previousMonthNotificationSecond &&
        previousMonthNotificationSecond
          .sort((a, b) => b.when * 1000 - a.when * 1000)
          .map(item => HistoryItem(item, withLocation))}
    </div>
  );

  return (
    <Modal
      modalHeading={'History'}
      modalBtnClose={''}
      modalBtnActionHandler={() => setOpenHistory(false)}
      backgroundColor='#fff'
      modalOpen={openHistory}
      setModalOpen={setOpenHistory}
    >
      <div className='history-modal'>
        <div className='activity-list'>
          {!history.length && (
            <div className={'activity-list__item'}>No history yet.</div>
          )}
          {history && HistoryItems(withLocation)}
        </div>
      </div>
    </Modal>
  );
};

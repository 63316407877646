import * as React from 'react';
import { mmAPI, postMessageModel } from 'services/Api';
import useSWR, { mutate } from 'swr';

import { FrameUIActionsTypes, useFrameUI } from 'providers/FrameUIProvider';
import { NotificationsContext } from 'providers/NotificationsProvider';
import { UploadContext } from 'providers/UploadProvider';
import { UserContext } from 'providers/UserProvider';

type useMaterialAssetsParams = {
  brand_model_id?: string;
  style_model_id?: string;
  materials?: any[];
  materialsLibrary: any[];
};

type useMaterialAssetsReturn = {
  materialLibrarySummaries: any[];
  materialSummaries: any[];
};

const useMaterialAssets = ({
  materials,
  materialsLibrary,
}: useMaterialAssetsParams): useMaterialAssetsReturn => {
  const { firebaseStorageUrl } = React.useContext(UploadContext);
  const getFirebaseStorageUrl = async (asset, resize = null) => {
    let pathResized;
    if (resize) {
      const pathResizeIndex = asset.path.indexOf('.png');
      pathResized = [...asset.path];
      pathResized.splice(pathResizeIndex, 0, resize);
    }
    if (asset) {
      try {
        const firebaseUrl = await firebaseStorageUrl(
          resize ? pathResized.join('') : asset.path
        );
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = () => {
            resolve(firebaseUrl);
          };
          img.onerror = () => {
            resolve(null);
          };
          img.src = firebaseUrl;
        });
      } catch (e) {
        console.error('e useMaterialAssets getFirebaseStorageUrl', asset, e);
      }
    }
  };

  const [materialSummaries, setMaterialSummaries] = React.useState([]);
  const [materialLibrarySummaries, setMaterialLibrarySummaries] = React.useState([]);
  const materialSummariesTransform = async m => {
    const { notes, colorway, use, colors, assets } = m;
    const { front, back } = colors.reduce((result, color) => {
      result[color.name] = color.code;
      return result;
    }, {});
    const frontImg =
      assets?.front?.progress >= 0 ? await getFirebaseStorageUrl(assets?.front) : null;
    const backImg =
      assets?.back?.progress >= 0 ? await getFirebaseStorageUrl(assets?.back) : null;
    const frontThumbImg =
      assets?.front?.progress >= 0
        ? await getFirebaseStorageUrl(assets?.front, '_300x400')
        : null;
    const backThumbImg =
      assets?.back?.progress >= 0
        ? await getFirebaseStorageUrl(assets?.back, '_300x400')
        : null;
    return Promise.resolve({
      ...m,
      frontImg,
      backImg,
      frontThumbImg,
      backThumbImg,
      metadata: Object.entries({
        content: notes,
        colorway,
        use,
        front,
        back,
      }).map(([label, text]) => ({
        label,
        text,
      })),
    });
  };
  React.useEffect(() => {
    const materialsEffect = async materialsData => {
      if (!materialsData) return;
      const materialSummariesTransformed = await Promise.all(
        materialsData.map(async m => await materialSummariesTransform(m))
      );
      setMaterialSummaries(materialSummariesTransformed);
    };
    materialsEffect(materials);
  }, [materials]);

  React.useEffect(() => {
    const materialsLibraryEffect = async materialsLibraryData => {
      if (!materialsLibraryData) return;
      const materialSummariesTransformed = await Promise.all(
        materialsLibraryData.map(m => materialSummariesTransform(m))
      );
      setMaterialLibrarySummaries([...materialSummariesTransformed]);
    };
    materialsLibraryEffect(materialsLibrary);
  }, [materialsLibrary]);

  return { materialSummaries, materialLibrarySummaries };
};

export default useMaterialAssets;

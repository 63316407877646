import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormHeader } from 'components';
import {
  TextInput,
  SelectInput,
  PhoneInputComponent,
} from 'components/Dashboard/FormComponents';
import countriesJson from './countries-iso-3166';

interface HeadquartersSectionProps {
  submitChanges?: (values: any) => any;
  formData: { address: AddressInterface };
}

interface AddressInterface {
  street: string;
  suite: string;
  country: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  website: string;
}

const HeadquartersSection: React.FC<HeadquartersSectionProps> = ({
  submitChanges,
  formData,
}) => {
  const [update, setUpdate] = React.useState(false);
  const { handleSubmit, register, errors, control } = useForm();
  const onSubmit = values => {
    setUpdate(false);
    if (values.country.length) {
      values.country = values.country[0].name;
    }

    submitChanges({ address: values });
  };

  return (
    <div>
      <FormHeader
        headingText='Headquarters'
        setUpdate={setUpdate}
        update={update}
        onSubmit={handleSubmit(onSubmit)}
      />
      <div className='row'>
        <TextInput
          label='Address 1'
          name='street'
          update={update}
          register={register}
          value={formData?.address?.street}
          required='This field is required'
          fullWidth
          errors={errors}
        />
        <TextInput
          label='Address 2'
          name='suite'
          update={update}
          register={register}
          value={formData?.address?.suite}
          required='This field is required'
          fullWidth
          errors={errors}
        />
        <SelectInput
          label='Country'
          name='country'
          value={formData?.address?.country}
          update={update}
          required
          control={control}
          options={countriesJson}
          errors={errors}
        />
        <TextInput
          label='City'
          name='city'
          update={update}
          register={register}
          required={false}
          value={formData?.address?.city}
          errors={errors}
          validate={value => {
            return (
              [/^[a-zA-Z0-9-_ ]+$/].every(pattern => pattern.test(value)) ||
              'Invalid city. Alphanumeric characters expected.'
            );
          }}
        />
        {errors?.city && <div className='error-text w-100'>{errors?.city?.message}</div>}
        <TextInput
          label='State/Province'
          name='state'
          update={update}
          register={register}
          value={formData?.address?.state}
          errors={errors}
          validate={value => {
            return (
              [/^[a-zA-Z0-9-_ ]+$/].every(pattern => pattern.test(value)) ||
              'Invalid state. Alphanumeric characters expected.'
            );
          }}
        />
        {errors?.state && (
          <div className='error-text w-100'>{errors?.state?.message}</div>
        )}
        <TextInput
          label='Zip/Postal Code'
          name='zip'
          update={update}
          register={register}
          value={formData?.address?.zip}
          errors={errors}
          validate={value => {
            return (
              [/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/].every(pattern =>
                pattern.test(value)
              ) || 'Invalid zip. Numeric value expected.'
            );
          }}
        />
        {errors?.zip && <div className='error-text w-100'>{errors?.zip?.message}</div>}
        <PhoneInputComponent
          label='Country Code/Contact Number'
          name='phone'
          update={update}
          value={formData?.address?.phone}
          control={control}
          errors={errors}
        />
        <TextInput
          label='Website'
          name='website'
          update={update}
          required={false}
          value={formData?.address?.website}
          register={register}
        />
      </div>
    </div>
  );
};

export default HeadquartersSection;

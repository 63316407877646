import React from 'react';
import './DashboardNav.scss';
import { Link } from '@reach/router';

export interface IDashboardNavItems {
  title: string;
  to?: string;
  visible?: boolean;
  activeBtn: boolean;
  disableBtn?: boolean;
}

export interface IDashboardNav {
  dashboardNavItems: IDashboardNavItems[];
  darkMode?: boolean;
  changeTab?: (string) => void;
}

const DashboardNav: React.FC<IDashboardNav> = props => {
  const { darkMode, dashboardNavItems, changeTab } = props;

  return (
    <div
      className={
        darkMode
          ? 'dashboard-nav dashboard-nav--dark-mode'
          : 'dashboard-nav dashboard-nav--light-mode'
      }
    >
      {dashboardNavItems &&
        dashboardNavItems
          .filter(({ visible = true }) => visible)
          .map(({ title, activeBtn, disableBtn = false, to }, idx) => (
            <div
              className={activeBtn ? 'dashboard-nav__btn active' : 'dashboard-nav__btn'}
              style={{ cursor: disableBtn && 'not-allowed' }}
              key={title}
              onClick={() => {
                !disableBtn && changeTab && changeTab(title);
              }}
            >
              {to ? <Link to={to}>{title}</Link> : title}
            </div>
          ))}
    </div>
  );
};

export default DashboardNav;

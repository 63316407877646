import React, { useContext, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import './ModalUploadDetails.scss';
import Modal from 'components/Common/Modal/Modal';
import { updateListing } from 'services/Api';
import BackIcon from 'assets/icons/Icon-angle-left.svg';
import SuccessIcon from 'assets/icons/check-circle-full.svg';
import ErrorIcon from 'assets/icons/exclamation-full.svg';
import { NotificationsContext, UserContext } from 'providers/contexts';
import Loader from 'components/Loader/Loader';
import { useTranslation } from 'react-i18next';

export interface IUploadedProducts {
  styleNum: string;
  productName: string;
  collection: string;
  updated: boolean;
  fileType: string;
  completed: boolean;
  style_name: string;
  style: string;
  style_number?: string;
  sku: string;
  upc: string;
  categories: string[];
  size: string;
  wholesale_prices: string;
  color: string;
  key: string;
}

export interface IProductDetails {
  field: string;
  label: string;
  editable?: boolean;
  detected: string;
  message?: string;
  error?: any;
  encode?: any;
  transformation?: any;
}

export interface IModalUploadDetails {
  uploadedContext: any[];
  uploadedProducts: IUploadedProducts[];
  uploadedError: any;
  backgroundColor?: string;
  modalHeading: string;
  modalBtnClose?: string;
  modalBtnActionLabel?: string;
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onDoneHandler?: (event: React.SyntheticEvent) => void;
  setUploadedProducts?: React.Dispatch<React.SetStateAction<any>>;
  setUploadedError?: React.Dispatch<React.SetStateAction<any>>;
}

export type ProductListingUploadError = {
  column: string;
  message: string;
  property: string;
  type: string;
};

const ModalBatchListings: React.FC<IModalUploadDetails> = props => {
  const {
    uploadedContext,
    uploadedProducts,
    uploadedError,
    onDoneHandler,
    modalHeading,
    modalOpen,
    setModalOpen,
    setUploadedProducts,
    setUploadedError,
  } = props;

  const { user } = useContext(UserContext);
  const { idToken } = user;
  const { setDisplayToast } = useContext(NotificationsContext);
  const [detailsListing, setDetailsListing] = React.useState(null);
  const [detailsView, setDetailsView] = React.useState(false);
  const [productDetails, setProductDetails] = React.useState<IProductDetails[]>(null);
  const [edit, setEdit] = useState(false);
  const { register, handleSubmit, errors } = useForm();

  const { t } = useTranslation();

  /** default fields for listing detail view fields */
  const [detailsViewFields, setDetailsViewFields] = React.useState<IProductDetails[]>([
    {
      field: 'style',
      label: 'Style',
      editable: false,
      detected: null,

      encode: function Encode(value, { context }) {
        const styleModel = context.find(c => parseInt(c.key) === parseInt(value));
        return (
          <a
            href={`/brand/${styleModel?.brand}/style/${styleModel?.key}`}
            target='_blank'
            rel='noreferrer'
          >
            {`/brand/${styleModel?.brand}/style/${styleModel?.key}`}
          </a>
        );
      },
    },
    {
      field: 'style_name',
      label: 'Style Name',
      editable: false,
      detected: null,
    },
    {
      field: 'style_number',
      label: 'Style Number',

      editable: false,
      detected: null,
    },
    {
      field: 'categories',
      label: 'Categories',
      editable: false,
      detected: null,

      encode: (value, { context }) => {
        const categories = context.filter(c => value.includes(c.key));
        return categories?.map(c => c.name).join(', ');
      },
    },
    { field: 'sku', label: 'SKU', detected: null },
    { field: 'upc', label: 'UPC', detected: null },
    {
      field: 'size',
      label: 'Size',
      detected: null,
    },
    {
      field: 'wholesale_prices',
      label: 'Wholesale Prices',
      detected: null,

      transformation: value => {
        const wholesaleTransform = JSON.stringify(
          value?.split(',').map(p => {
            const priceAttributes = p.split(' ');
            return { currency: priceAttributes[1], number: priceAttributes[0] };
          })
        );
        return wholesaleTransform;
      },
      encode: value => {
        return value?.map(p => `${p.number} ${p.currency}`).join(',');
      },
    },
  ]);

  useEffect(() => {
    /** Observes detailsListing.errors and adds to the base set of detail view fields */
    const detailsListingErrorsEffect = detailsListingErrors => {
      if (!detailsListingErrors) return;
      const detailsViewFieldsWithErrors = Object.entries(detailsListingErrors).map(
        ([property, errorMetadata]: [string, ProductListingUploadError]) => {
          return {
            field: property,
            label: errorMetadata.column,
            detected: '',
          };
        }
      );
      /** filter error fields that are not already present in details */
      const detailsViewFieldsWithErrorsUpdates = detailsViewFieldsWithErrors.filter(
        errorDetail => !detailsViewFields.map(f => f.field).includes(errorDetail.field)
      );
      if (detailsViewFieldsWithErrorsUpdates.length) {
        /** update detail view fields with filtered error fields */
        const updatedDetailsViewFields = [
          ...detailsViewFields,
          ...detailsViewFieldsWithErrorsUpdates,
        ];
        setDetailsViewFields(updatedDetailsViewFields);
      }
    };
    detailsListingErrorsEffect(detailsListing?.errors);
  }, [detailsListing?.errors, detailsViewFields]);

  useEffect(() => {
    /** Generates product details including detected values and errors */
    const detailsListingEffect = listing => {
      if (!listing) return;
      const productDetailsFromListing = detailsViewFields.map(f => {
        /** Compares current field with detected errors, adds a message to the field */
        const errorMatch: ProductListingUploadError | any = detailsListing?.errors
          ? Object.values(detailsListing?.errors).find(
              (errorMetadata: ProductListingUploadError) =>
                errorMetadata.property === f.field
            )
          : null;
        const detectedResolved =
          f.encode &&
          detailsListing[f.field] &&
          typeof detailsListing[f.field] !== 'string'
            ? f.encode(detailsListing[f.field], { context: uploadedContext })
            : detailsListing[f.field];
        return {
          ...f,
          error: errorMatch,
          detected: detectedResolved,
          message: errorMatch?.type
            ? t(`productUpload.${errorMatch?.type}`, {
                missingField: errorMatch?.column,
              })
            : !detectedResolved
            ? t(`productUpload.ListingParserWarning`, {
                missingField: f.field,
              })
            : '',
        };
      });
      setProductDetails(productDetailsFromListing);
    };
    detailsListingEffect(detailsListing);
  }, [detailsListing, detailsViewFields]);

  const onSubmit = () => {
    handleSubmit(async listingUpdateFields => {
      const listingUpdateFieldsTransformed = Object.assign(listingUpdateFields);

      const transformationMatches = detailsViewFields.filter(
        viewField => viewField.transformation
      );
      console.log('transformationMatches', transformationMatches);
      transformationMatches.forEach(detailViewField => {
        if (Object.keys(listingUpdateFieldsTransformed).includes(detailViewField.field)) {
          const prev = listingUpdateFieldsTransformed[detailViewField.field];
          const transformed = !prev ? '' : detailViewField.transformation(prev);
          console.log('transformed', transformed);
          listingUpdateFieldsTransformed[detailViewField.field] = transformed;
        }
      });

      const sizefield = listingUpdateFieldsTransformed.size;
      const measurementsfield = listingUpdateFieldsTransformed.measurements;
      listingUpdateFieldsTransformed.size = {
        name: sizefield,
        number: 0,
        variant: null,
        measurements: {
          waist: measurementsfield,
          chest: null,
          hips: null,
          height: null,
        },
      };

      const response = await updateListing(idToken, {
        listingKey: detailsListing.key,
        params: listingUpdateFieldsTransformed,
      });
      if (!response?.data?.errors) {
        let updatedDetailListing;
        const productsListing = uploadedProducts.map(item => {
          if (item.key === detailsListing.key) {
            const newItem = {
              ...item,
              ...listingUpdateFields,
              errors: {},
              completed: true,
              updated: true,
            };
            updatedDetailListing = newItem;
            return newItem;
          }
          return item;
        });
        setEdit(false);
        setUploadedProducts(productsListing);
        setDetailsListing(updatedDetailListing);
      } else {
        setDisplayToast({
          type: 'error',
          persist: false,
          message: `Review the form for errors. You can also reach our Client Services Department by email at: VAB001@themodernmirror.com`,
        });
        let updatedDetailListing;
        uploadedProducts.forEach(item => {
          if (item.key === detailsListing.key) {
            const errorsTransformed = Object.entries(response?.data?.errors).reduce(
              (agg, value) => {
                const [field, errorMetadata]: [string, any] = value;
                const addField = {
                  ...errorMetadata,
                  column: field,
                  property: field,
                };
                agg[field] = addField;
                return agg;
              },
              response?.data.errors
            );
            updatedDetailListing = {
              ...item,
              ...listingUpdateFields,
              errors: errorsTransformed,
            };
          }
        });
        setDetailsListing(updatedDetailListing);
      }
    })();
  };

  return (
    <Modal
      modalHeading={modalHeading}
      modalBtnActionLabel={
        (edit && 'Save') || (uploadedError && 'Cancel') || (detailsView ? 'Edit' : 'Done')
      }
      modalBtnActionHandler={e => {
        if (!edit && detailsView) {
          setEdit(true);
        } else if (edit) {
          onSubmit();
        } else {
          onDoneHandler?.(e);
        }
      }}
      secondaryBtnAction={() => {
        setEdit(false);
      }}
      secondaryBtnActionLabel='Cancel'
      modalBtnCloseHandler={() => setEdit(false)}
      backgroundColor='#fff'
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      edit={edit}
      closeOnClickAway={false}
    >
      {detailsView ? (
        <div className='modal-upload-details__details'>
          <span
            className='modal-upload-details__back'
            onClick={() => {
              setEdit(false);
              setDetailsView(!detailsView);
            }}
          >
            <img src={BackIcon} alt='Back' />
            Back
          </span>

          <div className='modal-upload-details__header'>
            <div className='row'>
              <div className='col-sm-3'>Field</div>
              <div className='col-sm-3'>Detected</div>
            </div>
          </div>
          <form>
            {productDetails?.length &&
              productDetails.map(
                ({ label, editable = true, detected, field, message, error }) => (
                  <div className='modal-upload-details__row' key={`${label}`}>
                    <div className='row'>
                      <div className='col-sm-3 modal-upload-details__field'>{label}</div>
                      <div className='col-sm-9 modal-upload-details__detected'>
                        {edit ? (
                          <div className='modal-upload-details__input'>
                            {editable ? (
                              <input
                                className='control--text'
                                type='text'
                                defaultValue={detected}
                                name={field}
                                ref={register}
                              />
                            ) : (
                              detected
                            )}
                            {message && (
                              <span style={{ color: 'red', marginTop: 10 }}>
                                {message}
                              </span>
                            )}
                          </div>
                        ) : !detected ? (
                          error && error.type !== 'ListingParserWarning' ? (
                            <span className='error-text'>Error</span>
                          ) : (
                            <span className='error-empty-text'>Empty</span>
                          )
                        ) : (
                          detected
                        )}
                      </div>
                    </div>
                  </div>
                )
              )}
          </form>
        </div>
      ) : uploadedProducts?.length && !uploadedError ? (
        <>
          {uploadedProducts.map((product, idx) => {
            const {
              fileType,
              productName,
              collection,
              styleNum,
              completed,
              updated,
            } = product;
            return (
              <div className='modal-upload-details__item' key={idx}>
                <div className='modal-upload-details__file'>
                  <span>{fileType}</span>
                </div>
                <div className='modal-upload-details__name'>
                  <div className='modal-upload-details__main'>
                    <span>
                      Style No {styleNum}/{collection}/{productName}
                    </span>
                    {updated ? 'Updated' : null}
                  </div>
                  {!completed ? (
                    <div className='modal-upload-details__action'>
                      {/* <button className='button-dashboard color-white-bg btn-icon'>
                              <img src={ReloadIcon} alt='Reload' />
                            retry
                            </button> */}
                      <button
                        className='button-dashboard color-white-bg'
                        onClick={() => {
                          setDetailsListing(product);
                          setDetailsView(!detailsView);
                        }}
                      >
                        view details
                      </button>
                    </div>
                  ) : null}
                </div>

                <div className='modal-upload-details__completed'>
                  <img
                    src={completed ? SuccessIcon : ErrorIcon}
                    alt={completed ? 'Completed' : 'Error'}
                  />
                </div>
              </div>
            );
          })}
        </>
      ) : uploadedError ? (
        <>
          Error uploading CSV file.
          <pre className='modal-upload-details__errors'>{uploadedError}</pre>
        </>
      ) : (
        <div className='modal-upload-details__details'>
          <Loader active={true} relative={true}></Loader>
        </div>
      )}
    </Modal>
  );
};

export default ModalBatchListings;

import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import './Tile.scss';
import ExpandIcon from 'assets/icons/icon-expand.svg';
import PlayIcon from 'assets/icons/play.svg';
import classNames from 'classnames';
import AtelierModel3DViewer from 'components/VirtualAtelier/Uploads/AtelierModelUploads/AtelierModel3DViewer';
import { ASSET_TYPES } from 'constants/assets';

export interface ITile {
  image?: string;
  gif?: string
  aspectRatio?: string;
  objectFit?: string;
  video?: string;
  model?: string;
  caption?: React.ReactElement | string;
  alt?: string;
  imgClassNames?: string[];
  renderingThumbnail?: boolean;
  item?: any;
  type?: string;
  original?: any;
  setOriginal?: (arg: any) => void;
  vaGalleryView?: boolean;
  ext?: string;
}

const Tile: React.FC<ITile> = props => {
  const {
    alt,
    image,
    gif,
    video,
    model,
    caption,
    aspectRatio,
    objectFit,
    imgClassNames,
    renderingThumbnail,
    type,
    original,
    setOriginal,
    vaGalleryView,
    item,
    ext = null,
  } = props;

  const vidRef = useRef(null);
  const [playing, setPlaying] = useState(false);

  const [icon, setIcon] = useState('');
  const [loadIcons, setLoadIcons] = useState(true);

  useEffect(() => {
    if (loadIcons) {
      loadIcon(ext);
    }
    return () => setLoadIcons(false);
  }, [alt, loadIcons]);

  const loadIcon = async (iconName: string) => {
    try {
      const importedIcon = await import(
        `assets/icons/${iconName.toLocaleUpperCase()}.svg`
      );
      setIcon(importedIcon.default);
    } catch (error) {
      const importedIcon = await import(`assets/icons/Icon-picture.svg`);
      setIcon(importedIcon.default);
    }
  };

  return (
    <>
      <div
        className='tile'
        style={{ height: !renderingThumbnail && !vaGalleryView ? '455px' : '' }}
      >
        <div
          className={classNames('ratio-wrap', {
            [`ratio${aspectRatio}`]: !(!renderingThumbnail && model),
          })}
          style={{ height: !renderingThumbnail && !vaGalleryView ? '455px' : model && vaGalleryView ? '455px' : '' }}
        >
          {image &&
            (ext && !ASSET_TYPES.image.extensions.includes(ext) ? (
              <img
                className={classNames('ratio-img', {
                  [`ratio-img__${objectFit}`]: objectFit,
                  [`${imgClassNames?.join(' ')}`]: imgClassNames,
                })}
                style={{
                  height: '64px',
                  width: '64px',
                  top: '50%',
                  bottom: 'unset',
                  left: '50%',
                  right: 'unset',
                  objectFit: 'unset',
                  transform: 'translate(-50%, -50%)',
                }}
                src={icon}
                alt={alt}
              />
            ) : (
              <div>
                <img
                  className={classNames('ratio-img', {
                    [`ratio-img__${objectFit}`]: objectFit,
                    [`${imgClassNames?.join(' ')}`]: imgClassNames,
                  })}
                  style={{ height: !renderingThumbnail && !vaGalleryView ? '455px' : '' }}
                  src={image}
                  alt={alt}
                />
              </div>
            ))}
          
          {gif && (
            <div>
              <img
                className={classNames('ratio-img', {
                  [`ratio-img__${objectFit}`]: objectFit,
                  [`${imgClassNames?.join(' ')}`]: imgClassNames,
                })}
                style={{ height: !renderingThumbnail && !vaGalleryView ? '455px' : '' }}
                src={gif}
                alt={alt}
              />
            </div>
          )}

          {video && (
            <>
              <ReactPlayer
                playing={playing}
                ref={vidRef}
                className={classNames(
                  'tile__player',
                  !renderingThumbnail && 'tile__player-height'
                )}
                url={video}
                controls={!renderingThumbnail}
                width='100%'
                height='100%'
                muted
              />
              <div
                className={playing || renderingThumbnail ? 'hide' : 'tile__playIcon'}
                onClick={() => setPlaying(true)}
              >
                <img src={PlayIcon} alt='Play' />
              </div>
            </>
          )}

          {model && (
            <div className='tile__player'>
              <AtelierModel3DViewer url={model} name={caption} />
            </div>
          )}

          {!video && !image && !model && !gif && (
            <div
              className='mm--h3 ratio-flex-center'
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: !renderingThumbnail && 'flex-start',
              }}
            >
              <div className='product-upload__drag-area'>
                <img
                  style={{
                    height: '64px',
                    width: '64px',
                  }}
                  src={icon}
                  alt={alt}
                />
                {!renderingThumbnail && (
                  <div className='product-upload__txt'>
                    <p className='product-upload__instructions'>
                      {"This file can't be previewed"}
                    </p>
                    <p className='txt-gray-light'> {alt}</p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {!renderingThumbnail && !gif && !ext && (
        <div className='tile__caption'>
          {caption}
          {type === 'image' && (
            <img
              src={ExpandIcon}
              className={'tile__expand'}
              onClick={() => setOriginal(item)}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Tile;

import React from 'react';
import './LoginForm.scss';
import Checkboxes from 'components/Common/Checkboxes/Checkboxes';
import { useForm } from 'react-hook-form';
import { Link } from '@reach/router';
import AssetPublic from 'components/AssetPublic';
import { convertHexToRGBA } from 'utils/hexToRgbaConverter';
import Asset from 'components/Asset';

export interface ILoginForm {
  logo: any;
  logoPublic?: boolean;
  title: string;
  usernameLabel: string;
  passwordLabel: string;
  onSubmit?: () => void;
  forgotPasswordLink?: string;
  forgotUsernameLink?: string;
  rememberOptionVisible?: boolean;
  mmlogo?: string;
  fontColor?: string;
  backgroundColor?: string;
}

const LoginForm: React.FC<ILoginForm> = props => {
  const {
    logo,
    logoPublic,
    title,
    children,
    usernameLabel,
    passwordLabel,
    forgotPasswordLink,
    forgotUsernameLink,
    rememberOptionVisible = true,
    mmlogo,
    onSubmit,
    fontColor,
    backgroundColor,
  } = props;
  const { register, handleSubmit, errors } = useForm();

  const modifiedFontColor = fontColor && convertHexToRGBA(fontColor, 30);

  return (
    <div className='login-form' style={{ backgroundColor: backgroundColor }}>
      <div className='login-form__header'>
        {logo && logoPublic && logo?.urls?.signed?.download && (
            <img
              className='login-form__logo'
              src={logo?.urls?.signed?.download || mmlogo}
              alt='Logo'
            />
        )}
        {logo && !logoPublic && (
          <Asset asset={['logo', logo]}>
            <img
              className='login-form__logo'
              src={logo?.urls?.download || mmlogo}
              alt='Logo'
            />
          </Asset>
        )}
      </div>
      <div className='login-form__body'>
        <form onSubmit={onSubmit || handleSubmit(values => console.log(values))}>
          <h2 className='login-form__heading' style={{ color: fontColor }}>
            {title}
          </h2>
          {children ? (
            children
          ) : (
            <>
              <div
                className={
                  errors.username ? 'control-block control-block--error' : 'control-block'
                }
              >
                <label className='control--label' style={{ color: fontColor }}>
                  {usernameLabel}
                </label>
                <input
                  name={usernameLabel}
                  className='control control--full-width control--text'
                  style={{ borderColor: modifiedFontColor }}
                  type='text'
                  ref={register({
                    required: 'Username is required.',
                  })}
                />
              </div>
              {errors.username && (
                <span className='error-text'>{errors.username.message}</span>
              )}

              <div
                className={
                  errors.password ? 'control-block control-block--error' : 'control-block'
                }
              >
                <label className='control--label' style={{ color: fontColor }}>
                  {passwordLabel}
                </label>
                <input
                  name={passwordLabel}
                  className='control control--full-width control--text'
                  style={{ borderColor: modifiedFontColor }}
                  type='text'
                  ref={register({
                    required: 'Password is required.',
                    minLength: {
                      value: 5,
                      message: 'Minimum length is 5 characters',
                    },
                  })}
                />
              </div>

              {errors.password && (
                <span className='error-text'>{errors.password.message}</span>
              )}

              {rememberOptionVisible && (
                <div className='pt-2 pb-2' style={{ color: fontColor }}>
                  <Checkboxes label='Remember me' />
                </div>
              )}

              <button
                className='button-primary'
                type='submit'
                style={{ backgroundColor: fontColor, color: backgroundColor }}
              >
                Enter
              </button>
              <button
                className='button-transparent-black'
                style={{
                  backgroundColor: backgroundColor,
                  color: fontColor,
                  borderColor: fontColor,
                }}
              >
                Create Account
              </button>

              <div className='login-form__links'>
                {forgotPasswordLink && (
                  <Link
                    to={forgotPasswordLink}
                    className='login-form__link'
                    style={{ color: modifiedFontColor }}
                  >
                    Forgot password?
                  </Link>
                )}
                {forgotUsernameLink && (
                  <Link
                    to={forgotUsernameLink}
                    className='login-form__link'
                    style={{ color: modifiedFontColor }}
                  >
                    Forgot username?
                  </Link>
                )}
              </div>
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default LoginForm;

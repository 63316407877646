import React from 'react';
import './Colorway.scss';
import DotsIcon from 'assets/icons/dots-grey.svg';

export interface IColors {
  name?: string;
  image?: string;
  selected?: boolean;
}

export interface IColorway {
  colors?: IColors[];
}

const Colorway: React.FC<IColorway> = props => {
  const { colors } = props;

  return (
    <div className='colorway'>
      <div className='colorway__heading'>
        Colorway
        {/* TBD onClick? */}
        <div className='colorway__action'>
          <img src={DotsIcon} alt='Three Dots Open' />
        </div>
      </div>
        <div className='colorway__wrap'>

        {colors &&
          colors.map(({ name, image, selected }, idx) => (
            <div className={selected ? 'colorway__row colorway--selected' : 'colorway__row'} key={idx}>
              <img className='colorway__color-image' src={image} alt={name} />
              {name}
            </div>  
          ))}
      </div>
    </div>
  );
};

export default Colorway;

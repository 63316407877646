import * as React from 'react';
import { useState } from 'react';
import './ForgotPassword.scss';
import { Link } from '@reach/router';
import { useForm } from 'react-hook-form';
import AuthNavigation from '../AuthNavigation/AuthNavigation';

export interface IForgotPassword {
  title: string;
  subtitle: string;
  backBtnTxt: string;
  backBtnUrl: string;
  submitBtnTxt: string;
  emailFieldLabel: string;
  onResetPassword?: (values) => void;
}

const ForgotPassword: React.FC<IForgotPassword> = props => {
  const {
    title,
    subtitle,
    backBtnTxt,
    backBtnUrl,
    submitBtnTxt,
    emailFieldLabel,
    onResetPassword,
  } = props;

  const { register, handleSubmit, errors } = useForm();
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [messageEmail, setMessageEmail] = useState('');
  const onSubmit = values => {
    onResetPassword?.(values);
  };
  const emailRegex = /^\S+@\S+$/i;

  const validateEmail = event => {
    const password = event.target.value;
    if (emailRegex.test(password)) {
      setIsValidEmail(true);
    } else {
      setIsValidEmail(false);
      setMessageEmail('Please enter a valid email!');
    }
  };

  return (
    <>
      <AuthNavigation />
      <div className='forgot-password'>
        <div className='container'>
          <h1 className='forgot-password__heading'>{title}</h1>
          <p className='forgot-password__subheading'>{subtitle}</p>
          <form id='reset-password' onSubmit={handleSubmit(onSubmit)}>
            <div
              className={
                !isValidEmail ? 'control-block control-block--error' : 'control-block'
              }
            >
              <label className='control--label'>{emailFieldLabel}</label>
              <input
                name='email'
                className='control control--full-width control--text'
                type='text'
                onChange={validateEmail}
                ref={register({
                  required: 'Required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Invalid email address',
                  },
                })}
              />
            </div>
            {!isValidEmail && (
              <span className='error-text text-sm' role='alert'>
                {messageEmail}
              </span>
            )}

            <button type='submit' className='button-primary'>
              {submitBtnTxt}
            </button>
          </form>
          <Link to={backBtnUrl} className='link-gray'>
            {backBtnTxt}
          </Link>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;

import React, { useRef } from 'react';
import ReactPlayer from 'react-player';
import './Video.scss';
import UploadMedia from 'components/Dashboard/UploadMedia/UploadMedia';

import PlayIconDark from 'assets/icons/simple-play.svg';
import PlayIconLight from 'assets/icons/simple-play-white.svg';

import RoundPlayIconDark from 'assets/icons/play.svg';
import RoundPlayIconLight from 'assets/icons/light-play.svg';

import PauseIconDark from 'assets/icons/pause.svg';
import PauseIconLight from 'assets/icons/pause-white.svg';
import LogoLocal from 'assets/images/mm_logo_white.png';
import classNames from 'classnames';
import { useLocation } from '@reach/router';
import { useIsBrandCustomSettings } from 'hooks/useLocation';

export interface IVideo {
  videoPath?: string;
  darkMode?: boolean;
  lightMode?: boolean;
  cta?: string;
  ctaUrl?: string;
  headline?: string;
  playerControls?: boolean;
  playIconBottom?: boolean;
  playIconCentered?: boolean;
  playtxt?: string;
  aspectRatio?: string;
  autoPlay?: boolean;
  thumbnailImage?: string;
  src?: string;
  handleUploadButtonClicked?: (e) => void;
  edit?: boolean;
  fontColor?: string;
}

const Video: React.FC<IVideo> = props => {
  const {
    aspectRatio,
    cta,
    headline,
    videoPath,
    darkMode,
    playerControls,
    playIconCentered,
    playIconBottom,
    playtxt,
    ctaUrl,
    autoPlay,
    src,
    handleUploadButtonClicked,
    edit,
    fontColor,
  } = props;
  let { thumbnailImage } = props;
  const vidRef = useRef(null);
  const [playerPlaying, setPlayerPlaying] = React.useState(false);
  const [content, setContent] = React.useState(true);

  thumbnailImage = src || thumbnailImage;

  const handlePlayIcon = () => (darkMode ? PlayIconLight : PlayIconDark);

  const handlePauseIcon = () => (darkMode ? PauseIconLight : PauseIconDark);

  const handleOnPlayClick = () => setPlayerPlaying(!playerPlaying);

  const fileType = 'PNG, JPG, Giff, xxxx ( video file type ) ';
  const txtInstructions = 'Drag your  media files  here to upload ';

  const popUpOptions = [{ selectItem: 'Upload Images' }, { selectItem: 'Upload Videos' }];

  const settingsView = useIsBrandCustomSettings()

  return (
    <div className='video-block'>
      <div
        className={classNames(`ratio${aspectRatio} ratio-wrap`, {
          'video-block__default': !thumbnailImage && !edit,
        })}
        style={{ backgroundColor: settingsView && "#F4F4F4" }}
      >
        {(edit || !thumbnailImage) && (
          <UploadMedia
            edit={edit}
            fileType={fileType}
            txtInstructions={txtInstructions}
            popUpOptions={popUpOptions}
            handleUploadButtonClicked={handleUploadButtonClicked}
            fontColor={fontColor}
          />
        )}
        {thumbnailImage && (
          <img className='video-block__player ratio-img' src={thumbnailImage} />
          // <ReactPlayer
          //   playing={autoPlay ? true : playerPlaying}
          //   ref={vidRef}
          //   className='video-block__player ratio-img'
          //   url={videoPath}
          //   width='100%'
          //   height='100%'
          //   muted
          //   controls={playerControls}
          //   onStart={() => setContent(false)}
          //   light={playerPlaying ? false : thumbnailImage}
          //   config={{
          //     file: {
          //       attributes: {
          //         poster: { thumbnailImage },
          //         controlsList: 'nodownload',
          //       },
          //     },
          //   }}
          // />
        )}

        {playIconBottom && !playerControls && playtxt ? (
          <div
            onClick={() => handleOnPlayClick()}
            className={
              darkMode
                ? 'video-block__play-bottom mm--h6 font-bold color-white'
                : 'video-block__play-bottom mm--h6 font-bold color-black'
            }
          >
            <img
              className='video-block__play-triangle'
              src={playerPlaying ? handlePauseIcon() : handlePlayIcon()}
              alt={playtxt}
            />
            <div style={{ color: fontColor }}>
              {playerPlaying ? 'pause the show' : playtxt}
            </div>
          </div>
        ) : null}

        {playIconCentered && !playIconBottom ? (
          <div
            className={!content ? 'hide' : 'video-block__playIcon'}
            onClick={() => setPlayerPlaying(true)}
          >
            <img src={darkMode ? RoundPlayIconDark : RoundPlayIconLight} alt='Play' />
          </div>
        ) : null}

        <div className={content || autoPlay ? 'video-block__content' : 'hide'}>
          {headline ? (
            <div
              className={
                darkMode
                  ? 'video-block__headline color-white mm--h5 font-bold'
                  : 'video-block__headline color-black mm--h5 font-bold'
              }
              style={{ color: fontColor }}
            >
              {headline}
            </div>
          ) : null}

          {cta ? (
            <a
              className={
                darkMode
                  ? 'video-block__btn button-secondary'
                  : 'video-block__btn button-primary'
              }
              href={ctaUrl}
            >
              {cta}
            </a>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Video;

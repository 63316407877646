export function getImageDimensions(src) {
  const gcd = (a, b) => {
    return b == 0 ? a : gcd(b, a % b);
  };

  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const r = gcd(img.width, img.height);
      const calculatedRatio = `${img.width / r}-${img.height / r}`;
      const attributes = { height: img.height, width: img.width };
      const aspectRatio = [`1-1`, `2-3`, `1-2`, `3-4`, `16-9`, `9-16`, `16-7`].includes(
        calculatedRatio
      )
        ? calculatedRatio
        : null;
      attributes.aspectRatio = aspectRatio;
      resolve(attributes);
    };
    img.onerror = reject;
    img.src = src;
  });
}

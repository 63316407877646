import React from 'react';
import { useForm } from 'react-hook-form';
import { FormHeader } from 'components';
import { TextInput } from 'components/Dashboard/FormComponents';

export interface CompanyProfileSectionProps {
  submitChanges?: (values: any) => any;
  formData: { name: string; legal_name: string; year_established: string };
}

const CompanyProfileSection: React.FC<CompanyProfileSectionProps> = ({
  submitChanges,
  formData,
}) => {
  const [update, setUpdate] = React.useState(false);
  const { handleSubmit, register, errors } = useForm();
  const onSubmit = values => {
    setUpdate(false);
    submitChanges(values);
  };
  return (
    <div>
      <FormHeader
        headingText='Company Profile'
        setUpdate={setUpdate}
        update={update}
        onSubmit={handleSubmit(onSubmit)}
      />
      <div className='row'>
        <TextInput
          label='Company Legal Name'
          name='legal_name'
          update={update}
          required='This field is required'
          value={formData?.legal_name}
          fullWidth
          validate={value => {
            return (
              [/^[a-zA-Z0-9-_ ]+$/].every(pattern => pattern.test(value)) ||
              'Invalid Legal Name. Alphanumeric characters expected.'
            );
          }}
          register={register}
        />
        {errors?.legal_name && (
          <div className='error-text w-100'>{errors?.legal_name?.message}</div>
        )}
        <TextInput
          label='Company Profile Name'
          name='name'
          update={update}
          required='This field is required'
          value={formData?.name}
          register={register}
          validate={value => {
            return (
              [/^[a-zA-Z0-9-_ ]+$/].every(pattern => pattern.test(value)) ||
              'Invalid Profile Name. Alphanumeric characters expected.'
            );
          }}
        />
        {errors?.name && <div className='error-text w-100'>{errors?.name?.message}</div>}
        <TextInput
          label='Year Established'
          name='year_established'
          update={update}
          value={formData?.year_established}
          register={register}
          validate={value => {
            return (
              [/^[0-9]*$/].every(pattern => pattern.test(value)) ||
              'Invalid Year Established. Numeric value expected.'
            );
          }}
        />
        {errors?.year_established && (
          <div className='error-text w-100'>{errors?.year_established?.message}</div>
        )}
      </div>
    </div>
  );
};

export default CompanyProfileSection;

import React, { useEffect, useContext } from 'react';
import useSWR from 'swr';

import './AssetLibraryPage/AssetLibraryPage.scss';
import Loader from 'components/Loader/Loader';
import AssetLibraryContainer from './AssetLibraryContainer';
import ErrorOverlay from './ErrorOverlay';
import { UserContext } from 'providers/contexts';
import { NotificationsContext } from 'providers/NotificationsProvider';
import { ModalsContext } from 'providers/ModalsProvider';
import { mmAPI, postBucketModel } from 'services/Api';

export default function AssetLibraryPage(props) {
  const { user } = useContext(UserContext);
  const { idToken } = user;
  const { brand_model_id } = props;

  const { setDisplayToast } = useContext(NotificationsContext);
  const { setDialogProps } = useContext(ModalsContext);

  const { data: bucket, error, mutate } = useSWR(
    ['/api/bucket/query/brand', idToken, brand_model_id],
    (url, idToken, brand) => {
      return mmAPI(url, idToken, { brand });
    },
    {
      suspense: false,
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        console.log('onerrorretry', error);
        if (error.status === 500) return;
      },
    }
  );

  React.useEffect(() => {
    const errorEffect = errorVal => {
      if (!errorVal) return;
      if (errorVal.response?.data?.error?.type === 'BucketNotFound') {
        setDialogProps({
          dialogBody:
            'Brand bucket required. Click continue to create bucket and continue to the Asset Library',
          dialogBtnAction: `Create ${user?.brand.name} Bucket`,
          dialogBtnClose: 'Go Back',
          btnActionHandler: async e => {
            const response = await postBucketModel(idToken, user?.brand?.key, {
              name: `${user?.brand.name} Bucket`,
            });
            if (!response?.data?.error) {
              const {
                data: { data: bucket },
              } = response;
              mutate(bucket);
              setDisplayToast({
                type: 'success',
                persist: true,
                message: `${bucket.name} has been created. Asset Library is now available.`,
              });
            }
          },
        });
      }
    };
    errorEffect(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, user?.brand]);

  useEffect(() => {
    console.log('error', error);
  }, [error]);

  if (error?.response?.data?.error?.type === 'BucketNotFound') {
    return <ErrorOverlay error={error} />;
  }
  if (!bucket || !bucket?.key) {
    return <Loader active />;
  }
  return <AssetLibraryContainer {...props} bucket={bucket} />;
}

import React from 'react';
import BGGrey from 'assets/images/bg-grey-f9f9f9.png';
import { Asset } from 'components';
import { Link } from '@reach/router';
import classNames from 'classnames';

const ReferenceAssetProgress = props => {
  const {
    asNav,
    asset,
    imageAsFileSeed,
    save,
    onUploadHandler,
    onDropHandler,
    ratio,
    objectFit,
  } = props;
  const { brand_model_id, style_model_id, reference_model_id } = props;
  const [, metadata] = asset;

  const imgRef = React.useRef<HTMLImageElement>();
  const [imageOnLoad, setImageOnLoad] = React.useState(false);

  return (
    <>
      {asNav ? (
        <div className='style-summary__vs-single'>
          <Asset
            asset={asset}
            saveImages={save}
            imageAsFileSeed={imageAsFileSeed}
            onUploadHandler={onUploadHandler}
            onDropHandler={files => onDropHandler(files)}
          >
            <img
              className={classNames(`style-summary__img-default ratio${ratio}`, {
                refLoaded: imageOnLoad,
              })}
              key={imgRef?.current?.src}
              ref={imgRef}
              src={BGGrey}
              onError={e => {
                console.log('onError', e.currentTarget.src);
              }}
              onLoad={e => {
                // console.log('onLoad', e.currentTarget.src);
                // console.log('onLoadRef', imgRef?.current?.src);
                setImageOnLoad(true);
              }}
            />
          </Asset>
        </div>
      ) : (
        <Link
          to={`/brand/${brand_model_id}/style/${style_model_id}/samples/${reference_model_id}`}
          className={classNames(`style-summary__vsm-drop-icon`, {
            [`ratio${ratio} ratio-wrap`]: !!ratio,
          })}
          data-sampleURI={`/samples/${reference_model_id}`}
        >
          <Asset
            asset={asset}
            saveImages={save}
            imageAsFileSeed={imageAsFileSeed}
            onUploadHandler={onUploadHandler}
            onDropHandler={files => onDropHandler(files)}
          >
            <img
              src={BGGrey}
              className={classNames('style-summary__slide', {
                'ratio-img': ratio,
                [`ratio-img__${objectFit}`]: objectFit,
                'style-summary__img-lg': metadata.progress !== 1,
              })}
            />
          </Asset>
        </Link>
      )}
    </>
  );
};

export default ReferenceAssetProgress;

import React, { useContext, useEffect, useState } from 'react';
import 'components/Dashboard/DashboardForms/DashboardForms.scss';
import 'react-phone-input-2/lib/style.css';
import { UserContext, NotificationsContext } from 'providers/contexts';
import CompanyProfileSection from './CompanyProfileSection';
import HeadquartersSection from './HeadquartersSection';
import ContactAdminSection from './ContactAdminSection';
import { updateBrand, getBrandModel } from 'services/Api';
import { FrameUIActionsTypes, useFrameUI } from 'providers/FrameUIProvider';
import Breadcrumbs from 'components/Common/Breadcrumbs/Breadcrumbs';

export interface CompanyProfileProps {
  brand_model_id: number;
}

const CompanyProfileContainer: React.FC<CompanyProfileProps> = ({ brand_model_id }) => {
  const { user } = useContext(UserContext);
  const { idToken } = user;
  const { setDisplayToast } = useContext(NotificationsContext);
  const [formData, setFormData] = useState(null);

  const { dispatch } = useFrameUI();
  const isFirstRender = React.useRef(true);

  useEffect(() => {
    const filterBarEffect = () => {
      if (isFirstRender.current) {
        isFirstRender.current = false;
        dispatch({ type: FrameUIActionsTypes.FILTER_BAR_RESET });
      }
    };
    filterBarEffect();
  }, []);

  useEffect(() => {
    const getBrandDetails = async () => {
      const response = await getBrandModel(idToken, { key: brand_model_id });
      setFormData(response?.data.data);
    };
    getBrandDetails();
  }, [idToken, brand_model_id]);

  const submitChanges = async values => {
    const response = await updateBrand(idToken, {
      ...values,
      brandKey: brand_model_id,
    });
    if (response.data?.error) {
      setDisplayToast({ type: 'error', message: response.data?.error?.message });
    } else {
      setFormData({ ...formData, ...response.data?.data });
      setDisplayToast({ type: 'success', message: 'Brand has been updated' });
    }
  };

  const pages = [
    { title: 'Settings', link: `/settings` },
    { title: 'Company profile', link: `` },
  ];

  return (
    <div>
      <div style={{ marginTop: '16px' }}>
        <Breadcrumbs pages={pages} />
      </div>
      <div className='dashboard-forms' style={{ marginTop: '11px' }}>
        <CompanyProfileSection submitChanges={submitChanges} formData={formData} />
      </div>
      <div className='dashboard-forms divider'>
        <HeadquartersSection submitChanges={submitChanges} formData={formData} />
      </div>
      <div className='dashboard-forms divider'>
        <ContactAdminSection submitChanges={submitChanges} formData={formData} />
      </div>
      <div
        style={{ height: '40px', width: '100%', backgroundColor: 'transparent' }}
      ></div>
    </div>
  );
};

export default CompanyProfileContainer;

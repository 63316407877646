import React from 'react';
import './LoginScreen.scss';
import LoginForm from 'components/Authentication/Login/LoginForm/LoginForm';
import { useLocation } from '@reach/router';
import MMLogoWhite from 'assets/images/modern-mirror-logo.svg';
import MMLogoBlack from 'assets/images/modern-mirror-logo-black.svg';
import { Asset } from 'components';
import { useIsBrandCustomSettings } from 'hooks/useLocation';

export interface ILoginScreen {
  title: string;
  logo: any;
  logoPublic?: boolean;
  onSubmit?: () => void;
  src?: string;
  bgImage?: any;
  centered?: boolean;
  rememberOptionVisible?: boolean;
  passwordLabel?: string;
  usernameLabel?: string;
  forgotPasswordLink?: string;
  forgotUsernameLink?: string;
  lightColor?: string;
  darkColor?: string;
}

const LoginScreen: React.FC<ILoginScreen> = props => {
  const {
    title,
    logo,
    logoPublic,
    children,
    onSubmit,
    centered,
    rememberOptionVisible = true,
    src,
    passwordLabel = 'password',
    usernameLabel = 'username',
    forgotPasswordLink = null,
    forgotUsernameLink = null,
    lightColor = '#fff',
    darkColor = '#000',
  } = props;
  let { bgImage } = props;
  bgImage = src || bgImage;

  const settingsView = useIsBrandCustomSettings()

  return (
    <Asset asset={['hero', bgImage]} signed={!settingsView} overlay={true}>
      <div className='login-screen' style={{
        backgroundImage: bgImage ? `url(${bgImage})` : 'none',
      }}>
        <div className='container'>
          <div
            className={centered ? 'login-screen__center' : 'login-screen__right-aligned'}
          >
            <LoginForm
              title={title}
              logo={logo}
              logoPublic={logoPublic}
              onSubmit={onSubmit}
              passwordLabel={passwordLabel}
              usernameLabel={usernameLabel}
              forgotPasswordLink={forgotPasswordLink}
              forgotUsernameLink={forgotUsernameLink}
              rememberOptionVisible={rememberOptionVisible}
              mmlogo={MMLogoBlack}
              fontColor={darkColor}
              backgroundColor={lightColor}
            >
              {children}
            </LoginForm>
          </div>

          <div className='login-screen__footer'>
            <img
              className='login-screen__mmlogo'
              src={bgImage ? MMLogoWhite : MMLogoBlack}
              alt='Modern Mirror Logo'
            />
          </div>
        </div>
      </div>
    </Asset>
  );
};

export default LoginScreen;

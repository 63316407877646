export function getRouteFromModelAndBrand(
  brandKey: number,
  modelKind: 'Line' | 'Collection' | 'Brand',
  modelKey: number
): string {
  const defaultProudctsRoute = `/brand/${brandKey}/products`;
  if (!modelKind) return defaultProudctsRoute;
  if (modelKind?.toLowerCase() === 'style')
    return `/brand/${brandKey}/${modelKind.toLowerCase()}/${modelKey}`;
  if (modelKind !== 'Brand')
    return `/brand/${brandKey}/products?${modelKind.toLowerCase()}s=${modelKey}`;
  return defaultProudctsRoute;
}

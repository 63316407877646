import React from 'react';
import './Search.scss';

import SearchIcon from 'assets/icons/search-grey.svg';
import ClearIcon from 'assets/icons/close.svg';

export interface ISearch {
  searchValue?: string;
  changeHandler?: (value) => void;
}

const Search: React.FC<ISearch> = props => {
  const { searchValue, changeHandler } = props;

  const [value, setValue] = React.useState(searchValue);
  const [open, setOpen] = React.useState(false);

  const node = React.useRef();

  React.useEffect(() => {
    const searchEffect = (searchValue, value) => {
      if (searchValue !== value) {
        setValue(searchValue);
      }
    };
    searchEffect(searchValue, value);
  }, [searchValue, value]);

  return (
    <div className='search' ref={node}>
      <input
        className='search__input'
        type='text'
        placeholder='Search'
        onChange={event => {
          changeHandler?.(event.target.value);
          value ? setOpen(true) : setOpen(false);
        }}
        onKeyPress={event => {
          if (event.charCode === 13) {
            // 'Enter' key pressed
            changeHandler?.(value);
          }
        }}
        onFocus={() => setOpen(true)}
        value={value}
      />

      <div className={value ? 'search__typing' : 'search__empty'}>
        <img
          className='search__search-close'
          src={ClearIcon}
          alt='Clear'
          onClick={() => {
            setValue('');
            changeHandler?.('');
          }}
        />
        <img className='search__search-icon' src={SearchIcon} alt='Search' />
      </div>
    </div>
  );
};

export default Search;

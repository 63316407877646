import React from 'react';
import './Tooltip.scss';
import TooltipInfoIcon from './TooltipInfoIcon';

export interface ITooltip {
  tooltipText: string;
  tooltipTitle?: string;
}

const Tooltip: React.FC<ITooltip> = props => {
  const { tooltipText, tooltipTitle } = props;

  return (
    <div className='tooltip-block'>
      <div className='tooltip-block__wrap'>
        <TooltipInfoIcon />
        {tooltipTitle}
        <div className='tooltip'>{tooltipText}</div>
      </div>
    </div>
  );
};

export default Tooltip;

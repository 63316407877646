import * as React from 'react';
import { Link } from '@reach/router';
import classNames from 'classnames';

import './Breadcrumbs.scss';
import { ConditionalWrap } from 'components/ConditionalWrap';

export interface IPages {
  title: string;
  link?: string;
  onClick?: (link: string) => void;
}

export interface IBreadcrumbs {
  pages: IPages[];
}

const Breadcrumbs: React.FC<IBreadcrumbs> = props => {
  const { pages } = props;

  return (
    <div className='breadcrumbs'>
      <div className='breadcrumbs__content'>
        <ul className='breadcrumbs__list'>
          {pages &&
            pages.map(({ title, link, onClick }, idx) => (
              <li
                key={idx}
                className={classNames('breadcrumbs__item', {
                  'breadcrumbs__item-link': link && onClick,
                })}
              >
                {idx !== pages.length - 1 ? (
                  <ConditionalWrap
                    condition={link && !onClick}
                    wrap={children => (
                      <Link className='breadcrumbs__group' to={link}>
                        {children}
                      </Link>
                    )}
                  >
                    <div className='breadcrumbs__group'>
                      <h2
                        className='breadcrumbs__previous'
                        onClick={onClick ? () => onClick(link) : undefined}
                      >
                        {title}
                      </h2>
                      /
                    </div>
                  </ConditionalWrap>
                ) : (
                  <ConditionalWrap
                    condition={link && !onClick}
                    wrap={children => (
                      <Link className='breadcrumbs__group' to={link}>
                        {children}
                      </Link>
                    )}
                  >
                    <h2
                      className='breadcrumbs__current'
                      onClick={onClick ? () => onClick(link) : undefined}
                    >
                      {title}
                    </h2>
                  </ConditionalWrap>
                )}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default Breadcrumbs;

import React, { createContext, useState } from 'react';

export const NotificationsContext = createContext({
  displayToast: null,
  setDisplayToast: null,
  notifications: [],
  setNotifications: null,
  addNotifications: null,
});

const NotificationsProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [displayToast, setDisplayToast] = useState(false);

  const addNotifications = newNotifications => 
    setNotifications([...notifications, ...newNotifications]);

  return (
    <NotificationsContext.Provider
      value={{
        displayToast,
        setDisplayToast,
        notifications,
        setNotifications,
        addNotifications,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsProvider;

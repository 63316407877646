import React, { useContext, useEffect, useState } from 'react';
import useSWR from 'swr';
import queryString from 'query-string';
import { UserContext } from 'providers/contexts';
import { mmAPI } from 'services/Api';
import ListingPage from 'components/ProductListingDashboard/ListingPage';
import { UploadContext } from 'providers/UploadProvider';
import 'components/ProductListingDashboard/ProductListingDashboard.scss';
import { getListingColor } from 'utils/getListingColor';

const Listing = ({ location, listing_model_id, ...props }) => {
  const { user } = useContext(UserContext);
  const { firebaseStorageUrl } = useContext(UploadContext);
  const [listingAttributes, setListingAttributes] = useState(null);
  const { edit } = queryString.parse(location.search);

  const { idToken } = user;

  const { data: listing, mutate: mutateListing } = useSWR(
    [`/api/listing/model/${listing_model_id}`, idToken],
    (url, idToken) => {
      return mmAPI(url, idToken);
    },
    {
      suspense: true,
    }
  );

  useEffect(() => {
    const listingEffect = async listingVal => {
      if (!listingVal) return;

      const listingMedia = await Promise.all(
        Object.values(listingVal.media).map(async media => {
          let firebaseImageUrl = null;
          try {
            firebaseImageUrl = media
              ? await firebaseStorageUrl(media.assets.image.path)
              : null;
          } catch (e) {
            console.error('firebaseStorageUrl error', e);
          }
          return {
            imageUrl: firebaseImageUrl,
            key: media.key,
            shot: media.shot,
            name: media.name,
            created: media.created,
            modified: media.modified,
            position: media.position,
          };
        })
      );
      setListingAttributes([
        {
          lookName: listingVal.look_number,
          line_model_id: listingVal.line,
          line_name: listingVal.line_name,
          collection_model_id: listingVal.collection,
          collection_name: listingVal.collection_name,
          heading: listingVal.style_name,
          style_number: listingVal.style_number,
          sku: listingVal.sku,
          upc: listingVal.upc,
          images: listingMedia.map(media => ({
            imageUrl: media.imageUrl,
            key: media.key,
            created: media.created,
            modified: media.modified,
            position: media.position,
          })),
          categories: listingVal.category_names.map((category, i) => {
            return {
              title: listingVal[`category_${i + 1}_label`] || `Category ${i + 1}`,
              category,
              key: listingVal.categories[i],
            };
          }),
          productInfo: [
            {
              title: 'Description',
              content: listingVal.description,
              accordion: false,
              type: 'textarea',
              name: 'description',
            },
            {
              title: 'Sizes',
              content: listingVal.size,
              accordion: true,
              type: 'input',
              name: 'size',
            },
            {
              title: 'Materials',
              content: listingVal.materials,
              accordion: true,
              type: 'input',
              name: 'materials',
            },
            {
              title: 'colour names & swatches',
              content: getListingColor(listingVal),
              accordion: true,
              type: 'textarea',
              name: 'color.name',
            },
            {
              title: 'Size Chart',
              content: listingVal.size_description,
              accordion: true,
              type: 'input',
              name: 'size_description',
            },
          ],
        },
      ]);
    };
    listingEffect(listing);
  }, [listing]);

  return (
    <>
      {listingAttributes && (
        <ListingPage
          listing={listingAttributes}
          mutateListing={mutateListing}
          lookNavigationVisible={false}
          listing_model_id={listing_model_id}
          listingObject={listing}
          editParam={edit}
          {...props}
        />
      )}
    </>
  );
};

export default Listing;

import React, { useState, useEffect } from 'react';
import CloseGray from 'assets/icons/close-gray.png';
import { CustomPicker, RGBColor, HSLColor, ColorChangeHandler } from 'react-color';
import { Saturation, Hue } from 'react-color/lib/components/common';
export interface ColorPickerProps {
  color?: string;
  closeColorPicker?: () => any;
  hex?: string;
  rgb?: string & RGBColor;
  hsl?: string & HSLColor;
  onChange?: ((color) => void) & ColorChangeHandler;
}

const ColorPicker: React.FC<ColorPickerProps> = props => {
  const { closeColorPicker, hex, rgb, onChange } = props;
  const [hexValue, setHexValue] = useState(hex);
  const [rgbValues, setRgbValues] = useState(rgb);

  useEffect(() => {
    setHexValue(hex);
  }, [hex]);

  useEffect(() => {
    setRgbValues(rgb);
  }, [rgb]);

  const rgbColorInput = ({ label, name, value }) => (
    <div className='sidebar__cp-color-rgb'>
      <label className='sidebar__cp-rgb-label' htmlFor={name} defaultValue={label}>
        {label}
      </label>
      <input
        className='sidebar__cp-rgb-input'
        type='text'
        name={name}
        maxLength={3}
        value={value}
        onChange={e => {
          const val = e.target.value;
          const fieldName = e.target.name;
          const newRgb = Object.assign({}, rgb);
          newRgb[fieldName] = Number(val);
          setRgbValues(newRgb);
          onChange(newRgb);
        }}
      />
    </div>
  );

  return (
    <div className='sidebar__color-picker'>
      <div className='sidebar__cp-header'>
        <div className='sidebar__cp-close'>
          <img src={CloseGray} alt='Close' onClick={() => closeColorPicker()} />
        </div>
        <div className='sidebar__cp-color'>
          <span className='sidebar__cp-box' style={{ backgroundColor: hex }}></span>
          <input
            className='sidebar__cp-hex-input'
            type='text'
            name='hex'
            maxLength={7}
            value={hexValue}
            onChange={e => {
              const val = e.target.value;
              setHexValue(val);
              if (val.length === 7) {
                onChange(val);
              }
            }}
          ></input>
        </div>
        <div className='sidebar__cp-center-content-container'>
          <div className='sidebar__cp-saturation-container'>
            <Saturation {...props} onChange={onChange} />
          </div>
          <div className='sidebar__cp-rgb-input-container'>
            {rgbColorInput({ label: 'R', name: 'r', value: rgbValues.r })}
            {rgbColorInput({ label: 'G', name: 'g', value: rgbValues.g })}
            {rgbColorInput({ label: 'B', name: 'b', value: rgbValues.b })}
          </div>
        </div>
        <div className='sidebar__cp-hue-slider'>
          <Hue {...props} onChange={onChange} direction={'horizontal'} />
        </div>
      </div>
    </div>
  );
};

export default CustomPicker(ColorPicker);

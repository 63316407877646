import React, { useContext } from 'react';
import Modal from 'components/Common/Modal/Modal';
import { Link } from '@reach/router';
import './CollectionAccessModal.scss';
import { useForm } from 'react-hook-form';
import { postTeamModel } from 'services/Api';
import TextBox from 'components/Common/TextBox/TextBox';
import { IPopUpOptions } from 'models/Dashboard/IPopUpOptions';
import { NotificationsContext } from 'providers/contexts';
import PopUp from 'components/Common/PopUp/PopUp';
import CaretBottomIcon from 'assets/icons/caret-bottom.svg';

export interface IAllMembers {
  first: string;
  last: string;
  email: string;
  memberAccess: string;
  assets?: any;
}

export interface ICollectionAccessModal {
  heading: string;
  numOfMembers: number;
  allMembers: IAllMembers[];
  popUpOptions: IPopUpOptions[];
  open: boolean;
  collection: any;
  idToken: string;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const CollectionAccessModal: React.FC<ICollectionAccessModal> = props => {
  const {
    heading,
    allMembers,
    numOfMembers,
    popUpOptions,
    open,
    setOpen,
    idToken,
    collection,
  } = props;
  const { register, handleSubmit } = useForm({});
  const [openPopUp, setOpenPopUp] = React.useState([]);
  const { setDisplayToast } = useContext(NotificationsContext);

  const onSubmit = async ({ name }) => {
    const grants = JSON.stringify([
      { model: 'Collection', model_id: collection.key, type: 'editor' },
    ]);
    try {
      const response = await postTeamModel(idToken, {
        name,
        grants: grants,
        model: 'Collection',
        model_id: collection.key,
      });
      if (response.data?.error) {
        setDisplayToast({
          type: 'error',
          message: response.data?.error?.message,
        });
      } else {
        setDisplayToast({
          type: 'success',
          message: `${collection.name}'s Access has been granted to ${name}`,
        });
      }
    } catch (error) {
      if (error?.message) {
        setDisplayToast({
          type: 'error',
          message: error.message,
        });
      }
    }
  };

  return (
    <>
      <div className='collection-access-modal'>
        <Modal
          modalHeading={heading}
          modalBtnClose={'cancel'}
          modalBtnActionLabel={'Save'}
          backgroundColor='#fff'
          modalOpen={open}
          setModalOpen={setOpen}
          modalSubheading={numOfMembers + ' members'}
        >
          <div className='collection-access-modal__label'>
            <TextBox
              name='name'
              required={false}
              label={'Add member'}
              placeholder='Email or name'
              register={register}
            />
          </div>

          <div className='collection-access-modal__add-btn'>
            <button
              onClick={handleSubmit(onSubmit)}
              className='button-primary button-wider'
            >
              Add
            </button>
          </div>

          <div className='collection-access-modal__members'>
            {allMembers &&
              allMembers.map(({ memberAccess, email, first, last, assets }, idx) => (
                <div className='collection-access-modal__member' key={idx}>
                  <div className='collection-access-modal__member-name'>
                    {assets?.profile_picture?.url ? (
                      <img
                        src={assets?.profile_picture?.url}
                        className='collection-access-modal__member-photo'
                        alt={first}
                      />
                    ) : (
                      <span className='collection-access-modal__member-photo'>
                        {first?.slice(0, 1)}
                        {last?.slice(0, 1)}
                      </span>
                    )}
                    {first} {last}
                  </div>
                  <div className='collection-access-modal__member-email'>{email}</div>
                  <div className='collection-access-modal__member-access'>
                    <button
                      className='button-select'
                      onClick={
                        openPopUp.includes(idx)
                          ? () => setOpenPopUp(openPopUp.filter(e => e !== idx))
                          : () => setOpenPopUp([openPopUp.includes(idx), idx])
                      }
                    >
                      {memberAccess}
                      <img src={CaretBottomIcon} alt='Select arrow down' />
                    </button>

                    <PopUp open={openPopUp.includes(idx)} setOpen={setOpenPopUp[idx]}>
                      {popUpOptions &&
                        popUpOptions.map(({ selectItem, to }, idx) => (
                          <li className='pop-up__li' key={idx}>
                            {to ? <Link to={to}>{selectItem}</Link> : selectItem}
                          </li>
                        ))}
                    </PopUp>
                  </div>
                </div>
              ))}
          </div>
        </Modal>
      </div>
    </>
  );
};

export default CollectionAccessModal;

import React from 'react';
export interface NotificationIconProps {
  fontColor: string;
}

const NotificationIcon: React.FC<NotificationIconProps> = ({ fontColor }) => {
  return (
    <svg
      className='frame__bell'
      width='17px'
      height='18px'
      viewBox='0 0 17 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <g
          id='bell'
          transform='translate(1.000000, 1.000000)'
          strokeWidth='1.5'
          stroke={fontColor || 'white'}
        >
          <path d='M14.206375,11.6443966 C13.203375,10.4558521 12.495125,9.85081008 12.495125,6.57415664 C12.495125,3.57352336 10.91225,2.50449882 9.609625,1.98528807 C9.4365,1.91647059 9.273625,1.75839731 9.220875,1.58634756 C8.992375,0.833445378 8.35175,0.170162017 7.50025,0.170162017 C6.648625,0.170162017 6.0076125,0.833820504 5.7814375,1.58709782 C5.7287125,1.76104739 5.5658125,1.91647059 5.392775,1.98528807 C4.088475,2.50524908 2.507225,3.57049815 2.507225,6.57415664 C2.505275,9.85081008 1.7970625,10.4558521 0.7939375,11.6443966 C0.3783125,12.1367798 0.742375,12.8760202 1.4693375,12.8760202 L13.535,12.8760202 C14.258,12.8760202 14.61975,12.1344807 14.206375,11.6443966 Z' />
          <path d='M10,12.8760202 L10,13.4810622 C10,14.1230118 9.736625,14.7385815 9.26775,15.192363 C8.798875,15.6462655 8.163,15.9012303 7.5,15.9012303 C6.837,15.9012303 6.201075,15.6462655 5.7322375,15.192363 C5.2633875,14.7385815 5,14.1230118 5,13.4810622 L5,12.8760202' />
        </g>
      </g>
    </svg>
  );
};
export default NotificationIcon;

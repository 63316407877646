import crypto from 'crypto';

export function hashString(s: crypto.BinaryLike): string {
  const shasum = crypto.createHash('sha1');
  shasum.update(s);
  const shas = shasum.digest('hex');
  return shas;
}

export function verifyPwned(data: string, hash: string): boolean {
  const suffix = hash.substring(5)
  return data.includes(suffix.toUpperCase());
}

export const errorMessage = 'The password has previously appeared in a data breach. Please use a more secure alternative.'
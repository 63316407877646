import React from 'react';
import ColorPicker from './ColorPicker';
export interface SidebarItemColorProps {
  open: boolean;
  setOpen: (id, boolean) => any;
  onColorChange: (id: any, color: any) => void;
  header: string;
  id: string;
  color: string;
}

const SidebarItemColor: React.FC<SidebarItemColorProps> = ({
  open,
  setOpen,
  onColorChange,
  header,
  id,
  color,
}) => {
  return (
    <div className='sidebar__item-name'>
      <div className='sidebar__wrap' style={{ position:"relative" }}>
        {header}
        <div
          className='sidebar__color'
          onClick={() => setOpen(id, true)}
          style={{ backgroundColor: color }}
        />
        {open && (
          <ColorPicker
            color={color}
            onChange={c => onColorChange(id, c)}
            closeColorPicker={() => setOpen(id, false)}
          />
        )}
      </div>
    </div>
  );
};

export default SidebarItemColor;

import * as React from 'react';
import './ChangePassword.scss';
import { useForm } from 'react-hook-form';
import AuthNavigation from '../AuthNavigation/AuthNavigation';
import Checkboxes from 'components/Common/Checkboxes/Checkboxes';
import { Link } from '@reach/router';
import { useState } from 'react';
import { PasswordRules } from 'constants/index';

export interface IChangePassword {
  temporaryPasswordField: string;
  passwordField: string;
  confirmPasswordField: string;
  submitBtnTxt: string;
  onSubmit?: () => void;
  passwordHint: string;
  terms?: boolean;
  loginLink: string;
}

export const ChangePassword: React.FC<IChangePassword> = props => {
  const {
    submitBtnTxt,
    temporaryPasswordField,
    passwordField,
    confirmPasswordField,
    onSubmit,
    passwordHint,
    terms = true,
    loginLink = '/',
  } = props;
  const { register, handleSubmit, errors, watch } = useForm();

  const [isValidPassword, setIsValidPassword] = useState(true);
  const [isValidConfirmPassword, setIsValidConfirmPassword] = useState(true);
  const [message, setMessage] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const validatePassword = event => {
    const password = event.target.value;
    setPassword(password);
    if (PasswordRules.regex.test(password)) {
      setIsValidPassword(true);
    } else {
      setIsValidPassword(false);
      setMessage('Enter a valid password!');
    }
  };
  const validateConfirmPassword = event => {
    const password = event.target.value;
    setConfirmPassword(password);
    if (password === watch('password')) {
      setIsValidConfirmPassword(true);
    } else {
      setIsValidConfirmPassword(false);
      setMessage('Confirm password does not match!');
    }
  };

  return (
    <>
      <AuthNavigation loginLink={loginLink} />
      <div className='change-password'>
        <div className='container'>
          <form onSubmit={handleSubmit(onSubmit)}>
            {temporaryPasswordField && (
              <div
                className={
                  errors.temporaryPassword
                    ? 'control-block control-block--error'
                    : 'control-block'
                }
              >
                <label className='control--label'>{temporaryPasswordField}</label>
                <input
                  className='control control--full-width control--text'
                  name='temporaryPassword'
                  type='password'
                  ref={register({
                    required: 'Enter temporary password',
                    pattern: {
                      value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/i,
                      message: passwordHint,
                    },
                  })}
                />
                {errors.temporaryPassword && (
                  <span className='error-text' role='alert'>
                    {errors.temporaryPassword.message}
                  </span>
                )}
              </div>
            )}
            <div
              className={
                !isValidPassword
                  ? 'control-block control-block--error'
                  : password === ''
                  ? 'control-block'
                  : 'control-block--success'
              }
            >
              <label className='control--label'>{passwordField}</label>
              <input
                name='password'
                className='control control--full-width control--text'
                type='password'
                onChange={validatePassword}
                ref={register({
                  required: 'Enter a new password',
                  pattern: {
                    value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/i,
                    message: passwordHint,
                  },
                })}
              />
              {!isValidPassword && (
                <>
                  <span className='error-text' role='alert'>
                    {message}
                  </span>
                  <p className='change-password__info'>{PasswordRules.reminderText}</p>
                </>
              )}
            </div>

            <div
              className={
                !isValidConfirmPassword
                  ? 'control-block control-block--error'
                  : confirmPassword === ''
                  ? 'control-block'
                  : 'control-block--success'
              }
            >
              <label className='control--label'>{confirmPasswordField}</label>
              <input
                name='confirmPassword'
                className='control control--full-width control--text'
                type='password'
                onChange={validateConfirmPassword}
                ref={register({
                  required: 'Confirm your new password',
                  validate: value =>
                    value === watch('password') || 'Passwords do not match.',
                })}
              />

              {!isValidConfirmPassword && (
                <>
                  <span className='error-text' role='alert'>
                    {message}
                  </span>
                  <p className='change-password__info'>{'Password doesn’t match'}</p>
                </>
              )}
            </div>

            {terms && (
              <div className='pt-3'>
                <Checkboxes label='I have read and accepted the terms and conditions' />
              </div>
            )}

            <button className='button-primary' type='submit'>
              {submitBtnTxt}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export interface IChangePasswordSuccess {
  loginLink: string;
}

export const ChangePasswordSuccess: React.FC<IChangePasswordSuccess> = props => {
  const { loginLink } = props;

  React.useEffect(() => {
    console.log('loginLink', loginLink);
  }, [loginLink]);

  return (
    <>
      <AuthNavigation loginLink={loginLink} />

      <div className='change-password'>
        <div className='container'>
          <div className='change-password__success-wrap'>
            <h3 className='change-password__success-txt pb-2'>Success!</h3>
            <h3 className='change-password__success-txt'>
              Your password has been changed successfully!
            </h3>
            <a className='change-password__login button-primary' href={loginLink}>
              Login
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

import * as React from 'react';

export interface ITextBox {
  name: string;
  label?: string;
  required?: boolean;
  placeholder?: string;
  helpText?: string;
  register?: any;
  value?: any;
  cssClass?: any;
  onFocus?:() => void;
  onBlur?:() => void;
}

const TextBox: React.FC<ITextBox> = props => {
  const { label, required, placeholder, helpText, register, name, value, cssClass, onFocus, onBlur } = props;

  return (
    <div className='text-box'>
      <div className='control-block'>
        <label
          className={required ? 'control--label control--required' : 'control--label'}
        >
          {label}
        </label>
        <input
          className={'control control--full-width control--text ' + cssClass}
          type='text'
          defaultValue={value}
          placeholder={placeholder}
          ref={register({ required: required })}
          name={name}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        {helpText ? <p className='control--help'>{helpText}</p> : null}
      </div>
    </div>
  );
};

export default TextBox;

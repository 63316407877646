import React, { useEffect, useState, useCallback } from 'react';
import { CSSTransition } from 'react-transition-group';
import './Alert.scss';

import WarningIcon from 'assets/icons/warning-open.svg';
import SuccessIcon from 'assets/icons/check-circle-full.svg';
import ErrorIcon from 'assets/icons/exclamation-full.svg';
import InfoIcon from 'assets/icons/info-panel-open.svg';

export interface IAlert {
  alertText: string;
  alertType: string;
  persist?: boolean;
  closeAlert?: () => void;
}

const Alert: React.FC<IAlert> = props => {
  const { alertText, alertType, persist = false, closeAlert } = props;
  const [alertOpen, setAlertOpen] = useState(false);

  const onCloseAlert = useCallback(() => {
    setAlertOpen(false);
    if (persist) {
      closeAlert?.();
    }
  }, [persist, closeAlert]);

  useEffect(() => {
    setAlertOpen(true);

    const persistEffect = (persistVal) => {
      if (!persistVal) {
        setTimeout(() => {
          onCloseAlert();
        }, 6000); // Alert disappears after 10 seconds
      }
    }
    persistEffect(persist)
  }, [persist, onCloseAlert]);

  const getIconType = (type: string) => {
    switch (type) {
      case 'error':
        return ErrorIcon;
      case 'warning':
        return WarningIcon;
      case 'success':
        return SuccessIcon;
      case 'info':
        return InfoIcon;
      default:
        break;
    }
  };


  const renderAlertBody = () => (
    <div className={`alert alert--${alertType}`}>
      <div className='alert__content'>
        <img className='alert__alert-icon' src={getIconType(alertType)} alt={alertType} />
        <h3>
          <span className='alert__title'>{alertType}: </span> {alertText}
        </h3>
        <div className='alert__close' onClick={onCloseAlert}>
          <svg
            width='32'
            height='32'
            viewBox='0 0 32 32'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path d='M16.9656 16.0239L21.8273 11.1621C22.0878 10.9016 22.0878 10.4795 21.8273 10.2196C21.5667 9.95908 21.1446 9.95908 20.8848 10.2196L16.0231 15.0814L11.1614 10.2189C10.9015 9.9583 10.4787 9.9583 10.2189 10.2189C9.9583 10.4794 9.9583 10.9015 10.2189 11.1614L15.0806 16.0239L10.2189 20.8856C9.9583 21.1461 9.9583 21.5683 10.2189 21.8281C10.3484 21.9591 10.5191 22.0239 10.6897 22.0239C10.8604 22.0239 11.031 21.9591 11.1614 21.8288L16.0231 16.9663L20.8848 21.828C21.0151 21.9583 21.1857 22.0231 21.3564 22.0231C21.5271 22.0231 21.6977 21.9583 21.828 21.828C22.0886 21.5675 22.0886 21.1454 21.828 20.8855L16.9655 16.0238L16.9656 16.0239Z' />
          </svg>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {persist ? (
        renderAlertBody()
      ) : (
          <CSSTransition
            in={alertOpen}
            classNames='alert'
            timeout={500}
            unmountOnExit
            onExited={() => closeAlert?.()}
          >
            {renderAlertBody()}
          </CSSTransition>
        )}
    </>
  );
};

export default Alert;

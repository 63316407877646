import React, { useState } from 'react';
import 'components/VirtualAtelier/Uploads/AtelierModelUploads/AtelierModel3DAnnotation.scss';
import ViewIcon from 'assets/icons/Icon-view.svg';
import CompareIcon from 'assets/icons/icon-compare.svg';
import PlusIcon from 'assets/icons/plus.svg';

import classNames from 'classnames';
import '@google/model-viewer/dist/model-viewer';

const AtelierModel3DAnnotation = props => {
  const {
    url,
    name,
    annots,
    setAnnots,
  } = props;
  const modelRef = React.useRef();

  const handleClick = event => {
    const { clientX, clientY } = event;
    if (modelRef.current) {
      const hit = modelRef.current.positionAndNormalFromPoint?.(clientX, clientY);
      if (hit) {
        hit.key = Date.now();
        hit.thread = [];
        hit.slot = annots.length + 1;
        setAnnots(annots => {
          return [...annots, hit];
        });
      }
    }
  };

  const getDataPosition = annot => {
    return `${annot.position.x}m ${annot.position.y}m ${annot.position.z}m`;
  };

  const getDataNormal = annot => {
    return `${annot.normal.x}m ${annot.normal.y}m ${annot.normal.z}m`;
  };

  const [hotspotsVisible, setHotspotsVisbile] = useState(true);
  const [addHotspot, setAddHotspot] = useState(false);

  return (
    <div className='model-viewer-wrapper'>
      <model-viewer
        class='tile__player'
        src={url}
        loading='eager'
        camera-controls
        onClick={addHotspot && handleClick}
        ref={ref => {
          modelRef.current = ref;
        }}
      >
        <div className='model-button-load button-primary btn-large' slot='poster'>
          Loading {name}
        </div>

        {/* Note we are createing annotation points and mapping through them here. */}
        {annots &&
          annots.map((annot) => {
            return (
              <button
                key={`hotspot-${annot.slot}`}
                className='hotspot'
                style={{ display: !hotspotsVisible && 'none' }}
                slot={`hotspot-${annot.slot}`}
                data-position={getDataPosition(annot)}
                data-normal={getDataNormal(annot)}
                data-visibility-attribute={'visible'}
                data-visible={hotspotsVisible}
              >
                {annot.slot}
                {/* <div className='hotspot-annotation'>sdfsddsf</div> */}
              </button>
            );
          })}

        <div className='style-sample-review-header'>
          <div className='style-sample-review-header__wrap'>
            <div className='style-sample-review-header__right-aligned'>
              <div
                className={classNames('button-dashboard  btn-fs-large', {
                })}
                style={{ cursor: annots?.length === 0 && 'not-allowed' }}
                onClick={() => annots?.length > 0 && setHotspotsVisbile(!hotspotsVisible)}
              >
                <img className='style-sample-review-header__img-icon' src={ViewIcon} />
                <div className='tooltip tooltip--black'>
                  <span className='tooltip__txt'>Toggle Hotspots</span>
                </div>
              </div>


              <div
                className={classNames('button-dashboard  btn-fs-large', {
                  'color-gray-bg': addHotspot,
                })}
                onClick={() => setAddHotspot(!addHotspot)}
              >
                <img className='style-sample-review-header__img-icon' src={PlusIcon} />
                <div className='tooltip tooltip--black'>
                  <span className='tooltip__txt'>Add Hotspot</span>
                </div>
              </div>

              {addHotspot && (
                <span>Click on the model in the preview to add a hotspot.</span>
              )}
            </div>
          </div>
        </div>

        {/* Note this is a statically generated annotation, gives us an idea of how to create and save */}
        {/* <button
          className='hotspot'
          slot='hotspot-2'
          data-position='-0.03605968300281273m 1.5440559847003736m 0.04797680980461294m'
          data-normal='-0.021742392667603923m 0.5827537861637984m 0.8123578602271575m'
          data-visibility-attribute='visible'
        >
          
        </button> */}
      </model-viewer>
    </div>
  );
};

export default AtelierModel3DAnnotation;

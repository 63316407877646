import React from 'react';
import ProductPage from 'components/ProductListingDashboard/ProductPage';

const ProductContainer = ({ product }) => {
  const popUpOptions = [{ selectItem: 'Edit' }, { selectItem: 'Delete' }];

  const productAttributes = [
    {
      lookName: '1234',
      heading: product.style_name,
      sku: '40133151',
      upc: '40133151',
      images: [
        {
          imageUrl:
            'https://images.unsplash.com/photo-1578996834513-32e076d69f89?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9',
        },
        {
          imageUrl:
            'https://images.unsplash.com/photo-1592840015316-0f1747257759?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9',
        },
        {
          imageUrl:
            'https://cdn-images.farfetch-contents.com/15/56/45/01/15564501_27973648_1000.jpg',
        },
      ],
      categories: [
        { title: 'Category 1', category: 'Menswear Footwear' },
        { title: 'Category 2', category: 'Runners' },
        { title: 'Category 2', category: 'Lace Up' },
        { title: 'Category 2', category: 'Flats' },
      ],
      productInfo: [
        {
          title: 'Description',
          content:
            'Make a splash in this Monogram print bikini from Burberry. Designed with a scoop neck and high-waisted bottoms, this flattering two-piece is perfect for beach-side bathing and lively pool parties alike. Race you to the beach!',
          accordion: true,
        },
        {
          title: 'Sizes',
          content: product.sizes.join(','),
          accordion: true,
        },
        {
          title: 'Materials',
          content:
            'Outer: Polyester 69%, Spandex/Elastane 31%, Lining: Polyamide 66%, Spandex/Elastane 34%',
          accordion: true,
        },
        {
          title: 'color names & swatches',
          accordion: true,
        },
        {
          title: 'Size Chart',
          content:
            'In hac habitasse platea dictumst. Quisque vel quam aliquam, sagittis tellus vitae, lobortis sem. Phasellus varius tristique dolor id tincidunt. Phasellus sit amet lorem hendrerit, viverra est vel, sollicitudin est.',
          accordion: true,
        },
      ],
    },
  ];

  return (
    <>
      <ProductPage
        product={productAttributes}
        popUpOptions={popUpOptions}
        lookNavigationVisible={false}
      />
    </>
  );
};

export default ProductContainer;

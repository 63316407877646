import * as React from 'react';
import Select from 'react-dropdown-select';
import './Dropdowns.scss';
import { Controller, Control } from 'react-hook-form';

export interface IDropdowns {
  name: string;
  label?: string;
  options: IOption[];
  fullWidth: boolean;
  multi?: boolean;
  placeholder?: string;
  grayIcon?: boolean;
  onChange?: (value: any) => void;
  required?: boolean;
  control?: Control<Record<string, any>>;
}

export interface IOption {
  label?: string;
  value?: any;
}

const Dropdowns: React.FC<IDropdowns> = props => {
  const {
    name,
    label,
    options,
    fullWidth,
    placeholder,
    grayIcon,
    multi,
    required,
    control,
    onChange,
  } = props;
  const [selectedValues, setSelectedValues] = React.useState([]);

  const handleChange = values => {
    setSelectedValues(values);
    onChange?.(values);
  };

  return (
    <div className={grayIcon ? 'Dropdowns Dropdowns__gray' : 'Dropdowns'}>
      <div className='control-group'>
        <div
          className={
            fullWidth
              ? 'control control--select control--full-width'
              : 'control control--select'
          }
        >
          <label
            className={required ? 'control--label control--required' : 'control--label'}
          >
            {label}
          </label>
          {props.children}
          <Controller
            as={
              <Select
                placeholder={placeholder}
                options={options}
                searchBy='name'
                values={selectedValues}
                labelField='label'
                valueField='value'
                onChange={handleChange}
                multi={multi}
              />
            }
            control={control}
            name={name}
            defaultValue={null}
            rules={{ required: required }}
          />
        </div>
      </div>
    </div>
  );
};

export default Dropdowns;

import * as React from 'react';
import './AuthNavigation.scss';

import SearchIcon from 'assets/icons/search.svg';
import MMLogoBlack from 'assets/images/modern-mirror-logo-black.svg';
import { Link } from '@reach/router';

export interface IAuthNavigation {
  loginLink?: string;
}

const AuthNavigation: React.FC<IAuthNavigation> = ({ loginLink = '/' }) => {
  return (
    <div className='auth-navigation'>
      <div className='container'>
        <div className='auth-navigation__layout'>
          <div className='auth-navigation__logo'>
            <img src={MMLogoBlack} alt='Logo' />
          </div>
          <div className='auth-navigation__right'>
            <a href={loginLink} className='auth-navigation__login'>
              login
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthNavigation;

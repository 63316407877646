import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          productUpload: {
            reviewSpreadsheet: 'Review the spreadsheet again.',
            PermissionError:
              'Please ensure you have proper permissions. If this error persists, you can reach our to our Client Services Department by email at: VAB001@themodernmirror.com',
            ListingParserKeyError:
              'Required field "{{ missingField }}" missing. Review the spreadsheet and upload again.',
            ListingParserDuplicatesError:
              'Duplicate listings found in the provided dataset. Please ensure only one row for each Listing Unique ID. Review the spreadsheet and upload again.',
            ListingParserWarning: 'Optional field "{{ missingField }}" is empty',
            ParameterError: 'Error parsing "{{ missingField }}" for upload',
          },
        },
      },
    },
  });

export default i18n;

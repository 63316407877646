import * as React from 'react';
import { mmAPI } from 'services/Api';
import useSWR from 'swr';

import { UserContext } from 'providers/UserProvider';

type useAccountsModelParams = {
  brand: number;
};

type useAccountsReturn = {
  accounts: any;
  mutateAccounts: () => void;
  getContactMatch: (contactTeam: any) => any | null;
  getContactMetadata: (contactKey: number) => any | null;
};

const useAccounts = (
  idToken: string,
  { brand }: useAccountsModelParams
): useAccountsReturn => {
  const { user } = React.useContext(UserContext);

  const { data: accounts, mutate: mutateAccounts } = useSWR(
    brand ? [`/api/account/query/brand/`, idToken, brand] : null,
    (url, idToken, brand) => {
      return mmAPI(url, idToken, { brand, cursor: 0 });
    },
    {
      suspense: true,
    }
  );

  const getContactMetadata = contactKey => {
    if (contactKey === user?.account?.key) return user?.account;
    const metadata = accounts?.find(a => a.key === contactKey);
    if (metadata) return metadata;
    return null;
  };

  const getContactMatch = contactTeam => {
    if (!contactTeam?.key) return null;
    const contactGrants = contactTeam.grants?.filter(g => g.model === 'Account');
    if (contactGrants?.length === 2) {
      const contactMatch = contactGrants.find(g => g.model_id !== user?.account?.key);
      return contactMatch;
    }
    return null;
  };

  return {
    accounts,
    mutateAccounts,
    getContactMatch,
    getContactMetadata,
  };
};

export default useAccounts;

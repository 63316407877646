import React from 'react';

export interface SearchIconProps {
  fontColor: string;
}

const SearchIcon: React.FC<SearchIconProps> = ({ fontColor }) => {
  return (
    <svg
      className='frame__search'
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill={fontColor || 'white'}
      strokeWidth={0}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.5684 18.449L23.7656 22.6328C23.8379 22.7053 23.8936 22.7885 23.9326 22.8772C24.0615 23.1704 24.0049 23.5251 23.7646 23.7656C23.6074 23.9221 23.4014 24 23.1953 24C22.9902 24 22.7842 23.9221 22.627 23.7656L18.4238 19.5753C17.3301 20.415 15.9648 20.9205 14.4805 20.9205C10.9072 20.9205 8 18.0223 8 14.4602C8 10.8982 10.9072 8 14.4805 8C18.0537 8 20.9609 10.8982 20.9609 14.4602C20.9609 15.9657 20.4375 17.3492 19.5684 18.449ZM14.4814 9.60254C11.7949 9.60254 9.6084 11.7819 9.6084 14.4603C9.6084 17.1387 11.7949 19.318 14.4814 19.318C17.168 19.318 19.3545 17.1387 19.3545 14.4603C19.3545 11.7819 17.168 9.60254 14.4814 9.60254Z'
      />
    </svg>
  );
};

export default SearchIcon;

import React, { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { Link, navigate } from '@reach/router';
import classNames from 'classnames';

import './StyleSummary.scss';
import 'components/Dashboard/AFSAssetReview/AFSAssetReview.scss';
import { WORKFLOW_STATUS, classNamesFromStatus, WorkflowStatusType } from './util';
import { SlickCenterMode } from './SlickCenterMode';
import PopUp from 'components/Common/PopUp/PopUp';
import Modal from 'components/Common/Modal/Modal';
import { deleteReferenceModel, postModel } from 'services/Api';
import useClickAway from 'hooks/useClickAway';
import useMessages from 'hooks/useMessages';
import { NotificationsContext } from 'providers/NotificationsProvider';
import { UserContext } from 'providers/UserProvider';
import { FrameUIActionsTypes, useFrameUI } from 'providers/FrameUIProvider';
import { UploadContext } from 'providers/UploadProvider';
import { getImageAspectRatio } from 'utils/getImageAspectRatio';
import { sortMediaPosition } from 'utils/sort';
import Loader from 'components/Loader/Loader';
import CompletedIcon from 'assets/icons/completed-checked.svg';
import ConversationIcon from 'assets/icons/icon-conversation.svg';
import UserIconGrey from 'assets/icons/user-grey.svg';
import AddIcon from 'assets/icons/add-icon.svg';
import PlusIcon from 'assets/icons/Icon-plus-gray.svg';
import ViewIcon from 'assets/icons/Icon-view.svg';
import WorkflowImage from 'assets/images/workflow.png';
import Asset from 'components/Asset';
import Colorway, { IColors } from '../Colorway/Colorway';
import { ASSET_TYPES } from 'constants/assets';

export type ProgressSpecs = {
  title: string;
  completed: boolean;
  link: string;
};

export interface IStyleInfo {
  title: string;
  content: string;
}

export type ApiAsset = {
  ext: string;
  key: number;
  path: string;
  progress: number;
  urls: {
    download: string;
    expires: number;
    signed: any;
    upload: string;
  };
};

export type Media = {
  assets: {
    image: ApiAsset;
  };
  brand: number;
  key: number;
  name: string;
  pose: string;
  position: number;
  progress: number;
  products: number[];
  shot: string;
  size: string;
  sku: string;
  source: 'brand' | 'vsm';
  style: number;
  style_number: string;
  tags: string[];
  type: 'image' | 'fabric' | 'trim' | 'hardware' | 'pattern';
  src?: string;
};

export interface IStyleSummary {
  style: any;
  media: Media[];
  references: any;
  styleInfo: IStyleInfo[];
  statusState: [string, (status: { status: string }) => void];
  productName: string;
  progressSpecs: {
    status_materials: ProgressSpecs;
    status_patterns: ProgressSpecs;
    status_techpack: ProgressSpecs;
  };
  title: string;
  tooltipText: string;
  tooltipTitle: string;
  brand_model_id: string;
  style_model_id: string;
  accessGranted: any;
  onDropHandler?: (files: File[], path?: []) => void;
  colors?: IColors[];
}

const StyleSummary: React.FC<IStyleSummary> = props => {
  const {
    style,
    media,
    onDropHandler,
    sampleSlideshow,
    sampleSlideshowUploading,
    styleMediaFetchInterval,
    setStyleMediaFetchInterval,
    styleInfo,
    statusState: [status = WORKFLOW_STATUS.TO_DO.value, onSetStatus],
    productName,
    progressSpecs,
    brand_model_id,
    style_model_id,
    accessGranted,
    colors,
  } = props;
  const { user } = useContext(UserContext);
  const { idToken } = user;

  const { dispatch } = useFrameUI();

  const allowedFileExtensions = ASSET_TYPES.image.extensions.concat(...ASSET_TYPES.model.extensions, ...ASSET_TYPES.gif.extensions);

  useEffect(() => {
    const filterBarEffect = () => {
      dispatch({
        type: FrameUIActionsTypes.LOCATIONBAR_TITLE,
        payload: 'Virtual Atelier',
      });
      dispatch({
        type: FrameUIActionsTypes.LOCATIONBAR_BACK_ONCLICK,
        payload: () => window.history.back(),
      });
    };
    filterBarEffect();

    return () => {
      dispatch({
        type: FrameUIActionsTypes.LOCATIONBAR_TITLE,
        payload: null,
      });
      dispatch({
        type: FrameUIActionsTypes.LOCATIONBAR_BACK_ONCLICK,
        payload: null,
      });
    };
  }, []);

  const [openPopUp, setOpenPopUp] = useState(false);
  const nodeChangeStatus = useRef();
  const [open, setOpen] = useState(false);
  useClickAway(nodeChangeStatus, setOpenPopUp);

  const popUpOptions = Object.values(WORKFLOW_STATUS)
    .filter(
      (s: WorkflowStatusType) =>
        s.job_type === user?.account?.job_type ||
        accessGranted?.includes('editor') ||
        accessGranted?.includes('owner')
    )
    .map(s => ({
      selectItem: s.value,
      value: s.value,
      accent: s.color.accent,
      bg: s.color.bg,
      job_type: s.job_type,
    }));

  const [imageSlideshow, setImageSlideshow] = useState(null);

  useEffect(() => {
    const mediaEffect = async (mediaVal: Media[]) => {
      if (!mediaVal) return;
      const getMediaAssetUrls = async (m: Media) => {
        const { assets, type } = m;
        const assetUrl =
          assets?.[type]?.progress > 0
            ? await firebaseStorageUrl(assets?.[type]?.path)
            : null;

        const aspectRatio =
          assetUrl && type === 'image' ? await getImageAspectRatio(assetUrl) : null;

        return {
          ...m,
          src: assetUrl,
          aspectRatio,
        };
      };

      const getMediaAssetsResolved = mediaModels => {
        return Promise.all(
          mediaModels.map(async m => {
            try {
              return await getMediaAssetUrls(m);
            } catch (e) {
              console.log('error loading media getMediaAssetUrls', m, e);
              return new Promise((resolve, reject) => {
                resolve(null);
              });
            }
          })
        );
      };

      try {
        const mediaResolved = await getMediaAssetsResolved(mediaVal);
        setImageSlideshow(
          [...mediaResolved].filter((m: Media) => m?.src).sort(sortMediaPosition)
        );
      } catch (e) {
        console.log('error resolving and setting all media assets', e);
      }
    };
    mediaEffect(media);
  }, [media]);

  const buttonActionHandler = () => {
    setDisplayToast({
      type: 'info',
      persist: true,
      message: `Access can be managed via the Manage Team view in Settings.`,
    });
  };

  const virtualSampleGallery = [
    {
      image:
        'https://images.unsplash.com/photo-1604025940862-ee1fa5d60347?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=888&q=80',
    },
    {
      image:
        'https://images.unsplash.com/photo-1604025940862-ee1fa5d60347?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=888&q=80',
      title: 'Turnable',
    },
    {
      image:
        'https://images.unsplash.com/photo-1604025940862-ee1fa5d60347?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=888&q=80',
      title: 'Details',
    },
    {
      image:
        'https://images.unsplash.com/photo-1604025940862-ee1fa5d60347?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=888&q=80',
      title: 'Animation',
    },
  ];

  const { setDisplayToast } = useContext(NotificationsContext);

  const { firebaseStorageUrl } = useContext(UploadContext);
  const getFirebaseStorageUrl = async asset => {
    if (asset) {
      const firebaseUrl = await firebaseStorageUrl(asset.path);
      return Promise.resolve(firebaseUrl);
    }
  };

  const { onMessagesOpen, setMessageModelAssoc } = useMessages(idToken, {
    model: null,
    open: false,
    reset: true,
  });

  const [sampleUploads, setSampleUploads] = useState({
    sample: {
      save: false,
    },
  });

  const onUploadHandler = () => {
    navigate(`/brand/${brand_model_id}/style/${style_model_id}/samples/sample`);
  };
  const handleUploadButtonClicked = (name, files) => {
    const [droppedFile] = files;
    const imgSrc = URL.createObjectURL(droppedFile);
    setSampleUploads(prev => ({
      ...prev,
      sample: {
        save: true,
      },
    }));
  };

  const deleteMediaActionHandler = useCallback(reference_model_id => {
    console.log('Remove Reference:', reference_model_id);
    const response = deleteReferenceModel(idToken, reference_model_id, {
      location: window.location.href,
    });
    // props.onDeleteMedia?.(reference_model_id);
  }, []);

  return (
    <>
      <div className='style-summary'>
        <div
          className={classNames('', {
            'style-summary__designer-role': user?.account.job_type === 'designer',
          })}
        >
          <div className='row'>
            <div className='col-lg-4'>
              <div className='style-summary__col-1'>
                <div className='style-summary__img-wrap style-summary__slideshow'>
                  {imageSlideshow?.length > 0 && (
                    <SlickCenterMode
                      name={'listingsSlideshow'}
                      media={imageSlideshow}
                      showNav={false}
                      showDropDefault={false}
                      mediaRatio='3-4'
                      mediaObjectFit='contain'
                      renderWithLink={false}
                    />
                  )}
                  {/* <Asset
                  asset={['image', featuredBrandMedia?.assets?.image]}
                  upload={false}
                >
                  <img
                    src={VirtualAtelierHeroImage}
                    alt={productName}
                    className='style-summary__img'
                  />
                </Asset> */}
                </div>

                <div className='style-summary__progress'>
                  <h3 className='style-summary__heading'>Product Assets </h3>
                  <p className='style-summary__subheading'>Upload Asset </p>

                  {progressSpecs &&
                    Object.entries(progressSpecs).map(
                      ([styleUploadAttribute, { title, completed, link }], idx) => (
                        <div
                          className='style-summary__progress-item'
                          key={styleUploadAttribute}
                        >
                          <div className='style-summary__progress-left'>
                            <img
                              src={completed ? CompletedIcon : AddIcon}
                              alt={completed ? 'Completed' : 'Add'}
                            />
                            {link ? <Link to={link}>{title}</Link> : title}
                          </div>
                          <img
                            className='style-summary__view-current'
                            src={ViewIcon}
                            onClick={() => navigate(link)}
                            alt='View'
                          />
                        </div>
                      )
                    )}
                </div>
              </div>
            </div>

            <div className='col-lg-3'>
              <div className='style-summary__col-2'>
                <h6 className='style-title-small uppercase pb-4'>{`${
                  style.listing?.style_name || productName
                }`}</h6>
                {(style.listing?.addition || style.addition) && (
                  <button className='button-badge color-white-3-bg border'>
                    addition
                  </button>
                )}
                {styleInfo &&
                  styleInfo.map(({ title, content }, idx) => (
                    <div className='style-summary__info' key={title}>
                      <span className='style-summary__title'>{title} /</span> {content}
                    </div>
                  ))}
                <div className='style-summary__workflow'>
                  <div className='style-summary__workflow__label uppercase'>
                    Workflow Status
                  </div>
                  <div
                    className='afs-asset-review__info style-summary__workflow__button'
                    onClick={() => {
                      setOpenPopUp(false);
                      setOpen(!open);
                    }}
                  >
                    <div>View Workflow</div>
                  </div>
                </div>
                <button className={classNamesFromStatus(status)}>{status}</button>

                <div className='afs-asset-review__workflow' ref={nodeChangeStatus}>
                  <button
                    className='button-small color-white-bd-bg mr-3 btn-large'
                    onClick={() => {
                      setOpenPopUp(!openPopUp);
                    }}
                  >
                    Change Status
                  </button>
                  <div className={openPopUp ? 'visible-popup' : 'hidden-popup'}>
                    {openPopUp ? (
                      <PopUp open={openPopUp}>
                        {popUpOptions &&
                          popUpOptions.map(({ selectItem, value, job_type }) => (
                            <li
                              className={classNames('pop-up__li border-l-8', {
                                'border-blue-400': job_type === 'vsm',
                                'border-green-400': job_type === 'client',
                              })}
                              key={selectItem}
                              onClick={() => {
                                onSetStatus({ status: value });
                                setOpenPopUp(!openPopUp);
                              }}
                            >
                              {selectItem}
                            </li>
                          ))}
                      </PopUp>
                    ) : null}
                  </div>

                  <Modal
                    modalHeading='Virtual Sample Workflow'
                    modalBtnActionHandler={() => setOpen(false)}
                    modalBtnActionLabel='Close'
                    backgroundColor='#fff'
                    modalOpen={open}
                    setModalOpen={setOpen}
                  >
                    <div className='afs-asset-review__workflow-img'>
                      <img src={WorkflowImage} alt='Workflow' />
                    </div>
                  </Modal>
                </div>
              </div>
              <div className='style-summary__col-2'>
                {colors && <Colorway colors={colors} />}
              </div>
            </div>
            <div className='col-lg-5'>
              <div className='style-summary__col-3'>
                <div className='style-summary__virtual-sample__heading'>
                  <h3 className='style-summary__ style-title-small uppercase'>
                    Virtual Sample
                  </h3>

                  <div className='flex button-dashboard'>
                    <div className='style-summary_cta' onClick={() => null}>
                      <label htmlFor='batchUploadPlus' className='mt-4'>
                        <img src={PlusIcon} className='mr-2 color-gray-fill' />
                      </label>
                      <input
                        id='batchUploadPlus'
                        type='file'
                        multiple='true'
                        onChange={e => {
                          const { files } = e.currentTarget;
                          setDisplayToast({
                            type: 'info',
                            persist: false,
                            message: `Processing Virtual Sample uploads...`,
                          });

                          onDropHandler(files);
                        }}
                        name='batchUpload'
                      />
                    </div>
                    <div
                      className='style-summary_cta'
                      onClick={() => {
                        setMessageModelAssoc(style);
                        onMessagesOpen(style);
                      }}
                    >
                      <img src={ConversationIcon} className='mr-2' />
                    </div>
                  </div>
                </div>
                <div className='mm--p color-gray'>Click on image to enter</div>
                <div className='style-summary__white-area'>
                  <div className='style-summary__vs-main style-summary__slideshow'>
                    {sampleSlideshowUploading?.length > 0
                      ? sampleSlideshowUploading.map(upload => (
                          <AssetProgress key={upload.key} {...upload} />
                        ))
                      : sampleSlideshow?.length > 0 && (
                          <SlickCenterMode
                            name={'samplesSlideshow'}
                            media={sampleSlideshow.filter(s => allowedFileExtensions.includes(s.type))}
                            onDropHandler={onDropHandler}
                            deleteMediaActionHandler={deleteMediaActionHandler}
                            showNav={false}
                            mediaRatio='3-4'
                            mediaObjectFit='contain'
                            renderWithLink={true}
                          />
                        )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StyleSummary;

export const AssetProgress = props => {
  const { asset, imageAsFileSeed, save, onUploadHandler, customMetadata } = props;
  const [, metadata] = asset;
  const { imageUploadProgress } = useContext(UploadContext);
  const progress = imageUploadProgress[metadata?.path];

  return (
    <Asset
      asset={asset}
      saveImages={save}
      imageAsFileSeed={imageAsFileSeed}
      onUploadHandler={onUploadHandler}
      customMetadata={customMetadata}
    >
      <span>{!!progress && ` Uploading: ${progress}%`}</span>
    </Asset>
  );
};

export function getImageAspectRatio(src) {
  const gcd = (a, b) => {
    return b == 0 ? a : gcd(b, a % b);
  };

  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const r = gcd(img.width, img.height);
      const calculatedRatio = `${img.width / r}-${img.height / r}`;
      resolve(
        [`1-1`, `2-3`, `1-2`, `3-4`, `16-9`, `9-16`, `16-7`].includes(calculatedRatio)
          ? calculatedRatio
          : null
      );
    };
    img.onerror = reject;
    img.src = src;
  });
}

import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import classNames from 'classnames';
import { Control, FieldErrors } from 'react-hook-form/dist/types';
import { Controller } from 'react-hook-form';

export interface PhoneInputProps {
  update: boolean;
  label: string;
  fullWidth?: boolean;
  required?: boolean;
  multi?: boolean;
  name: string;
  control?: Control<Record<string, any>>;
  value: string;
  errors?: FieldErrors;
}

const PhoneInputComponent: React.FC<PhoneInputProps> = ({
  control,
  required,
  label,
  name,
  value,
  update,
}) => {
  const [phone, setPhone] = useState('');

  return (
    <div className='col-md-6'>
      <div className='control-block'>
        <label
          className={classNames('control--label', { 'control--required': required })}
        >
          {label}
        </label>
        {update ? (
          <div>
            <Controller
              as={
                <PhoneInput
                  country={'us'}
                  value={phone}
                  onChange={phone => setPhone(phone)}
                />
              }
              control={control}
              name={name}
              rules={{ required: required }}
              defaultValue={value || ''}
            />
          </div>
        ) : (
          <span className='dashboard-forms__updated'>{value}</span>
        )}
      </div>
    </div>
  );
};

export default PhoneInputComponent;

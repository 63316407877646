import React, { useState } from 'react';
import { Link, navigate } from '@reach/router';
import classNames from 'classnames';
import PopUp from 'components/Common/PopUp/PopUp';
import { IPopUpOptions } from 'models/Dashboard/IPopUpOptions';
import Checkbox from 'components/Common/Checkboxes/Checkboxes';
import ManageIconGray from 'assets/icons/manage-icon-gray.svg';
import './InventoryTable.scss';

export interface ICellData {
  col: IData[];
  key: any;
  data?: any;
}

export interface IData {
  cell?: string;
  imageUrl?: string;
  button?: string;
  color?: string;
  linkTo?: string;
}

export interface IHeaderGroups {
  header: string;
}

export interface IInventoryTable {
  headerGroups: IHeaderGroups[];
  cellData: ICellData[];
  popUpOptions: IPopUpOptions[];
  selectedProductKeys: any[];
  placeholder?: string;
  handleToggle?: (product_key: any) => void;
  handleSelectAll?: () => void;
  handleDeselectAll?: () => void;
}

export interface IInventoryTableRow {
  headerGroups: IHeaderGroups[];
  rowData: ICellData;
  popUpOptions: IPopUpOptions[];
  checked: boolean;
  onToggle?: (product_key: any) => void;
  onPopUpModal: (itemKey: any, ModalComponent: React.ReactElement) => void;
}

const InventoryTable: React.FC<IInventoryTable> = props => {
  const {
    headerGroups,
    cellData,
    popUpOptions,
    selectedProductKeys,
    placeholder = 'No listings are available',
    handleToggle,
    handleSelectAll,
    handleDeselectAll,
  } = props;
  const [selectedItem, setSelectedItem] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [ActiveModalComponent, setActiveModalComponent] = useState(null);

  const handlePopUpModal = (itemKey, ModalComponent) => {
    setSelectedItem(itemKey);
    setActiveModalComponent(ModalComponent);
    setModalOpen(true);
  };

  return (
    <div className='inventory-table'>
      <table className='table'>
        <thead>
          <tr>
            <th>
              {selectedProductKeys.length === cellData.length &&
              selectedProductKeys.length > 0 ? (
                <Checkbox checked={true} onChange={handleDeselectAll} />
              ) : (
                <Checkbox checked={false} onChange={handleSelectAll} />
              )}
            </th>
            {headerGroups?.map(({ header }) => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {cellData.length === 0 ? (
            <tr>
              <td>{placeholder}</td>
            </tr>
          ) : (
            cellData?.map(rowData => (
              <InventoryTableRow
                key={`row-${rowData.key}`}
                headerGroups={headerGroups}
                rowData={rowData}
                popUpOptions={popUpOptions}
                checked={selectedProductKeys.includes(rowData.key)}
                onToggle={handleToggle}
                onPopUpModal={handlePopUpModal}
              />
            ))
          )}
        </tbody>
      </table>
      {ActiveModalComponent &&
        modalOpen &&
        React.cloneElement(ActiveModalComponent, {
          open: modalOpen,
          setOpen: setModalOpen,
          data: cellData.find(item => item.key === selectedItem)?.data,
        })}
    </div>
  );
};

const InventoryTableRow: React.FC<IInventoryTableRow> = props => {
  const { headerGroups, rowData, popUpOptions, checked, onToggle, onPopUpModal } = props;
  const [open, setOpen] = useState(false);

  return (
    <tr>
      <td>
        <Checkbox
          value={rowData.key}
          checked={checked}
          onChange={event => onToggle?.(parseInt(event.currentTarget.value))}
        />
      </td>

      {rowData.col?.map(({ linkTo, cell, imageUrl, button, color }, index) => (
        <td
          data-th={headerGroups[index].header}
          key={headerGroups[index].header}
          className={classNames({ link: !!linkTo })}
          onClick={() => linkTo && navigate(linkTo)}
        >
          {imageUrl && <img className='table-img' src={imageUrl} alt='Inventory' />}
          {button && (
            <button className={`button-badge color-${color}-bg`}>{button}</button>
          )}
          {cell}
        </td>
      ))}

      <td>
        <img
          className='manage-team__dots'
          onClick={() => setOpen(!open)}
          src={ManageIconGray}
          alt='Manage Icon'
        />

        <PopUp open={open} setOpen={setOpen}>
          {popUpOptions?.map(({ selectItem, to, ModalComponent, onClick }) => (
            <li
              className='pop-up__li'
              onClick={() => {
                setOpen(false);
                if (ModalComponent) {
                  onPopUpModal?.(rowData.key, ModalComponent);
                } else if (onClick) {
                  onClick(rowData.data);
                }
              }}
              key={selectItem}
            >
              {to ? <Link to={to}>{selectItem}</Link> : selectItem}
            </li>
          ))}
        </PopUp>
      </td>
    </tr>
  );
};

export default InventoryTable;

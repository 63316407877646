import React from 'react';
import './AssetLibraryNav.scss';
import AssetLibraryFolder, {
  IAssetLibraryFolder,
} from '../AssetLibraryFolder/AssetLibraryFolder';

export interface INavFolders {
  name?: string;
  subFolders?: IAssetLibraryFolder[];
}

export interface IAssetLibraryNav {
  navFolders?: INavFolders[];
  activeFolderPath?: string[];
  onClickFolder?: (path: Array<string>) => void;
}

const AssetLibraryNav: React.FC<IAssetLibraryNav> = props => {
  const { navFolders, activeFolderPath = [], onClickFolder } = props;

  return (
    <div className='asset-library-nav'>
      {navFolders &&
        navFolders.map(({ name, subFolders }, idx) => (
          <AssetLibraryFolder
            key={idx}
            name={name}
            subFolders={subFolders}
            activeFolderPath={activeFolderPath}
            onClickFolder={onClickFolder}
          />
        ))}
    </div>
  );
};

export default AssetLibraryNav;

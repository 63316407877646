import React, { useEffect, useState, useContext, useMemo } from 'react';

import 'components/UploadAssetLibrary/UploadAssetLibrary.scss';

import Modal from 'components/Common/Modal/Modal';
import Search from 'components/Common/Search/Search';
import Breadcrumbs, { IPages } from 'components/Common/Breadcrumbs/Breadcrumbs';
import { ASSETS_LIBRARY, parsePathFromArray } from '../util';
import { UserContext } from 'providers/UserProvider';
import { getBucketAssetFolder } from 'services/Api';
import FolderIcon from 'assets/icons/Icon-folder.svg';
import ArrowIcon from 'assets/icons/angle-right-gray.svg';

export interface IOptions {
  label: string;
}

export interface IFilterDropdowns {
  label: string;
  options: IOptions[];
}

export interface IAssetLibraryMoveToModal {
  headingText: string;
  bucket: any;
  page: string;
  options?: IOptions[];
  filterDropdowns?: IFilterDropdowns[];
  open: boolean;
  setOpen?: (open: boolean) => void;
  onSelect?: (src: string) => void;
}

const AssetLibraryMoveToModal: React.FC<IAssetLibraryMoveToModal> = props => {
  const { headingText, bucket, page, open, setOpen, onSelect } = props;

  const {
    user: { idToken },
  } = useContext(UserContext);
  const [search, setSearch] = useState('');

  const [folderPath, setFolderPath] = useState([]);
  const [activeFolders, setActiveFolders] = useState([]);

  useEffect(() => {
    const fetchFolderHandler = async () => {
      setActiveFolders([]);

      const folder_path = '/' + folderPath.map(p => p + '/').join('');
      const response = await getBucketAssetFolder(
        idToken,
        bucket.key,
        ASSETS_LIBRARY[page].apis.get_folder,
        { path: folder_path }
      );
      const { data: blobs } = response.data;

      const folders =
        folderPath.length > 0 ? [] : ASSETS_LIBRARY[page].folders.map(f => ({ name: f }));
      for (const blob of blobs) {
        const subPathName = blob.path.substring(folder_path.length);
        if (subPathName === '') {
          continue;
        }
        const subPath = subPathName.split('/');
        const [name] = subPath;
        if (subPath.length >= 2) {
          // check to add child folder if missing
          if (folders.findIndex(f => name.localeCompare(f.name) === 0) === -1) {
            folders.push({ name });
          }
        }
      }
      setActiveFolders(folders);
    };
    fetchFolderHandler();
  }, [bucket.key, page, folderPath]);

  const onSubmit = e => {
    e.preventDefault();
    onSelect?.(parsePathFromArray(folderPath));
    setOpen(false);
  };

  const pages = useMemo(() => {
    const pagesArray = [];
    let link = '/';
    for (let i = 0; i < folderPath.length; i += 1) {
      const p = folderPath[i];
      link += `/${p}`;
      const page: IPages = {
        title: p,
      };
      if (i < folderPath.length - 1) {
        page.link = link;
        page.onClick = () => setFolderPath(folderPath.slice(0, i + 1));
      }
      pagesArray.push(page);
    }
    if (folderPath.length > 0) {
      pagesArray.push({
        title: '<',
        link: '/',
        onClick: () => setFolderPath(folderPath.slice(0, folderPath.length - 1)),
      });
    }
    return pagesArray;
  }, [folderPath]);

  const filteredFolders = activeFolders?.filter(f =>
    f.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div className='upload-asset-library'>
      <Modal
        modalHeading={headingText}
        modalBtnClose='Cancel'
        modalBtnActionLabel='Move'
        modalBtnActionHandler={onSubmit}
        backgroundColor='#fff'
        modalOpen={open}
        setModalOpen={setOpen}
      >
        <div className='upload-asset-library__body'>
          <div className='upload-asset-library__search'>
            <Search changeHandler={setSearch} />
          </div>
          <Breadcrumbs pages={pages} />
          <div className='upload-asset-library__folders'>
            {filteredFolders &&
              filteredFolders.map(({ name }, idx) => (
                <div key={name} className='upload-asset-library__folder'>
                  <span
                    className='upload-asset-library__folder__heading-flex'
                    onClick={() => setFolderPath([...folderPath, name])}
                  >
                    <img
                      src={FolderIcon}
                      className='upload-asset-library__folder__folder-icon'
                      alt={name}
                    />
                    {name}
                  </span>
                  <div className='upload-asset-library__folder__arrow-icon'>
                    <img src={ArrowIcon} alt='Open' />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AssetLibraryMoveToModal;

import React, {
  createContext,
  useContext,
  useCallback,
  useEffect,
  useState,
} from 'react';
import useSWR from 'swr';
import { mmAPI, setAuthToken } from 'services/Api';
import usePersistedState from 'hooks/usePersistedState';
import { NotificationsContext } from './NotificationsProvider';
import { navigate } from '@reach/router';
import { auth } from '../firebase';
import { firebaseStorageUrl } from 'providers/UploadProvider';
import { accountMethodLogin, appMethodAcceptInvite } from 'services/Api';
import { ERRORS } from '../constants';
import LogRocket from 'logrocket';
import unionBy from 'lodash.unionby';

export const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [user, setUser] = useState({
    loading: true,
  });

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <AuthProvider>{children}</AuthProvider>
    </UserContext.Provider>
  );
};

const AuthProvider = ({ children }) => {
  const { user, setUser } = useContext(UserContext);
  const { idToken, custom_token } = user;
  const [persistedBrand, persistBrand] = usePersistedState('brand', null);
  const { setDisplayToast } = useContext(NotificationsContext);

  const loginWithIdTokenResult = useCallback(
    async idTokenResult => {
      // console.trace(
      //   'loginWithIdTokenResult',
      //   `user`,
      //   user,
      //   `user?.account`,
      //   user?.account
      // );
      /** User context already hyrdrated with MM API account, likely from custom_token effect */
      if (user.account) return;

      const { claims, token: idToken } = idTokenResult;
      // console.trace('claims', !claims?.account, claims);
      if (!claims?.account) return;

      try {
        setUser(prevUser => ({
          ...prevUser,
          idToken,
          loading: true,
        }));
        const loginResponse = await accountMethodLogin({ idToken });
        /** User firebase claims indicate need for MM API signup */
        if (!loginResponse.data?.data?.account || loginResponse.data?.data?.error) {
          // console.trace('ERRORS.AUTH_MM_API_LOGIN_FAILED');
          /**
           * Missing account or found error during MM api login
           */
          throw new Error(ERRORS.AUTH_MM_API_LOGIN_FAILED);
        } else {
          /**
           * Account established from MM api login response
           */
          const { account } = loginResponse.data?.data;
          // console.trace('accountMethodLogin LogRocket identify account', account);
          LogRocket.identify(account.key, {
            name: account.name,
            email: account.email,
          });
          if (
            account &&
            account.assets?.profile_picture &&
            !account.assets.profile_picture.url
          ) {
            try {
              const profile_picture_url = await firebaseStorageUrl(
                account.assets.profile_picture.path
              );
              account.assets.profile_picture.url = profile_picture_url;
            } catch (err) {
              LogRocket.captureException(err, {
                extra: {
                  name: account.name,
                  email: account.email,
                  accountKey: account.key,
                },
              });
            }
          }
          setUser(prevUser => {
            return {
              ...prevUser,
              account,
              idToken,
              custom_token,
            };
          });
          // console.trace('setAuthToken for requests');
          setAuthToken(idToken);
        }
      } catch (error) {
        // console.trace('error.message accountMethodLogin', error.message);
        if (error.type !== ERRORS.AUTH_MM_API_INVALIDSESSION) {
          setDisplayToast({ type: 'error', message: error.message });
        }
      } finally {
        setUser(prevUser => ({
          ...prevUser,
          loading: false,
        }));
      }
    },
    [user.account, user, custom_token]
  );

  useEffect(() => {
    const customTokenEffect = () => {
      if (!custom_token) return;
      // console.trace('refresh session signInWithCustomToken', custom_token, idToken);
      auth.signInWithCustomToken(custom_token).then(auth => {
        // console.trace('signInWithCustomToken result', auth, user);
        setUser(prevUser => ({
          ...prevUser,
          refreshToken: auth?.user?.refreshToken,
          userAuth: auth,
          custom_token: null,
        }));
      });
    };
    customTokenEffect();
  }, [user, custom_token, idToken, setUser]);

  /**
   * Triggered by firebaserAuth state changes
   */
  const authStateEffect = async firebaseAuth => {
    /**
     * effect runs if client side auth session changes
     */
    if (!firebaseAuth) {
      // console.trace('authStateEffect firebaseAuth null, set user.account null');
      setUser(prevUser => ({
        ...prevUser,
        account: null,
      }));
    } else if (firebaseAuth) {
      const { refreshToken } = firebaseAuth;
      // console.trace('authStateEffect refreshToken', refreshToken);
      if (!user.account && !user.mfa) {
        // console.trace('authStateEffect user.account null');
        const idTokenResult = await firebaseAuth.getIdTokenResult(true);
        // console.trace('authStateEffect getIdTokenResult', idTokenResult);
        await loginWithIdTokenResult(idTokenResult);
        // console.trace('authStateEffect complete loginWithIdTokenResult');
      }
    } else {
      // console.trace('authStateEffect finally', user, user.account, user.mfa);
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onIdTokenChanged(auth => {
      // console.trace('onIdTokenChanged', auth);
      authStateEffect(auth);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const userBrandEffect = userBrand => {
      if (userBrand) {
        persistBrand(userBrand);
      } else if (persistedBrand) {
        setUser(prevUser => ({
          ...prevUser,
          brand: persistedBrand,
        }));
      }
    };
    userBrandEffect(user.brand);
  }, [user.brand, persistedBrand]);

  const { data: brand } = useSWR(
    user?.brandKey ? [`/api/brand/model/${user?.brandKey}`, idToken] : null,
    (url, idToken) => {
      // console.trace('user?.brandKey', user?.brandKey);
      return mmAPI(url, idToken);
    },
    { suspense: false }
  );

  useEffect(() => {
    const userBrandKeyEffect = userBrandKey => {
      if (userBrandKey && brand) {
        setUser(prevUser => ({
          ...prevUser,
          brand,
          brandKey: null,
        }));
      }
    };
    userBrandKeyEffect(user.brandKey, brand);
  }, [user.brandKey, brand]);

  useEffect(() => {
    /** Determines if invite local state is linked to account, saves to user context if so */
    const handleInviteData = async () => {
      if (!user?.invite || !user?.account || user?.inviteFlow) return;
      /**
       * Invite for existing MM account, request MM api accept invite endpoint
       */
      const { token } = user.invite;
      setUser(prevUser => ({
        ...prevUser,
        inviteFlow: true,
      }));
      // console.trace('handleInviteData => appMethodAcceptInvite');
      try {
        const response = await appMethodAcceptInvite(token);
        console.log('invite response', response);
        if (response.data.error) {
          setUser(prevUser => ({
            ...prevUser,
            error: response.data.error,
          }));
        }
        if (response?.data) {
          /**
           * Accepted invite should return grants
           */

          // console.trace('appMethodAcceptInvite response', response.data.data);
          const { invite, permissions: accountPermissions } = response.data;
          const { data: permissions } = invite;
          const { model_id: brandKey } = permissions.find(g => g.model === 'Brand');
          const { grants } = permissions.find(g => g.KIND === 'Team');
          const { model, model_id } =
            grants?.find(g => !['Brand', 'Team'].includes(g.model)) || {};
          console.trace(
            'invited accepted compare permissions',
            user?.permissions,
            permissions,
            accountPermissions
          );
          setUser(prevUser => ({
            ...prevUser,
            invite,
            inviteFlow: false,
          }));
          if (accountPermissions) {
            setUser(prevUser => {
              console.trace(
                'invited accepted permissions on uer compare',
                prevUser?.permissions,
                accountPermissions
              );
              return {
                ...prevUser,
                permissions: !prevUser?.permissions
                  ? accountPermissions
                  : unionBy(prevUser?.permissions, accountPermissions, 'key'),
              };
            });
          }
          /**
           * Accepted invite style, brand, line, or collection key used for navigation, based on grants
           */
          console.trace(
            'Accepted invite style, brand, line, or collection key used for navigation, based on grants',
            model
          );
          let navigateTarget = '/brands';
          if (model?.toLowerCase() === 'style') {
            navigateTarget = `/brand/${brandKey}/${model.toLowerCase()}/${model_id}`;
          } else if (model) {
            navigateTarget = `/brand/${brandKey}/products?${model.toLowerCase()}s=${model_id}`;
          } else {
            navigateTarget = `/brand/${brandKey}/products`;
          }
          setDisplayToast({
            persist: true,
            type: 'info',
            message: `${user?.email} has been invited to ${brand && brand.name}`,
          });
          navigate(navigateTarget);
        } else {
          LogRocket.captureMessage('Invite flow broken', {
            tags: {
              // additional data to be grouped as "tags"
              journey: 'AuthProvider',
              step: 'handleInviteData',
            },
            extra: {
              // additional arbitrary data associated with the event
              invite: token,
            },
          });
        }
      } catch (error) {
        console.log('invite error', error);
        LogRocket.captureException(error);
        setUser(prevUser => ({
          ...prevUser,
          error,
        }));
      }
    };
    handleInviteData();
  }, [user.invite, user?.permissions, user?.account, brand, user?.email]);
  return <>{children}</>;
};

export default UserProvider;

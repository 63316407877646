import { useLocation } from '@reach/router';

export const useLocationSearchParam = queryParam => {
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  return search.get(queryParam);
};

export const useLocationIsAtelier = () => {
  const location = useLocation();
  return location.pathname.indexOf('atelier') > 0;
};

export const useLocationIsProducts = () => {
  const location = useLocation();
  return location.pathname.indexOf('products') > 0;
};

export const useLocationPageName = () => {
  const location = useLocation();
  if (location.pathname.indexOf('atelier') > 0) {
    return 'atelier';
  }
  if (location.pathname.indexOf('products') > 0) {
    return 'products';
  }
  return 'default';
};

export const useLocationIsSettings = () => {
  const location = useLocation();
  return location.pathname.indexOf('settings') > 0;
};

export const useIsBrandCustomSettings = () => {
  const location = useLocation();
  return location.pathname.match('customization');
};

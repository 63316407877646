import { UploadContext } from 'providers/UploadProvider';
import ManageIcon from 'assets/icons/manage-icon.svg';
import React, { useState, useEffect, useCallback, useContext } from 'react';
import { ASSET_TYPES } from 'constants/assets';
import { deleteModel } from 'services/Api';
import LogRocket from 'logrocket';
import './Materials.scss';
import { UserContext, NotificationsContext } from 'providers/contexts';
import PopUp from '../../Common/PopUp/PopUp';

export type MaterialSummaryMetadata = {
  label: string;
  text: string;
};

export type MaterialModelSummary = {
  key: string;
  name: string;
  assets?: any;
  frontImg: string;
  frontThumbImg: string;
  backImg: string;
  backThumbImg: string;
  metadata: MaterialSummaryMetadata[];
};

type AtelierUploadConfig = {
  endpoint: string;
  heading: string;
  model: 'Techpack' | 'Graphics' | 'Materials' | 'References' | 'Final';
};

export type IMaterials = {
  materials: MaterialModelSummary[];
  onClickMaterialEditLink?: (material) => void;
  type: string;
  mutateMaterials: () => void;
  style_model_id: string;
  brand_model_id: string;
  atelierUploadConfig: AtelierUploadConfig;
};

const AssetProgress = props => {
  const {
    image = null,
    src,
    asset,
    onClickMaterialEditLink,
    material,
    materialName,
    file,
    mutateMaterials,
  } = props;
  const { imageUploadProgress, imageUrl } = React.useContext(UploadContext);
  const progress = imageUploadProgress?.[asset?.path];

  const backgroundImage = image || src || imageUrl?.[asset?.path];

  const [iconFile, setIcon] = useState('');
  const [loadIcons, setLoadIcons] = useState(true);

  useEffect(() => {
    setIcon('');
    setLoadIcons(true);
    const extension = material?.assets[materialName]?.ext;
    if (extension) {
      if (loadIcons && !ASSET_TYPES.image.extensions.includes(extension)) {
        loadIcon(extension);
      }
    }
    return () => setLoadIcons(false);
  }, [loadIcons, material, materialName]);

  const loadIcon = async (iconName: string) => {
    try {
      const importedIcon = await import(
        `assets/icons/${iconName.toLocaleUpperCase()}.svg`
      );
      setIcon(importedIcon.default);
    } catch (error) {
      const importedIcon = await import(`assets/icons/Icon-picture.svg`);
      setIcon(importedIcon.default);
    }
  };

  return (
    <>
      <div
        className={
          iconFile || image
            ? 'materials__card'
            : 'materials__card add-new-material__no-img'
        }
      >
        {iconFile ? (
          <div className='as-file-preview__thumbnail'>
            <img src={iconFile} alt='material-img' />
          </div>
        ) : (
          <div
            className='as-file-preview__thumbnail'
            style={{ backgroundImage: 'url(' + image + ')' }}
          />
        )}
        {!image && !iconFile && (
          <button
            className='button-dashboard color-white-bd-bg'
            onClick={() => {
              onClickMaterialEditLink?.();
            }}
          >
            Upload{!!progress && `ing ${progress}%`}
          </button>
        )}
      </div>
    </>
  );
};

const Materials: React.FC<IMaterials> = ({
  materials,
  type,
  onClickMaterialEditLink,
  mutateMaterials,
  style_model_id,
  brand_model_id,
  atelierUploadConfig,
}) => {
  const [open, setOpen] = React.useState(false);
  const [selectedItem, setSelectedItem] = useState('');
  const { user } = useContext(UserContext);
  const { setDisplayToast } = useContext(NotificationsContext);
  const { idToken } = user;

  const MaterialDeleteLink = useCallback(
    async m => {
      setOpen(false);
      try {
        const response = await deleteModel(idToken, 'material', m.key, {
          style: style_model_id,
          brand: brand_model_id,
          model: atelierUploadConfig.model,
          location: window.location.href,
        });

        if (response?.data) {
          setDisplayToast({
            type: 'success',
            message: `${m.name} Material Deleted Succesfully`,
          });
          mutateMaterials();
        } else {
          setDisplayToast({
            type: 'error',
            message: `Error while deleting ${m.name} Material`,
          });
        }
      } catch (error) {
        setDisplayToast({
          type: 'error',
          message: error.message,
        });
      }
    },
    [idToken]
  );

  const onArchiveHandler = React.useCallback(
    async item => {
      try {
        const response = await deleteModel(idToken, 'material', item.key, {
          archive: true,
          style: style_model_id,
          brand: brand_model_id,
          model: atelierUploadConfig.model,
          location: window.location.href,
        });
        setDisplayToast({
          type: 'success',
          persist: false,
          message:
            response?.data?.data?.message || `Material has been archived successfully.`,
        });
        mutateMaterials();
      } catch (error) {
        LogRocket.captureException(error);
        setDisplayToast({
          type: 'error',
          persist: false,
          message: `Error occured while archiving sample.`,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const popUpOptions = [
    {
      selectItem: 'Edit',
      onClick: m => onClickMaterialEditLink(m),
    },
    {
      selectItem: 'Download',
      onClick: () => console.log('Download clicked'),
      download: true,
      disabled: false,
    },
    {
      selectItem: 'Delete',
      onClick: m => MaterialDeleteLink(m),
    },
    {
      selectItem: 'Archive',
      onClick: m => onArchiveHandler(m),
    },
  ];

  return (
    <div className='materials'>
      {materials.length === 0 && (
        <div className='product-upload'>
          <div className='product-upload__empty-area'>
            <div className='product-upload__txt'>
              <p className='product-upload__instructions'>{`There is no ${type}`}</p>
              <p className='txt-gray-light'>
                {`Add ${type} from the upload ${type} section`}
              </p>
            </div>
          </div>
        </div>
      )}
      {materials &&
        materials.map((m, idx) => {
          const { key, name, assets, frontImg, frontThumbImg, backImg, backThumbImg } = m;
          return (
            <div className='materials__item' key={idx}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  position: 'relative',
                }}
              >
                <div className='materials__note'>{name}</div>
                <div>
                  <img
                    className='manage-team__dots'
                    onClick={() => {
                      setOpen(!open);
                      setSelectedItem(key);
                    }}
                    src={ManageIcon}
                    alt='Manage Icon'
                  />
                  <PopUp open={open && selectedItem === key}>
                    {popUpOptions.map(({ selectItem, disabled, download, onClick }) =>
                      download ? (
                        <li
                          style={
                            disabled
                              ? {
                                  pointerEvents: 'none',
                                  opacity: '0.5',
                                }
                              : {}
                          }
                          className='pop-up__li'
                          key={selectItem}
                        >
                          <a
                            className={`cursor-pointer`}
                            href={`${
                              assets?.front?.urls?.download ||
                              assets?.back?.urls?.download
                            }`}
                            download={`${
                              assets?.front?.urls?.download ||
                              assets?.back?.urls?.download
                            }`}
                            rel='noreferrer'
                            target='_blank'
                          >
                            {selectItem}
                          </a>
                        </li>
                      ) : (
                        <li
                          className='pop-up__li'
                          onClick={() => {
                            setOpen(false);
                            if (onClick) {
                              onClick(m);
                            }
                          }}
                          key={selectItem}
                        >
                          {selectItem}
                        </li>
                      )
                    )}
                  </PopUp>
                </div>
              </div>
              <div className='materials__item-wrap'>
                <div className='materials__front-back'>
                  <div className='materials__item-material'>
                    <span>Front</span>
                    <AssetProgress
                      asset={assets?.front}
                      image={frontImg || frontThumbImg}
                      onClickMaterialEditLink={() => onClickMaterialEditLink(m)}
                      material={m}
                      materialName={'front'}
                      mutateMaterials={mutateMaterials}
                    />
                  </div>
                  <div className='materials__item-material'>
                    <span>Back</span>
                    <AssetProgress
                      asset={assets?.back}
                      image={backImg || backThumbImg}
                      onClickMaterialEditLink={() => onClickMaterialEditLink(m)}
                      material={m}
                      materialName={'back'}
                      mutateMaterials={mutateMaterials}
                    />
                  </div>
                </div>
                <div className='materials__info'>
                  {materials[idx].metadata &&
                    materials[idx].metadata.map(({ label, text }, idx) => (
                      <div className='materials__info-line' key={idx}>
                        {label} <span>{text}</span>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default Materials;

import React from 'react';
import { UserContext } from 'providers/UserProvider';
import { ERRORS } from 'constants/errors';
import { ModalsContext } from 'providers/ModalsProvider';
import { navigate } from '@reach/router';
import ErrorOverlay from './ErrorOverlay';

interface IProps {
  error: Error;
  componentStack: string;
  resetComponentState: any;
  resetErrorBoundary: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const ErrorFallback: React.FC<IProps> = props => {
  const { error, resetComponentState, resetErrorBoundary } = props;
  const { setUser } = React.useContext(UserContext);
  const { setOpenDialog, setDialogProps } = React.useContext(ModalsContext);

  React.useEffect(() => {
    const errorEffect = errorVal => {
      if (!errorVal) return;
      console.log('errorVal.response', errorVal.response);
      console.log('errorVal.type', errorVal.type);
      if (
        [ERRORS.PERMISSION_ERROR].includes(errorVal.response?.data?.error?.type) ||
        [ERRORS.PERMISSION_ERROR].includes(errorVal.type)
      ) {
        console.log(
          'setDialogProps',
          errorVal.response?.data?.error?.message,
          errorVal.message
        );
        setDialogProps({
          dialogBody: `${ERRORS.PERMISSION_ERROR}: ${
            errorVal.response?.data?.error?.message || errorVal.message
          }`,
          dialogBtnAction: 'Go Back',
          btnActionHandler: e => {
            window.history.back();
          },
        });
      }
    };
    errorEffect(error);
  }, [error, resetErrorBoundary, resetComponentState]);
  return (
    <>
      <ErrorOverlay error={error} {...props} />
    </>
  );
};

export default ErrorFallback;

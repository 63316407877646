import * as React from 'react';

const useClickAway = (node, setOpen, triggerNode = null) => {
  React.useEffect(() => {
    const handleClose = event => {
      if (
        setOpen &&
        !(node.current && node.current.contains(event.target)) &&
        !(
          triggerNode &&
          triggerNode.current &&
          triggerNode.current.contains(event.target)
        )
      ) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClose);

    return () => {
      document.removeEventListener('mousedown', handleClose);
    };
  }, [node, triggerNode]);
};

export default useClickAway;

/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React from 'react';
import Grid, { IChildrenProps } from '@rowsncolumns/grid';
import { Rect, Text } from 'react-konva';

export type ProductCSVGrid = {
  dataColumns: string[];
  dataSrc: any[];
};

export const DataGrid: React.FC<ProductCSVGrid> = props => {
  const { dataColumns, dataSrc } = props;
  const width = 900;
  const height = 600;
  const gridRef = React.useRef();
  const frozenColumns = 1;
  const Cell = ({
    rowIndex,
    columnIndex,
    x,
    y,
    width,
    height,
    header,
  }: IChildrenProps) => {
    const field = dataColumns[columnIndex - 1];
    const value = dataSrc[rowIndex]?.[columnIndex - 1];
    const text = header
      ? columnIndex < frozenColumns
        ? 'No'
        : `${field}`
      : columnIndex < frozenColumns
      ? `${rowIndex + 1}`
      : `${value || ''}`;
    const fill = header ? '#eee' : 'white';
    return (
      <>
        <Rect
          x={x}
          y={y}
          height={height}
          width={width}
          fill={fill}
          stroke='grey'
          strokeWidth={0.5}
        />
        <Text
          x={x}
          y={y}
          height={height}
          width={width}
          text={text}
          fontStyle={header ? 'bold' : 'normal'}
          verticalAlign='middle'
          align='center'
        />
      </>
    );
  };
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Grid
        columnCount={dataColumns.length + 1}
        height={40}
        rowCount={1}
        frozenColumns={frozenColumns}
        ref={gridRef}
        width={width}
        columnWidth={index => {
          if (index === 0) return 80;
          if (index % 3 === 0) return 200;
          return 100;
        }}
        rowHeight={index => {
          if (index % 2 === 0) return 40;
          return 20;
        }}
        showScrollbar={false}
        itemRenderer={props => <Cell {...props} header />}
      />
      <Grid
        columnCount={dataColumns.length + 1}
        rowCount={dataSrc.length}
        frozenColumns={frozenColumns}
        height={height}
        width={width}
        columnWidth={index => {
          if (index === 0) return 80;
          if (index % 3 === 0) return 200;
          return 100;
        }}
        rowHeight={index => {
          if (index % 2 === 0) return 40;
          return 20;
        }}
        onScroll={({ scrollLeft }) => {
          gridRef.current.scrollTo({ scrollLeft });
        }}
        itemRenderer={Cell}
      />
    </div>
  );
};

import React, { useState, useEffect, useRef } from 'react';
import './ProductSlider.scss';
import PlusIcon from 'assets/icons/Icon-plus-gray.svg';
import DragAndDrop from 'components/Upload/DragAndDrop';
import { postMediaMethodAttach, postMediaMethodDetach } from 'services/Api';
import { UserContext } from 'providers/UserProvider';
import { Link, useParams } from '@reach/router';
import { NotificationsContext } from 'providers/NotificationsProvider';
import classNames from 'classnames';
import Previous from 'assets/icons/navigate_before_black.svg';
import Next from 'assets/icons/navigate_next_black.svg';
import AngleLeft from 'assets/icons/Icon-angle-left-darker.svg';
import AngleRight from 'assets/icons/Icon-angle-right.svg';
import { getListingColor } from 'utils/getListingColor';
export interface ProductSlide {
  key?: number;
  mediaKey?: number;
  imageUrl: string;
  name?: string;
  created?: number;
  modified?: number;
  position?: number;
}

export type ProductSlider = {
  slides: ProductSlide[];
  productName: string;
  listing?: any;
  mutateListingData?: any;
  mutateBrandMedia?: any;
  listingsPosition?: Record<string, { keys: any[]; positions: number[] }>;
  setListingsPosition?: any;
  editOrderToggle?: any;
  listingData?: any;
  setListingData?: any;
};

const ProductSlider: React.FC<ProductSlider> = props => {
  const {
    listing,
    slides,
    productName,
    mutateListingData,
    mutateBrandMedia,
    listingsPosition,
    setListingsPosition,
    editOrderToggle,
    listingData,
    setListingData,
  } = props;

  const { user } = React.useContext(UserContext);
  const { idToken } = user;

  const [hoverOver, setHoverOver] = useState({});
  const [imageSlides, setImageSlides] = useState(slides);

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [scrollIds, setScrollIds] = useState(null);

  const { brand_model_id } = useParams();

  const { setDisplayToast } = React.useContext(NotificationsContext);

  useEffect(() => {
    setImageSlides(slides);
  }, [slides]);

  const onDropDetachedHandler = async e => {
    const mediaDragged = e.dataTransfer;
    const mediaDraggedKey = mediaDragged.getData('mediakey');
    const mediaDraggedName = mediaDragged.getData('medianame');
    const previousListingKey = mediaDragged.getData('listingkey');

    if (parseInt(previousListingKey) === parseInt(listing.key)) {
      setDropTargetActive(false);
      return;
    }
    let itemMoving;
    // disassociate/detach image with old style before adding it to new style after drop
    if (previousListingKey) {
      try {
        await postMediaMethodDetach(idToken, {
          brand: brand_model_id,
          media: mediaDraggedKey,
        });
        mutateListingData?.();
        setDisplayToast({
          type: 'info',
          message: `Image has been disassociated`,
        });
        const newUpdatedListing = listingData.map(item => {
          if (item.key === parseInt(previousListingKey)) {
            itemMoving = item.media[mediaDraggedName];
            delete item.media[mediaDraggedName];
          }
          return item;
        });
        setListingData(newUpdatedListing);
      } catch (error) {
        setDisplayToast({
          type: 'error',
          message: `Error disassociating image`,
        });
      }
    }

    // add image to new style
    try {
      let mediaCount;

      listingData.forEach(item => {
        if (item.key === listing.key) {
          mediaCount = Object.keys(item.media).length;
        }
      });

      await postMediaMethodAttach(idToken, {
        brand: brand_model_id,
        media: mediaDraggedKey,
        style_number: listing.style_number,
        shot: mediaDraggedName,
        listingUniqueId: listing.listing_unique_id,
        position: mediaCount + 1,
      });
      setDisplayToast({
        type: 'info',
        message: `Image is now associated with ${listing.style_name}`,
      });
      const newUpdatedListing = listingData.map(item => {
        if (item.key === listing.key) {
          if (itemMoving) {
            item.media[mediaDraggedName] = itemMoving;
          }
        }
        return item;
      });
      setListingData(newUpdatedListing);
      if (scrollIds && scrollIds.length > 2) {
        const elem = document.getElementById(`${scrollIds[scrollIds.length - 1]}`);
        elem.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
      }
    } catch (error) {
      setDisplayToast({
        type: 'error',
        message: `Error associating image with ${listing.style_name}`,
      });
    }
    setDropTargetActive(false);
    mutateListingData?.();
    mutateBrandMedia?.();
  };

  const onDragStart = e => {
    const imageUrl = e.target.src;
    e.dataTransfer.setData('text/uri-list', imageUrl);
    e.dataTransfer.setData('text/plain', imageUrl);
    e.dataTransfer.setData('text/html', e.target.outerHTML);
    e.dataTransfer.setData('mediakey', e.target.dataset.mediakey);
    e.dataTransfer.setData('medianame', e.target.dataset.medianame);
    e.dataTransfer.setData('listingkey', e.target.dataset.listingkey);
    e.dataTransfer.setData([e.target.dataset.listingkey], e.target.dataset.listingkey);
    e.dataTransfer.setData('listingname', e.target.dataset.listingname);
  };

  const [dropTargetActive, setDropTargetActive] = React.useState(false);
  const onDragEnterHandler = e => {
    const mediaDragged = e.dataTransfer;
    const enterDoNotActivate = mediaDragged.types.includes(listing.key.toString());
    if (enterDoNotActivate) {
      setDropTargetActive(false);
    } else {
      setDropTargetActive(true);
    }
  };
  const onDragLeaveHandler = e => {
    setDropTargetActive(false);
  };

  // saving the id for each image so it can be used to scroll to images consecutively
  useEffect(() => {
    const scrollIds = imageSlides.map((item, index) => {
      const slideId = item.key + listing.key + index;
      return slideId;
    });
    setScrollIds(scrollIds);
  }, [imageSlides]);

  // using the id of the img element scroll to it
  const scrollToElement = (id, index) => {
    const elem = document.getElementById(`${id}`);
    setCurrentSlideIndex(index);
    elem.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    });
  };

  return (
    <DragAndDrop
      onDropHandler={e => {
        onDropDetachedHandler(e);
      }}
      onDragEnterHandler={e => {
        onDragEnterHandler(e);
      }}
      onDragLeaveHandler={e => {
        onDragLeaveHandler(e);
      }}
    >
      <div
        className={classNames('product-slider', {
          'color-active-bg': dropTargetActive,
        })}
        data-listingkey={listing.key}
      >
        <div className={classNames('product-slider__title', listing.addition && 'product-slider__title-maxwidth')}>
          <Link to={`/brand/${brand_model_id}/products/listing/${listing.key}`}>
            {productName}
          </Link>
          {listing.addition && <span className='product-slider__addition'>Addition</span>}
        </div>
        <div
          className='product-slider__slider'
          style={{ display: 'flex', flexDirection: 'row' }}
        >
          {scrollIds && scrollIds.length > 2 && (
            <input
              className='product-slider__arrows product-slider__arrow-prev'
              type='image'
              onClick={() => {
                for (const [key, value] of Object.entries(scrollIds)) {
                  if (key === currentSlideIndex.toString()) {
                    const newCurrentIndex = parseInt(key) - 1;
                    if (newCurrentIndex >= 0) {
                      const previousId = scrollIds[newCurrentIndex];
                      scrollToElement(previousId, newCurrentIndex);
                    }
                  }
                }
              }}
              style={{
                opacity: (currentSlideIndex === 0 || currentSlideIndex === 1) && 0.5,
              }}
              src={AngleLeft}
            />
          )}
          <div
            style={{
              maxWidth: '24rem',
              display: 'flex',
              flexDirection: 'row',
              overflow: 'hidden',
              scrollBehavior: 'smooth',
              // overflow: 'scroll', // to display scroll bar
            }}
            className='product-slider__slider-wrap'
          >
            {imageSlides.map((slide, index, array) => {
              const slideId = slide.key + listing.key + index;
              return (
                <div
                  className='product-slider__slide-tile'
                  key={slideId}
                  style={{
                    maxHeight: '150px',
                    minWidth: 110,
                    margin: 0,
                    position: 'relative',
                  }}
                  onMouseOver={() => {
                    const imageKey = slide.key;
                    const newObj = { ...hoverOver, [imageKey]: true };
                    setHoverOver(newObj);
                  }}
                  onMouseLeave={() => {
                    const imageKey = slide.key;
                    const newObj = { ...hoverOver, [imageKey]: false };
                    setHoverOver(newObj);
                  }}
                >
                  {editOrderToggle && (
                    <div
                      style={{
                        position: 'absolute',
                        background: 'rgba(255,255,255,.5)',
                        height: '100%',
                        width: '100%',
                        zIndex: 10,
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <div className={'product-slider__img-position'}>{index + 1}</div>

                      {hoverOver[slide.key] && (
                        <>
                          <img
                            style={{
                              float: 'left',
                              cursor: 'pointer',
                              display: !index && 'none',
                              // maxWidth: '120px',
                            }}
                            src={Previous}
                            onClick={() => {
                              const previousIndex = index - 1;
                              scrollToElement(slideId, previousIndex);
                              const slidesArr = array.slice();
                              const previousSlide = slidesArr[previousIndex];
                              slidesArr[previousIndex] = slide;
                              slidesArr[index] = previousSlide;
                              setImageSlides(slidesArr);
                              setListingsPosition &&
                                setListingsPosition({
                                  ...listingsPosition,
                                  [listing.key]: {
                                    keys: [...slidesArr],
                                    positions: slidesArr.map((s, i) => i + 1),
                                  },
                                });
                              setHoverOver({});
                            }}
                          />
                          <img
                            style={{
                              float: 'right',
                              cursor: 'pointer',
                              display: index + 1 === array.length && 'none',
                            }}
                            src={Next}
                            onClick={() => {
                              const nextIndex = index + 1;
                              scrollToElement(slideId, nextIndex);
                              const slidesArr = array.slice();
                              const nextSlide = slidesArr[nextIndex];
                              slidesArr[nextIndex] = slide;
                              slidesArr[index] = nextSlide;
                              setImageSlides(slidesArr);
                              setListingsPosition &&
                                setListingsPosition({
                                  ...listingsPosition,
                                  [listing.key]: {
                                    keys: [...slidesArr],
                                    positions: slidesArr.map((s, i) => i + 1),
                                  },
                                });
                              setHoverOver({});
                            }}
                          />
                        </>
                      )}
                    </div>
                  )}
                  <img
                    id={`${slideId}`}
                    style={{ zIndex: 1, cursor: 'grab', position: 'absolute' }}
                    // className={'product-slider__img ratio-img'}
                    src={slide.imageUrl}
                    alt='Slide Product'
                    data-mediakey={slide.key}
                    data-medianame={slide.name}
                    data-listingkey={listing.key}
                    data-listingname={listing.style_name}
                    draggable='true'
                    onDragStart={onDragStart}
                    className='product-slider__slide-img'
                  />
                </div>
              );
            })}
            <div
              className='product-slider__slide-plus'
              style={{
                marginRight: 20,
              }}
            >
              <img src={PlusIcon} alt='Plus - Add' style={{ minWidth: '16px' }} />
              <p className='product-slider__slide-plus-txt'>Drop product media here</p>
            </div>
          </div>

          {/* next slide button */}
          {scrollIds && scrollIds.length > 2 && (
            <input
              className='product-slider__arrows product-slider__arrow-next'
              type='image'
              onClick={() => {
                for (const [key, value] of Object.entries(scrollIds)) {
                  if (key === currentSlideIndex.toString()) {
                    const index = parseInt(key);
                    const numberOfSlidesToMove = index === 0 || index === 1 ? 2 : 1;
                    const newCurrentIndex = index + numberOfSlidesToMove;
                    if (newCurrentIndex <= scrollIds.length - 1) {
                      const nextId = scrollIds[newCurrentIndex];
                      scrollToElement(nextId, newCurrentIndex);
                    }
                  }
                }
              }}
              style={{ opacity: currentSlideIndex === imageSlides.length - 1 && 0.5 }}
              src={AngleRight}
            />
          )}
        </div>

        <div className='row product-slider__bottom'>
          <div className='col-md-6'>
            <div className='product-slider__detail'>
              <span>Style No /</span> {listing.style_number}
            </div>
          </div>
          <div className='col-md-6'>
            <div className='product-slider__detail'>
              <span>Color /</span> {getListingColor(listing)}
            </div>
          </div>
          <div className='col-md-6'>
            <div className='product-slider__detail'>
              <span>Garment /</span> {listing.category_names.join(',')}
            </div>
          </div>
          <div className='col-md-6'>
            <div className='product-slider__detail'>
              <span>SKU /</span> {listing.sku}
            </div>
          </div>
        </div>
      </div>
    </DragAndDrop>
  );
};

export default ProductSlider;

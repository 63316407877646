import React, { useEffect, useState, useContext, useRef } from 'react';
import { useForm } from 'react-hook-form';
import useSWR from 'swr';
import classNames from 'classnames';

import './MaterialForm.scss';
import TextBox from 'components/Common/TextBox/TextBox';
import DragAndDrop from 'components/Upload/DragAndDrop';
import PopUp from 'components/Common/PopUp/PopUp';
import { MaterialModel } from 'components/VirtualAtelier/Uploads/MaterialModel';
import { mmAPI, postModel, putModel } from 'services/Api';
import { UserContext } from 'providers/UserProvider';
import { UploadContext } from 'providers/UploadProvider';
import { NotificationsContext } from 'providers/NotificationsProvider';
import { ModalsContext } from 'providers/ModalsProvider';
import useMaterialAssets from 'hooks/useMaterialAssets';
import UploadAssetLibrary from './UploadAssetLibrary';
import { blobToFile } from 'utils/file';
import { ASSET_TYPES } from 'constants/assets';

export type SampleMaterialCard = {
  title: string;
  name: string;
  image?: string;
  file?: File;
  fields: IField[];
  asset?: any;
};

export interface IField {
  label: string;
  name: string;
  value?: string;
}

type AtelierUploadConfig = {
  endpoint: string;
  heading: string;
  model: 'Techpack' | 'Graphics' | 'Materials' | 'References' | 'Final';
};

export type MaterialForm = {
  materials?: MaterialModel[];
  materialsLibrary?: MaterialModel[];
  materialType?: string;
  materialSummaries?: any[];
  material?: any;
  brand_model_id?: string;
  style_model_id?: string;
  atelierUploadConfig: AtelierUploadConfig;
  onCancel?: (e: Event) => void;
  onSave?: (changeView?, updateStyleStatus?) => void;
  onUploadHandler?: () => void;
};

export interface Attachments {
  side?: string;
  extension?: string;
}

const MaterialForm: React.FC<MaterialForm> = props => {
  const {
    onUploadHandler,
    materialType,
    material = null,
    brand_model_id,
    style_model_id,
    atelierUploadConfig,
    onCancel,
    onSave,
    materials,
    materialsLibrary,
  } = props;
  const { user } = useContext(UserContext);
  const { idToken } = user;
  const { setDisplayToast } = useContext(NotificationsContext);
  const { handleFireBaseUpload } = useContext(UploadContext);

  const frontColor = material?.colors.find(c => c.name === 'front');
  const backColor = material?.colors.find(c => c.name === 'back');

  const [sampleMaterialCards, setSampleMaterialCards] = useState<SampleMaterialCard[]>([
    {
      title: 'front',
      name: 'front',
      image: material?.frontImg || material?.frontThumbImg,
      fields: [
        { name: 'front_hex', label: 'Hex code', value: frontColor?.hexcode },
        { name: 'front_pantone', label: 'Pantone', value: frontColor?.pantone },
      ],
    },
    {
      title: 'back',
      name: 'back',
      image: material?.backImg || material?.backThumbImg,
      fields: [
        { name: 'back_hex', label: 'Hex code', value: backColor?.hexcode },
        { name: 'back_pantone', label: 'Pantone', value: backColor?.pantone },
      ],
    },
  ]);

  const [fields, setFields] = useState<IField[]>([
    {
      label: 'Name',
      name: 'name',
      value: material?.name,
    },
    {
      label: 'Use',
      name: 'use',
      value: material?.use,
    },
    {
      label: 'Colorway',
      name: 'colorway',
      value: material?.colorway,
    },
    {
      label: 'Content',
      name: 'notes',
      value: material?.notes,
    },
  ]);

  const newMaterialAttachments = [
    { side: 'front', extension: null },
    { side: 'back', extension: null },
  ];

  const existingMaterialAttachments = material && material.attachments;
  const [attachments, setAttachments] = useState<Attachments[]>(
    material ? existingMaterialAttachments : newMaterialAttachments
  );
  const [frontFile, setFrontFile] = useState(null);
  const [backFile, setBackFile] = useState(null);

  const { data: bucket, error } = useSWR(
    ['/api/bucket/query/brand', idToken, brand_model_id],
    (url, idToken, brand) => {
      return mmAPI(url, idToken, { brand });
    },
    {
      suspense: false,
    }
  );

  const onDropHandler = (name, files) => {
    const [droppedFile] = files;
    const imgSrc = URL.createObjectURL(droppedFile);
    setSampleMaterialCards(prev =>
      prev.map(p => ({
        ...p,
        image: p.name === name ? imgSrc : p.image,
        file: p.name === name ? droppedFile : p.file,
      }))
    );
    name === 'front' ? setFrontFile(droppedFile) : setBackFile(droppedFile);
    updateAttachments(name, droppedFile);
  };

  const updateAttachments = (assetSide, file) => {
    const currentAttachments = attachments.map(attachment => {
      if (attachment.side === assetSide) {
        return { ...attachment, extension: file.name.split('.').pop() };
      }
      return { side: attachment.side, extension: attachment.extension };
    });
    setAttachments(currentAttachments);
  };

  const updateAttachmentsLibrarySelection = (assetSide, ext) => {
    const currentAttachments = attachments.map(attachment => {
      if (attachment.side === assetSide) {
        return { ...attachment, extension: ext };
      }
      return { side: attachment.side, extension: attachment.extension };
    });
    setAttachments(currentAttachments);
  };

  const onLibrarySelection = (name, asset) => {
    const {
      name: fileName,
      src,
      content_type: contentType,
      metadata: { ext = null },
    } = asset;

    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = event => {
      const blob = xhr.response;
      const file = blobToFile(blob, fileName);
      name === 'front' ? setFrontFile(file) : setBackFile(file);
      updateAttachmentsLibrarySelection(name, ext || contentType.split('/').pop());
      setSampleMaterialCards(prev =>
        prev.map(p => ({
          ...p,
          image: p.name === name ? src : p.image,
          file: p.name === name ? file : p.file,
        }))
      );
    };
    xhr.open('GET', src);
    xhr.send();
  };

  const onMaterialComplete = async values => {
    const location = material
      ? window.location.href.split('/edit').splice(0, 1)
      : window.location.href.split('/add').splice(0, 1);
    location.push('/summary');
    const formValues = {
      ...values,
      brand: brand_model_id,
      styles: style_model_id,
      type: materialType,
      model: atelierUploadConfig.model,
      location: location.join(''),
    };
    try {
      formValues.attachments = JSON.stringify(attachments);
      const materialResponse = material
        ? await putModel(idToken, 'material', material.key, formValues)
        : await postModel(idToken, 'material', formValues);
      const materialData = materialResponse?.data?.data;

      if (materialData) {
        const uploadsTotal = sampleMaterialCards.reduce((p, c) => (c.file ? ++p : p), 0);

        const batchAssetKeys = sampleMaterialCards.reduce((agg, c) => {
          if (c.file) {
            agg.push(materialData?.assets[c.name]?.key);
          }
          return agg;
        }, []);
        console.log('batchAssetKeys', batchAssetKeys);
        sampleMaterialCards.forEach(c => {
          if (c.file) {
            const proportion = 1 / uploadsTotal;
            handleFireBaseUpload(
              materialData?.assets[c.name]?.path,
              c.file,
              {
                ...materialData?.assets[c.name],
                ...{
                  model: 'Material',
                  model_id: materialData?.key,
                  proportion,
                  batchAssetKeys,
                },
              },
              c.name,
              () => onSave?.(false)
            );
          }
          onUploadHandler?.();
        });
        onSave?.(true, true);
      } else {
        const materialError = materialResponse?.data?.error;
        errorHandler(materialError);
      }
    } catch (e) {
      errorHandler(e);
    }
  };
  const errorHandler = error => {
    console.error(error);
    setDisplayToast({
      persist: false,
      type: 'error',
      message:
        'Error with Material API. Please try again or contact support to report details about the error.',
    });
  };
  const onMaterialCancel = e => {
    onCancel?.(e);
  };

  const { handleSubmit, register, errors, control } = useForm();

  const onSubmit = values => {
    values.colors = [];
    if (values.front_hex || values.front_pantone) {
      values.colors.push({
        name: 'front',
        notes: 'front',
        code: values.front_hex,
        hexcode: values.front_hex,
        pantone: values.front_pantone,
      });
    }
    if (values.back_hex || values.back_pantone) {
      values.colors.push({
        name: 'back',
        notes: 'back',
        code: values.back_hex,
        hexcode: values.back_hex,
        pantone: values.back_pantone,
      });
    }
    values.colors = JSON.stringify(values.colors);
    onMaterialComplete(values);
  };

  return (
    <div className='add-new-material'>
      <div className='add-new-material__body'>
        <div className='add-new-material__inner'>
          <form id={`material-new-${material?.key}`} onSubmit={handleSubmit(onSubmit)}>
            <div className='add-new-material__row'>
              <div className='add-new-material__cards'>
                {sampleMaterialCards &&
                  sampleMaterialCards.map(({ title, image, name, asset }, idx) => (
                    <div className='add-new-material__card' key={name}>
                      <div className='add-new-material__card-wrap'>
                        <div className='upload-material__card-header'>{title}</div>
                        <DragAndDrop onDropHandler={files => onDropHandler(name, files)}>
                          <AssetProgress
                            name={name + 'Img'}
                            image={image}
                            asset={asset}
                            bucket={bucket}
                            onChange={e => onDropHandler(name, e.target.files)}
                            onLibrarySelection={assets =>
                              onLibrarySelection(name, assets[0])
                            }
                            materials={materials}
                            materialsLibrary={materialsLibrary}
                            material={material}
                            materialName={name}
                            frontFile={frontFile}
                            backFile={backFile}
                          />
                        </DragAndDrop>
                      </div>
                      {sampleMaterialCards[idx].fields &&
                        sampleMaterialCards[idx].fields.map(({ label, name, value }) => (
                          <TextBox
                            name={name}
                            label={label}
                            value={value}
                            key={name}
                            register={register}
                          />
                        ))}
                    </div>
                  ))}
              </div>
              <div className='add-new-material__fields'>
                {fields &&
                  fields.map(({ label, name, value }, idx) => (
                    <TextBox
                      name={name}
                      label={label}
                      key={name}
                      value={value}
                      register={register}
                    />
                  ))}
              </div>
            </div>

            <div className='add-new-material__actions'>
              <button
                className='button-transparent-black mr-2'
                type='reset'
                onClick={onMaterialCancel}
              >
                Cancel
              </button>
              <button className='button-primary' type='submit'>
                Complete
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export type MaterialFormAssetProgress = {
  materials?: MaterialModel[];
  materialsLibrary?: MaterialModel[];
};

export type AssetProgressProps = {
  name?: string;
  image: any;
  asset: any;
  onChange: any;
  materials: any;
  materialsLibrary: any;
  onLibrarySelection: any;
  material: any;
  materialName: any;
  frontFile?: any;
  backFile?: any;
  bucket?: any;
};

const AssetProgress: React.FC<AssetProgressProps> = props => {
  const {
    name,
    image,
    asset,
    bucket,
    onChange,
    materials,
    materialsLibrary,
    onLibrarySelection,
    material,
    materialName,
    frontFile,
    backFile,
  } = props;
  const { imageUploadProgress } = useContext(UploadContext);
  const progress = imageUploadProgress[asset?.path];

  const { setAppModal, setAppModalProps, setOpenAppModal, openAppModal } = useContext(
    ModalsContext
  );

  const { materialLibrarySummaries } = useMaterialAssets({
    materials,
    materialsLibrary,
  });

  const [open, setOpen] = useState(false);
  const [uploadModal, setUploadModal] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>();

  const [iconFile, setIcon] = useState('');
  const [loadIcons, setLoadIcons] = useState(true);

  useEffect(() => {
    let extension;
    setIcon('');
    setLoadIcons(true);
    if (!image && !material) return () => setLoadIcons(false);

    //Logic concerning file icons on new material creation..
    if (frontFile || backFile) {
      if (frontFile && materialName === 'front') {
        if (frontFile?.name?.includes('.')) {
          extension = frontFile?.name?.split('.').pop();
        } else {
          extension = frontFile?.type?.split('/').pop();
        }
      } else if (backFile && materialName === 'back') {
        if (backFile?.name?.includes('.')) {
          extension = backFile?.name?.split('.').pop();
        } else {
          extension = backFile?.type?.split('/').pop();
        }
      } else {
        extension = material?.assets[materialName]?.path.split('.').pop();
      }

      if (loadIcons && !ASSET_TYPES.image.extensions.includes(extension)) {
        loadIcon(extension);
      }
      return () => setLoadIcons(false);
    }

    //Logic concerning file icons with existing materials
    if (material && material.assets[materialName]?.ext) {
      extension = material.assets[materialName]?.ext;

      if (loadIcons && !ASSET_TYPES.image.extensions.includes(extension)) {
        loadIcon(extension);
      }
      return () => setLoadIcons(false);
    }
  }, [loadIcons, frontFile, backFile, image, material, materialName]);

  const loadIcon = async (iconName: string) => {
    try {
      const importedIcon = await import(
        `assets/icons/${iconName.toLocaleUpperCase()}.svg`
      );
      setIcon(importedIcon.default);
    } catch (error) {
      const importedIcon = await import(`assets/icons/Icon-picture.svg`);
      setIcon(importedIcon.default);
    }
  };

  useEffect(() => {
    setAppModalProps(prev => ({
      ...prev,
      materialSummaries: [...materialLibrarySummaries],
    }));
  }, [materialLibrarySummaries, setAppModalProps]);

  return (
    <div
      className={classNames('upload-atelier-assets', {
        'add-new-material__img': image,
        'add-new-material__no-img': !image,
      })}
      style={{
        backgroundImage: 'url(' + image + ')',
      }}
    >
      {iconFile ? (
        <div className='as-file-preview__thumbnail'>
          <img src={iconFile} alt='material-img' />
        </div>
      ) : (
        <div
          className='as-file-preview__thumbnail'
          style={{ backgroundImage: 'url(' + image + ')' }}
        />
      )}
      {(!image || asset?.progress < 1) && !iconFile && (
        <>
          <label
            onClick={() => setOpen(!open)}
            className='button-dashboard color-white-bd-bg'
          >
            Upload{!!progress && `ing ${progress}%`}
          </label>
          <PopUp open={open} setOpen={setOpen}>
            <li className='pop-up__li upload-atelier-assets__upload-buttons'>
              <label
                htmlFor={'upload_option_search'}
                onClick={() => {
                  setOpen(false);
                  setUploadModal(true);
                }}
              >
                Search Library
              </label>
            </li>
            <li
              className='pop-up__li upload-atelier-assets__upload-buttons'
              onClick={() => fileInputRef.current && fileInputRef.current.click()}
            >
              <label>Upload Assets</label>
            </li>
          </PopUp>
          <input
            type='file'
            id={name}
            name={name}
            onChange={onChange}
            ref={fileInputRef}
            className='d-none'
          />
        </>
      )}
      {bucket && (
        <UploadAssetLibrary
          headingText='Asset Library'
          bucket={bucket}
          open={uploadModal}
          setOpen={setUploadModal}
          onSelect={onLibrarySelection}
        />
      )}
    </div>
  );
};

export default MaterialForm;

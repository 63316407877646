import React from 'react';

const SubmitButton = ({ label }) => {
  return (
    <div className='md:flex md:items-center mb-6'>
      <div className='md:w-1/12'>
        <label
          className='block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4'
          htmlFor='inline-username'
        >
          Action
        </label>
      </div>
      <div className='md:w-2/3'>
        <button
          data-test='submit-invite'
          type='submit'
          className='shadow bg-black hover:bg-black-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded'
        >
          {label}
        </button>
      </div>
    </div>
  );
};

export default SubmitButton;

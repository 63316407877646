export const getCroppedImg = (image, crop) => {
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext('2d');
  ctx.fillStyle = '#ffffff'; //color doesn't matter, but we want full opacity

  const scratchCanvas = document.createElement('canvas');
  scratchCanvas.width = crop.width;
  scratchCanvas.height = crop.height;
  const scratchCtx = scratchCanvas.getContext('2d');

  //drawing code
  scratchCtx.clearRect(0, 0, scratchCanvas.width, scratchCanvas.height);
  scratchCtx.fillStyle = '#ffffff';
  scratchCtx.fillRect(0, 0, crop.width, crop.height);
  scratchCtx.beginPath();
  scratchCtx.arc(crop.width / 2, crop.height / 2, crop.width / 2, 0, 2 * Math.PI, true);
  scratchCtx.closePath();
  scratchCtx.clip();

  //draw image from crop selection
  scratchCtx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  );

  scratchCtx.beginPath();
  scratchCtx.arc(0, 0, crop.width / 2, 0, 2 * Math.PI, true);
  scratchCtx.clip();
  scratchCtx.closePath();
  scratchCtx.restore();

  //cropped image drawn in our actual canvas
  ctx.drawImage(scratchCanvas, 0, 0);

  return new Promise((resolve, reject) => {
    canvas.toBlob(
      blob => {
        if (!blob) {
          reject(new Error('Canvas is empty'));
          return;
        }
        resolve(blob);
      },
      'image/jpeg',
      1
    );
  });
};

export const getViewportImg = (image, viewport) => {
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = viewport.width;
  canvas.height = viewport.height;
  const ctx = canvas.getContext('2d');
  ctx.fillStyle = '#ffffff'; //color doesn't matter, but we want full opacity

  const scratchCanvas = document.createElement('canvas');
  scratchCanvas.width = viewport.width;
  scratchCanvas.height = viewport.height;
  const scratchCtx = scratchCanvas.getContext('2d');

  //drawing code
  scratchCtx.clearRect(0, 0, scratchCanvas.width, scratchCanvas.height);
  scratchCtx.fillStyle = '#ffffff';
  scratchCtx.fillRect(0, 0, viewport.width, viewport.height);

  //draw image from crop selection
  //cropped image drawn in our actual canvas
  scratchCtx.drawImage(
    image,
    canvas.width / 2 - image.width / 2,
    canvas.height / 8 - image.height / 8
  );

  //cropped image drawn in our actual canvas
  ctx.drawImage(scratchCanvas, 0, 0);

  return new Promise((resolve, reject) => {
    canvas.toBlob(
      blob => {
        if (!blob) {
          reject(new Error('Canvas is empty'));
          return;
        }
        resolve(blob);
      },
      'image/jpeg',
      1
    );
  });
};

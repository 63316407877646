import React, { createContext, useState } from 'react';

export const ModalsContext = createContext({
  openDialog: null,
  setOpenDialog: null,
  dialogProps: null,
  setDialogProps: null,
  openAppModal: null,
  setOpenAppModal: null,
  AppModal: null,
  setAppModal: null,
  appModalProps: null,
  setAppModalProps: null,
  selectedMember: null,
  setSelectedMember: null,
});

const ModalsProvider = ({ children }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogProps, setDialogProps] = useState({});
  const [openAppModal, setOpenAppModal] = useState(false);
  const [AppModal, setAppModal] = useState(null);
  const [appModalProps, setAppModalProps] = useState({});
  const [selectedMember, setSelectedMember] = useState({});

  return (
    <ModalsContext.Provider
      value={{
        openDialog,
        setOpenDialog,
        dialogProps,
        setDialogProps,
        openAppModal,
        setOpenAppModal,
        AppModal,
        setAppModal,
        appModalProps,
        setAppModalProps,
        selectedMember,
        setSelectedMember,
      }}
    >
      {children}
    </ModalsContext.Provider>
  );
};

export default ModalsProvider;

import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { navigate } from '@reach/router';
import queryString from 'query-string';
import 'books.css';
import { TextInput, SubmitButton, SelectInput } from 'components';
import Switch from 'components/Common/Switch/Switch';
import { UserContext, NotificationsContext } from 'providers/contexts';
import { apiBrandModel, createUpdateLine, createUpdateCollection } from 'services/Api';
import { Seasons } from '../../constants';

interface Props {
  location: Location;
  brand_model_id?: string;
}

interface Location {
  search: string;
  pathname: string;
}

const BrandsCreateForm: React.FC<Props> = ({ location, brand_model_id }) => {
  const {
    user: { idToken },
  } = useContext(UserContext);
  const { setDisplayToast } = useContext(NotificationsContext);
  const { handleSubmit, register, errors } = useForm();
  const [assetLibraryEnabled, setAssetLibraryEnabled] = useState<boolean>(false);

  const params = queryString.parse(location.search);
  const { type, action } = params;
  const brand = type === 'brand' || location.pathname.indexOf('/brand') > 0;
  const line = type === 'line';
  const collection = type === 'collection';
  const update = action === 'update' || location.pathname.indexOf('/edit') > 0;

  const onSubmit = async values => {
    let response;
    if (brand) {
      response = await apiBrandModel(idToken, {
        ...values,
        preferences: JSON.stringify({
          ...values?.preferences,
          asset_library: assetLibraryEnabled,
        }),
      });
      if (!response.data?.error) {
        setDisplayToast({ type: 'success', message: 'Brand has been created' });
        return navigate(`/brand/${response.data?.data?.key}`);
      }
    } else if (line) {
      values.brand = brand_model_id;
      response = await createUpdateLine(idToken, values, action);
      if (!response.data?.error) {
        setDisplayToast({
          type: 'success',
          message: `Line has been ${update ? 'updated' : 'created'}`,
        });
        return navigate(`/line/${response.data?.data?.key}`);
      }
    } else if (collection) {
      values.brand = brand_model_id;
      response = await createUpdateCollection(idToken, values, action);
      if (!response.data?.error) {
        setDisplayToast({
          type: 'success',
          message: `Collection has been ${update ? 'updated' : 'created'}`,
        });
        return navigate(`/collection/${response.data?.data?.key}`);
      }
    }
    setDisplayToast({ type: 'error', message: response.data?.error?.message });
  };

  let inputLabel,
    headingLabel = '';
  let options = [];
  const headingLabelAction = update ? 'Update' : 'Create';
  if (brand) {
    inputLabel = 'Brand name';
    headingLabel = `${headingLabelAction} Brand`;
  } else if (line) {
    inputLabel = 'Line name';
    headingLabel = `${headingLabelAction} Line`;
  } else if (collection) {
    inputLabel = 'Collection name';
    headingLabel = `${headingLabelAction}  Collection`;
    options = [
      { key: Seasons.spring_summer, name: 'Spring/Summer' },
      { key: Seasons.fall_winter, name: 'Fall/Winter' },
      { key: Seasons.prefall, name: 'Prefall' },
      { key: Seasons.resort, name: 'Resort' },
    ];
  }

  return (
    <div className='m-auto w-3/4'>
      <h2 className='text-3xl'>{headingLabel}</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          label={inputLabel}
          name='name'
          register={register}
          required={true}
          errors={errors?.name}
        />
        {brand && (
          <Switch label={'Asset Library'} onSwitchChange={setAssetLibraryEnabled} />
        )}
        {line && (
          <TextInput
            label='Description'
            name='description'
            errors={errors?.description}
            register={register}
          />
        )}
        {collection && (
          <>
            <SelectInput
              name='season'
              label='Season'
              required
              options={options}
              register={register}
            />
            <TextInput
              name='year'
              required
              label='Year'
              errors={errors?.year}
              register={register}
            />
          </>
        )}
        <SubmitButton label='Create' />
      </form>
    </div>
  );
};

export default BrandsCreateForm;

import React from 'react';
import './Accordion.scss';
import { CSSTransition } from 'react-transition-group';

import ArrowIcon from 'assets/icons/angle-right-gray.svg';

export interface IAccordion {
  heading: string;
}

const Accordion: React.FC<IAccordion> = props => {
  const { heading } = props;
  const [open, setOpen] = React.useState(false);
  const nodeDialog = React.useRef();

  return (
    <>
      <div className='accordion'>
        <div className='accordion__heading' onClick={() => setOpen(!open)}>
          {heading}
          <img
            className={open ? 'arrow-bottom' : 'arrow-right'}
            src={ArrowIcon}
            alt='Open'
          />
        </div>

        <div className='accordion__content' ref={nodeDialog}>
          <CSSTransition in={open} classNames='accordion' timeout={300} unmountOnExit>
            <div>{props.children}</div>
          </CSSTransition>
        </div>
      </div>
    </>
  );
};

export default Accordion;

import React, { useEffect, useContext, useState, useCallback, useMemo } from 'react';
import LogRocket from 'logrocket';
import { UserContext, NotificationsContext, UploadContext } from 'providers/contexts';
import { PersonalProfileSection } from 'components';

import { getBrandModel, updateAccount, putAccountModel, updateBrand } from 'services/Api';
import SideNavigation from 'components/Dashboard/SideNavigation/SideNavigation';
import { navigate, useLocation, Match } from '@reach/router';
import CompanyProfileSection from '../CompanyProfile/CompanyProfileSection';
import HeadquartersSection from '../CompanyProfile/HeadquartersSection';
import ContactAdminSection from '../CompanyProfile/ContactAdminSection';
import Team from 'components/Brands/Team';
import Loader from 'components/Loader/Loader';
import { rolesGrantCheck } from 'utils/role';
import { getRouteFromModelAndBrand } from 'utils/getRouteFromModelAndBrand';
import EnrollMfa from '../SecurityPrivacy/OnboardedSecurity/EnrollMfa';
import Breadcrumbs from 'components/Common/Breadcrumbs/Breadcrumbs';
import firebase from 'firebase';

type ISettingsContainer = {
  brand_model_id?: number;
  accessGranted?: string[];
};

const SettingsContainer: React.FC<ISettingsContainer> = ({
  accessGranted,
  children,
  brand_model_id,
}) => {
  const { user, setUser } = useContext(UserContext);
  const { idToken, account } = user;
  const { setDisplayToast, setNotifications } = useContext(NotificationsContext);
  const { firebaseStorageUrl } = useContext(UploadContext);
  const [formData, setFormData] = useState(account);
  const [companyFormData, setCompanyFormData] = useState(account);
  const [loading, setLoading] = useState(false);

  const dateFormatOptions = useMemo(
    () =>
      ({
        hour: 'numeric',
        minute: 'numeric',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      } as Intl.DateTimeFormatOptions),
    []
  );

  const settingPages = [
    { title: 'Settings', link: `/settings` },
    { title: 'Setting & Privacy', link: `` },
  ];

  useEffect(() => {
    const setFormDataFromUserAccount = () => {
      setFormData(user?.account);
    };
    setFormDataFromUserAccount();
  }, [user?.account]);

  useEffect(() => {
    const getBrandDetails = async () => {
      const response = await getBrandModel(idToken, {
        key: user?.account?.preferences?.brand,
      });
      setCompanyFormData(response?.data.data);
    };
    getBrandDetails();
  }, [idToken, user?.account?.preferences?.brand]);

  const submitOnboarding = useCallback(async () => {
    const { key: accountKey } = account;
    setLoading(true);
    const response = await putAccountModel(idToken, accountKey, {
      status: 'onboarded',
    });
    if (response.data?.error) {
      setDisplayToast({
        type: 'error',
        message: `Onboarding error: ${response.data?.error?.message}`,
      });
      LogRocket.captureMessage(
        `Onboarding error: putAccountModel set status to onboarded`,
        {
          tags: {
            // additional data to be grouped as "tags"
            journey: 'Settings',
            step: 'submitOnboarding',
          },
          extra: {
            // additional arbitrary data associated with the event
            account,
            accessGranted: JSON.stringify(accessGranted, null, 2),
          },
        }
      );
    } else {
      setUser(prevUser => ({
        ...prevUser,
        account: response?.data?.data,
      }));
      const routeFromTeamPermissions = getRouteFromUserPermissions(user);
      setLoading(false);
      setNotifications(n => [
        ...n,
        {
          firstName: 'Modern',
          lastName: 'Mirror',
          action: 'Onboarding Complete',
          when: new Date().toLocaleDateString(undefined, dateFormatOptions),
        },
      ]);
      await navigate(routeFromTeamPermissions);
    }
  }, [user]);

  const submitChanges = useCallback(
    async (values = null) => {
      const { key: accountKey } = account;
      const response = await updateAccount(idToken, {
        ...values,
        accountKey,
      });
      if (response.data?.error) {
        setDisplayToast({
          type: 'error',
          message: `Onboarding error: ${response.data?.error?.message}`,
        });
        LogRocket.captureMessage(`Onboarding error: updateAccount with values`, {
          tags: {
            // additional data to be grouped as "tags"
            journey: 'Settings',
            step: 'submitChanges',
          },
          extra: {
            // additional arbitrary data associated with the event
            values,
            account,
            response: response.data,
          },
        });
      } else {
        setFormData({ ...formData, ...response?.data?.data });
        const accountData = response?.data?.data;
        try {
          if (accountData?.assets?.profile_picture?.progress) {
            accountData.assets.profile_picture.url = await firebaseStorageUrl(
              accountData?.assets?.profile_picture?.path
            );
          }
        } catch (e) {
          LogRocket.captureException(e);
        }
        setUser(prevUser => ({
          ...prevUser,
          account: accountData,
        }));
      }
    },
    [account, idToken]
  );

  const submitCompanyChanges = useCallback(
    async (values = null) => {
      const response = await updateBrand(idToken, {
        ...values,
        brandKey: user?.account?.preferences?.brand,
      });
      if (response.data?.error) {
        setDisplayToast({
          type: 'error',
          message: `Onboarding error: ${response.data?.error?.message}`,
        });
        LogRocket.captureMessage(`Onboarding error: updateAccount with values`, {
          tags: {
            // additional data to be grouped as "tags"
            journey: 'Settings',
            step: 'submitChanges',
          },
          extra: {
            // additional arbitrary data associated with the event
            values,
            response: response.data,
          },
        });
      } else {
        setCompanyFormData({ ...companyFormData, ...response?.data?.data });
      }
    },
    [user?.account?.preferences?.brand, companyFormData, idToken]
  );

  const location = useLocation();

  const [stepsGranted, setStepsGranted] = useState(null);

  const stepsVal = useMemo(
    () => [
      {
        title: 'company profile',
        url: `/brand/${user?.brand?.key || user?.account?.preferences?.brand}/edit`,
        completed: [
          'onboarded',
          'onboarding-personal',
          'onboarding-team',
          'onboarding-features',
          'onboarding-security',
        ].includes(user?.account?.status),
        accessGranted: rolesGrantCheck(['owner', 'editor'], accessGranted, user),
        onSave: submitCompanyChanges,
      },
      {
        title: 'personal profile',
        url: `/settings/profile`,
        completed: ['onboarded', 'onboarding-features', 'onboarding-security'].includes(
          user?.account?.status
        ),
        accessGranted: rolesGrantCheck(['owner', 'editor', 'read'], accessGranted, user),
        onSave: submitChanges,
      },
      // {
      //   title: 'manage team',
      //   url: `/brand/${user?.brand?.key}/team`,
      //   completed: [
      //     'onboarded',
      //     'onboarding-features',
      //     'onboarding-security',
      //   ].includes(user?.account?.status),
      //   accessGranted: rolesGrantCheck(['owner'], accessGranted, user),
      // },
      // {
      //   title: 'features & preferences',
      //   url: `/brand/${user?.brand?.key}/settings/features`,
      //   completed: ['onboarded', 'onboarding-security'].includes(user?.account?.status),
      //   inProgress: location.pathname.indexOf('/settings/features') > 0,
      //   accessGranted: rolesGrantCheck(['owner']), accessGranted)),
      // },
      {
        title: 'security & privacy',
        url: `/settings/security`,
        completed: ['onboarded', 'onboarding-branding'].includes(user?.account?.status),
        accessGranted: rolesGrantCheck(['owner', 'editor', 'read'], accessGranted, user),
        onSave: submitOnboarding,
      },
      // {
      //   title: 'branding/marketing',
      //   url: `/brand/${
      //     user?.brand?.key || user?.account?.preferences?.brand
      //   }/customization`,
      //   completed: ['onboarded'].includes(user?.account?.status),
      //   accessGranted: rolesGrantCheck(['owner', 'editor'], accessGranted, user),
      // },
    ],
    [
      accessGranted,
      user?.account?.status,
      user?.account?.preferences,
      user?.brand?.key,
      submitOnboarding,
      submitChanges,
      submitCompanyChanges,
    ]
  );

  useEffect(() => {
    const accessGrantedEffect = () => {
      if (accessGranted?.length > 0) {
        setStepsGranted(stepsVal.filter(s => s.accessGranted));
      }
    };
    accessGrantedEffect();
  }, [stepsVal]);

  const [firstStep, setFirstStep] = useState(null);

  useEffect(() => {
    const urlParamEffect = () => {
      if (stepsGranted?.length > 0 && !firstStep) {
        const [matchFirstStep] = stepsGranted;
        if (matchFirstStep) {
          setFirstStep(matchFirstStep);
          const settingsPathMatch = stepsGranted.some(s =>
            stepsGranted.map(g => g.url).includes(location.pathname)
          );
          if (!settingsPathMatch) {
            navigate(matchFirstStep.url);
          }
        } else {
          setDisplayToast({
            type: 'error',
            message: 'Onboarding error: stepsGranted effect',
          });
          LogRocket.captureMessage(`Onboarding error: matchFirstStep null value`, {
            tags: {
              // additional data to be grouped as "tags"
              journey: 'Settings',
              step: 'urlParamEffect',
            },
            extra: {
              // additional arbitrary data associated with the event
              stepsGranted,
              firstStep,
              account,
              accessGranted: JSON.stringify(accessGranted, null, 2),
            },
          });
        }
      }
    };
    urlParamEffect();
  }, [stepsGranted, firstStep, location.pathname]);

  const [currentStep, setCurrentStep] = useState(null);
  useEffect(() => {
    const stepsGrantedEffect = () => {
      if (!stepsGranted) return;
      const currentStepMatch = stepsGranted.findIndex(
        s => location.pathname.indexOf(s.url) === 0
      );
      setCurrentStep(currentStepMatch);
    };
    stepsGrantedEffect();
  }, [stepsGranted, location.pathname]);

  const getRouteFromUserPermissions = useCallback(user => {
    const { permissions } = user;
    const brandPermission = permissions.find(p => p.model === 'Brand');
    if (brandPermission.type === 'owner') {
      return `/brand/${brandPermission.model_id}`;
    }
    // not a brand admin, search for line, collection, or style permissions
    const modelPermission = permissions.find(p => !['Brand', 'Team'].includes(p.model));
    return getRouteFromModelAndBrand(
      brandPermission?.model_id,
      modelPermission?.model,
      modelPermission?.model_id
    );
  }, []);

  const enforceMFA = (stepsGranted, onSuccess) => {
    console.log('stepsGranted', stepsGranted);
    console.log('stepsGranted[currentStep].title', stepsGranted[currentStep].title);
    console.log(
      'currentUser.multiFactor.enrolledFactors',
      !firebase.auth().currentUser.multiFactor.enrolledFactors
    );
    if (
      stepsGranted[currentStep].title === 'security & privacy' &&
      !firebase.auth().currentUser.multiFactor.enrolledFactors.length
    ) {
      setDisplayToast({
        type: 'error',
        message: 'Please enable MFA to complete onboarding process.',
      });
      LogRocket.captureMessage(`MFA Enforcement`);
      return;
    }
    onSuccess();
  };

  const stepNextHandler = useCallback(
    triggerOnChange => {
      if (currentStep < stepsGranted?.length - 1) {
        if (triggerOnChange && stepsGranted[currentStep].onSave) {
          enforceMFA(stepsGranted, stepsGranted[currentStep].onSave);
        }
        enforceMFA(stepsGranted, () => {
          navigate(stepsGranted[currentStep + 1].url);
        });
        navigate(stepsGranted[currentStep + 1].url);
      } else {
        enforceMFA(stepsGranted, submitOnboarding);
      }
    },
    [stepsGranted, currentStep]
  );
  const stepContinueHandler = useCallback(() => {
    stepNextHandler(true);
  }, [stepNextHandler]);

  if (loading) {
    return <Loader active={true} />;
  }
  if (!(stepsGranted && currentStep > -1)) {
    return null;
  }

  return (
    <div
      className={
        user?.account?.status.indexOf('onboarding') === 0
          ? `stepper-grid-template-columns`
          : ''
      }
    >
      {user?.account?.status.indexOf('onboarding') === 0 &&
        location.pathname.indexOf('customization') < 0 && (
          <SideNavigation
            steps={stepsGranted.map(s =>
              location.pathname.indexOf(s.url) >= 0
                ? {
                    ...s,
                    inProgress: true,
                  }
                : s
            )}
            buttonPrimaryLabel={
              currentStep === stepsGranted.length - 1 ? 'Finish' : 'Continue'
            }
            buttonPrimaryHandler={stepContinueHandler}
            buttonSecondaryLabel={'Skip'}
            buttonSecondaryHandler={stepNextHandler}
          />
        )}
      <div>
        {location.pathname.indexOf('/profile') > 0 && (
          <>
            <div className='dashboard-forms'>
              <PersonalProfileSection submitChanges={submitChanges} formData={formData} />
            </div>
            {/* <div className='dashboard-forms divider'>
              <DigitalShowroomSection
                submitChanges={submitChanges}
                formData={formData}
              />
            </div> */}
          </>
        )}
        {location.pathname.indexOf('/security') >= 0 && (
          <>
            <Breadcrumbs pages={settingPages} />
            <div className='dashboard-forms'>
              <EnrollMfa />
            </div>
            {/* <div className='dashboard-forms divider'>
              <UserPasswordManagementSection />
            </div> */}
          </>
        )}
        <Match path='/brand/:brand_model_id/edit'>
          {props =>
            props.match && (
              <div>
                <div className='dashboard-forms'>
                  <CompanyProfileSection
                    submitChanges={submitCompanyChanges}
                    formData={companyFormData}
                  />
                </div>
                <div className='dashboard-forms'>
                  <HeadquartersSection
                    submitChanges={submitCompanyChanges}
                    formData={companyFormData}
                  />
                </div>
                <div className='dashboard-forms'>
                  <ContactAdminSection
                    submitChanges={submitChanges}
                    formData={formData}
                  />
                </div>
              </div>
            )
          }
        </Match>
        <Match path='/brand/:brand_model_id/team'>
          {props => props.match && <Team brand_model_id={brand_model_id} {...props} />}
        </Match>

        {children && <div className='dashboard-forms'>{children} </div>}
      </div>
    </div>
  );
};

export default SettingsContainer;

import React, { useState } from 'react';
import PropTypes from 'prop-types';

export interface IDragAndDrop {
  setImageAsFile?: React.Dispatch<React.SetStateAction<any>>;
  onDropHandler?: (event: React.SyntheticEvent | Array<any>) => void;
  onDragEnterHandler?: (event: React.SyntheticEvent) => void;
  onDragOverHandler?: (event: React.SyntheticEvent) => void;
  onDragLeaveHandler?: (event: React.SyntheticEvent) => void;
  folder?: boolean;
  children?: React.ReactNode;
}

// drag and drop tutorial https://www.smashingmagazine.com/2020/02/html-drag-drop-api-react/
const DragAndDrop: React.FC<IDragAndDrop> = ({
  setImageAsFile = null,
  onDropHandler = null,
  onDragEnterHandler = null,
  onDragOverHandler = null,
  onDragLeaveHandler = null,
  folder = false,
  children,
}) => {
  const [dragEnterCount, setDragEnterCount] = useState(0);

  const preventDefaultEventActions = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDragEnter = event => {
    preventDefaultEventActions(event);
    setDragEnterCount(i => i + 1);
    onDragEnterHandler?.(event);
  };

  const handleDragLeave = event => {
    preventDefaultEventActions(event);
    setDragEnterCount(i => i - 1);
    if (dragEnterCount - 1 <= 0) {
      onDragLeaveHandler?.(event);
    }
  };

  const handleDragOver = event => {
    preventDefaultEventActions(event);
    onDragOverHandler?.(event);
  };

  const handleDrop = event => {
    preventDefaultEventActions(event);
    setDragEnterCount(0);

    if (!folder) {
      // file
      if (event.dataTransfer.files.length) {
        const [file] = event.dataTransfer.files;
        setImageAsFile?.(file);
        onDropHandler?.(event.dataTransfer.files);
      } else {
        onDropHandler?.(event);
      }
    } else {
      // folder
      const files = [];

      const traverseFileTree = (item, path = '') => {
        if (item.isFile) {
          // Get file
          item.file(function (file) {
            onDropHandler?.([
              {
                file,
                url: path + file.name,
              },
            ]);
          });
        } else if (item.isDirectory) {
          // Get folder contents
          const dirReader = item.createReader();
          dirReader.readEntries(function (entries) {
            for (let i = 0; i < entries.length; i++) {
              traverseFileTree(entries[i], path + item.name + '/');
            }
          });
        }
      };

      for (const item of event.dataTransfer.items) {
        const itemEntry = item.webkitGetAsEntry();
        if (itemEntry) {
          traverseFileTree(itemEntry);
        }
      }
    }
  };

  return (
    <div
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
    >
      {children}
    </div>
  );
};

DragAndDrop.propTypes = {
  setImageAsFile: PropTypes.func,
  onDropHandler: PropTypes.func,
  onDragEnterHandler: PropTypes.func,
  onDragOverHandler: PropTypes.func,
  onDragLeaveHandler: PropTypes.func,
  children: PropTypes.node,
};

export default DragAndDrop;

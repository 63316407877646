import React, { useEffect, useState, useContext, useMemo } from 'react';
import useSWR from 'swr';
import { mmAPI } from 'services/Api';
import { UserContext } from 'providers/contexts';
import SummarizeTeam from 'components/Dashboard/ManageTeam/SummarizeTeam';
import ManageTeam from 'components/Dashboard/ManageTeam/ManageTeam';
import { useParams } from '@reach/router';

export const ModelTeamContainer = ({
  model,
  viewMode = 'SUMMARIZE',
  triggerModelAccessModal,
}) => {
  const { user } = useContext(UserContext);
  const { idToken } = user;

  const { brand_model_id } = useParams();

  const [membersFromAccounts, setMembersFromAccounts] = useState([]);
  const [membersFromInvites, setMembersFromInvites] = useState([]);
  const [membersTableItems, setMembersTableItems] = useState([]);

  useEffect(() => {
    const membersEffect = () => {
      setMembersTableItems(membersFromAccounts);
    };
    membersEffect();
  }, [membersFromAccounts, membersFromInvites]);

  const manageModelTeamContextOptions = useMemo(
    () => [
      {
        selectItem: 'Admin Access',
        onClick: () => {
          console.log('teamModel', model);
          triggerModelAccessModal(model, 'admin');
        },
      },
      {
        selectItem: 'Editor Access',
        onClick: () => {
          console.log('teamModel', model);
          triggerModelAccessModal(model, 'editor');
        },
      },
      {
        selectItem: 'Guest Access',
        onClick: () => {
          console.log('teamModel', model);
          triggerModelAccessModal(model, 'guest');
        },
      },
    ],
    [triggerModelAccessModal, model]
  );

  return (
    <div key={model.key}>
      {viewMode === 'MANAGE' && <ManageTeam members={membersTableItems} />}
      {viewMode === 'SUMMARIZE' && (
        <SummarizeTeam
          popUpOptions={manageModelTeamContextOptions}
          team={{ ...model }}
          model={model}
        />
      )}
    </div>
  );
};

import React, { useContext, useEffect, useState } from 'react';
import { navigate } from '@reach/router';
import useSWR from 'swr';
import ModelsList from 'components/Dashboard/Collections/CollectionsList/ModelsList';
import { CreateModelModal } from 'components/Common/Modal';
import Breadcrumbs from 'components/Common/Breadcrumbs/Breadcrumbs';
import { mmAPI, createUpdateLine } from 'services/Api';
import { UserContext, NotificationsContext, ViewModeContext } from 'providers/contexts';
import { VIEWMODE } from 'providers/ViewModeProvider';
import { FrameUIActionsTypes, useFrameUI } from 'providers/FrameUIProvider';
import useSidebar from 'hooks/useSidebar';

const Collections = ({ brand_model_id, line_model_id }) => {
  const {
    user: { idToken, brand, line },
  } = useContext(UserContext);

  const { setDisplayToast } = useContext(NotificationsContext);
  const { viewMode, setViewMode, setViewsList } = useContext(ViewModeContext);

  const { onSidebarOpen } = useSidebar(idToken, { brand_model_id });

  const [open, setOpen] = useState(false);

  const pages = [
    { title: brand.name, link: `/brand/${brand.key}/products` },
    { title: 'Collections', link: `/brand/${brand.key}/collections` },
  ];

  const { data: collections } = useSWR(
    [
      line_model_id ? '/api/collection/query/line' : '/api/collection/query/brand/',
      idToken,
      line_model_id || brand_model_id,
    ],
    (url, idToken, queryModel) => {
      return mmAPI(url, idToken, { [line_model_id ? 'line' : 'brand']: queryModel });
    },
    {
      suspense: true,
    }
  );

  const { data: lines } = useSWR(
    line_model_id ? null : ['/api/line/query/brand/', idToken, brand_model_id],
    (url, idToken, brand) => {
      return mmAPI(url, idToken, { brand });
    },
    {
      suspense: true,
    }
  );

  const [collectionsByLine, setCollectionsByLine] = useState({});
  useEffect(() => {
    const linesEffect = collectionsVal => {
      if (!collections || !lines) return;
      const collectionsGrouped = collectionsVal.reduce((acc, curr, i) => {
        if (!acc[curr.line]) acc[curr.line] = [curr];
        else if (acc[curr.line]) acc[curr.line].push(curr);
        return acc;
      }, {});
      setCollectionsByLine(collectionsGrouped);
    };
    linesEffect(collections);
  }, [collections, lines]);

  useEffect(() => {
    const viewModeEffect = () => {
      setViewsList([VIEWMODE.LIST, VIEWMODE.CARD]);
      if (![VIEWMODE.LIST.key, VIEWMODE.CARD.key].includes(viewMode)) {
        setViewMode(VIEWMODE.LIST.key);
      }
    };
    viewModeEffect();
  }, []);

  const createModelActionHandler = async values => {
    const response = await createUpdateLine(
      idToken,
      {
        ...values,
        brand: brand_model_id,
      },
      'POST'
    );
    if (response.data?.error) {
      setDisplayToast({ type: 'error', message: response.data?.error?.message });
    } else {
      const { name, key } = response.data?.data;
      setDisplayToast({ type: 'success', message: `Line ${name} has been created` });
      navigate(`/brand/${brand_model_id}/line/${key}`);
    }
  };

  const popUpOptions = [
    {
      selectItem: 'Create New Collection',
      ModalComponent: (
        <CreateModelModal
          formId='createCollection'
          modelName='Collection'
          modelInputLabel='Collection Name'
          open={open}
          setOpen={setOpen}
          btnActionHandler={createModelActionHandler}
        />
      ),
    },
  ];

  const { dispatch } = useFrameUI();

  useEffect(() => {
    const filterBarEffect = () => {
      dispatch({ type: FrameUIActionsTypes.FILTER_BAR_VISIBLE, payload: true });
      dispatch({
        type: FrameUIActionsTypes.FILTER_BAR_OPTIONS,
        payload: [...popUpOptions],
      });
    };

    filterBarEffect();

    return () => {
      dispatch({ type: FrameUIActionsTypes.FILTER_BAR_RESET });
    };
  }, []);

  const collectionContextOptions = [
    {
      selectItem: 'Info',
      onClick: onSidebarOpen,
    },
  ];

  return (
    <>
      <Breadcrumbs pages={pages} />
      {line_model_id && collections && (
        <ModelsList
          models={collections.map(c => ({
            ...c,
            route: `/brand/${brand_model_id}/products?collections=${c.key}`,
          }))}
          popUpOptions={collectionContextOptions}
          modelTitle={`${line ? line.name : brand.name} Collections`}
        />
      )}
      {!line_model_id &&
        lines &&
        collectionsByLine &&
        Object.entries(collectionsByLine).map(
          ([line, lineCollections]: [string, any]) => (
            <>
              <div className='heading'>
                {lines.find(l => l.key === parseInt(line))?.name}
              </div>
              <ModelsList
                models={lineCollections.map(c => ({
                  ...c,
                  route: `/brand/${brand_model_id}/products?collections=${c.key}`,
                }))}
                popUpOptions={collectionContextOptions}
              />
            </>
          )
        )}
    </>
  );
};

export default Collections;

import React from 'react';
import Modal from 'components/Common/Modal/Modal';
import './CollectionAccessModal.scss';
import MasonryLayout from 'components/MasonryLayout/MasonryLayout';
import Search from 'components/Common/Search/Search';

export interface IAllMembers {
  memberFirstName: string;
  memberLastName: string;
  memberEmail: string;
  memberAccess: string;
  memberPhoto?: string;
}

export interface ICollectionAccessModal {
  heading: string;
  open: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  materialSummaries?: any[];
  onLibrarySelection?: (src) => void;
}

const AtelierLibraryModal: React.FC<ICollectionAccessModal> = props => {
  const [materialLibrary, setMaterialLibrary] = React.useState([]);
  const { heading, open, setOpen, materialSummaries, onLibrarySelection } = props;
  const libraryFromSummaries = materialSummaries => {
    const library = materialSummaries.reduce((lib, s) => {
      s.frontImg &&
        lib.push({
          src: s.frontImg,
          thumbSrc: s.frontThumbImg,
          asset: s.assets.front,
          material: s,
          tags: ['front', s.name],
        });
      s.backImg &&
        lib.push({
          src: s.backImg,
          thumbSrc: s.backThumbImg,
          asset: s.assets.back,
          material: s,
          tags: ['back', s.name],
        });
      return lib;
    }, []);
    return library;
  };
  React.useEffect(() => {
    const library = libraryFromSummaries(materialSummaries);
    setMaterialLibrary(library);
  }, [materialSummaries]);

  const [filterValue, setFilterValue] = React.useState(null);
  const filterChangeHandler = value => {
    if (!value) {
      setFilterValue(null);
      const library = libraryFromSummaries(materialSummaries);
      setMaterialLibrary(library);
      return;
    }
    setMaterialLibrary(prev => {
      setFilterValue(value);
      if (!prev.length) {
        const library = libraryFromSummaries(materialSummaries);
        return library.filter(p =>
          p?.tags?.some(t => t.toLowerCase()?.indexOf(value.toLowerCase()) >= 0)
        );
      }
      return prev.filter(p =>
        p?.tags?.some(t => t.toLowerCase()?.indexOf(value.toLowerCase()) >= 0)
      );
    });
  };
  return (
    <>
      <div className='collection-access-modal'>
        <Modal
          modalHeading={heading}
          modalBtnClose={'cancel'}
          modalBtnActionLabel={'Continue'}
          modalBtnActionHandler={() => setOpen(false)}
          backgroundColor='#fff'
          modalOpen={open}
          setModalOpen={setOpen}
        >
          <>
            {materialLibrary.length === 0 && !filterValue ? (
              <>Library is currently empty. Upload assets to build up the library.</>
            ) : (
              <>
                <div className='collection-access-modal__label'>
                  <div className=''>
                    <Search changeHandler={filterChangeHandler} />
                  </div>
                </div>

                <div className='collection-access-modal__add-btn'></div>

                <div className='collection-access-modal__members'>
                  {filterValue && materialLibrary.length === 0 ? <>No results</> : null}
                  <MasonryLayout columns={3} gap={25}>
                    {materialLibrary.map(m => {
                      const height = 200 + Math.ceil(Math.random() * 300);
                      return (
                        <div
                          key={m.asset.key}
                          onClick={e => {
                            onLibrarySelection(m.src);
                            setOpen(prev => !prev);
                          }}
                        >
                          <div
                            className='modal-materialLibrary__item'
                            style={{ height: `${height}px` }}
                          >
                            <img
                              className='modal-materialLibrary__img'
                              src={m.thumbSrc}
                            />
                          </div>
                          <div className='modal-materialLibrary__content'>
                            <h3 className='modal-materialLibrary__headline'>
                              {m.material.name}
                            </h3>
                            <h4 className='modal-materialLibrary__subheading'></h4>
                          </div>
                        </div>
                      );
                    })}
                  </MasonryLayout>
                </div>
              </>
            )}
          </>
        </Modal>
      </div>
    </>
  );
};

export default AtelierLibraryModal;

import React from 'react';
import './Dialog.scss';

export interface IDialogButtons {
  btnUrl?: string;
  btnTxt: string;
}

export interface IDialog {
  backgroundColor?: string;
  dialogBody: string;
  dialogBtnClose?: string;
  dialogBtnAction?: string;
  closeAction?: boolean;
  open?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  onClose?: (result: boolean) => void;
}

const Dialog: React.FC<IDialog> = props => {
  const {
    dialogBody,
    dialogBtnAction = 'Yes',
    dialogBtnClose = 'Cancel',
    closeAction = true,
    backgroundColor,
    open = true,
    setOpen,
    onClose,
  } = props;
  const nodeDialog = React.useRef();

  const closeDialog = (result = false) => {
    setOpen?.(false);
    onClose?.(result);
  };

  return (
    <>
      {open && (
        <div className='dialog'>
          <div className='dialog__content' ref={nodeDialog}>
            <div className='dialog__inner' style={{ backgroundColor: backgroundColor }}>
              <p>{dialogBody}</p>
            </div>
            <div className='dialog__footer'>
              {closeAction && (
                <button
                  className='button-transparent-black button-smaller'
                  onClick={() => closeDialog(false)}
                >
                  {dialogBtnClose}
                </button>
              )}
              <button
                className='button-regular color-warning-bg'
                onClick={() => closeDialog(true)}
              >
                {dialogBtnAction}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Dialog;

import React, { useContext } from 'react';
import { Link } from '@reach/router';
import './ModelsList.scss';
import { IPopUpOptions } from 'models/Dashboard/IPopUpOptions';
import PopUp from 'components/Common/PopUp/PopUp';
import Checkbox from 'components/Common/Checkboxes/Checkboxes';
import { ViewModeContext } from 'providers/contexts';
import { VIEWMODE } from 'providers/ViewModeProvider';
import DotsIcon from 'assets/icons/dots-grey.svg';
import Asset from 'components/Asset';
import { useFrameUI, FrameUIActionsTypes } from 'providers/FrameUIProvider';

export interface IModel {
  key: string;
  name: string;
  image?: any;
  route: string;
}

export interface IModelBox {
  model: IModel;
  popUpOptions: IPopUpOptions[];
}

export interface IModelsList {
  modelTitle?: string;
  models: IModel[];
  popUpOptions: IPopUpOptions[];
}

const ModelsList: React.FC<IModelsList> = props => {
  const { modelTitle = '', models, popUpOptions } = props;
  const { viewMode } = useContext(ViewModeContext);

  return (
    <div className='models-list'>
      <div className='heading'>{modelTitle}</div>
      {models &&
        (viewMode === VIEWMODE.LIST.key ? (
          <div className='models-list-body__list'>
            {models.map(model => (
              <div className='model-box' key={model.key}>
                <ModelListBox model={model} popUpOptions={popUpOptions} />
              </div>
            ))}
          </div>
        ) : (
          <div className='models-list-body__grid row'>
            {models.map(model => (
              <div className='col-4' key={model.key}>
                <ModelGridBox model={model} popUpOptions={popUpOptions} />
              </div>
            ))}
          </div>
        ))}
    </div>
  );
};

const ModelListBox: React.FC<IModelBox> = props => {
  const { model, popUpOptions } = props;
  const { key, name, route } = model;
  const { dispatch } = useFrameUI();

  const [open, setOpen] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [ActiveModalComponent, setActiveModalComponent] = React.useState(null);

  const handlePopUpModal = ModalComponent => {
    setActiveModalComponent(ModalComponent);
    setModalOpen(true);
  };

  return (
    <div className='model-box__content block w-full p-3 bg-white text-grey-darkest no-underline'>
      {popUpOptions?.length > 0 && (
        <div className='model-box__checkbox'>
          <Checkbox />
        </div>
      )}
      <Link
        className='model-box__title'
        onClick={() =>
          dispatch({
            type: FrameUIActionsTypes.SET_SELECTED_COLLECTION,
            // @ts-ignore
            payload: model,
          })
        }
        to={route}
      >
        {name}
      </Link>
      {popUpOptions?.length > 0 && (
        <img
          className='model-box__dots'
          src={DotsIcon}
          alt={`${name} options`}
          onClick={() => setOpen(!open)}
        />
      )}
      <PopUp open={open} setOpen={setOpen}>
        {popUpOptions &&
          popUpOptions.map(({ selectItem, onClick, to, ModalComponent }) => (
            <li
              className='pop-up__li'
              onClick={() => {
                setOpen(false);
                if (ModalComponent) {
                  handlePopUpModal(ModalComponent);
                }
                onClick?.(model);
              }}
              key={selectItem}
            >
              {to ? <Link to={to}>{selectItem}</Link> : selectItem}
            </li>
          ))}
      </PopUp>
      {ActiveModalComponent &&
        modalOpen &&
        React.cloneElement(ActiveModalComponent, {
          open: modalOpen,
          setOpen: setModalOpen,
          data: key,
          model: model,
        })}
    </div>
  );
};

const ModelGridBox: React.FC<IModelBox> = props => {
  const { model, popUpOptions } = props;

  return (
    <div className='model-box'>
      <Asset asset={['hero', model.image]} upload={false}>
        <img
          className='model-box__image object-cover object-top w-full'
          src='//satyr.io/758x16:9'
          alt={model?.name || ''}
        />
      </Asset>
      <ModelListBox model={model} popUpOptions={popUpOptions} />
    </div>
  );
};

export default ModelsList;

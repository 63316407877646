import React, { useContext } from 'react';
import { navigate, useParams } from '@reach/router';

import { ModalsContext } from 'providers/ModalsProvider';

export default function ErrorOverlay() {
  const { dialogProps } = useContext(ModalsContext);
  const { brand_model_id } = useParams();

  const backHandler = () => {
    navigate(`/brand/${brand_model_id}`);
  };

  return (
    <div className='dialog'>
      <div className='dialog__content'>
        <div className='dialog__inner'>{dialogProps?.dialogBody}</div>
        <div className='dialog__footer'>
          <button className='button-transparent-black' onClick={backHandler}>
            Go Back
          </button>
          <button
            className='button-regular color-warning-bg'
            onClick={dialogProps.btnActionHandler}
          >
            {dialogProps?.dialogBtnAction}
          </button>
        </div>
      </div>
    </div>
  );
}

import moment from 'moment';
import React from 'react';
import TimeAgo from 'react-time-ago';
import classNames from 'classnames';

type NotificationSegment = {
  displayText: string;
  url: string;
  accented: boolean;
};

type NotificationItem = {
  profileImg: string;
  firstName: string;
  lastName: string;
  segments: Array<NotificationSegment>;
  when: number;
};
interface INotificationProp {
  notification: NotificationItem;
}

const NotificationItem: React.FC<INotificationProp> = props => {
  const { notification } = props;
  const { profileImg, firstName, lastName, when } = notification;

  let display = null;
  let counter = 1;
  let space = '';

  return (
    <div className='notifications__item'>
      {profileImg ? (
        <img className='notifications__img' src={profileImg} alt='Profile' />
      ) : (
        <div className='notifications__thumbnail'>
          {firstName.slice(0, 1)}

          {lastName.slice(0, 1)}
        </div>
      )}
      <div className='notifications__content'>
        <div className='notifications__action'>
          {notification.segments &&
            notification.segments.map((segment, index) => {
              if (counter > 1) {
                space = '3px';
              }

              if (segment.url && segment.url.length) {
                if (!segment.accented) {
                  display = (
                    <a
                      style={{ paddingLeft: space }}
                      key={counter}
                      href={segment.url}
                      target='_work'
                      className={classNames({'notifications__activity-type': index === 1})}
                    >
                      {segment.displayText}
                    </a>
                  );
                } else {
                  display = (
                    <a
                      style={{ paddingLeft: space }}
                      key={counter}
                      href={segment.url}
                      target='_work'
                      className={classNames({'notifications__activity-type': index === 1})}
                    >
                      {segment.displayText}
                    </a>
                  );
                }
              } else {
                if (!segment.accented) {
                  display = (
                    <span
                      className={classNames({'notifications__activity-type': index === 1})}
                      style={{ paddingLeft: space }}
                    >
                      {segment.displayText}
                    </span>
                  );
                } else {
                  display = (
                    <span className={classNames({'notifications__activity-type': index === 1})} style={{ paddingLeft: space }}>{segment.displayText}</span>
                  );
                }
              }
              counter += 1;
              return display;
            })}
        </div>
        <div className='notifications__time'>
          <TimeAgo date={when * 1000} />
        </div>
      </div>
    </div>
  );
};

export default NotificationItem;

import React, { useEffect, useContext, useCallback, useMemo } from 'react';
import useSWR from 'swr';
import { mmAPI } from 'services/Api';
import { UserContext } from 'providers/contexts';
import { FrameUIActionsTypes, useFrameUI } from 'providers/FrameUIProvider';
import { ModalsContext } from 'providers/ModalsProvider';
import ModelAccessModalSimple from 'components/Dashboard/Collections/CollectionAccessModal/ModelAccessModalSimple';
import { ModelTeamContainer } from './ModelTeamContainer';
import { TeamContainer } from './TeamContainer';
import Breadcrumbs from 'components/Common/Breadcrumbs/Breadcrumbs';

const Team = ({ brand_model_id }) => {
  const { user } = useContext(UserContext);
  const { idToken } = user;
  const { dispatch } = useFrameUI();

  const { data: lines } = useSWR(
    ['/api/line/query/brand/', idToken, brand_model_id],
    (url, idToken, brand) => {
      return mmAPI(url, idToken, { brand, cursor: 0 });
    },
    {
      suspense: false,
    }
  );

  const { data: collections } = useSWR(
    ['/api/collection/query/brand/', idToken, brand_model_id],
    (url, idToken, brand) => {
      return mmAPI(url, idToken, { brand, cursor: 0 });
    },
    {
      suspense: false,
    }
  );

  const { setAppModal, setAppModalProps, setOpenAppModal, openAppModal } = useContext(
    ModalsContext
  );

  const modal = (
    <ModelAccessModalSimple
      heading={`${user?.brand?.name} Access`}
      open={openAppModal}
      setOpen={setOpenAppModal}
      model={user?.brand}
    />
  );

  const triggerModelAccessModal = (model, jobType) => {
    console.log('triggerModelAccessModal', model, jobType);
    setAppModalProps(prev => ({
      ...prev,
      heading: `${model?.name} ${jobType} Access`,
      model,
      jobType,
    }));
    setAppModal(modal);
    setOpenAppModal(true);
  };

  useEffect(() => {
    const filterBarEffect = () => {
      dispatch({ type: FrameUIActionsTypes.FILTER_BAR_VISIBLE, payload: false });
      return () => {
        dispatch({ type: FrameUIActionsTypes.FILTER_BAR_RESET });
      };
    };
    return filterBarEffect();
  }, []);

  const pages = [
    { title: 'Settings', link: `/settings` },
    { title: 'Manage Team', link: `/team` },
  ];

  return (
    <>
      <div className='TeamView'>
        <Breadcrumbs pages={pages} />
        <div className='TeamTables'>
          <TeamContainer
            viewMode={'MANAGE'}
            key={brand_model_id}
            lines={lines}
            collections={collections}
            brand_model_id={brand_model_id}
          />
        </div>

        <div className='TeamView'>
          <div className='TeamTables'>
            <div className='collections-list'>
              <div className='collections-list__heading-item heading'>
                <div className='manage-team__header'>
                  <h2 className='manage-team__headline'>Brand Members</h2>
                </div>
              </div>
              {user?.brand && (
                <ModelTeamContainer
                  key={user?.brand?.key}
                  model={user?.brand}
                  triggerModelAccessModal={triggerModelAccessModal}
                />
              )}
              <div className='collections-list__heading-item heading'>
                <div className='manage-team__header'>
                  <h2 className='manage-team__headline'>Members by Line</h2>
                </div>
              </div>

              {lines &&
                lines.map(line => (
                  <ModelTeamContainer
                    key={line.key}
                    model={line}
                    triggerModelAccessModal={triggerModelAccessModal}
                  />
                ))}

              <div className='collections-list__heading-item heading'>
                <div className='manage-team__header'>
                  <h2 className='manage-team__headline'>Members by Collection</h2>
                </div>
              </div>

              {collections &&
                collections.map(collection => (
                  <ModelTeamContainer
                    key={collection.key}
                    model={collection}
                    triggerModelAccessModal={triggerModelAccessModal}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Team;

import React, { useEffect, useRef } from 'react';
import SlickSlider from 'react-slick';
import DragAndDrop from 'components/Upload/DragAndDrop';
import Tile from 'components/Tile/Tile';
import SlickCenterModeThumb from './SlickCenterModeThumb';
import ReferenceAssetProgress from './ReferenceAssetProgress';
import { Link, useParams } from '@reach/router';

export const SlickCenterMode = props => {
  const {
    name,
    media,
    slideIndex,
    afterChange,
    onDropHandler,
    showNav = false,
    showDropDefault = true,
    mediaRatio,
    mediaObjectFit,
    deleteMediaActionHandler,
    renderWithLink,
  } = props;
  const slickRef = useRef<SlickSlider>();
  const slickNavRef = useRef<SlickSlider>();
  const settings = {
    afterChange: i => {
      afterChange?.(media[i]);
    },
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: showNav && slickNavRef.current,
  };
  const navSettings = {
    focusOnSelect: true,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: slickRef.current,
  };

  useEffect(() => {
    slickRef.current.slickGoTo(slideIndex);
  }, [slideIndex]);

  const { brand_model_id, style_model_id } = useParams();

  const renderLink = m => {
    return (
      <>
        {!['gltf', 'glb', 'model'].includes(m?.type) ? (
          <div className={`style-summary__slide style-summary_${m?.type}`} key={m?.key}>
            <Tile
              key={m?.key}
              caption={
                ['look', 'back', 'front', 'left', 'right', 'close_up'].includes(m?.shot)
                  ? `${m?.shot}`
                  : ``
              }
              imgClassNames={['style-summary__slide']}
              objectFit={mediaObjectFit}
              aspectRatio={m?.aspectRatio || mediaRatio}
              image={m?.src}
              vaGalleryView={true}
            />
          </div>
        ) : (
          <div
            className={`style-summary__slide style-summary__model-viewer style-summary__${m.type}`}
            key={m?.key}
          >
            <Tile
              key={m?.key}
              model={m?.src}
              imgClassNames={['style-summary__slide']}
              aspectRatio='1-1'
              objectFit='contain'
              caption=''
              vaGalleryView={true}
            />
          </div>
        )}
      </>
    );
  };
  return (
    <>
      <SlickSlider {...settings} ref={slickRef}>
        {media
          ?.filter(m => m?.src || m?.asset || m?.dropRegion)
          .map(m => (
            <div key={m?.key} data-name={name}>
              {m?.src ? (
                renderWithLink ? (
                  <Link
                    to={`/brand/${brand_model_id}/style/${style_model_id}/samples/${m?.key}`}
                    data-sampleURI={`/samples/${m?.key}`}
                  >
                    {renderLink(m)}
                  </Link>
                ) : (
                  <div>{renderLink(m)}</div>
                )
              ) : m?.asset ? (
                <ReferenceAssetProgress
                  {...m}
                  onDropHandler={onDropHandler}
                  ratio={'3-4' || mediaRatio}
                  objectFit={mediaObjectFit}
                  key={m?.key}
                />
              ) : null}
            </div>
          ))}
      </SlickSlider>

      {showNav && (
        <div>
          <SlickSlider {...navSettings} ref={slickNavRef}>
            {media
              ?.filter(m => m?.src || m?.asset || m?.dropRegion)
              .map(m => {
                const { src, name, key } = m;
                return (
                  <SlickCenterModeThumb
                    m={m}
                    key={m.key}
                    onDropHandler={onDropHandler}
                    deleteMediaActionHandler={deleteMediaActionHandler}
                    showNav={false}
                    asNav={true}
                    metadata={{ src, name, key }}
                  />
                );
              })}
          </SlickSlider>
        </div>
      )}
    </>
  );
};

const customPaging = ({ src }) => {
  return (
    <div className='style-summary__vs-single'>
      <img className='style-summary__img-sm' src={src} />
      {/* <div className='style-summary__vs-title'>{title}</div> */}
    </div>
  );
};

const DragDropSelectRegion = props => {
  const { onDropHandler, mediaRatio } = props;

  return (
    <DragAndDrop onDropHandler={files => onDropHandler(files)}>
      <div className={`ratio${m?.aspectRatio || mediaRatio} ratio-wrap`}>
        <div className='ratio-img'>
          <div className='product-upload__drag-area '>
            <div className='product-upload__txt'>
              <p className='product-upload__instructions'>
                Drag and drop or select files
              </p>
              <p className='txt-gray-light'>File types: image files</p>
            </div>
            <div className='image-upload'>
              <label
                htmlFor={'batchUpload'}
                className='button-small color-white-bd-bg mt-4'
              >
                Select Files
              </label>
              <input
                id={'batchUpload'}
                type='file'
                onChange={e => {
                  const { files } = e.currentTarget;
                  onDropHandler(files);
                }}
                name={'batchUpload'}
              />
            </div>
          </div>
        </div>
      </div>
    </DragAndDrop>
  );
};

export default SlickCenterMode;

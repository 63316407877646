import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import useSWR from 'swr';
import { UploadContext, ModalsContext, UserContext } from 'providers/contexts';
import { FormHeader, Asset } from 'components';
import {
  TextInput,
  SelectInput,
  PhoneInputComponent,
  ImageInput,
  CheckBoxContainerInput,
  SwitchInput,
} from 'components/Dashboard/FormComponents';
import { JobTypes, DEPARTMENTS } from 'constants/index';
import { AssetMetadataProps } from 'components/Asset';
import { ImageCropModal } from '../ImageCrop/ImageCropModal';
import { mmAPI } from 'services/Api';

interface PersonalProfileSectionProps {
  submitChanges?: (values: any) => any;
  formData?: FormData;
  memberProfile?: boolean;
}

interface FormData {
  userName: string;
  email: string;
  name?: string;
  title?: string;
  department?: string;
  employee_number?: string;
  mobile_number?: string;
  contact_number?: string;
  avatar?: string;
  assets: AssetMetadataProps;
  date_format?: string;
  language?: string;
  role?: string;
  job_type?: string;
  username?: string;
  mfa?: boolean;
}

const PersonalProfileSection: React.FC<PersonalProfileSectionProps> = ({
  submitChanges,
  formData,
  memberProfile,
}) => {
  const { user } = useContext(UserContext);
  const { idToken } = user;
  const { setImageUrl } = useContext(UploadContext);
  const { setAppModal, openAppModal, setOpenAppModal } = useContext(ModalsContext);

  const [update, setUpdate] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [saveImage, setSaveImage] = useState(false);
  const { handleSubmit, register, errors, control } = useForm();

  const onSubmit = useCallback(values => {
    setUpdate(false);
    if (values.profile_picture?.length) {
      setSaveImage(true); // trigger upload
      delete values.profile_picture;
    }
    values.name = `${values?.firstName} ${values?.lastName}`;
    if (values.department?.length) {
      values.department = values.department[0].name;
    }
    if (values.role?.length) {
      values.role = values.role[0].name;
    }
    if (values.language?.length) {
      values.language = values.language.map(l => l.value).join('/');
    }
    if (values.date_format?.length) {
      values.date_format = values.date_format[0].name;
    }
    submitChanges(values);
  }, []);

  const departments = Object.values(DEPARTMENTS).map(d => ({
    name: d,
  }));

  const roleOptions = [
    { key: JobTypes.admin, name: 'Admin' },
    { key: JobTypes.designer, name: 'Designer' },
    { key: JobTypes.marketing, name: 'Marketing' },
    { key: JobTypes.sales, name: 'Sales Rep' },
    { key: JobTypes.delegate, name: 'Delegate' },
  ];

  const languages = [
    { name: 'Spanish', value: 'Spanish' },
    { name: 'English', value: 'English' },
    { name: 'Mandarin', value: 'Mandarin' },
    { name: 'Catalan', value: 'Catalan' },
  ];

  const { data: lines, error } = useSWR(
    user?.brand ? ['/api/line/query/brand/', idToken, user?.brand?.key] : null,
    (url, idToken, brand) => {
      return mmAPI(url, idToken, { brand });
    },
    {
      suspense: true,
    }
  );

  const [linesOptions, setLinesOptions] = useState([]);
  useEffect(() => {
    setLinesOptions(lines?.map(l => l.name));
  }, [lines]);

  const dateFormats = [{ name: 'MM/DD/YYYY' }, { name: 'DD/MM/YYYY' }];
  const [assets] = Object.entries(formData?.assets);
  const [type, metadata] = assets;

  const handleUploadButtonClicked = useCallback(
    e => {
      const [imageFile] = e.target.files;
      if (!imageFile) {
        return;
      }

      setOpenAppModal(true);
      const modal = (
        <ImageCropModal
          srcFile={imageFile}
          btnActionHandler={onUpdatePhoto}
          open={openAppModal}
          setOpen={setOpenAppModal}
        />
      );
      setAppModal(modal);
    },
    [openAppModal]
  );

  const onUpdatePhoto = useCallback(
    imageFile => {
      setImageUrl({
        [type]: URL.createObjectURL(imageFile),
        key: metadata.key,
        ['file']: imageFile,
      });
    },
    [metadata.key]
  );

  return (
    <div>
      <FormHeader
        headingText='Personal Profile'
        setUpdate={setUpdate}
        setCancel={setCancel}
        update={update}
        onSubmit={handleSubmit(onSubmit)}
        permissionLevel={formData?.job_type || formData?.role}
        memberProfile={memberProfile}
      />
      <div className='row'>
        <TextInput
          label='Username'
          name='username'
          update={update}
          value={formData?.username}
          register={register}
          errors={errors}
          validate={value => {
            return (
              [/^[\w-.]+(?:(?:\+([\w-.]+))?@([\w-]+\.)+[\w-]{2,4})?$/].every(pattern =>
                pattern.test(value)
              ) || 'Invalid Username. Alphanumeric characters expected.'
            );
          }}
        />
        {errors?.username && (
          <div className='error-text w-100'>{errors?.username?.message}</div>
        )}
        <TextInput
          label='Email'
          name='email'
          update={update}
          required='This field is required'
          value={formData?.email}
          register={register}
          errors={errors}
          validate={value => {
            return (
              [/^[\w-.]+(?:\+([\w-.]+))?@([\w-]+\.)+[\w-]{2,4}$/].every(pattern =>
                pattern.test(value)
              ) || 'Invalid Email.'
            );
          }}
        />
        {errors?.email && (
          <div className='error-text w-100'>{errors?.email?.message}</div>
        )}
        <TextInput
          label='First Name'
          name='firstName'
          update={update}
          value={formData?.name?.split(' ')[0]}
          register={register}
          errors={errors}
          required='This field is required'
          validate={value => {
            return (
              [/^[\w-.]+(?:(?:\+([\w-.]+))?@([\w-]+\.)+[\w-]{2,4})?$/].every(pattern =>
                pattern.test(value)
              ) || 'Invalid First Name. Alphanumeric characters expected.'
            );
          }}
        />
        {errors?.firstName && (
          <div className='error-text w-100'>{errors?.firstName?.message}</div>
        )}
        <TextInput
          label='Last Name'
          name='lastName'
          update={update}
          value={formData?.name?.split(' ')[1]}
          register={register}
          errors={errors}
          required='This field is required'
          validate={value => {
            return (
              [/^[\w-.]+(?:(?:\+([\w-.]+))?@([\w-]+\.)+[\w-]{2,4})?$/].every(pattern =>
                pattern.test(value)
              ) || 'Invalid Last Name'
            );
          }}
        />
        {errors?.lastName && (
          <div className='error-text w-100'>{errors?.lastName?.message}</div>
        )}
        <TextInput
          label='Title'
          name='title'
          update={update}
          value={formData?.title}
          register={register}
          errors={errors}
          required='This field is required'
          validate={value => {
            return (
              [/^[\w ]*$/].every(pattern => pattern.test(value)) ||
              'Invalid Title. Alphanumeric characters expected.'
            );
          }}
        />
        {errors?.title && (
          <div className='error-text w-100'>{errors?.title?.message}</div>
        )}
        <SelectInput
          label='Department'
          name='department'
          update={update}
          options={departments}
          value={formData?.department}
          control={control}
          errors={errors}
          required
        />
        {formData?.role === 'admin' && (
          <SelectInput
            label='Account Type'
            name='job_type'
            update={update}
            options={roleOptions}
            value={formData?.job_type}
            control={control}
            errors={errors}
            className='capitalize'
          />
        )}
        {/* <SelectInput
          label='Language'
          name='language'
          update={update}
          options={languages}
          value={formData?.language}
          control={control}
          errors={errors}
          multi={true}
          required
        /> */}

        <Asset
          asset={assets}
          saveImages={saveImage}
          setSaveImage={setSaveImage}
          className='col-md-6'
          crop={true}
        >
          <ImageInput
            label='Profile Image'
            name='profile_picture'
            register={register}
            update={update}
            errors={errors}
            fullName={formData?.name}
            cancelUpdate={cancel}
            handleUploadButtonClicked={handleUploadButtonClicked}
          />
        </Asset>

        {/* <CheckBoxContainerInput
          update={false}
          label='Line(s)'
          name='lines'
          options={linesOptions}
          register={register}
          errors={errors}
        /> */}

        <PhoneInputComponent
          label='Country Code/Contact Number'
          name='contact_number'
          update={update}
          control={control}
          value={formData?.contact_number}
          errors={errors}
        />

        <PhoneInputComponent
          label='Country Code/Mobile Number'
          name='mobile_number'
          update={update}
          control={control}
          value={formData?.mobile_number}
          errors={errors}
        />
        <TextInput
          label='Employee Number'
          name='employee_number'
          update={update}
          value={formData?.employee_number}
          register={register}
          errors={errors}
          validate={value => {
            return (
              [/^[0-9]*$/].every(pattern => pattern.test(value)) ||
              'Invalid Employee Number. Numeric value expected'
            );
          }}
        />
        {errors?.employee_number && (
          <div className='error-text w-100'>{errors?.employee_number?.message}</div>
        )}
        <SelectInput
          label='Date Format'
          name='date_format'
          update={update}
          options={dateFormats}
          value={formData?.date_format}
          control={control}
          errors={errors}
        />
        {/* <SwitchInput
          label='Two Factor Authentication'
          name='mfa'
          update={update}
          value={formData?.mfa}
          register={register}
          errors={errors}
        /> */}
      </div>
    </div>
  );
};

export default PersonalProfileSection;

import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useLocationSearchParam } from 'hooks/useLocation';
import { auth } from '../firebase';
import { NotificationsContext } from 'providers/contexts';
import {
  ChangePassword,
  ChangePasswordSuccess,
} from 'components/Authentication/ChangePassword/ChangePassword';
import { checkPasswordIfPwned } from 'services/Api';
import { Toast } from 'components';
import { PasswordRules } from 'constants/index';
import { hashString, verifyPwned, errorMessage } from 'utils/pwned';

const ResetPassword = () => {
  const oobCode = useLocationSearchParam('code');
  const project = useLocationSearchParam('project');

  const { setDisplayToast } = useContext(NotificationsContext);
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState('');
  const [successScreen, setSuccessScreen] = useState(false);

  useEffect(() => {
    const pageEffect = () => {
      auth
        .verifyPasswordResetCode(oobCode)
        .then(accountEmail => {
          setEmail(accountEmail);
          setLoading(false);
        })
        .catch(error => {
          setDisplayToast({ type: 'error', message: error.message });
        });
    };
    pageEffect();
  }, [oobCode]);

  const checkIfPwned = async value => {
    const hashedPassword = hashString(value);
    const checkPwn = await checkPasswordIfPwned({
      hash: hashedPassword.substring(0, 5),
    });
    const found = verifyPwned(checkPwn.data.data, hashedPassword);
    return found;
  };

  const confirmPasswordReset = async values => {
    const { password } = values;
    const isPassPwnd = await checkIfPwned(password);
    if (isPassPwnd) {
      setDisplayToast({
        type: 'error',
        message: errorMessage,
      });
      return;
    }
    auth
      .confirmPasswordReset(oobCode, password)
      .then(response => {
        setSuccessScreen(true);
      })
      .catch(error => {
        setDisplayToast({ type: 'error', message: error.message });
      });
  };

  const getLoginLink = useCallback(() => {
    console.log('project', project);
    if (project === 'mm-client-dash') {
      console.log(
        'process.env.REACT_APP_PUBLIC_URL_clientDas',
        process.env.REACT_APP_PUBLIC_URL_clientDash
      );
      return process.env.REACT_APP_PUBLIC_URL_clientDash;
    }
    return '/';
  }, [project]);

  return (
    <>
      <Toast />
      {!successScreen ? (
        <ChangePassword
          confirmPasswordField='Confirm password'
          passwordField='New password'
          submitBtnTxt='Submit'
          passwordHint={PasswordRules.reminderText}
          terms={true}
          onSubmit={confirmPasswordReset}
          loginLink={getLoginLink()}
        />
      ) : (
        <ChangePasswordSuccess loginLink={getLoginLink()} />
      )}
    </>
  );
};

export default ResetPassword;

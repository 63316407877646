import React, { Dispatch, SetStateAction } from 'react';
export interface FormHeaderProps {
  headingText: string;
  setUpdate?: Dispatch<SetStateAction<boolean>>;
  setCancel?: Dispatch<SetStateAction<boolean>>;
  update?: boolean;
  onSubmit?: () => void;
  permissionLevel?: string;
  memberProfile?: boolean;
}

const FormHeader: React.FC<FormHeaderProps> = ({
  setUpdate,
  setCancel,
  update,
  headingText,
  onSubmit,
  permissionLevel,
  memberProfile,
}) => {
  return (
    <div className='dashboard-forms__header'>
      <div className='dashboard-forms__headline'>
        <h2 className='product-upload__heading'>{headingText}</h2>
      </div>
      {!memberProfile && <div className='dashboard-forms__btns'>
        {update && (
          <button
            className='button-transparent-black button-smallest'
            onClick={() => {
              setUpdate(!update);
              // setCancel(true);
            }}
          >
            Cancel
          </button>
        )}
        <button
          className='button-transparent-black button-smallest'
          onClick={() => {
            !update ? setUpdate(!update) : onSubmit();
          }}
        >
          {update ? 'Update' : 'Edit'}
        </button>
      </div>}
    </div>
  );
};

export default FormHeader;

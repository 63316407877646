import React from 'react';
import LazyLoad from 'react-lazyload';
import './ImageGrid.scss';
import 'components/Dashboard/UploadMedia/UploadMedia.scss';
import { Asset } from 'components';

export interface IImageGrid {
  images: any;
  aspectRatio: string;
  saveImages?: boolean;
  onUploadHandler?: () => Promise<void>;
  upload?: boolean;
  fontColor?: string;
}

const ImageGrid: React.FC<IImageGrid> = props => {
  const { images, aspectRatio, saveImages, onUploadHandler, upload, fontColor } = props;
  const assets = images && Object.entries(images);
  return (
    <div className='image-grid'>
      <div className='container'>
        <div className='image-grid__wrap'>
          {assets &&
            assets.length &&
            assets.map((asset, idx) => (
              <LazyLoad height={200} offset={600} key={idx}>
                <div className='image-grid__block'>
                  <div className={`ratio-wrap ratio${aspectRatio}`}>
                    <Asset
                      overlay={true}
                      asset={asset}
                      saveImages={saveImages}
                      onUploadHandler={onUploadHandler}
                      upload={upload}
                    >
                      <div className='image-grid__overlay ratio-img'>
                        <div className='button-dashboard color-white-bd-bg' style={{ color: fontColor }}>
                          Select files
                        </div>
                      </div>
                    </Asset>
                  </div>
                </div>
              </LazyLoad>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ImageGrid;

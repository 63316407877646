import React, { useContext, useEffect, useState } from 'react';
import { navigate } from '@reach/router';
import useSWR from 'swr';
import ModelsList from 'components/Dashboard/Collections/CollectionsList/ModelsList';
import { CreateModelModal } from 'components/Common/Modal';
import Breadcrumbs from 'components/Common/Breadcrumbs/Breadcrumbs';
import { mmAPI, createUpdateLine } from 'services/Api';
import {
  UserContext,
  NotificationsContext,
  ViewModeContext,
  ModalsContext,
} from 'providers/contexts';
import { VIEWMODE } from 'providers/ViewModeProvider';
import { FrameUIActionsTypes, useFrameUI } from 'providers/FrameUIProvider';
import useSidebar from 'hooks/useSidebar';
import ModelAccessModal from 'components/Dashboard/Collections/CollectionAccessModal/ModelAccessModal';
import useTeam from 'hooks/useTeam';

const Lines = ({ brand_model_id }) => {
  const {
    user: { idToken, brand },
  } = useContext(UserContext);

  const { setDisplayToast } = useContext(NotificationsContext);
  const { viewMode, setViewMode, setViewsList } = useContext(ViewModeContext);

  const { onSidebarOpen } = useSidebar(idToken, { brand_model_id });

  const [open, setOpen] = useState(false);

  const pages = [
    { title: brand.name, link: `/brand/${brand.key}` },
    { title: 'Lines', link: `/brand/${brand.key}/lines` },
  ];

  const { data: lines } = useSWR(
    ['/api/line/query/brand/', idToken, brand_model_id],
    (url, idToken, brand) => {
      return mmAPI(url, idToken, { brand });
    },
    {
      suspense: true,
    }
  );

  useEffect(() => {
    const viewModeEffect = () => {
      setViewsList([VIEWMODE.LIST, VIEWMODE.CARD]);
      if (![VIEWMODE.LIST.key, VIEWMODE.CARD.key].includes(viewMode)) {
        setViewMode(VIEWMODE.LIST.key);
      }
    };
    viewModeEffect();
  }, []);

  const createModelActionHandler = async values => {
    const response = await createUpdateLine(
      idToken,
      {
        ...values,
        brand: brand_model_id,
      },
      'POST'
    );
    if (response.data?.error) {
      setDisplayToast({ type: 'error', message: response.data?.error?.message });
    } else {
      const { name, key } = response.data?.data;
      setDisplayToast({ type: 'success', message: `Line ${name} has been created` });
      navigate(`/brand/${brand_model_id}/line/${key}`);
    }
  };

  const popUpOptions = [
    {
      selectItem: 'Create New Line',
      ModalComponent: (
        <CreateModelModal
          formId='createLine'
          modelName='Line'
          modelInputLabel='Line Name'
          open={open}
          setOpen={setOpen}
          btnActionHandler={createModelActionHandler}
        />
      ),
    },
  ];

  const { dispatch } = useFrameUI();

  useEffect(() => {
    const filterBarEffect = () => {
      dispatch({ type: FrameUIActionsTypes.FILTER_BAR_VISIBLE, payload: true });
      dispatch({
        type: FrameUIActionsTypes.FILTER_BAR_OPTIONS,
        payload: [...popUpOptions],
      });
    };

    filterBarEffect();

    return () => {
      dispatch({ type: FrameUIActionsTypes.FILTER_BAR_RESET });
    };
  }, []);

  const { setAppModal, setAppModalProps, setOpenAppModal, openAppModal } = useContext(
    ModalsContext
  );

  const { teamAccountsState, teamInvitesState, setTeamModelAssoc } = useTeam(idToken, {
    brand_model_id,
    matchMultiTeams: true,
  });

  useEffect(() => {
    const teamUsersStateEffect = (teamAccountsState, teamInvitesState) => {
      if (teamAccountsState && teamInvitesState) {
        setAppModalProps({
          open: openAppModal,
          setOpen: setOpenAppModal,
          allMembers: teamAccountsState,
          allInvites: teamInvitesState,
        });
      }
    };
    teamUsersStateEffect(teamAccountsState, teamInvitesState);
  }, [teamAccountsState, teamInvitesState]);

  const triggerModelAccessModal = (model, jobType) => {
    setTeamModelAssoc({ model, jobType });
    const modal = (
      <ModelAccessModal
        heading={`${model?.name} Line Access`}
        open={openAppModal}
        setOpen={setOpenAppModal}
        allMembers={teamAccountsState}
        allInvites={teamInvitesState}
        model={model}
        jobType={jobType}
      />
    );
    setAppModal(modal);
    setAppModalProps(prev => ({
      ...prev,
      model,
      jobType,
    }));

    setOpenAppModal(true);
  };

  const lineContextOptions = [
    {
      selectItem: 'Info',
      onClick: model => onSidebarOpen(model, { jobType: 'editor' }),
    },
    {
      selectItem: 'Access',
      onClick: model => triggerModelAccessModal(model, 'editor'),
    },
    {
      selectItem: 'View Collections',
      onClick: model =>
        navigate(`/brand/${brand_model_id}/line/${model.key}/collections`),
    },
  ];

  return (
    <>
      <Breadcrumbs pages={pages} />
      {lines && (
        <ModelsList
          models={lines.map(l => ({
            ...l,
            route: `/brand/${brand_model_id}/products?lines=${l.key}`,
          }))}
          popUpOptions={lineContextOptions}
          modelTitle={'Lines'}
        />
      )}
    </>
  );
};

export default Lines;

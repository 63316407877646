import * as React from 'react';

export interface IRadioButton {
  label?: string;
  value?: string;
  name: string;
  register?: any;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (e) => void;
}

const RadioButton: React.FC<IRadioButton> = props => {
  const {
    label,
    value,
    name,
    onChange,
    register,
    checked = false,
    disabled = false,
  } = props;

  return (
    <div className='RadioButtons'>
      <label className='control control--radio mt-2'>
        {label}
        <input
          name={name}
          value={value}
          type='radio'
          defaultChecked={checked}
          checked={checked}
          disabled={disabled}
          ref={register && register({ required: true })}
          onChange={e => onChange?.(e)}
        />
        <div className='control__indicator'></div>
      </label>
    </div>
  );
};

export default RadioButton;

import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useLocationSearchParam } from 'hooks/useLocation';
import { navigate } from '@reach/router';
import { NotificationsContext } from 'providers/contexts';
import AuthNavigation from 'components/Authentication/AuthNavigation/AuthNavigation';
import { recoverEmail } from 'services/Api';

const RecoverEmail = () => {
  const oobCode = useLocationSearchParam('code');
  const project = useLocationSearchParam('project');
  const intent = useLocationSearchParam('intent');
  const { setDisplayToast } = useContext(NotificationsContext);
  const [invalidLink, setInvalidLink] = useState(false);
  const [emailRecovered, setEmailRecovered] = useState(null);

  const getLoginPathFromIntent = () => {
    if (intent === 'emailUpdateForMfa') {
      return '/profile';
    }
    if (intent === 'emailVerificationForMfa') {
      return '/authentication';
    }
    return '/';
  };

  const getLoginLink = useCallback(() => {
    if (project === 'mm-client-dash') {
      return process.env.REACT_APP_PUBLIC_URL_clientDash + getLoginPathFromIntent();
    }
    return '/';
  }, [project]);

  useEffect(async () => {
    if (oobCode) {
      const response = await recoverEmail({
        actionCode: oobCode 
      });

      if (response.data?.error) {
        setEmailRecovered(response.data?.error?.message);
        setDisplayToast({ type: 'error', message: response.data?.error?.message });
      } else if (response.data?.data == 'success') {
        setEmailRecovered('success');
        setDisplayToast({ type: 'success', message: 'Email Recovered' });
        if (project !== 'mm-client-dash') navigate('/');
      } else {
        setEmailRecovered('failed');
        setDisplayToast({ type: 'error', message: 'Unknown' });
      }
    } else {
      setInvalidLink(true);
    }
  }, [oobCode]);

  return (
    <>
      {invalidLink && (
        <div className='change-password'>
          <div className='container'>
            <div className='change-password__success-wrap'>
              <h3 className='change-password__success-txt pb-2'>Invalid Link!</h3>
            </div>
          </div>
        </div>
      )}
      {project === 'mm-client-dash' && <AuthNavigation loginLink={getLoginLink()} />}

      {project === 'mm-client-dash' && (
        <div className='change-password'>
          <div className='container'>
            <div className='change-password__success-wrap'>
              <h3 className='change-password__success-txt pb-2'>
                {emailRecovered === 'success' && (
                  <>
                    <span>Email verification successful. </span>

                    {['emailUpdateForMfa', 'emailVerificationForMfa'].includes(
                      intent
                    ) && (
                      <div>
                        <a href={getLoginLink()} className='login-form__link'>
                          Go back to Client Dashboard
                        </a>
                      </div>
                    )}
                  </>
                )}
                {emailRecovered && emailRecovered !== 'success' && (
                  <span>Email verification error: {emailRecovered}</span>
                )}
              </h3>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RecoverEmail;

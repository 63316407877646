import React from 'react';
import Dialog, { IDialog } from 'components/Common/Dialog/Dialog';

type ConfirmDialogProps = IDialog & {
  btnActionHandler?: (value: any) => void;
  data?: any;
  closeAction?: boolean;
};

export const ConfirmDialog: React.FC<ConfirmDialogProps> = props => {
  const { btnActionHandler, data, ...otherProps } = props;

  const onCloseDialog = result => {
    if (result) {
      btnActionHandler?.(data);
    }
  };
  return (
    <Dialog {...otherProps} onClose={onCloseDialog}>
      {props.children}
    </Dialog>
  );
};

import React, { useState, useEffect } from 'react';
import { Link } from '@reach/router';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import { useLocation } from '@reach/router';
import './FilePreview.scss';
import PopUp from 'components/Common/PopUp/PopUp';
import { IPopUpOptions } from 'models/Dashboard/IPopUpOptions';
import Checkboxes from 'components/Common/Checkboxes/Checkboxes';
import { ASSET_TYPES } from 'constants/assets';
import CloseIcon from 'assets/icons/close.svg';
import DotsIcon from 'assets/icons/dots-grey-horizontal.svg';

export interface IFilePreview {
  imageUrl?: string;
  name?: string;
  itemData?: any;
  popUpOptions?: IPopUpOptions[];
  checked?: boolean;
  onSelectFile?: (itemData?: any) => void;
}

const FilePreview: React.FC<IFilePreview> = props => {
  const { imageUrl, name, itemData, popUpOptions, checked, onSelectFile } = props;
  const [openPopUp, setOpenPopUp] = useState(false);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [iconFile, setIcon] = useState('');
  const [loadIcons, setLoadIcons] = useState(true);
  const location = useLocation();
  useEffect(() => {
    const extension = itemData.ext;
    if (loadIcons && !ASSET_TYPES.image.extensions.includes(extension)) {
      loadIcon(extension);
    }
    return () => setLoadIcons(false);
  }, [loadIcons, itemData]);

  useEffect(() => {
    if (location.search.replaceAll('%20', ' ').includes(name)) {
      setLightboxOpen(true);
    }
  }, [name, location.search]);

  const loadIcon = async (iconName: string) => {
    try {
      const importedIcon = await import(
        `assets/icons/${iconName.toLocaleUpperCase()}.svg`
      );
      setIcon(importedIcon.default);
    } catch (error) {
      const importedIcon = await import(`assets/icons/Icon-picture.svg`);
      setIcon(importedIcon.default);
    }
  };

  return (
    <div
      className={classNames('as-file-preview', {
        'as-file-preview-active': checked,
      })}
    >
      <Checkboxes checked={checked} onChange={e => onSelectFile(itemData)} />
      {iconFile ? (
        <div
          className='as-file-preview__thumbnail'
          onClick={() => setLightboxOpen(!lightboxOpen)}
        >
          <img src={iconFile || imageUrl} alt='Open Pop Up' />
        </div>
      ) : (
        <div
          className='as-file-preview__thumbnail'
          style={{ backgroundImage: 'url(' + imageUrl + ')' }}
          onClick={() => setLightboxOpen(!lightboxOpen)}
        />
      )}
      <div className='as-file-preview__description'>
        <span onClick={() => setLightboxOpen(!lightboxOpen)}>{name}</span>
        <div className='as-file-preview__dots'>
          <img
            src={DotsIcon}
            alt='Open Pop Up'
            onClick={() => setOpenPopUp(!openPopUp)}
          />
        </div>
        <PopUp open={openPopUp} setOpen={setOpenPopUp}>
          {popUpOptions &&
            popUpOptions.map(({ selectItem, to, onClick }) => (
              <li
                className='pop-up__li'
                onClick={() => {
                  setOpenPopUp(false);
                  if (onClick) {
                    onClick(itemData);
                  }
                }}
                key={selectItem}
              >
                {to ? <Link to={to}>{selectItem}</Link> : selectItem}
              </li>
            ))}
        </PopUp>
      </div>

      <CSSTransition classNames='lightbox' timeout={200} in={lightboxOpen} unmountOnExit>
        <div className='as-file-preview__lightbox'>
          <div className='as-file-preview__lightbox-header'>
            <img
              src={CloseIcon}
              alt='Close'
              onClick={() => setLightboxOpen(!lightboxOpen)}
            />
          </div>
          <div className='as-file-preview__lightbox-inner'>
            <img src={imageUrl} alt={name} className='as-file-preview__lightbox-img' />
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default FilePreview;

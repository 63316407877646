import React, { useRef } from 'react';
import useClickAway from 'hooks/useClickAway';
import { useEffect } from 'react';

export interface IPopUp {
  open: boolean;
  setOpen?: (open: boolean) => void;
  messageModal?: boolean;
}

const PopUp: React.FC<IPopUp> = props => {
  const { open, setOpen, messageModal } = props;

  const popUpRef = useRef();
  useClickAway(popUpRef, setOpen);

  return (
    <>
      {open && (
        <div className='pop-up__expand' ref={popUpRef} onClick={() => setOpen?.(!open)}>
          <div className='pop-up__ul'>{props.children}</div>
        </div>
      )}
    </>
  );
};

export default PopUp;

import React, { useEffect, useState, useContext, useMemo } from 'react';
import useSWR from 'swr';
import { mmAPI } from 'services/Api';
import { UserContext } from 'providers/contexts';
import useTeam from 'hooks/useTeam';
import SummarizeTeam from 'components/Dashboard/ManageTeam/SummarizeTeam';
import ManageTeam from 'components/Dashboard/ManageTeam/ManageTeam';

export const TeamContainer = ({
  team,
  model,
  lines,
  viewMode = 'SUMMARIZE',
  brand_model_id,
  triggerModelAccessModal,
}) => {
  const { user } = useContext(UserContext);
  const { idToken } = user;
  const { teamInvites, brandTeamsInvites } = useTeam(idToken, { brand_model_id });

  const { data: accounts } = useSWR(
    [
      `/api/account/query/${team ? 'team' : 'brand'}/`,
      idToken,
      team ? team.key : brand_model_id,
    ],
    (url, idToken, queryModel) => {
      return mmAPI(url, idToken, { [team ? 'team' : 'brand']: queryModel, cursor: 0 });
    },
    {
      suspense: true,
    }
  );

  const [membersFromAccounts, setMembersFromAccounts] = useState([]);
  const [membersFromInvites, setMembersFromInvites] = useState([]);
  const [membersTableItems, setMembersTableItems] = useState([]);
  useEffect(() => {
    setMembersTableItems([...membersFromAccounts, ...membersFromInvites]);
  }, [membersFromAccounts, membersFromInvites]);

  useEffect(() => {
    const accountsEffect = () => {
      if (!accounts) return;
      let accountsMapped = accounts
        .sort((a, b) => b.created - a.created)
        .map(account => ({
          ...account,
          accountType: account.job_type,
          activeAccount: 'active',
        }));
      const accountsFiltered = {};
      for (const account of accountsMapped) {
        if (!accountsFiltered[account.email]) {
          accountsFiltered[account.email] = account;
        }
      }
      accountsMapped = Object.values(accountsFiltered);
      setMembersFromAccounts(accountsMapped);
      setMembersFromInvites(prevMembers =>
        prevMembers.filter(item => !accountsMapped.find(m => m.email == item.email))
      );
    };
    accountsEffect();
  }, [accounts]);

  useEffect(() => {
    const invitesEffect = () => {
      let invitesMapped = [...(teamInvites ?? []), ...(brandTeamsInvites ?? [])]
        .filter(i => ['pending', 'invited'].includes(i.status))
        .filter(item => !membersFromAccounts.find(m => m.email == item.email))
        .map(invite => ({
          ...invite,
          name: `${invite.first_name || ''} ${invite.last_name || ''}`,
          accountType: invite.job_type,
          activeAccount: 'invited',
          inviteURL: `${process.env.REACT_APP_PUBLIC_URL}/invite/?token=${invite.token}`,
        }));
      const invitesFiltered = {};
      for (const invite of invitesMapped) {
        invitesFiltered[invite.email] = invite;
      }
      invitesMapped = Object.values(invitesFiltered);
      setMembersFromInvites(invitesMapped);
    };
    invitesEffect();
  }, [teamInvites, brandTeamsInvites]);

  const [teamModel, setTeamModel] = useState(null);
  const manageModelTeamContextOptions = useMemo(
    () => [
      {
        selectItem: 'Access',
        onClick: () => {
          console.log('teamModel', teamModel);
          triggerModelAccessModal(teamModel);
        },
      },
    ],
    [triggerModelAccessModal, teamModel]
  );

  useEffect(() => {
    if (team) {
      const modelGrant = team.grants.find(g => g.model === model);
      const teamModelMatch = lines.find(l => l.key === modelGrant.model_id);
      setTeamModel(teamModelMatch);
    } else {
      //assume this is a manageteam table for brand
      setTeamModel(user?.brand);
    }
  }, [team, lines]);

  return (
    <div>
      {viewMode === 'MANAGE' && <ManageTeam team={team} members={membersTableItems} />}
      {viewMode === 'SUMMARIZE' && (
        <SummarizeTeam
          team={team}
          model={team}
          members={membersTableItems}
          popUpOptions={manageModelTeamContextOptions}
          teamModel={teamModel}
        />
      )}
    </div>
  );
};

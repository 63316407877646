import React, { LegacyRef } from 'react';
import classNames from 'classnames';
import { FieldErrors } from 'react-hook-form/dist/types';

export interface TextInputProps {
  label: string;
  name: string;
  register?: ({ required, pattern, validate }) => LegacyRef<HTMLInputElement>;
  required?: boolean | string;
  placeholder?: string;
  errors?: FieldErrors;
  value?: string;
  disabled?: boolean;
  pattern?: string;
  onChange?: (arg0: string) => void;
  validate?: (arg0: string) => void;
  update?: boolean;
  fullWidth?: boolean;
}

const TextInput: React.FC<TextInputProps> = ({
  label,
  name,
  register,
  required,
  placeholder,
  errors = {},
  value = '',
  disabled = false,
  pattern,
  onChange,
  update = false,
  fullWidth = false,
  validate,
}) => {
  const checkErrors = () => {
    return Object.prototype.hasOwnProperty.call(errors, name);
  };

  return (
    <div className={`col-md-${fullWidth ? 12 : 6}`}>
      <div className='control-block'>
        <label
          className={classNames('control--label', { 'control--required': required })}
        >
          {label}
        </label>
        {update ? (
          <div>
            <input
              className='control control--text'
              type='text'
              defaultValue={value}
              placeholder={placeholder}
              name={name}
              ref={
                register
                  ? register({
                      required,
                      pattern,
                      validate,
                    })
                  : null
              }
            />
            {/* {checkErrors() && <span className='error-text'>This field is required</span>} */}
          </div>
        ) : (
          <span className='dashboard-forms__updated'>{value}</span>
        )}
      </div>
    </div>
  );
};

export default TextInput;

import React from 'react';
import 'components/VirtualRunway/VirtualRunwayHeader/VirtualRunwayHeader.scss';
import HistoryIcon from 'assets/icons/history.svg';
import UserIcon from 'assets/icons/user-grey.svg';
import { Link } from '@reach/router';
import BackIcon from 'assets/icons/exit.svg';

export interface IVirtualRunwayHeader {
  heading: {
    label: string;
    link?: string;
  };
  buttonActionHandler?: (arg?) => void;
  brand_model_id?: string;
  style_model_id?: string;
  getProductLevelHistory?: any;
}

const AtelierHeader: React.FC<IVirtualRunwayHeader> = props => {
  const {
    buttonActionHandler,
    heading: { label, link },
    getProductLevelHistory,
  } = props;

  return (
    <div className='virtual-runway-header'>
      <div className='atelier__wrap'>
        <div className='virtual-runway-header__wrap'>
          <div className='virtual-runway-header__left-aligned'>
            <Link to={link}>
              <img
                className='style-sample-review-header__back'
                src={BackIcon}
                alt='Back'
              />
            </Link>
            <div className='virtual-runway-header__heading'>
              <span className='style-sample-review-header__title uppercase'>
                Product Assets
              </span>
              {link ? <Link to={link}>{label}</Link> : label}
            </div>
          </div>
          <div className='virtual-runway-header__right-aligned'>
            <img
              className='virtual-runway-header__history'
              src={HistoryIcon}
              alt='history'
              onClick={() => getProductLevelHistory()}
            />
            <button className='virtual-runway-header__user-icon'>
              <img src={UserIcon} alt='user' />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AtelierHeader;

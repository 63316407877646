import React from 'react';
import ReactPaginate from 'react-paginate';
import { PER_PAGES } from 'providers/PaginationProvider';
import './Pagination.scss';

export interface IPagination {
  pageCount: number;
  curPage: number;
  onGotoPage?: (page: number) => void;
  perPage: number;
  curIndex: number;
  curCount: number;
  totalCount: number;
  onChangePerPage?: (per_page: number) => void;
}

const Pagination: React.FC<IPagination> = props => {
  const {
    pageCount,
    curPage,
    onGotoPage,
    perPage,
    onChangePerPage,
    curIndex,
    curCount,
    totalCount,
  } = props;

  return (
    <div className='pagination'>
      <div className='pagination__detail'>
        <select
          value={perPage}
          onChange={event => onChangePerPage(parseInt(event.target.value))}
          className='select-basic'
        >
          {PER_PAGES.map(per_page => (
            <option value={per_page.value} key={per_page.value}>
              {per_page.label}
            </option>
          ))}
        </select>
        <div className='pagination__detail-text'>
          Showing {curIndex + 1} to {curIndex + curCount} of {totalCount} items.
        </div>
      </div>
      <div className='pagination__controller'>
        <ReactPaginate
          previousLabel={''}
          nextLabel={''}
          previousLinkClassName={'pagination__controller-prev'}
          nextLinkClassName={'pagination__controller-next'}
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          pageCount={pageCount}
          forcePage={curPage}
          containerClassName={'pagination__ul'}
          onPageChange={page => onGotoPage(page.selected)}
        />
      </div>
    </div>
  );
};

export default Pagination;

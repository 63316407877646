import React from 'react';
import './ControlsBar.scss';

import RedoIcon from 'assets/icons/Icon-plus.svg';
import UndoIcon from 'assets/icons/Icon-redo.svg';
import PencilIcon from 'assets/icons/pencil.svg';

export interface IControlsBar {
  submitChanges?: (boolean) => any;
  dispatch?: any;
  state: any;
  setCancelChanges?: (boolean) => any;
}

const ControlsBar: React.FC<IControlsBar> = ({ submitChanges, dispatch, state, setCancelChanges }) => {
  return (
    <div className='controls-bar'>
      <div className='container'>
        <div className='controls-bar__btns'>
          <div className='controls-bar__btns-group'>
            {state.customize ? (
              <>
                <div className='controls-bar__item'>
                  <button
                    onClick={() => {
                      dispatch && dispatch({ type: 'setCustomize', payload: false });
                      setCancelChanges(true)
                    }}
                    className='button-transparent-black controls-bar__button'
                  >
                    Cancel
                  </button>
                </div>
                <div className='controls-bar__item'>
                  <button
                    className='button-primary-v2 controls-bar__button'
                    onClick={() => {
                      dispatch && dispatch({ type: 'setCustomize', payload: false });
                      submitChanges && submitChanges(true);
                    }}
                  >
                    Save
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className='controls-bar__item'>
                  <button
                    onClick={() =>
                      dispatch && dispatch({ type: 'setCustomize', payload: true })
                    }
                    className='button-transparent-black controls-bar__button'
                  >
                    Customize
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ControlsBar;

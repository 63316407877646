import React, { LegacyRef, useState } from 'react';
import classNames from 'classnames';
import UploadIcon from 'assets/icons/Icon-upload.svg';
import { FieldErrors, Control } from 'react-hook-form/dist/types';

export interface ProfileImageInputProps {
  update?: boolean;
  value?: string;
  label?: string;
  required?: boolean;
  name: string;
  errors?: FieldErrors;
  handleUploadButtonClicked?: (e) => void;
  register?: ({ required }) => LegacyRef<HTMLInputElement>;
  src?: string;
  fullName?: string;
  cancelUpdate?: boolean;
  validate?: boolean;
  logo?: boolean;
}

const ProfileImageInput: React.FC<ProfileImageInputProps> = ({
  label,
  update,
  required,
  handleUploadButtonClicked,
  register,
  errors,
  name,
  src,
  fullName,
  cancelUpdate,
  validate = false,
  logo = false,
}) => {
  const checkErrors = () => {
    return errors && Object.prototype.hasOwnProperty.call(errors, name);
  };
  const currentLabel = (
    <label className={classNames('control--label', { 'control--required': required })}>
      {label}
    </label>
  );

  const initials =
    fullName && fullName?.split(' ')[0].charAt(0) + fullName?.split(' ')[1].charAt(0);

  return (
    <div>
      {update ? (
        <div className='dashboard-forms__block'>
          {label && currentLabel}

          {src ? (
            <div
              className={!logo ? 'profile-img' : 'logo-img'}
              style={{
                backgroundImage: 'url(' + src + ')',
              }}
            ></div>
          ) : (
            <div className={!logo ? 'profile-img' : 'logo-img'}>{initials}</div>
          )}

          <div className='image-upload'>
            <label htmlFor={name} className='button-dashboard color-white-bd-bg'>
              <img className='btn-upload-img' src={UploadIcon} alt='Upload' />
              Upload
            </label>
            <input
              id={name}
              type='file'
              onChange={handleUploadButtonClicked}
              onClick={event => ((event.target as HTMLInputElement).value = null)}
              ref={register && register({ required })}
              name={name}
            />
          </div>
          {checkErrors() && <span className='error-text'>This field is required</span>}
        </div>
      ) : (
        <div className='control-block'>
          {currentLabel}
          {src ? (
            <div
              className={!logo ? 'profile-img' : 'logo-img'}
              style={{
                backgroundImage: 'url(' + src + ')',
              }}
            ></div>
          ) : (
            <div className={!logo ? 'profile-img' : 'logo-img'}>{initials}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default ProfileImageInput;

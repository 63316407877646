import React from 'react';
import { useForm } from 'react-hook-form';
import Modal from './Modal';

export type CreateModelModalProps = {
  modelName: string;
  modelInputLabel: string;
  modalHeading?: string;
  btnActionLabel?: string;
  btnCloseLabel?: string;
  btnActionHandler?: (values) => void;
  formId?: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  closeOnSubmit?: boolean;
  data?: any;
  closeOnClickAway?: boolean;
};
//
export const CreateModelModal: React.FC<CreateModelModalProps> = ({
  formId,
  modelName,
  modelInputLabel,
  modalHeading = 'Add New',
  btnActionLabel = 'save',
  btnCloseLabel = 'cancel',
  btnActionHandler,
  open,
  setOpen,
  closeOnSubmit = false,
  data,
  closeOnClickAway = true,
}) => {
  const { handleSubmit, register } = useForm();
  const onSubmit = values => {
    btnActionHandler?.({
      ...values,
      data,
    });
    closeOnSubmit && setOpen(false);
  };
  return (
    <Modal
      modalHeading={`${modalHeading} ${modelName}`}
      modalBtnClose={btnCloseLabel}
      modalBtnActionLabel={btnActionLabel}
      backgroundColor='#fff'
      modalOpen={open}
      setModalOpen={setOpen}
      formHandlerId={formId}
      closeOnClickAway={closeOnClickAway}
    >
      <form id={formId} onSubmit={handleSubmit(onSubmit)}>
        <div className='control-block'>
          <label className='control--label control--required'>{modelInputLabel}</label>
          <input
            ref={register}
            name={`name`}
            required
            className='control control--text'
            type='text'
          />
        </div>
      </form>
    </Modal>
  );
};

import React, { useEffect, useState, useContext, useCallback } from 'react';
import useSWR from 'swr';

import { FrameUIActionsTypes, useFrameUI } from 'providers/FrameUIProvider';
import { NotificationsContext } from 'providers/NotificationsProvider';
import { firebaseStorageUrl, UploadContext } from 'providers/UploadProvider';
import { UserContext } from 'providers/UserProvider';
import useAccounts from './useAccounts';
import { mmAPI, postMessageModel } from 'services/Api';
import { extToAssetType } from 'constants/assets';
import { useLocationIsSettings } from './useLocation';

type useMessagesModelParams = {
  brand_model_id?: number;
  style_model_id?: number;
  model: MessageModelAssoc;
  onClickMessageAsset?: (arg?) => void;
  open?: boolean;
  reset?: boolean;
  messageFetchInterval?: number;
};

type useMessagesReturn = {
  messages: any;
  mutateMessages: () => void;
  setMessageModelAssoc: (arg?) => void;
  onMessagesOpen: (model?) => void;
  postMessage: (message) => Promise<any>;
};

export type MessageModelAssoc = {
  key: number;
  KIND: string;
  name: string;
};

const useMessages = (
  idToken: string,
  {
    model,
    onClickMessageAsset,
    open,
    brand_model_id,
    messageFetchInterval = 0,
  }: useMessagesModelParams
): useMessagesReturn => {
  const { state, dispatch } = useFrameUI();
  const { setDisplayToast } = useContext(NotificationsContext);

  const { user } = useContext(UserContext);

  const isSettings = useLocationIsSettings();
  const accountsBrand = brand_model_id || (isSettings && user?.brand?.key);
  const { getContactMatch, getContactMetadata } = useAccounts(idToken, {
    brand: accountsBrand,
  });

  const setMessageModelAssoc = messageModelAssoc => {
    dispatch({
      type: FrameUIActionsTypes.CHATBAR_LOADING,
      payload: true,
    });
    const contactMatch = getContactMatch(messageModelAssoc);
    const chatTypeProps = {
      type: null,
      title: null,
    };
    if (contactMatch) {
      chatTypeProps.type = 'direct';
      chatTypeProps.title = getContactMetadata(contactMatch?.model_id)?.first;
    } else if (messageModelAssoc) {
      chatTypeProps.type = 'model';
      chatTypeProps.title = messageModelAssoc?.name;
    }
    dispatch({
      type: FrameUIActionsTypes.CHATBAR_PROPS,
      payload: {
        ...state.chatbar.props,
        ...chatTypeProps,
        model: messageModelAssoc,
      },
    });
  };

  useEffect(() => {
    if (model) {
      setMessageModelAssoc(model);
    }
  }, [model]);

  useEffect(() => {
    dispatch({
      type: FrameUIActionsTypes.CHATBAR_PROPS,
      payload: {
        ...state.chatbar.props,
        onClickMessageAsset,
      },
    });
  }, [onClickMessageAsset]);

  const messageFetchIntervalRef = React.useRef(messageFetchInterval);
  const [messageFetchIntervalCount, setMessageFetchIntervalCount] = useState(0);

  const messageFetcher = useCallback((idToken, model, model_id) => {
    // console.log('model, model_id', model, model_id);
    // console.log('messageFetchInterval', messageFetchIntervalRef.current);
    if (model === 'Style') {
      return mmAPI('/api/message/query/style', idToken, { style: model_id });
    }
    return mmAPI('/api/message/query/object', idToken, { model, model_id });
  }, []);

  const { data: messages, mutate: mutateMessages } = useSWR(
    state.chatbar?.props?.model?.key
      ? [idToken, state.chatbar.props.model.KIND, state.chatbar.props.model.key]
      : state.chatbar?.props?.model?.teamId
      ? [idToken, 'Team', state.chatbar.props.model.teamId]
      : null,
    messageFetcher,
    {
      suspense: false,
      refreshInterval: messageFetchIntervalRef.current,
      onSuccess: () => {
        setMessageFetchIntervalCount(prev => {
          prev = prev + 1;
          return prev;
        });
      },
    }
  );

  useEffect(() => {
    const messagesEffect = async messagesVal => {
      if (messagesVal) {
        const getMessageMetadata = async m => {
          const {
            text: lastMessage,
            sender: firstName,
            sender_id,
            modified: timestamp,
            assets,
            key,
            attachment_type,
            attachment_name,
          } = m;
          const profilePicture = {
            path: getContactMetadata(sender_id)?.assets?.profile_picture?.path,
            metadata: getContactMetadata(sender_id)?.assets?.profile_picture,
            url: null,
          };
          if (profilePicture?.metadata?.progress > 0)
            try {
              profilePicture.url = await firebaseStorageUrl(profilePicture?.path);
            } catch (err) {
              console.log(err);
            }
          const filePath = assets?.[attachment_type]?.path;
          const assetMetadata =
            assets?.[attachment_type]?.progress > 0
              ? await firebaseStorageMetadata(filePath)
              : null;
          const assetUrl =
            assets?.[attachment_type]?.progress > 0
              ? await firebaseStorageUrl(filePath)
              : null;
          return {
            profilePictureUrl: profilePicture.url,
            timestamp,
            lastMessage,
            firstName,
            assets,
            attachment_type,
            attachment_name,
            assetMetadata,
            assetUrl,
            key,
            senderKey: sender_id,
          };
        };

        const getMessages = messages => {
          return Promise.all(messages.map(m => getMessageMetadata(m)));
        };

        const messages = await getMessages(messagesVal);
        dispatch({
          type: FrameUIActionsTypes.CHATBAR_MESSAGES,
          payload: messages,
        });
      }
      dispatch({ type: FrameUIActionsTypes.CHATBAR_LOADING, payload: false });
    };
    messagesEffect(messages);
  }, [messages]);

  const manageRefreshInterval = React.useCallback(
    messageFetchIntervalCountVal => {
      // console.log('eval messageFetchInterval', messageFetchIntervalRef.current);
      if (messageFetchIntervalRef.current === 300) {
        if (
          messages
            ?.filter(
              m =>
                m.attachment_type &&
                [-1, 1].includes(m?.assets[m.attachment_type]?.progress)
            )
            .every(m => m?.assetUrl)
        ) {
          messageFetchIntervalRef.current = 0;
          setMessageFetchIntervalCount(0);
          // console.log(
          //   'reset setMessageFetchIntervalCount with assetUrls, stop fetch after count',
          //   messageFetchIntervalCountVal
          // );
        } else {
          if (messageFetchIntervalCountVal >= 9) {
            console.log('reset after 9 messageFetchIntervalCount');
            messageFetchIntervalRef.current = 0;
            setMessageFetchIntervalCount(0);
          }
        }
        // console.log(
        //   'setMessageFetchIntervalCount one plus',
        //   messageFetchIntervalCountVal
        // );
      }

      if (messageFetchIntervalCountVal >= 9) {
        console.log('reset after 9 messageFetchIntervalCount');
        messageFetchIntervalRef.current = 0;
        setMessageFetchIntervalCount(0);
      }
    },
    [messages]
  );

  useEffect(() => {
    // console.log('======= effect manageRefreshInterval');
    manageRefreshInterval(messageFetchIntervalCount);
  }, [messageFetchIntervalCount, manageRefreshInterval]);

  const { handleFireBaseUpload, firebaseStorageMetadata } = useContext(UploadContext);

  useEffect(() => {
    const openEffect = openVal => {
      if (openVal) {
        dispatch({ type: FrameUIActionsTypes.CHATBAR_OPEN, payload: true });
      }
    };
    openEffect(open);
  }, [open]);

  const onMessagesOpen = model => {
    if (model) {
      setMessageModelAssoc(model);
    }
    // dispatch({ type: FrameUIActionsTypes.CHATBAR_LOADING, payload: true });
    dispatch({ type: FrameUIActionsTypes.CHATBAR_OPEN, payload: true });
  };

  const firebaseUploadCallback = useCallback(() => {
    messageFetchIntervalRef.current = 200;
    mutateMessages();
  }, [mutateMessages]);

  const postMessage = useCallback(
    async values => {
      setDisplayToast(null);
      dispatch({
        type: FrameUIActionsTypes.CHATBAR_LOADING,
        payload: true,
      });
      if (!state.chatbar?.props?.model?.key && !state.chatbar?.props?.model?.teamId) {
        return;
      }
      const {
        message: text,
        file,
        fileMetadata,
        objectAsDataUrl = false,
        mention,
        mentionedPersons,
      } = values;

      const model_id = state.chatbar.props.model.key || state.chatbar.props.model.teamId;
      const model = state.chatbar.props.model.KIND || 'Team';

      const extPopped = file?.name?.split('.')?.pop();
      const attachment_type =
        extToAssetType(extPopped?.toLowerCase()) || fileMetadata?.type;
      const attachment_name = attachment_type ? file?.name : null;

      try {
        const { data: messageResponse } = await postMessageModel(idToken, {
          model,
          model_id,
          text,
          attachment_type,
          attachment_name,
          mention,
          mentioned_person: mentionedPersons,
        });
        return new Promise((resolve, reject) => {
          if (messageResponse?.error) {
            setDisplayToast({
              type: 'error',
              persist: false,
              message: messageResponse.error.message,
            });
            dispatch({
              type: FrameUIActionsTypes.CHATBAR_LOADING,
              payload: false,
            });
          } else {
            const messageModel = messageResponse.data;
            if (file && attachment_type) {
              const filePath = messageModel.assets[attachment_type]?.path;
              console.log(
                'messageModel?.assets[attachment_type]',
                messageModel?.assets[attachment_type]
              );
              handleFireBaseUpload(
                filePath,
                file,
                {
                  ...fileMetadata,
                  ...messageModel?.assets[attachment_type],
                  model: 'Message',
                  model_id: messageModel.key,
                },
                [attachment_type],
                firebaseUploadCallback,
                objectAsDataUrl
              );
            }
            setMessageFetchIntervalCount(0);
            mutateMessages();
            resolve(messages);
          }
        });
      } catch (error) {
        dispatch({
          type: FrameUIActionsTypes.CHATBAR_LOADING,
          payload: false,
          persist: false,
        });
        return Promise.reject(error);
      }
    },
    [state, state.chatbar?.props]
  );

  return {
    messages,
    mutateMessages,
    setMessageModelAssoc,
    onMessagesOpen,
    postMessage,
  };
};

export default useMessages;

import React, { useState, useRef, useEffect, useCallback } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import PrevIcon from 'assets/icons/arrow-prev.svg';
import classNames from 'classnames';
import AtelierModel3DViewer from '../Uploads/AtelierModelUploads/AtelierModel3DViewer';

export interface SlickRailMode {
  media: any[];
  onClickSampleAsset?: any;
  slideIndex?: any;
}

export const SlickRailMode: React.FC<SlickRailMode> = props => {
  const { media, slideIndex = 0, onClickSampleAsset } = props;
  const [currentImage, setCurrentImage] = useState(slideIndex);
  const [sliderHeight, setSliderHeight] = useState(0);
  const ref = useRef(null);
  const [height, setHeight] = useState(null);

  useEffect(() => {
    setCurrentImage(slideIndex);
  }, [slideIndex]);

  const slideImage = useCallback(node => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height);
    }
  }, []);

  if (!media || typeof media === 'undefined') return;
  return (
    <div className=''>
      <div className='afs-asset-review__compare-block'>
        <div className='product-listing-dashboard__thumbnail'>
          <div className='product-listing-dashboard__right-rail product-listing-dashboard__flex-end'>
            {media?.length > 0 &&
              media?.map(({ key, type, assetUrl, reference_model_id, name }, idx) => (
                <div className='product-listing-dashboard__tooltip' key={key}>
                  {!['gltf', 'glb', 'model'].includes(type) ? (
                    <img
                      className={classNames(
                        `SlickRailMode${type}`,

                        {
                          'product-listing-dashboard__small-img-va product-listing-dashboard__current-img':
                            currentImage === idx,
                          'product-listing-dashboard__small-img-va': currentImage !== idx,
                        }
                      )}
                      src={assetUrl}
                      onClick={() => {
                        setCurrentImage(idx);
                        onClickSampleAsset?.(reference_model_id);
                      }}
                    />
                  ) : (
                    <div
                      className={classNames(
                        `SlickRailMode${type}`,

                        {
                          'product-listing-dashboard__small-img-va product-listing-dashboard__current-img':
                            currentImage === idx,
                          'product-listing-dashboard__small-img-va': currentImage !== idx,
                        }
                      )}
                      onClick={() => {
                        setCurrentImage(idx);
                        onClickSampleAsset?.(reference_model_id);
                      }}
                    >
                      <AtelierModel3DViewer url={assetUrl} />
                    </div>
                  )}
                  <span>{name}</span>
                </div>
              ))}
          </div>
        </div>
        <div className='product-listing-dashboard__slider-main'>
          <div
            ref={slideImage}
            className='product-listing-dashboard__slider'
            style={{ height: height + 'px' }}
          >
            <TransitionGroup component={null}>
              <CSSTransition classNames='fade-in' timeout={400} key={currentImage}>
                <>
                  <div className='product-listing-dashboard__tile'>
                    <div className='ratio3-4 ratio-wrap'>
                      <div>
                        {!['gltf', 'glb', 'model'].includes(
                          media?.[currentImage]?.type
                        ) ? (
                          <img
                            className='ratio-img ratio-img__contain'
                            src={media?.[currentImage]?.assetUrl || media?.[0].assetUrl}
                          />
                        ) : (
                          <div>
                            <AtelierModel3DViewer url={media?.[currentImage]?.assetUrl} />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              </CSSTransition>
            </TransitionGroup>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlickRailMode;

import React, { useContext } from 'react';
import { NotificationsContext } from 'providers/contexts';
import Alert from 'components/Common/Alert/Alert';
import './Toast.scss';

const Toast = () => {
  const { displayToast, setDisplayToast } = useContext(NotificationsContext);

  return (
    <>
      {displayToast && (
        <div className='toast-container'>
          <Alert
            alertText={displayToast.message}
            alertType={displayToast.type}
            persist={displayToast.persist}
            closeAlert={() => setDisplayToast(false)}
          />
        </div>
      )}
    </>
  );
};

export default Toast;

import React, { createContext, useState, useContext } from 'react';
import { storage, storageUnscan } from '../firebase';
import { NotificationsContext } from 'providers/contexts';
import { ActivityContext } from 'components/VirtualAtelier/Uploads/AtelierUploads';
import { UserContext } from 'providers/UserProvider';
import API from 'services/Api';
import LogRocket from 'logrocket';

export const UploadContext = createContext({
  handleFireBaseUpload: (
    path,
    imageAsFile,
    metadata,
    type,
    onUploadHandler,
    objectAsDataUrl
  ) => null,
  firebaseStorageMetadata: path => null,
  firebaseStorageUrl: path => null,
  imageUrl: null,
  setImageUrl: null,
  imageUploadProgress: null,
  handleFireBaseUploadFile: (path, file, onUploadHandler, displayToast, metadata) => null,
});

export const firebaseStorageUrl = path => storage.ref(path).getDownloadURL();

const UploadProvider = ({ children }) => {
  const { user } = useContext(UserContext);
  const { idToken } = user;
  const { setDisplayToast } = useContext(NotificationsContext);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageUploadProgress, setImageUploadProgress] = useState({});

  const firebaseStorageMetadata = path => storage.ref(path).getMetadata();
  const activityContext = useContext(ActivityContext);
  const handleFireBaseUpload = (
    path,
    imageAsFile,
    metadata,
    type,
    onUploadHandler = null,
    objectAsDataUrl = false
  ) => {
    try {
      if (!imageAsFile || imageAsFile === '') {
        console.error(`empty file, will not start upload`, imageAsFile);
        return;
      }
      console.log('handleFireBaseUpload upload path', path, metadata);
      const {
        key,
        original_name: name,
        path: assetPath,
        ext,
        model,
        model_id,
        proportion,
        batchAssetKeys,
      } = metadata;
      const putMetadata = {
        customMetadata: {
          key,
          name,
          path: assetPath,
          ext,
          model,
          model_id,
          proportion,
          batchAssetKeys: JSON.stringify(batchAssetKeys),
        },
      };
      console.log('putMetadata', putMetadata);
      const uploadTask = objectAsDataUrl
        ? storageUnscan.ref(path).putString(imageAsFile, 'data_url', putMetadata)
        : storageUnscan.ref(path).put(imageAsFile, putMetadata);
      //initiates the firebase side uploading
      uploadTask.on(
        'state_changed',
        snapShot => {
          //takes a snap shot of the process as it is happening
          // console.log('snapShot', snapShot);
          const progress = Math.round(
            (snapShot.bytesTransferred / snapShot.totalBytes) * 100
          );
          setImageUploadProgress(prev => ({
            ...prev,
            [snapShot.ref.fullPath]: progress,
          }));
        },
        uploadTaskError => {
          console.trace(uploadTaskError);
          LogRocket.captureException(uploadTaskError);
        },
        async () => {
          setDisplayToast({
            type: 'info',
            message: `${type} has been uploaded and is being scanned for security compliance.`,
          });
          // gets the functions from storage refences the image storage in firebase by the children
          // gets the download url then sets the image from firebase as the value for the imgUrl key:

          // progress=-1 indicates that the file is being scanned for malware
          const options = {
            url: `/api/asset/model/${metadata.key}?auth=${idToken}`,
            method: 'PUT',
            params: {
              progress: -1,
              asset_type: activityContext.assetType,
            },
          };
          const unscannedResponse = await API(options);
          console.log('unscanned response', unscannedResponse);
          if (onUploadHandler) {
            onUploadHandler();
          }
        }
      );
    } catch (error) {
      console.trace(error);
      LogRocket.captureException(error);
    }
  };

  const handleFireBaseUploadFile = (
    path,
    file,
    onUploadHandler = null,
    displayToast = false,
    metadata = null
  ) => {
    if (!file) {
      console.error(`empty file, will not start upload`, file);
      return;
    }
    console.log('handleFireBaseUploadFile upload path', path, metadata);
    const { key, original_name: name, path: assetPath, ext, model, model_id } = metadata;
    const putMetadata = {
      customMetadata: { key, name, path: assetPath, ext, model, model_id },
    };
    const uploadTask = storageUnscan.ref(path).put(file, putMetadata);
    //initiates the firebase side uploading
    uploadTask.on(
      'state_changed',
      snapShot => {
        //takes a snap shot of the process as it is happening
        // console.log('snapShot', snapShot);
        const progress = Math.round(
          (snapShot.bytesTransferred / snapShot.totalBytes) * 100
        );
        setImageUploadProgress(prev => ({
          ...prev,
          [snapShot.ref.fullPath]: progress,
        }));
      },
      err => {
        //catches the errors
        console.log('uploadErr');
        console.log(err);
      },
      async () => {
        // gets the functions from storage refences the image storage in firebase by the children
        // gets the download url then sets the image from firebase as the value for the imgUrl key:
        const downloadUrl = await storage.ref(path).getDownloadURL();
        console.log('uploadErr path', downloadUrl);
        console.log('uploadErr file', path);

        if (displayToast) {
          setDisplayToast({
            type: 'success',
            message: `${file.name} has been uploaded to ${path}`,
          });
        }
        if (onUploadHandler) {
          onUploadHandler(downloadUrl);
        }
      }
    );
  };

  return (
    <UploadContext.Provider
      value={{
        handleFireBaseUpload,
        firebaseStorageUrl,
        firebaseStorageMetadata,
        imageUrl,
        setImageUrl,
        imageUploadProgress,
        handleFireBaseUploadFile,
      }}
    >
      {children}
    </UploadContext.Provider>
  );
};

export default UploadProvider;

export const FOLDER = 'folder';
export const FILE = 'file';

export const ASSETS_LIBRARY = {
  assets_library: {
    base_url: 'assets_library',
    folders: [
      'Blocks',
      'Carry Forward',
      'Tech Packs',
      'Materials',
      'Graphics',
      'Prints',
      'Branding',
    ],
    apis: {
      get_folder: 'get_library_folder',
      create_folder: 'create_library_folder',
      update_folder: 'update_library_folder',
      update_file: 'update_library_file',
      delete_folder: 'delete_library_folder',
      delete_file: 'delete_library_file',
      archive_folder: 'archive_library_folder',
      archive_file: 'archive_library_file',
      download_files: 'download_library_files',
    },
    assets_storage_path: 'assets/bucket/',
  },
  product_asset: {
    base_url: 'product_asset',
    folders: [
      'Techpack',
      'Patterns',
      'Materials',
      'Graphics',
      'References',
      'Final',
      'Sandbox',
    ],
    apis: {
      get_folder: 'get_product_folder',
      create_folder: 'create_product_folder',
      update_folder: 'update_product_folder',
      update_file: 'update_product_file',
      delete_folder: 'delete_product_folder',
      delete_file: 'delete_product_file',
      archive_folder: 'archive_product_folder',
      archive_file: 'archive_product_file',
      download_files: 'download_product_files',
    },
    assets_storage_path: 'product/assets/bucket/',
  },
  archive_library: {
    base_url: 'archive_library',
    folders: [],
    apis: {
      get_folder: 'get_archive_folder',
      create_folder: 'create_archive_folder',
      update_folder: 'update_archive_folder',
      update_file: 'update_archive_file',
      delete_folder: 'delete_archive_folder',
      delete_file: 'delete_archive_file',
    },
    assets_storage_path: 'archive/assets/bucket/',
  },
};

export const parseFolderPath = (folders, path) => {
  if (path.length === 0) {
    return;
  }
  const parentFolder = path.shift();
  let folder = folders.find(f => parentFolder.localeCompare(f.name) === 0);
  if (!folder) {
    folder = {
      name: parentFolder,
      subFolders: [],
    };
    folders.push(folder);
  } else if (!folder.subFolders) {
    folder.subFolders = [];
  }
  parseFolderPath(folder.subFolders, path);
};

export const parsePathFromArray = (path_array, encode = false) =>
  path_array.map(p => '/' + (encode ? encodeURIComponent(p) : p)).join('');

export const parseFolderBlobs = (folders, blobs) => {
  for (const blob of blobs) {
    const path = blob.path.split('/');
    path.shift();
    path.pop();
    parseFolderPath(folders, path);
  }
  return folders;
};

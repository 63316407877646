import classNames from 'classnames';

export type WorkflowStatusType = {
  value: string;
  color: {
    accent: string;
    bg: string;
  };
  job_type?: string;
};

export type WorkflowStatusEnum = {
  [key: string]: WorkflowStatusType;
};

export const WORKFLOW_STATUS: WorkflowStatusEnum = {
  TO_DO: {
    value: 'To Do',
    color: { accent: 'color-info-3', bg: 'color-info-3-bg' },
    job_type: 'vsm',
  },
  UPLOADED: {
    value: 'Asset Uploaded',
    color: { accent: 'color-info-3', bg: 'color-error-3-bg' },
    job_type: 'client',
  },
  IN_PROGRESS: {
    value: 'In Progress',
    color: { accent: 'color-info-3', bg: 'color-info-3-bg' },
    job_type: 'vsm',
  },
  FEEDBACK: {
    value: 'Feedback Required',
    color: { accent: 'color-info-3', bg: 'color-warning-3-bg' },
    job_type: 'vsm',
  },
  READY: {
    value: 'Ready for Review',
    color: { accent: 'color-info-3', bg: 'color-warning-3-bg' },
    job_type: 'vsm',
  },
  DECLINED: {
    value: 'Declined',
    color: { accent: 'color-info-3', bg: 'color-declined-bg' },
    job_type: 'client',
  },
  APPROVED: {
    value: 'Approved',
    color: { accent: 'color-info-3', bg: 'color-success-3-bg' },
    job_type: 'client',
  },
  FINAL: {
    value: 'Final',
    color: { accent: 'color-info-3', bg: 'color-success-2-bg' },
    job_type: 'vsm',
  },
};

export const classNamesFromStatus = (status: string): string => {
  const statusVals = Object.values(WORKFLOW_STATUS).find(s => s.value === status);
  return classNames('button-badge', statusVals?.color.bg);
};

import React, { useEffect, useState, useContext } from 'react';
import { navigate } from '@reach/router';

import { UserContext } from 'providers/UserProvider';
import 'components/Dashboard/SideNavigation/SideNavigation.scss';
import ProductMediaUpload from './ProductMediaUpload';
import ProductUpload from './ProductUpload';
import SideNavigation from 'components/Dashboard/SideNavigation/SideNavigation';
import ProductMediaManage from '../CustomizeSignOff/ProductMediaManage';
import { putMediaPositions } from 'services/Api';
import { NotificationsContext } from 'providers/contexts';
import { ModalsContext } from 'providers/ModalsProvider';

const tooltipTxt =
  'Your Product Data & Product Images are key elements of your portal. They are how you manage product catalogue. Please download template to upload product info';
const dragTxt = 'Drag and drop or select files';
const fileType = 'CSV';

const mediaFileType = 'JPEG, WEBP, PNG, JPG';

const popUpOptions = [{ selectItem: 'Search Images' }, { selectItem: 'Search Videos' }];

type Location = {
  search: string;
  pathname: string;
};

const BrandProductUpload: React.FC<{
  brand_model_id;
  location: Location;
}> = ({ brand_model_id, location }) => {
  const { user } = useContext(UserContext);
  const { idToken, brand } = user;
  const { displayToast, setDisplayToast } = useContext(NotificationsContext);

  const [step, setStep] = React.useState(null);
  const [stepSaveHandler, setStepSaveHandler] = React.useState(null);
  const [listingsPositions, setListingsPositions] = useState<
    Record<string, { keys: any[]; positions: number[] }>
  >();

  const { setDialogProps, openDialog, setOpenDialog } = useContext(ModalsContext);

  useEffect(() => {
    const locationEffect = pathname => {
      if (pathname.indexOf('/products/upload') > 0) setStep('/products/upload');
      else if (pathname.indexOf('/products/media/upload') > 0)
        setStep('/products/media/upload');
      else if (pathname.indexOf('/products/media/manage') > 0)
        setStep('/products/media/manage');
    };
    locationEffect(location.pathname);
  }, [location]);

  const steps = [
    {
      title: 'product upload',
      url: `/brand/${user.brand.key}/products/upload`,
      completed: false,
      inProgress: step === '/products/upload',
    },
    {
      title: 'product media upload',
      url: `/brand/${user.brand.key}/products/media/upload`,
      completed: false,
      inProgress: step === '/products/media/upload',
    },
    {
      title: 'product media management',
      url: `/brand/${user.brand.key}/products/media/manage`,
      completed: false,
      inProgress: step === '/products/media/manage',
    },
  ];
  const pages = [
    { title: brand.name, link: `/brand/${brand.key}` },
    { title: 'Products', link: `/brand/${brand.key}/products` },
    { title: 'Upload', link: `/brand/${brand.key}/products/upload` },
  ];
  const pagesMedia = [
    { title: brand.name, link: `/brand/${brand.key}` },
    { title: 'Products', link: `/brand/${brand.key}/products` },
    { title: 'Media', link: `/brand/${brand.key}/products/media` },
    { title: 'Upload', link: `/brand/${brand.key}/products/media/upload` },
  ];

  const stepSkipHandler = () => {
    const inProgressIndex = steps.findIndex(s => s.inProgress);
    if (inProgressIndex + 1 === steps.length) {
      setDialogProps({
        dialogBody:
          'Any unsaved data will be lost, are you sure you want to leave this page?',
        dialogBtnAction: 'Yes',
        dialogBtnClose: 'Cancel',
        btnActionHandler: () => {
          navigate(`/brand/${user.brand.key}/products`);
        },
      });
      setOpenDialog(true);
    }
    navigate(steps[inProgressIndex + 1]?.url);
  };

  const updateListingPositionOrder = async () => {
    console.log('listingsPosition', listingsPositions);
    for (const listingKey in listingsPositions) {
      const listing = listingsPositions[listingKey];
      const media = await putMediaPositions(
        idToken,
        listing.keys.map(m => m.key).join(','),
        listing.positions.join(',')
      );
      console.log('media', media);
      setDisplayToast({
        type: 'info',
        message: `Media positions updated`,
      });
      // #TODO trigger visual cue for updated positions in listing row rendered by ProductSlider
    }
  };

  return (
    <>
      {openDialog && <NavigateAwayOverlay />}
      <div className={`stepper-grid-template-columns`}>
        <SideNavigation
          steps={steps}
          buttonPrimaryHandler={
            step === '/products/media/manage'
              ? updateListingPositionOrder
              : stepSaveHandler
          }
          buttonSecondaryLabel={'Next'}
          buttonSecondaryHandler={stepSkipHandler}
        />
        {step === '/products/upload' && (
          <ProductUpload
            fileType={fileType}
            tooltipTxt={tooltipTxt}
            popUpOptions={popUpOptions}
            pages={pages}
            brandModelId={brand_model_id}
            stepSaveHandler={stepSaveHandler}
            setStepSaveHandler={setStepSaveHandler}
          />
        )}
        {step === '/products/media/upload' && (
          <ProductMediaUpload
            fileType={mediaFileType}
            dragTxt={dragTxt}
            tooltipTxt={`Upload images named according to product metadata: styleNumber-listingUniqueId-shot.extension`}
            popUpOptions={popUpOptions}
            pages={pagesMedia}
            brandModelId={brand_model_id}
          />
        )}
        {step === '/products/media/manage' && (
          <ProductMediaManage
            setListingsPosition={setListingsPositions}
            listingsPosition={listingsPositions}
          />
        )}
      </div>
    </>
  );
};

const NavigateAwayOverlay = () => {
  const { dialogProps, setOpenDialog } = useContext(ModalsContext);

  const closeHandler = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <div className='dialog'>
        <div className='dialog__content'>
          <div className='dialog__inner'>{dialogProps?.dialogBody}</div>
          <div className='dialog__footer'>
            <button className='button-transparent-black' onClick={closeHandler}>
              {dialogProps.dialogBtnClose}
            </button>
            <button
              className='button-regular color-warning-bg'
              onClick={dialogProps?.btnActionHandler}
            >
              {dialogProps?.dialogBtnAction}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BrandProductUpload;

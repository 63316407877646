import React, {
  useState,
  useContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { Link } from '@reach/router';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import useSWR from 'swr';
import LogRocket from 'logrocket';
import moment from 'moment';

import 'components/Dashboard/UploadSketches/AdditionalReference.scss';
import 'components/Dashboard/ProductUpload/ProductUpload.scss';

import AtelierAdditionsPreview from './AtelierModelUploadsPreview';
import AtelierModelUploadsListView from './AtelierModelUploadsListView';
import SaveToAssetLibraryModal from 'components/Dashboard/AssetLibrary/SaveToAssetLibraryModal/SaveToAssetLibraryModal';
import Tooltip from 'components/Common/Tooltip/Tooltip';
import { AssetMetadataProps } from 'components/Asset';
import Switch from 'components/Common/Switch/Switch';
import DashboardNav from 'components/Dashboard/DashboardNav/DashboardNav';

import { CreateModelModal } from 'components/Common/Modal';
import AssetLibraryFilesUpload from 'components/Dashboard/AssetLibrary/AssetLibraryUpload/AssetLibraryFilesUpload';
import { FOLDER, ASSETS_LIBRARY } from 'components/Dashboard/AssetLibrary/util';

import Breadcrumbs, { IPages } from 'components/Common/Breadcrumbs/Breadcrumbs';
import PopUp from 'components/Common/PopUp/PopUp';
import { IPopUpOptions } from 'models/Dashboard/IPopUpOptions';
import {
  mmAPI,
  postModel,
  postModels,
  deleteModel,
  postActivityMethod,
  putModel,
  getNotificationsByScope,
} from 'services/Api';
import { UserContext } from 'providers/UserProvider';
import { UploadContext } from 'providers/UploadProvider';
import { NotificationsContext } from 'providers/NotificationsProvider';
import { ModalsContext } from 'providers/ModalsProvider';
import { ASSET_TYPES, getAssetTypeFromFileType } from 'constants/assets';
import HistoryIcon from 'assets/icons/history.svg';
import ListIconBlack from 'assets/icons/Icon-list-black.svg';
import SlideIcon from 'assets/icons/Icon-slide.svg';
import PlusIcon from 'assets/icons/plus-btn.svg';
import PrevIcon from 'assets/icons/arrow-prev.svg';
import CloseIcon from 'assets/icons/close.svg';
import { getImageDimensions } from 'utils/getImageDimensions';
import { HistoryModal } from 'components/Common/Modal/HistoryModal';

import UploadAssetLibrary from 'components/Dashboard/MaterialForm/UploadAssetLibrary';
import { blobToFile } from 'utils/file';

type AtelierUploadConfig = {
  endpoint: string;
  heading: string;
  model: 'Techpack' | 'Graphics' | 'References' | 'Final';
  action: number;
};

export interface AtelierGalleryImage {
  key: string;
  name: string;
  type: string;
  original?: string;
  thumbnail?: string;
  localFile?: File;
  localUrl?: string;
  save?: boolean;
  asset?: [string, AssetMetadataProps];
  inProgress?: boolean;
  created?: number;
  progress?: number;
  imageAsFileSeed?: File;
  path?: number[];
  pathname?: string;
  ext?: string;
  print?: boolean;
  model?: string;
  model_id?: number;
  deleteModelActionHandler?: (item: AtelierGalleryImage) => void;
  renameModelActionHandler?: (item: AtelierGalleryImage) => void;
  onArchiveHandler?: (item: AtelierGalleryImage) => void;
  onUploadHandler?: () => void;
  onClickHandler?: (item: AtelierGalleryImage) => void;
}

const STEP_UPLOAD = 'upload';
const STEP_PREVIEW = 'preview';

const VIEWMODE = {
  SLIDE: 'SLIDE',
  LIST: 'LIST',
};

export interface IAtelierAdditions {
  brand_model_id: string;
  style_model_id: string;
  atelierUploadConfig: AtelierUploadConfig;
  setSteps?: (arg) => void;
  onSetStepComplete?: (arg) => void;
  onClickContinue?: () => void;
}

const AtelierAdditions: React.FC<IAtelierAdditions> = ({
  brand_model_id,
  style_model_id,
  atelierUploadConfig,
  setSteps,
  onSetStepComplete,
  onClickContinue,
}) => {
  const { user } = useContext(UserContext);
  const { idToken } = user;

  const { setDisplayToast } = useContext(NotificationsContext);

  const { endpoint, heading } = atelierUploadConfig;

  const [step, setStep] = useState(STEP_UPLOAD);

  const [viewMode, setViewMode] = useState(VIEWMODE.LIST);
  const [notApplicableToogle, setNotApplicableToggle] = useState(false);

  const [lightboxOpen, setLightboxOpen] = useState(false);
  const lightboxRef = useRef<HTMLDivElement>();

  const [slideIndex, setSlideIndex] = useState(0);
  const [slides, setSlides] = useState<AtelierGalleryImage[]>([]);
  const [expandedImage, setExpandedImage] = useState<AtelierGalleryImage>();

  const [openHistory, setOpenHistory] = useState(false);
  const [history, setHistory] = useState([]);

  const graphicNavItems = ['Graphic', 'Print'];
  const [tab, setTab] = useState(graphicNavItems[0]);

  const { data: styleUploadModels } = useSWR(
    [endpoint, idToken, style_model_id],
    (url, idToken, style) => {
      return mmAPI(url, idToken, { style });
    },
    { suspense: true }
  );

  const { data: bucket, error } = useSWR(
    ['/api/bucket/query/brand', idToken, brand_model_id],
    (url, idToken, brand) => {
      return mmAPI(url, idToken, { brand });
    },
    {
      suspense: false,
    }
  );

  const [styleUploadModel, setStyleUploadModel] = useState(null);
  useEffect(() => {
    const styleUploadModelsEffect = async () => {
      if (['Techpack', 'Graphics'].includes(atelierUploadConfig.model)) {
        const model = atelierUploadConfig.model.toLowerCase();
        if (styleUploadModels?.length >= 1) {
          setStyleUploadModel(styleUploadModels[0]);
        } else if (!styleUploadModel) {
          const name = `${atelierUploadConfig.model} - ${user?.style?.name} - ${user?.brand?.name}`;
          const response = await postModel(idToken, model, {
            brand: brand_model_id,
            style: style_model_id,
            name,
            notes: name,
            location: window.location.href,
          });
          const { data: modelData } = response.data;
          setStyleUploadModel(modelData);
        }
      } else if (user?.style) {
        setStyleUploadModel(user?.style);
      }
    };
    styleUploadModelsEffect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user?.style,
    styleUploadModel,
    styleUploadModels,
    brand_model_id,
    style_model_id,
    user.brand,
    atelierUploadConfig.model,
    idToken,
  ]);

  const { data: uploadModelReferences, mutate: mutateUploadModelReferences } = useSWR(
    styleUploadModel
      ? [
          `/api/reference/query/model`,
          idToken,
          ['Techpack', 'Graphics'].includes(atelierUploadConfig.model)
            ? atelierUploadConfig.model
            : 'Style',
          styleUploadModel.key,
        ]
      : null,
    (url, idToken, model, model_id) => {
      return mmAPI(url, idToken, { model, model_id });
    },
    { suspense: true }
  );

  const getSectionScopeHistory = async () => {
    const { data: sectionLevelHistory } = await getNotificationsByScope(
      idToken,
      ['References', 'Final'].includes(atelierUploadConfig.model)
        ? brand_model_id
        : styleUploadModel?.key
    );
    let filteredHistory;
    if (atelierUploadConfig.model === 'References') {
      filteredHistory = sectionLevelHistory?.data?.filter(h =>
        h.segments[1]?.displayText?.includes('additional reference')
      );
    } else if (atelierUploadConfig.model === 'Final') {
      filteredHistory = sectionLevelHistory?.data?.filter(h =>
        h.segments[1]?.displayText?.includes('final file')
      );
    } else {
      filteredHistory = sectionLevelHistory?.data;
    }
    setHistory(filteredHistory);
  };

  useEffect(() => {
    if (styleUploadModel) {
      getSectionScopeHistory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [styleUploadModel]);

  const getSectionLevelHistory = () => {
    getSectionScopeHistory();
    setOpenHistory(true);
  };

  const getAssetScopeHistory = async item => {
    const { data: assetLevelHistory } = await getNotificationsByScope(idToken, item?.key);
    setHistory(assetLevelHistory.data);
  };

  const getAssetLevelHistory = item => {
    getAssetScopeHistory(item);
    setOpenHistory(true);
  };

  const [referenceModels, setReferenceModels] = useState<AtelierGalleryImage[]>([]);
  const [imagesFromFiles, setImagesFromFiles] = useState<AtelierGalleryImage[]>([]);
  const [currentPath, setCurrentPath] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const uploadModelReferencesEffect = async () => {
      if (uploadModelReferences) {
        let filteredReferences;
        if (atelierUploadConfig.model === 'Final') {
          filteredReferences = uploadModelReferences.filter(item =>
            item.tags.includes('final')
          );
        } else {
          filteredReferences = uploadModelReferences.filter(
            item => item.tags.length === 0
          );
        }
        const imagesFromReferences = referenceModels.filter(r =>
          filteredReferences.some(u => u.key === r.key)
        );
        const newUploadModelReferences = filteredReferences.filter(
          r => !imagesFromReferences.some(u => u.key === r.key)
        );
        if (newUploadModelReferences.length === 0) return;
        const imagesFromUploadModelReferences = (
          await Promise.all(
            newUploadModelReferences.map(t => referenceModelGalleryTransform(t))
          )
        ).filter(r => !!r) as AtelierGalleryImage[];
        const newReferenceModels = [
          ...imagesFromReferences,
          ...imagesFromUploadModelReferences,
        ] as AtelierGalleryImage[];
        newReferenceModels.sort((a, b) => b.created - a.created);
        setReferenceModels(newReferenceModels);
        setImagesFromFiles(prev =>
          prev.filter(c => !newReferenceModels.some(r => r.key === c.key))
        );
        setStep(STEP_PREVIEW);
      }
    };
    uploadModelReferencesEffect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadModelReferences]);

  const {
    firebaseStorageUrl,
    handleFireBaseUploadFile,
    handleFireBaseUpload,
  } = useContext(UploadContext);
  const getFirebaseStorageUrl = async asset => {
    return asset && asset.path ? await firebaseStorageUrl(asset.path) : null;
  };

  const {
    setOpenDialog,
    setDialogProps,
    setAppModal,
    setOpenAppModal,
    openAppModal,
  } = useContext(ModalsContext);

  const onDeleteHandler = useCallback(
    item => {
      setDialogProps({
        dialogBody: `Deleting assets can’t be undone. Are you sure you want to delete?`,
        btnActionHandler: async () => {
          try {
            setDisplayToast({
              type: 'info',
              persist: true,
              message: `Processing Asset deletion...`,
            });

            const { data: referenceDeleted } = await deleteModel(
              idToken,
              'reference',
              item.key,
              {
                style: style_model_id,
                brand: brand_model_id,
                configModel: atelierUploadConfig.model,
                location: window.location.href,
              }
            );
            if (referenceDeleted.error) {
              setDisplayToast({ type: 'error', message: referenceDeleted.error.message });
            } else {
              setReferenceModels(prev => prev.filter(c => c.key !== item.key));
              setImagesFromFiles(prev => prev.filter(c => c.key !== item.key));

              setDisplayToast({
                type: 'success',
                persist: false,
                message: `Asset has been deleted successfully.`,
              });
            }
          } catch (error) {
            setDisplayToast({
              type: 'error',
              persist: false,
              message: `Error occured while deleting asset. ${error}`,
            });
          }
        },
      });
      setOpenDialog(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [idToken]
  );

  const onArchiveHandler = values => {
    const { type } = values;
    setDialogProps({
      dialogBody: `Are you sure you want to continue to archive ${values.name}?`,
      btnActionHandler: async () => {
        try {
          const response = await deleteModel(idToken, 'reference', values.key, {
            archive: true,
            style: style_model_id,
            brand: brand_model_id,
            configModel: atelierUploadConfig.model,
            location: window.location.href,
          });
          setReferenceModels(prev => prev.filter(c => c.key !== values.key));
          setImagesFromFiles(prev => prev.filter(c => c.key !== values.key));

          if (type === FOLDER) {
            // folder
            const folderItems = getFolderItems([...currentPath, values.key], true);
            for (const item of folderItems) {
              if (item.type === FOLDER) {
                continue;
              }

              const { name } = item;
              const xhr = new XMLHttpRequest();
              xhr.responseType = 'blob';
              xhr.onload = event => {
                const blob = xhr.response;
                const file = blobToFile(blob, name);

                const folder_path =
                  `${values.name}/` +
                  item.path
                    .slice(currentPath.length + 1)
                    .map(p => {
                      const folder = folderItems.find(f => +f.key === +p);
                      return folder.name + '/';
                    })
                    .join('');
                const ext = name.split('.').pop();
                const metadata = {
                  ext: ext,
                };

                const url = `${ASSETS_LIBRARY.archive_library.assets_storage_path}${bucket.key}/${folder_path}${name}`;
                handleFireBaseUploadFile(url, file, null, false, metadata);
              };
              xhr.open('GET', item.original);
              xhr.send();
            }
          } else {
            // file
            const { name } = values;
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = event => {
              const blob = xhr.response;
              const file = blobToFile(blob, name);

              const url = `${ASSETS_LIBRARY.archive_library.assets_storage_path}${bucket.key}/${name}`;
              const ext = name.split('.').pop();
              const metadata = {
                ext: ext,
              };
              handleFireBaseUploadFile(url, file, null, false, metadata);
            };
            xhr.open('GET', values.original);
            xhr.send();
          }

          setDisplayToast({
            type: 'success',
            persist: false,
            message: `Asset has been archived successfully.`,
          });
        } catch (error) {
          setDisplayToast({
            type: 'error',
            persist: false,
            message: `Error occured while archiving asset. ${error}`,
          });
        }
      },
    });
    setOpenDialog(true);
  };

  const onClickActivityHandler = async props => {
    try {
      await postActivityMethod(idToken, 'reference', {
        reference: props.key,
        activity: 'reference-downloaded',
        asset_type: atelierUploadConfig?.model,
        style_id: style_model_id,
        brand_id: brand_model_id,
        location: window.location.href,
      });
      LogRocket.track('reference-downloaded', {
        reference: props.key,
        user: JSON.stringify(user),
      });
    } catch (error) {
      LogRocket.captureException(error, {
        tags: {
          // additional data to be grouped as "tags"
          label: 'AtelierModelUploads: error on activity POST',
          journey: 'reference-downloaded',
          step: 'onClickActivityHandler',
        },
        extra: {
          reference: props.key,
          user: JSON.stringify(user),
        },
      });
    }
  };

  const referenceModelGalleryTransform = useCallback(
    async referenceModelVal => {
      const referenceTransformed = {
        key: referenceModelVal.key,
        type: referenceModelVal.type,
        name: referenceModelVal.name,
        created: referenceModelVal.created,
        path: referenceModelVal.path,
        deleteModelActionHandler,
        renameModelActionHandler,
        onArchiveHandler,
        onClickHandler: onClickActivityHandler,
        ext: referenceModelVal.assets[referenceModelVal.type].ext,
        print: referenceModelVal?.print || null,
      } as AtelierGalleryImage;
      try {
        if (referenceModelVal.type === 'folder') {
          referenceTransformed.inProgress = false;
        } else {
          const imgSrc = await getFirebaseStorageUrl(
            referenceModelVal.assets[referenceModelVal.type]
          );
          referenceTransformed.original = referenceTransformed.thumbnail = imgSrc;
          referenceTransformed.inProgress =
            referenceModelVal.assets[referenceModelVal.type].progress === 0;
        }
        return Promise.resolve(referenceTransformed);
      } catch (e) {
        return null;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPath]
  );

  const onLibrarySelection = assets => {
    const assetFiles = [];
    for (const asset of assets) {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = async event => {
        const blob = xhr.response;
        const file = blobToFile(blob, asset.name);
        assetFiles.push(file);
        if (assetFiles.length >= assets.length) {
          const newAtelierImages = await productAssetUploadHandler(assetFiles);
          for (const image of newAtelierImages) {
            const [_, imageMetadata] = image.asset;
            const url = imageMetadata.path;
            const customMetadata = {
              model: image.model,
              model_id: image.model_id,
            };

            handleFireBaseUpload(
              url,
              image.localFile,
              { ...imageMetadata, ...customMetadata },
              image.type
            );
          }
        }
      };
      xhr.open('GET', asset.src);
      xhr.send();
    }
  };

  const uploadPanelConfig = {
    fileType: Object.values(ASSET_TYPES)
      .reduce((extensions, types) => {
        return extensions.concat(types.extensions);
      }, [])
      .join(', '),
    multiple: true,
    disabled: notApplicableToogle,
    emptyStateFileTypes: 'File types:  DXF AAMA/ASTM and RUL .jpg .pdf .png etc',
  };

  const images = useMemo(() => {
    const allImages = [...imagesFromFiles, ...referenceModels];
    const imageKeys = allImages.map(({ key }) => key);
    const dedupedImages = allImages.filter(
      ({ key }, index) => !imageKeys.includes(key, index + 1)
    );
    return dedupedImages;
  }, [imagesFromFiles, referenceModels]);

  const getFolderItems = useCallback(
    (folderPath, all = false) => {
      return images
        .filter(r => {
          const rPath = r.path ?? [];
          if (rPath.length < folderPath.length) {
            return false;
          }
          if (!all && rPath.length !== folderPath.length) {
            return false;
          }
          for (let i = 0; i < folderPath.length; i++) {
            if (rPath[i] !== folderPath[i]) {
              return false;
            }
          }
          return true;
        })
        .filter(r => {
          if (tab === 'Graphic') {
            return r?.print !== true;
          }
          if (tab === 'Print') {
            return r?.print === true;
          }
        });
    },
    [images, tab]
  );

  const filteredImages = useMemo(() => getFolderItems(currentPath), [
    getFolderItems,
    currentPath,
  ]);

  const deleteModelActionHandler = data => {
    onDeleteHandler(data);
  };

  const renameModelActionHandler = data => {
    setAppModal(
      <CreateModelModal
        formId='Rename'
        modalHeading='Rename'
        modelName=''
        btnCloseLabel='CANCEL'
        btnActionLabel='RENAME'
        open={openAppModal}
        setOpen={setOpenAppModal}
        modelInputLabel='NEW NAME'
        btnActionHandler={async values => {
          const { name } = values;
          try {
            const { data: referenceUpdate } = await putModel(
              idToken,
              'reference',
              data.key,
              {
                name,
                brand: brand_model_id,
                style: style_model_id,
                rename: true,
                configModel: atelierUploadConfig.model,
                location: window.location.href,
              }
            );
            if (referenceUpdate.data?.error) {
              setDisplayToast({
                type: 'error',
                message: referenceUpdate.data?.error?.message,
              });
            } else {
              setDisplayToast({ type: 'success', message: `Reference name updated` });
              setReferenceModels(prev => [...prev.filter(r => r.key !== data.key)]);
              mutateUploadModelReferences();
            }
          } catch (error) {
            setDisplayToast({
              type: 'error',
              persist: false,
              message: `Error occured while renaming asset. ${error}`,
            });
          }
        }}
        closeOnSubmit={true}
      />
    );
    setOpenAppModal(true);
  };

  const onSaveAssetLibraryHandler = values => {
    const { type } = values;
    setAppModal(
      <SaveToAssetLibraryModal
        headingText='Do you want to save file to asset library'
        bucket={bucket}
        model={atelierUploadConfig.model}
        open={openAppModal}
        setOpen={setOpenAppModal}
        onSelect={({ path, sandbox }) => {
          if (type === FOLDER) {
            // folder
            const folderItems = getFolderItems([...currentPath, values.key], true);
            for (const item of folderItems) {
              if (item.type === FOLDER) {
                continue;
              }

              const { name } = item;
              const xhr = new XMLHttpRequest();
              xhr.responseType = 'blob';
              xhr.onload = event => {
                const blob = xhr.response;
                const file = blobToFile(blob, name);

                const folder_path =
                  `${values.name}/` +
                  item.path
                    .slice(currentPath.length + 1)
                    .map(p => {
                      const folder = folderItems.find(f => +f.key === +p);
                      return folder.name + '/';
                    })
                    .join('');
                const ext = name.split('.').pop();
                const metadata = {
                  ext: ext,
                };
                const url = `${ASSETS_LIBRARY.product_asset.assets_storage_path}${bucket.key}${path}/${folder_path}${name}`;
                handleFireBaseUploadFile(url, file, null, false, metadata);
                if (sandbox) {
                  const sandboxUrl = `${ASSETS_LIBRARY.product_asset.assets_storage_path}${bucket.key}/Sandbox/${folder_path}${name}`;
                  handleFireBaseUploadFile(sandboxUrl, file, null, false, metadata);
                }
              };
              xhr.open('GET', item.original);
              xhr.send();
            }
          } else {
            // file
            const { name } = values;
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = event => {
              const blob = xhr.response;
              const file = blobToFile(blob, name);

              const url = `${ASSETS_LIBRARY.product_asset.assets_storage_path}${bucket.key}${path}/${name}`;
              const ext = name.split('.').pop();
              const metadata = {
                ext: ext,
              };
              handleFireBaseUploadFile(url, file, null, false, metadata);
              if (sandbox) {
                const sandboxUrl = `${ASSETS_LIBRARY.product_asset.assets_storage_path}${bucket.key}/Sandbox/${name}`;
                handleFireBaseUploadFile(sandboxUrl, file, null, false, metadata);
              }
            };
            xhr.open('GET', values.original);
            xhr.send();
          }
        }}
      />
    );
    setOpenAppModal(true);
  };

  const editTileOptions = [
    {
      selectItem: 'Download',
      download: true,
      onClick: onClickActivityHandler,
    },
    {
      selectItem: 'Delete',
      onClick: deleteModelActionHandler,
    },
    {
      selectItem: 'Rename',
      onClick: renameModelActionHandler,
    },
    {
      selectItem: 'Archive',
      onClick: onArchiveHandler,
    },
    {
      selectItem: 'Save asset library',
      onClick: onSaveAssetLibraryHandler,
    },
    {
      selectItem: 'History',
      onClick: getAssetLevelHistory,
    },
    {
      selectItem: 'Copy Link',
      disabled: false,
      onClick: () => {
        navigator.clipboard.writeText(
          process.env.REACT_APP_PUBLIC_URL + location.pathname
        );
        setDisplayToast({
          persist: false,
          type: 'success',
          message: `Copied to clipboard.`,
        });
      },
    },
  ];

  const rendertooltipTitle = (param: string) => {
    switch (param) {
      case 'Tech Pack & Specs':
        return `Please upload tech pack & specs files we support most file types
        Supported file types: ${uploadPanelConfig.fileType}`;
      case 'Graphic & Prints':
        return `Upload graphic asset in coresponding section. Upload additonal references that didn’t include in other section
        Supported file types: ${uploadPanelConfig.fileType}`;
      case 'Additional References':
        return `Upload additonal references that didn’t include in other section 
        Supported file types: ${uploadPanelConfig.fileType}`;
      case 'Final Files':
        return `Once a Product has been Approved, collate all of the up to date and approved files associated with the Product and upload here.
        For example, the 3D file, any approved Branding, Graphics, Prints, Trims and hardware all can be uploaded at once for access at any time in the lifecycle of the Product. 
        Only upload approved files. 
        Supported file types: ${uploadPanelConfig.fileType}`;
      default:
        return '';
    }
  };

  const createFolderActionHandler = async values => {
    const params = {
      type: 'folder',
      brand: brand_model_id,
      model: ['References', 'Final'].includes(atelierUploadConfig.model)
        ? 'Style'
        : atelierUploadConfig.model,
      model_id: styleUploadModel.key,
      style: style_model_id,
      name: values.name,
      tags: atelierUploadConfig.model === 'Final' ? 'final' : null,
      path: currentPath.length > 0 ? currentPath.join(',') : null,
      pathname: '/' + currentPathname.join('/'),
      print: atelierUploadConfig.model === 'Graphics' && tab === 'Print',
      configModel: atelierUploadConfig.model,
      location: window.location.href,
    };
    await postModel(idToken, 'reference', params);
    mutateUploadModelReferences();
  };

  const onClickFolder = reference_key => {
    setCurrentPath(prev => [...prev, reference_key]);
  };

  const productAssetUploadHandler = useCallback(
    async (localFiles: File[]) => {
      let isInvalidType = false;
      const fileAssociations = localFiles.map(file => {
        const { type } = getAssetTypeFromFileType(file) || {};
        if (!type) {
          setDisplayToast({
            type: 'error',
            persist: false,
            message: `${file.name} is not a supported file type.`,
          });
          isInvalidType = true;
        }
        return {
          type: isInvalidType ? null : type,
          name: file.name,
        };
      });

      if (isInvalidType) return null;

      const params = {
        types: fileAssociations.map(f => f.type).join(','),
        brand: brand_model_id,
        model: ['References', 'Final'].includes(atelierUploadConfig.model)
          ? 'Style'
          : atelierUploadConfig.model,
        model_id: styleUploadModel.key,
        style: style_model_id,
        names: fileAssociations.map(f => f.name).join(','),
        tags: atelierUploadConfig.model === 'Final' ? 'final' : null,
        path: currentPath.length > 0 ? currentPath.join(',') : null,
        print: atelierUploadConfig.model === 'Graphics' && tab === 'Print',
        configModel: atelierUploadConfig.model,
        location: window.location.href,
      };

      const response = await postModels(idToken, 'reference', params);
      const graphicReferenceData = response?.data?.data;
      const atelierGalleryImages = localFiles.map<AtelierGalleryImage>((file, index) => {
        const { type } = getAssetTypeFromFileType(file) || {};
        const imgSrc = URL.createObjectURL(file);
        return {
          type,
          name: file.name,
          original: imgSrc,
          thumbnail: imgSrc,
          localFile: file,
          localUrl: imgSrc,
          save: true,
          asset: [type, graphicReferenceData[index]?.assets[type]],
          progress: -1,
          imageAsFileSeed: file,
          key: graphicReferenceData[index].key,
          path: currentPath,
          created: moment().unix(),
          print: graphicReferenceData[index]?.print || null,
          model: graphicReferenceData[index]?.model,
          model_id: graphicReferenceData[index]?.model_id,
          deleteModelActionHandler,
          renameModelActionHandler,
          onArchiveHandler,
          onUploadHandler: () => {
            setSteps(prev => {
              return prev.map(p =>
                p.slug === atelierUploadConfig.model.toLowerCase() && !p.completed
                  ? { ...p, completed: true }
                  : { ...p }
              );
            });
            onSetStepComplete({
              [`status_${atelierUploadConfig.model.toLowerCase()}`]: true,
              action: atelierUploadConfig.action,
            });
            mutateUploadModelReferences();
          },
          onClickHandler: onClickActivityHandler,
        };
      });
      setImagesFromFiles(prev => [...atelierGalleryImages, ...prev]);
      return atelierGalleryImages;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      idToken,
      tab,
      currentPath,
      styleUploadModel?.key,
      brand_model_id,
      style_model_id,
      atelierUploadConfig.model,
      atelierUploadConfig.action,
    ]
  );

  const pages = useMemo(() => {
    const pagesArray = [];
    let link = '/';
    for (let i = 0; i < currentPath.length; i += 1) {
      const key = currentPath[i];
      const reference = images.find(r => +r.key === +key);
      link += `/${reference.name}`;
      const page: IPages = {
        title: reference.name,
      };
      if (i < currentPath.length - 1) {
        page.link = link;
        page.onClick = () => setCurrentPath(currentPath.slice(0, i + 1));
      }
      pagesArray.push(page);
    }
    if (currentPath.length > 0) {
      pagesArray.push({
        title: '<',
        link: '/',
        onClick: () => setCurrentPath(currentPath.slice(0, currentPath.length - 1)),
      });
    }
    return pagesArray;
  }, [currentPath, images]);

  const currentPathname = currentPath.map(key => images.find(r => +r.key === +key).name);

  const popUpOptions: IPopUpOptions[] = [
    {
      selectItem: 'Add new files',
      onClick: () => {
        setAppModal(
          <AssetLibraryFilesUpload
            prefferedFileTypes={uploadPanelConfig.emptyStateFileTypes}
            isProductAssetUpload={true}
            open={openAppModal}
            setOpen={setOpenAppModal}
            onProductAssetUploadHandler={productAssetUploadHandler}
          />
        );
        setOpenAppModal(true);
      },
    },
    {
      selectItem: 'Add from library',
      onClick: () => {
        setAppModal(
          <UploadAssetLibrary
            headingText='Asset Library'
            bucket={bucket}
            open={openAppModal}
            setOpen={setOpenAppModal}
            onSelect={onLibrarySelection}
            multiple={true}
          />
        );
        setOpenAppModal(true);
      },
    },
    {
      selectItem: 'Create folder',
      onClick: () => {
        setAppModal(
          <CreateModelModal
            formId='createFolder'
            modelName='Folder'
            modelInputLabel='Name'
            modalHeading='Create'
            btnActionLabel='Create'
            open={openAppModal}
            setOpen={setOpenAppModal}
            btnActionHandler={createFolderActionHandler}
            closeOnClickAway={false}
            closeOnSubmit
          />
        );
        setOpenAppModal(true);
      },
    },
  ];

  const setOriginal = item => {
    setExpandedImage(item);
    setSlideIndex(slides.findIndex(slide => slide.key === item.key));
    setLightboxOpen(!lightboxOpen);
  };

  useEffect(() => {
    if (filteredImages.length === 0) return;
    setSlides(filteredImages.filter(s => s.type !== 'folder'));
  }, [filteredImages]);

  const [imageUrlZoomed, setImageUrlZoomed] = useState(null);

  const srcToFileObjectUrl = (name, src) => {
    if (!src) return null;
    return new Promise(function (resolve, reject) {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = event => {
        const blob = xhr.response;
        const file = blobToFile(blob, name);
        const fileObjectUrl = URL.createObjectURL(file);
        resolve(fileObjectUrl);
      };
      xhr.open('GET', src);
      xhr.send();
    });
  };

  useEffect(() => {
    const imageZoomedUrlEffect = async slide => {
      const extension = slide?.ext || slide?.name?.split('.').pop();
      if (slide) {
        const imageUrlFromAsset = slides.find(
          item => parseInt(item.key) === parseInt(slide.key)
        )?.original;

        if (ASSET_TYPES.image.extensions.includes(extension)) {
          const { height, width, aspectRatio } = await getImageDimensions(
            imageUrlFromAsset
          );

          const fileObjectUrlZoomed = await srcToFileObjectUrl(
            slide.name,
            imageUrlFromAsset
          );

          setImageUrlZoomed({
            slideKey: slide.key,
            src: fileObjectUrlZoomed,
            width,
            height,
          });
        }
        setExpandedImage(slides[slideIndex]);
      }
    };
    imageZoomedUrlEffect(slides[slideIndex]);
  }, [slideIndex, slides]);

  const [iconFile, setIcon] = useState('');
  const [loadIcons, setLoadIcons] = useState(true);

  useEffect(() => {
    const extension =
      slides[slideIndex]?.ext || slides[slideIndex]?.name.split('.').pop();
    if (loadIcons && !ASSET_TYPES.image.extensions.includes(extension)) {
      loadIcon(extension);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadIcons, slideIndex]);

  const loadIcon = async (iconName: string) => {
    try {
      const importedIcon = await import(
        `assets/icons/${iconName.toLocaleUpperCase()}.svg`
      );
      setIcon(importedIcon.default);
    } catch (error) {
      const importedIcon = await import(`assets/icons/Icon-picture.svg`);
      setIcon(importedIcon.default);
    }
  };

  return (
    <div className='additional-reference'>
      <div className='additional-reference__heading'>
        <div className='additional-reference__heading-wrapper'>
          {heading}
          <div className='additional-reference__heading-icons'>
            <img
              className='additional-reference__history'
              src={HistoryIcon}
              alt='history'
              onClick={() => getSectionLevelHistory()}
            />
            <Tooltip tooltipText={rendertooltipTitle(heading)} />
            {viewMode === VIEWMODE.SLIDE ? (
              <img
                className='additional-reference__viewmode'
                style={{ cursor: step === STEP_PREVIEW ? 'pointer' : 'not-allowed' }}
                src={SlideIcon}
                alt='Rail'
                onClick={() => setViewMode(VIEWMODE.LIST)}
              />
            ) : (
              <img
                className='additional-reference__viewmode'
                style={{ cursor: step === STEP_PREVIEW ? 'pointer' : 'not-allowed' }}
                src={ListIconBlack}
                alt='List'
                onClick={() => setViewMode(VIEWMODE.SLIDE)}
              />
            )}
            <img
              src={PlusIcon}
              alt='Plus'
              className='additional-reference__add'
              style={{ cursor: step === STEP_PREVIEW ? 'pointer' : 'not-allowed' }}
              onClick={() => setOpen(!open)}
            />
            <PopUp open={open} setOpen={setOpen}>
              {popUpOptions &&
                popUpOptions.map(({ selectItem, to, onClick, ModalComponent }) => (
                  <li
                    className='pop-up__li'
                    onClick={() => {
                      setOpen(false);
                      if (ModalComponent) {
                        // eslint-disable-next-line no-console
                        console.log('handlePopUpModal ModalComponent');
                      } else if (onClick) {
                        onClick();
                      }
                    }}
                    key={selectItem}
                  >
                    {to ? <Link to={to}>{selectItem}</Link> : selectItem}
                  </li>
                ))}
            </PopUp>
          </div>
        </div>
      </div>
      {heading === 'Graphic & Prints' && (
        <DashboardNav
          dashboardNavItems={graphicNavItems.map(item => ({
            title: item,
            activeBtn: item === tab,
          }))}
          darkMode={false}
          changeTab={value => setTab(value)}
        />
      )}
      <div className='additional-reference__folder-path'>
        <Breadcrumbs pages={pages} />
      </div>
      <div className='additional-reference__body'>
        {viewMode === VIEWMODE.SLIDE ? (
          <AtelierAdditionsPreview
            images={filteredImages.filter(s => s.type !== 'folder')}
            setOriginal={setOriginal}
            getAssetLevelHistory={getAssetLevelHistory}
          />
        ) : (
          <AtelierModelUploadsListView
            media={filteredImages}
            editTileOptions={editTileOptions}
            onClickRow={onClickFolder}
          />
        )}
      </div>
      <div className='additional-reference__actions'>
        {onClickContinue && (
          <button className='button-primary' onClick={onClickContinue}>
            Continue
          </button>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '0 0 24px 40px',
        }}
      >
        <Switch label={'not applicable'} onSwitchChange={setNotApplicableToggle} />
        {step === STEP_UPLOAD && (
          <div className='additional-reference__actions'>
            {filteredImages.length > 0 && (
              <button
                className='button-transparent-black mr-2'
                onClick={() => setStep(STEP_PREVIEW)}
              >
                View Uploads
              </button>
            )}
          </div>
        )}
      </div>
      <HistoryModal
        history={history}
        openHistory={openHistory}
        setOpenHistory={setOpenHistory}
      />
      <CSSTransition classNames='lightbox' timeout={200} in={lightboxOpen} unmountOnExit>
        <div className='product-listing-dashboard__light-box'>
          <div className='product-listing-dashboard__lightbox-header'>
            {`${expandedImage && expandedImage?.name}`}
            <div className='product-listing-dashboard__lightbox-buttons-wrap'>
              <div className='product-listing-dashboard__lightbox-arrows'>
                <img
                  className='product-listing-dashboard__main-prev'
                  src={PrevIcon}
                  alt='Previous'
                  onClick={() =>
                    setSlideIndex(slideIndex === 0 ? slides.length - 1 : slideIndex - 1)
                  }
                />
                <img
                  className='product-listing-dashboard__main-next'
                  src={PrevIcon}
                  alt='Next'
                  onClick={() =>
                    setSlideIndex(slideIndex === slides.length - 1 ? 0 : slideIndex + 1)
                  }
                />
              </div>
              <img
                className='cursor-pointer'
                src={CloseIcon}
                alt='Close'
                onClick={() => {
                  setLightboxOpen(!lightboxOpen);
                }}
              />
            </div>
          </div>
          <div className='product-listing-dashboard__lightbox-inner' ref={lightboxRef}>
            <TransitionGroup component={null}>
              <CSSTransition
                classNames='fade-in'
                timeout={200}
                key={slides?.[slideIndex]?.name}
              >
                <TransformWrapper
                  initialScale={0.9}
                  centerOnInit={true}
                  minScale={0.2}
                  limitToBounds={false}
                  wheel={{
                    step: 0.1,
                  }}
                  doubleClick={{
                    mode: 'reset',
                  }}
                  initialPositionX={
                    lightboxRef?.current?.offsetWidth / 2 - imageUrlZoomed?.width / 2
                  }
                  initialPositionY={0}
                >
                  <TransformComponent>
                    <img
                      src={
                        ASSET_TYPES.image.extensions.includes(expandedImage?.ext)
                          ? slides?.[slideIndex]?.original || imageUrlZoomed?.src
                          : iconFile
                      }
                      alt={slides?.[slideIndex]?.name}
                    />
                  </TransformComponent>
                </TransformWrapper>
              </CSSTransition>
            </TransitionGroup>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default AtelierAdditions;
